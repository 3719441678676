import React from 'react';
import styled from 'styled-components';
import { GlobalStyle, Screen } from '../../styles';
import { Loader } from '../../assets/svg';



const LoaderContainer = styled.div`
  position: fixed;
  top: 38%;
  left: 56%;
  z-index: 99;
  ${Screen.largePhone`
    top: 40%;
    left: 44%;
  `};
`;
const LoaderWrapper = styled.div`
  height: 20%;
  width: 20%;
`;
const dashboardLoader = ({ color }) => (
  <>
    <GlobalStyle />
    <LoaderContainer>
      <LoaderWrapper>
        <Loader color={color} />
      </LoaderWrapper>
    </LoaderContainer>
  </>
);

export default dashboardLoader;
