import Personal from './personal';
import Business from './business';
import Loan from './loan';
import About from './about';
import Story from './story';
import Sustainability from './sustainability';
import Careers from './career';
import Relations from './relations';
import Help from './help';
import Landing from './landing';
import Ways from './ways';
import Whistle from './whistle';
import Construction from './construction';
import Policy from './policy';
import Terms from './terms';
import Media from './media';
import Blog from './blog';
import Security from './security'
import Agm from './agm'
import Egm from './egm'
import Calculator from './calculator'


const ports = {
  Personal,
  Business,
  Loan,
  About,
  Story,
  Sustainability,
  Careers,
  Relations,
  Help,
  Landing,
  Ways,
  Whistle,
  Construction,
  Policy,
  Terms,
  Security,
  Media,
  Blog,
  Agm,
  Egm,
  Calculator
};

export default ports;
