import { 
    MEETING_FAILURE, 
    MEETING_REQUEST, 
    MEETING_SUCCESS
} from "../constants"



export const egmReducer = (state, action) => {
    switch(action.type){
        case MEETING_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case MEETING_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case MEETING_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const agmReducer = (state, action) => {
    switch(action.type){
        case MEETING_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case MEETING_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case MEETING_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}