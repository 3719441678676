const api = {
  req: async (url, method = 'GET', body = null) => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin':'localhost:3000',
          // 'X-Frame-Options': 'SAMEORIGIN'
        },
      };

      if (body) options.body = JSON.stringify(body);

      const response = await fetch(url, options);
      const data = await response.json();
      // console.log(data)
      return data;
    } catch (e) {
      console.log(e);
      return e.response
        ? e.response.data
        : {
            status: 'fail',
            responseCode: '01',
            responseMessage: 'Network Error',
          };
    }
  },
};

export default api;
