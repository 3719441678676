import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, Christabel, InvestorMobile, Jewel, NonsoImg, Obinna } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const App = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                  <Header>Jewel Okwechime</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                </div>
                {/* <Image src={''} alt='chika' /> */}
                <Image src={Jewel} alt='jewel' />
                <Paragraph>
                <p>
                Ms. Jewel Okwechime holds a BTech degree from Halton College, Cheshire, England 
and M.Eng in Chemical and Bio-Process Engineering from the University of Surrey, 
Guilford, England. Her core competencies include Environmental Engineering, Project 
Management, Risk Management, Sustainability Business Development and 
Performance Optimization. She was Senior Environmental Consultant at Atkins Global, 
Warrington, UK, Senior Environmental Adviser, Worldwide Energy, Western Australia, 
Director, J&R Environmental Services, London, UK. She is the Managing Director, Deltic
Africa.
                  </p>
                  {/* <p>
                    He is a Chartered Accountant. He obtained his BSc in Marketing from the 
                    University of Nigeria. He started his career with KPMG as Senior Auditor 
                    between 2004 to 2008. He joined BGL PLC as Special Assistant to the ED, Capital 
                    Markets between 2008 to 2010 where he was till he moved to Heirs Holdings Limited 
                    as CFO between 2010 to 2015.
                  </p> */}
                </Paragraph>
              </FlexBox>
            ): (
              <FlexBox>
              {/* <Image src={''} alt='chika' /> */}
              <Image src={Jewel} alt='jewel' />
                <Content>
                  <Header>Jewel Okwechime</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                  <p style={{ paddingTop: '2rem'}}>
                  Ms. Jewel Okwechime holds a BTech degree from Halton College, Cheshire, England 
and M.Eng in Chemical and Bio-Process Engineering from the University of Surrey, 
Guilford, England. Her core competencies include Environmental Engineering, Project 
Management, Risk Management, Sustainability Business Development and 
Performance Optimization. She was Senior Environmental Consultant at Atkins Global, 
Warrington, UK, Senior Environmental Adviser, Worldwide Energy, Western Australia, 
Director, J&R Environmental Services, London, UK. She is the Managing Director, Deltic
Africa.
                  </p>
                  {/* <p>
                    He is a Chartered Accountant. He obtained his BSc in Marketing from the 
                    University of Nigeria. He started his career with KPMG as Senior Auditor 
                    between 2004 to 2008. He joined BGL PLC as Special Assistant to the ED, Capital 
                    Markets between 2008 to 2010 where he was till he moved to Heirs Holdings Limited 
                    as CFO between 2010 to 2015.
                  </p> */}
                </Content>
              </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }

  ${Screen.mediumpc`
  min-height: 20vh;
  `}
  ${Screen.smallpc`
  min-height: 50vh;
  `}
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 7rem;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 5rem;
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;
${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;
  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
    ${Screen.mediumPhone`
    font-size: 14px!important;
    `}
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;

const Card = styled.div`
  width: 30%;
  height: 350px;
  background-color: #d9d9d9;
`;
export default App;
