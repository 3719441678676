import { useState } from 'react'
import styled from 'styled-components'
import { Decorate, Screen } from '../../styles';
import { HelpersComponent } from "../../components"
// import { Footer } from '../../components/footer';
import api from '../../services/api';
import { Tools } from '../../utils'


const Loader = HelpersComponent.componentLoader
const Alert = HelpersComponent.Alert
const Calculator = () => {
    const [ userData, setUserData ] = useState({
        loanAmount: '',
        downPayment: '',
        interestRate: '',
        duration: '',
        repaymentPeriod: ''
    })
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState("")
    const [alertHeader, setAlertHeader] = useState("")

    const { loanAmount, downPayment, interestRate, duration, repaymentPeriod } = userData
    const validation = Tools.validateInput([loanAmount, downPayment, interestRate, duration, repaymentPeriod])
    const r = {
        onChange(e) {
            setUserData({
                ...userData,
                [e.target.name]: e.target.value
            })
        },

        async handleCalculator() {
            try {

                const rate = Number(interestRate) / 100
                const body = {
                    loanAmount,
                    downPayment,
                    interestRate: rate,
                    duration,
                    repaymentPeriod
                }
                const result = await api.req('/api/document/calculator', 'POST', body)
                return result
            } catch (error) {
                
            }
        },

        async onSubmit(e) {
            e.preventDefault()
            try {
                setLoading(true)
                const data = await r.handleCalculator()
                if(data.status === "success"){
                    setShowAlert(true)
                    setAlertHeader("Repayment Plan")
                    setAlertContent(`You will pay ${data.details.paymentPlan} ${data.details.repaymentPeriod}`)
                    setUserData({
                        loanAmount: '',
                        downPayment: '',
                        interestRate: '',
                        duration: '',
                        repaymentPeriod: ''
                    })
                } else {
                  setShowAlert(true)
                  setAlertHeader("")
                  setAlertContent(data.responseMessage)
                }
                setLoading(false) 
            } catch (error) {
                
            }
        },

        async handleKeyPress(e) {
            if (e.key === 'Enter') {
                r.onSubmit(e);
            }
        },

        closeModal(){
            setShowAlert(false)
        }
    }
  return (
    <AppContainer>
        {/* <NavigationBar.Navigation /> */}
        <Wrapper>
            <Header>Mortgage Calculator</Header>
            <FormContainer onSubmit={(e) => r.onSubmit(e)}>
                <FieldWrapper>
                <label>Total Amount (&#8358;)</label>
                <input
                    name='loanAmount'
                    placeholder='Enter total amount e.g 10000, 50000, etc'
                    onChange={(e) => r.onChange(e)}
                    value={loanAmount}
                    onKeyDown={(e) => r.handleKeyPress(e)}
                 /><br />
                <em>Total amount for your mortgage</em>
                </FieldWrapper>
                <FieldWrapper>
                <label>Down Payment (&#8358;)</label>
                <input
                    name='downPayment'
                    placeholder='Enter down payment e.g 10000, 50000, etc'
                    onChange={(e) => r.onChange(e)}
                    value={downPayment}
                    onKeyDown={(e) => r.handleKeyPress(e)}

                /><br />
                <em>Initial payment to be deposited for your mortgage processing</em>
                </FieldWrapper>
                <FieldWrapper>
                <label>Interest Rate (%)</label>
                <input
                    name='interestRate' 
                    placeholder='Enter interest rate e.g 5, 10, etc'
                    onChange={(e) => r.onChange(e)}
                    value={interestRate}
                    onKeyDown={(e) => r.handleKeyPress(e)}
                /><br />
                <em>The annual fixed interest rate for this mortgage</em>
                </FieldWrapper>
                <FieldWrapper>
                <label>Select Payment Period</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='repaymentPeriod'
                    onChange={(e) => r.onChange(e)}
                    value={repaymentPeriod}
                    onKeyDown={(e) => r.handleKeyPress(e)}
                  >
                    <option></option>
                    <option value={'monthly'}>Monthly</option>
                    <option value={'quarterly'}>Quarterly</option>
                    <option value={'yearly'}>Yearly</option>
                  </select>
                </Select>
                <em>The intervals at which repayment will be made</em>
                </FieldWrapper>
                <FieldWrapper>
                <label>Duration ({repaymentPeriod === 'monthly' ? 'In months' : 'In years'})</label>
                <input
                    name='duration' 
                    placeholder='Enter duration e.g 1, 2, etc'
                    onChange={(e) => r.onChange(e)}
                    value={duration}
                    onKeyDown={(e) => r.handleKeyPress(e)}
                /><br />
                <em>The length of time it would take you to pay off a mortgage in full</em>
                </FieldWrapper>
            </FormContainer>
                <ButtonWrapper>
                    {
                        loading 
                        ? <Button className='loading'><Loader color={'#fff'} /></Button>
                        : <Button 
                        className={
                          validation ? 'disabled' : undefined
                        } onClick={(e) => r.onSubmit(e)}>Calculate</Button>
                    }
                </ButtonWrapper>
        </Wrapper>
        {/* <Footer /> */}
        { showAlert && <Alert header={alertHeader} content={alertContent} r={r}  />}
    </AppContainer>
  )
}

const AppContainer = styled.div`
width: 100%;
`;

const Wrapper = styled.div`
width: 400px;
height: auto;
background-color: #F0F0F0;
margin: 7rem auto 5rem;
padding: 3rem 2rem;
border-radius: 5px;

${Screen.mediumPhone`
width: 90%;
`}
${Screen.smallPhone`
width: 95%;
`}

/* &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 20px;
} */
`;

const Header = styled.h4`
text-align: center;
color: #002668;
`;

const FormContainer = styled.form`
  width: 100%;
  height: auto;
  margin-top: 3rem;
  /* background-color: white; */

  h1 {
    font-size: 13px;
    font-weight: 800;
    font-family: 'Roboto Flex', sans-serif;
    align-self: left;
    color: #0040a4;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  label {
    font-size: 14px;
    font-weight: bold;
    color: #002668;
    margin: 0;
    /* text-transform: uppercase; */

  }

  em {
    font-size: 12px;
  }

  input {
    width: 100%;
    margin-top: 10px;
    /* margin-bottom: 2px; */
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Select = styled.div`
  /* width: 100%; */
  ${Decorate.select}

  .select-css {
    border-color: #dbd9d9;
    color: #9a9494;
    background-color: #fff;
  }

  .select-css option {
    font-size: 15px;
  }
`;

const FieldWrapper = styled.div`
margin-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div``
// const Button = styled.div`
//   display: flex;
//   height: 3rem;
//   margin-top: 30px;
//   padding: 15px 30px;
//   border-radius: 5px;
//   color: white;
//   outline: none;
//   background-color: #002668;
//   font-size: 14px;
//   font-weight: bold;
//   cursor: pointer;
//   justify-content: center;

//   &.disabled {
//     pointer-events: none;
//     background-color: #ddd;
//     color: #002668;
//   }
// `;


const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #002668;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  font-weight: bold;

  &.disabled {
    pointer-events: none;
    background-color: #ccc;
    color: #002668;
  }

  &.loading {
    height: 50px;
    width: 30px;
  }
`;

export default Calculator