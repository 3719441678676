import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import { RelationComponents } from '../../components'
import { Screen } from '../../styles';
import { AboutLeadership } from '../../assets/img';
import { Footer } from '../../components/footer';
import { AgmContext } from '../../contexts/meeting';
import { MEETING_FAILURE, MEETING_REQUEST, MEETING_SUCCESS } from '../../constants';
import api from '../../services/api';

// const Navigation = NavigationBar.Navigation;
const Notice = RelationComponents.Meeting;

const Meeting = () => {
    const [ mobile, setMobile ] = useState(false)
    const { dispatch, state } = useContext(AgmContext)
    const mounted = useRef()

    useEffect(() => {
        window.scrollTo(0, 0);
        const responsive = () => {
            return window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
        }
        responsive()
        window.addEventListener('resize', () => responsive())

        return () => {
            window.removeEventListener('resize', () => responsive())
        }
    }, [])

    // Get meeting link
    useEffect(() => {
      mounted.current = true
      const getMeetingLink = async () => {
        try {
          if(state.data.length < 1){
            dispatch({ type: MEETING_REQUEST})
            const result = await api.req('/api/document/agm-link')
            dispatch({
              type: MEETING_SUCCESS,
              payload: JSON.parse(result.details.data)
            })
          }
        } catch (error) {
          dispatch({
            type: MEETING_FAILURE,
            payload: error.response && error.response.result.responseMessage 
            ? error.response.result.responseMessage : error.responseMessage
          })
          
        }
      }
      getMeetingLink()

      return () => {
        mounted.current = false
      }
    }, [])


  return (
    <Box>
        {/* nav */}
        {/* <Navigation /> */}
        {/* End of nav */}

        {/* Hero Section */}
        <Section className='hero'></Section>
        {/* End of Hero Section */}

        {/* Content */}
        <Section>
              {
                mobile ? (
                    // <Container>
                    <>
                    <Header>Notice of Meeting</Header>
                    <Notice  />
                {/* </Container> */}
    
                    <Header>Live Coverage</Header>
                    <VideoCard>
                      <iframe width="100%" height="100%" src={state?.data[0]?.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </VideoCard>
                    
                    </>
    
                ):(
                    <Container>
                    <Header className='notice'>Notice of Meeting</Header>
                    <Notice />
    
                    <Header>Live Coverage</Header>
                    <VideoCard>
                    <iframe
                      title="Annual General Meeting"
                      style={{ border: 'none', height: '100%', width: '100%' }}
                      src="https://africaprudential.zoom.us/webinar/register/WN_n-SWLEYAQ9qvjHgwIc-0oA"
                      sandbox="allow-forms allow-scripts allow-same-origin"
                      allow="microphone; camera"
                      allowFullScreen
                      />
                    </VideoCard>

                    {/* <VideoCard>
                      <iframe width="100%" height="100%" src={state?.data[0]?.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </VideoCard> */}
    
                </Container>
                )
            }
        </Section>
        {/* End of Content */}

        {/* Footer */}
        {/* <Footer /> */}
        {/* End of Footer */}

    </Box>
  )
}

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;

const Section = styled.div`
  width: 100%;
  /* min-height: ; */
  padding-left: 10rem;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    padding-left: 0;
    ${Screen.mediumpc`
    min-height: 20vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }

  ${Screen.tablet`
    padding-left: 0
  `}
  ${Screen.phone`
    padding-left: 0
  `}
  ${Screen.smallPhone`
    padding-left: 0
  `}
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  padding-top: 7rem;
  ${Screen.pad`
  font-size: 1.5rem
  `}
  ${Screen.mediumpc`
  font-size: 1.5rem;
  `}
  ${Screen.smallpc`
  font-size: 1.5rem;
  padding-left: 0;
  padding-top: 5rem
  `}
  ${Screen.tablet`
  font-size: 1.5rem;
  padding-left: 5rem;
  padding-top: 5rem
  `}
  ${Screen.surfaceDuo`
  font-size: 1.5rem;
  padding-left: 2rem;
  padding-top: 5rem
  `}
  ${Screen.mediumPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}

  /* &.notice {
    ${Screen.tablet`
  margin-top: 10rem
  `}
  } */
`;

const Container = styled.div`
width: 80%;
${Screen.smallPhone`
  width: 100%
  `}
`;

const VideoCard = styled.div`
  width: 100%;
  height: 500px;

  ${Screen.miniLargeScreen`
  height: 400px;
  width: 90%;
  `}
  ${Screen.pad`
  height: 400px;
  `}
  ${Screen.mediumpc`
  height: 300px;
  `}
  ${Screen.smallpc`
  height: 300px;
  `}
  ${Screen.ipadAir`
  height: 300px;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto;
  `}
   /* ${Screen.miniTablet`
  margin-left: 0;
  `} */
   ${Screen.surfaceDuo`
      width: 80%!important;
  height: 300px;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
   width: 100%!important;
  height: 300px;
  margin: 0!important;
  `}
  ${Screen.phone`
   width: 100%!important;
  height: 300px;
  margin: 0!important;
  `}
  ${Screen.smallPhone`
   width: 100%!important;
  height: 300px;
  margin: 0!important;
  `}

${Screen.galaxyFold`
  width: 100%!important;
  height: 300px;
  margin: 0!important;
  `}

  iframe {
    margin-top: 2rem
  }
`;
export default Meeting