import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { Screen } from '../../styles'

const modal = ({ showModal, cookieButton }) => {
  return (
    <Modal display={showModal ? 'block' : 'none'}>
      <ModalCard>
        <ModalContent>
          <h2>We use cookies 🍪</h2>
          <p>
          We use cookies to ensure you have the best browsing experience on our website.
          By using our site, you acknowledge that you have read understood our <br />
          <TextLink to={'/privacy-policy#cookies'}>Cookies Policy</TextLink> &amp; <TextLink to={'/privacy-policy'}>Privacy Policy</TextLink>
          </p>
        </ModalContent>
        <Button onClick={cookieButton}>Accept</Button>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  display: ${({ display }) => display};
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  ${Screen.pad`
  padding-top: 50px;
  `}
  ${Screen.surfaceDuo`
  padding-top: 10px;
  `}
  ${Screen.mediumPhone`
  padding-top: 10px;
  `}
  ${Screen.phone`
  padding-top: 10px;
  `}
  ${Screen.smallPhone`
  padding-top: 10px;
  `}
`;
const ModalCard = styled.div`
  width: 550px;
  height: 350px;
  margin: 10rem auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 5rem 2rem 2rem;

  ${Screen.surfaceDuo`
  width: 400px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 350px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.phone`
  width: 300px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  width: 300px;
  height: auto;
  padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.galaxyFold`
  width: 270px;
  height: auto;
  padding-top: 2rem .5rem 1rem;
  `}
`;
const ModalContent = styled.div`
  text-align: center;
  h2 {
    color: #c81e19;
    font-size: 2rem;
    padding-bottom: 1rem;

    ${Screen.mediumPhone`
    font-size: 1.5rem;
    `}
    ${Screen.phone`
    font-size: 1.5rem;
    `}
    ${Screen.smallPhone`
    font-size: 1.5rem;
    `}
    ${Screen.galaxyFold`
    font-size: 1.3rem;
    `}
  }

  p {
    color: #002668;
    ${Screen.mediumPhone`
     text-align: start;
     word-spacing: .1rem;
    `}
    ${Screen.phone`
     text-align: start;
     word-spacing: .1rem;
    `}
    ${Screen.smallPhone`
     text-align: start;
     word-spacing: .1rem;
    `}
     ${Screen.galaxyFold`
     text-align: start;
     word-spacing: 0;
    `}
  }
`;

const TextLink = styled(HashLink)`
color: #c81e19;
text-decoration: none;
font-weight: bold;
`;
const Button = styled.div`
height: 3rem;
width: max-content;
margin-top: 3rem;
margin-bottom: 2rem;
background-color: #002668;
box-shadow: 5px 3px 5px #ccc;
color: #fff!important;
border-radius: 5px;
font-size: 1.2rem;
font-weight: bold;
padding: .7rem 2rem;
cursor: pointer;

${Screen.mediumPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
${Screen.phone`
padding: .7rem 1rem;
font-size: 1rem;
`}
${Screen.smallPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
`;
export default modal;
