import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Screen } from '../../styles'

const Information = () => {
    const link = localStorage.getItem('doc-link')
    const doc = [{ uri: link, fileName: 'AMB Information Security Policy' }]

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <Box>
        <DocViewer documents={doc} pluginRenderers={DocViewerRenderers} />
    </Box>
  )
}

export default Information

const Box = styled.div`
#pdf-download {
    display: none;
}

width: 75%;
height: 700px;
margin: 7rem auto;
border: 2px solid black;
${Screen.miniLargeScreen`
width: 80%;
margin: 5rem auto;
`}
${Screen.pad`
width: 90%;
margin: 5rem auto;
`}
${Screen.mediumpc`
width: 95%;
margin: 7rem auto;
`}
`