import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Decorate, NavButton } from '../../../styles';
import stateCountry from 'state-country';
import { Plus } from '../../../assets/img';

const StepThree = ({ next, prev, values, handleChange }) => {
  const validation =
    values.surname === '' ||
    values.firstname === '' ||
    values.middlename === '' ||
    values.gender === '' ||
    values.marital === '' ||
    values.ownerAddress1 === '' ||
    values.ownerCountry === '' ||
    values.ownerState === '' ||
    values.ownerAddress1 === '';
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [checked, setChecked] = useState(false);
  const [signatory, setSignatory] = useState(false);
  const checkValue = () => {
    setChecked(!checked);
  };
  const addSignatory = () => {
    setSignatory(!signatory);
  };

  useEffect(() => {
    const getCountries = () => {
      setCountries(stateCountry.getAllCountries());
    };
    const getStates = () => {
      setStates(stateCountry.getAllStatesInCountry(values.country));
    };
    getCountries();
    getStates();
  }, [values]);

  // List of countries
  const listOfCountries =
    countries && countries.length
      ? countries.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;

  // List of states
  const listOfStates =
    states && states.length
      ? states.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;
  return (
    <FormContainer>
      <FormHeader>Step 3 of 5: Personal Details of Signatory</FormHeader>
      <Form autoComplete='off'>
        <FlexBox>
          <label>Surname</label>
          <Input>
            <input
              type={'text'}
              name='surname'
              placeholder='Enter surname here'
              onChange={handleChange('surname')}
              value={values.surname}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Firstname</label>
          <Input>
            <input
              type={'text'}
              name='firstname'
              placeholder='Enter firstname here'
              onChange={handleChange('firstname')}
              value={values.firstname}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Middle name</label>
          <Input>
            <input
              type={'text'}
              name='middlename'
              placeholder='Enter middlename here'
              onChange={handleChange('middlename')}
              value={values.middlename}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Gender</label>
          <Select>
            <select
              className='select-css'
              name='gender'
              onChange={handleChange('gender')}
              value={values.gender}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Male'}>Male</option>
              <option value={'Female'}>Female</option>
            </select>
          </Select>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Marital Status</label>
          <Select>
            <select
              className='select-css'
              name='marital'
              onChange={handleChange('marital')}
              value={values.marital}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Single'}>Single</option>
              <option value={'Engaged'}>Engaged</option>
              <option value={'Married'}>Married</option>
              <option value={'Divorced'}>Divorced</option>
              <option value={'Widowed'}>Widowed</option>
            </select>
          </Select>
        </FlexBox>
        <FlexBox mt='3rem'>
          <label>Residential Address</label>
          <InputContainer>
            <Input className='group'>
              <input
                type={'text'}
                name='ownerAddress1'
                placeholder='Residential address here'
                onChange={handleChange('ownerAddress1')}
                value={values.ownerAddress1}
              />
            </Input>

            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='ownerAddress2'
                placeholder='Address line #2'
                onChange={handleChange('ownerAddress2')}
                value={values.ownerAddress2}
              />
            </Input>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='ownerCountry'
                onChange={handleChange('ownerCountry')}
                value={values.ownerCountry}
              >
                <option value='' disabled hidden>
                  Choose Country
                </option>
                <option></option>
                {listOfCountries}
              </select>
            </Select>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='ownerState'
                value={values.ownerState}
                onChange={handleChange('ownerState')}
              >
                <option value='' disabled hidden>
                  Choose State
                </option>
                <option></option>
                {listOfStates}
              </select>
            </Select>
            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='ownerZip'
                placeholder='Zip/Area Code'
                onChange={handleChange('ownerZip')}
                value={values.ownerZip}
              />
            </Input>
            <div className='checkbox'>
              <input
                type={'checkbox'}
                readOnly
                checked={checked}
                onClick={checkValue}
              />
              <label>
                My residential address is the same as my mailing address
              </label>
            </div>
          </InputContainer>
        </FlexBox>
        {!checked && (
          <FlexBox>
            <label style={{ paddingTop: '2rem' }}>Mailing Address</label>
            <InputContainer mt='2rem'>
              <Input className='group'>
                <input
                  type={'text'}
                  name='ownerMailingAddress1'
                  placeholder='Residential address here'
                  onChange={handleChange('ownerMailingAddress1')}
                  value={values.ownerMailingAddress1}
                />
              </Input>
              <Input className='group' mt='2rem'>
                <input
                  type={'text'}
                  name='ownerMailingAddress2'
                  placeholder='Address line #2'
                  onChange={handleChange('ownerMailingAddress2')}
                  value={values.ownerMailingAddress2}
                />
              </Input>
              <br />
              <Select className='group'>
                <select
                  className='select-css'
                  name='ownerMailingCountry'
                  onChange={handleChange('ownerMailingCountry')}
                  value={values.ownerMailingCountry}
                >
                  <option value='' disabled hidden>
                    Choose Country
                  </option>
                  <option></option>
                  {listOfCountries}
                </select>
              </Select>
              <br />
              <Select className='group'>
                <select
                  className='select-css'
                  name='ownerMailingState'
                  value={values.ownerMailingState}
                  onChange={handleChange('ownerMailingState')}
                >
                  <option value='' disabled hidden>
                    Choose State
                  </option>
                  <option></option>
                  {listOfStates}
                </select>
              </Select>
              <Input className='group' mt='2rem'>
                <input
                  type={'text'}
                  name='ownerMailingZip'
                  placeholder='Zip/Area Code'
                  onChange={handleChange('ownerMailingZip')}
                  value={values.ownerMailingZip}
                />
              </Input>
            </InputContainer>
          </FlexBox>
        )}
        <FlexBox mt='2rem'>
          <label>Home Number</label>
          <Input>
            <input
              type={'text'}
              name='homeNumber'
              onChange={handleChange('homeNumber')}
              value={values.homeNumber}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Education Level</label>
          <Input>
            <input
              type={'text'}
              name='education'
              onChange={handleChange('education')}
              value={values.education}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>School/Institution</label>
          <Input>
            <input
              type={'text'}
              name='school'
              onChange={handleChange('school')}
              value={values.school}
            />
          </Input>
        </FlexBox>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2rem',
            cursor: 'pointer',
          }}
          onClick={addSignatory}
        >
          <Image src={Plus} alt='icon' />
          <p
            style={{
              paddingTop: '.5rem',
              fontWeight: 'bold',
              color: '#002668',
              textDecoration: 'underline',
              paddingLeft: '.1rem',
            }}
          >
            Add Signatory
          </p>
        </div>
        {signatory && (
          <>
            <FlexBox mt='2rem'>
              <label>Surname</label>
              <Input>
                <input
                  type={'text'}
                  name='surname'
                  placeholder='Enter surname here'
                  onChange={handleChange('surname')}
                  value={values.surname}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Firstname</label>
              <Input>
                <input
                  type={'text'}
                  name='firstname'
                  placeholder='Enter firstname here'
                  onChange={handleChange('firstname')}
                  value={values.firstname}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Middle name</label>
              <Input>
                <input
                  type={'text'}
                  name='middlename'
                  placeholder='Enter middlename here'
                  onChange={handleChange('middlename')}
                  value={values.middlename}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Gender</label>
              <Select>
                <select
                  className='select-css'
                  name='gender'
                  onChange={handleChange('gender')}
                  value={values.gender}
                >
                  <option value='' disabled hidden>
                    Select
                  </option>
                  <option></option>
                  <option value={'Male'}>Male</option>
                  <option value={'Female'}>Female</option>
                </select>
              </Select>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Marital Status</label>
              <Select>
                <select
                  className='select-css'
                  name='marital'
                  onChange={handleChange('marital')}
                  value={values.marital}
                >
                  <option value='' disabled hidden>
                    Select
                  </option>
                  <option></option>
                  <option value={'Single'}>Single</option>
                  <option value={'Engaged'}>Engaged</option>
                  <option value={'Married'}>Married</option>
                  <option value={'Divorced'}>Divorced</option>
                  <option value={'Widowed'}>Widowed</option>
                </select>
              </Select>
            </FlexBox>
            <FlexBox mt='3rem'>
              <label>Residential Address</label>
              <InputContainer>
                <Input className='group'>
                  <input
                    type={'text'}
                    name='ownerAddress1'
                    placeholder='Residential address here'
                    onChange={handleChange('ownerAddress1')}
                    value={values.ownerAddress1}
                  />
                </Input>

                <Input className='group' mt='2rem'>
                  <input
                    type={'text'}
                    name='ownerAddress2'
                    placeholder='Address line #2'
                    onChange={handleChange('ownerAddress2')}
                    value={values.ownerAddress2}
                  />
                </Input>
                <br />
                <Select className='group'>
                  <select
                    className='select-css'
                    name='ownerCountry'
                    onChange={handleChange('ownerCountry')}
                    value={values.ownerCountry}
                  >
                    <option value='' disabled hidden>
                      Choose Country
                    </option>
                    <option></option>
                    {listOfCountries}
                  </select>
                </Select>
                <br />
                <Select className='group'>
                  <select
                    className='select-css'
                    name='ownerState'
                    value={values.ownerState}
                    onChange={handleChange('ownerState')}
                  >
                    <option value='' disabled hidden>
                      Choose State
                    </option>
                    <option></option>
                    {listOfStates}
                  </select>
                </Select>
                <Input className='group' mt='2rem'>
                  <input
                    type={'text'}
                    name='ownerZip'
                    placeholder='Zip/Area Code'
                    onChange={handleChange('ownerZip')}
                    value={values.ownerZip}
                  />
                </Input>
                <div className='checkbox'>
                  <input
                    type={'checkbox'}
                    readOnly
                    checked={checked}
                    onClick={checkValue}
                  />
                  <label>
                    My residential address is the same as my mailing address
                  </label>
                </div>
              </InputContainer>
            </FlexBox>
            {!checked && (
              <FlexBox>
                <label style={{ paddingTop: '2rem' }}>Mailing Address</label>
                <InputContainer mt='2rem'>
                  <Input className='group'>
                    <input
                      type={'text'}
                      name='ownerMailingAddress1'
                      placeholder='Residential address here'
                      onChange={handleChange('ownerMailingAddress1')}
                      value={values.ownerMailingAddress1}
                    />
                  </Input>
                  <Input className='group' mt='2rem'>
                    <input
                      type={'text'}
                      name='ownerMailingAddress2'
                      placeholder='Address line #2'
                      onChange={handleChange('ownerMailingAddress2')}
                      value={values.ownerMailingAddress2}
                    />
                  </Input>
                  <br />
                  <Select className='group'>
                    <select
                      className='select-css'
                      name='ownerMailingCountry'
                      onChange={handleChange('ownerMailingCountry')}
                      value={values.ownerMailingCountry}
                    >
                      <option value='' disabled hidden>
                        Choose Country
                      </option>
                      <option></option>
                      {listOfCountries}
                    </select>
                  </Select>
                  <br />
                  <Select className='group'>
                    <select
                      className='select-css'
                      name='ownerMailingState'
                      value={values.ownerMailingState}
                      onChange={handleChange('ownerMailingState')}
                    >
                      <option value='' disabled hidden>
                        Choose State
                      </option>
                      <option></option>
                      {listOfStates}
                    </select>
                  </Select>
                  <Input className='group' mt='2rem'>
                    <input
                      type={'text'}
                      name='ownerMailingZip'
                      placeholder='Zip/Area Code'
                      onChange={handleChange('ownerMailingZip')}
                      value={values.ownerMailingZip}
                    />
                  </Input>
                </InputContainer>
              </FlexBox>
            )}
            <FlexBox mt='2rem'>
              <label>Home Number</label>
              <Input>
                <input
                  type={'text'}
                  name='homeNumber'
                  onChange={handleChange('homeNumber')}
                  value={values.homeNumber}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Education Level</label>
              <Input>
                <input
                  type={'text'}
                  name='education'
                  onChange={handleChange('education')}
                  value={values.education}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>School/Institution</label>
              <Input>
                <input
                  type={'text'}
                  name='school'
                  onChange={handleChange('school')}
                  value={values.school}
                />
              </Input>
            </FlexBox>
          </>
        )}
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
const Select = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  ${Decorate.select};
  .select-css {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
  }
  &.group {
    width: 100%;
    margin-left: -0.1rem;
  }
`;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  &.group {
    width: 100%;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
  }
`;
const InputContainer = styled.div`
  width: 50%;
  margin-top: ${({ mt }) => mt};
  .checkbox {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    label {
      white-space: nowrap;
      font-size: 0.9rem;
      padding-left: 1rem;
    }

    input {
      transform: scale(2);
      margin-top: 0.5rem;
      cursor: pointer;
      border-radius: 0;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;

  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const Image = styled.img`
  width: 2rem;
  height: 2rem;
`;
export default StepThree;
