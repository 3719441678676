import { Close, Plus } from '../../assets/img';

export const helpLinks = [
  {
    name: 'Debit Cards',
  },
  {
    name: 'Personal Banking',
  },
  {
    name: 'Business Banking',
  },
  {
    name: 'Mortgage Banking',
  },
  {
    name: 'Abbey Mobile',
  },
];

// Abbey mobile
export const abbeyMobileQuestions = [
  {
    open: Plus,
    close: Close,
    text: 'What is AbbeyMobile?',
    subItem: [
      {
        content: `AbbeyMobile is a mobile payment application 
          offering a convenient, secure, reliable, and speedy digital 
          banking experience. Apart from being easy to navigate its 
          fund management features, you can apply for a mortgage to 
          start the process of home ownership from the app. It is the 
          financial app of your choice.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What do I need to Sign up?',
    subItem: [
      {
        content: `A smart device running on IOS or Android operating 
          services. With AbbeyMobile App you do not need to have an 
          account with Abbey Mortgage App you enjoy seamless transactions 
          on this app. You can sign up with your BVN or an existing bank 
          account with any bank and a new account would be created for you.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What are the features/services available on the app?',
    subItem: [
      {
        text: 'AbbeyMobile allows you to: ',
      },
      {
        text: 'Open Abbey Bank Accounts',
      },
      {
        text: 'Access your Abbey Accounts',
      },
      {
        text: 'Send Money to any Abbey Bank account',
      },
      {
        text: 'Send Money to other banks',
      },
      {
        text: 'Airtime and Bills Payments',
      },
      {
        text: 'Request Mortgage Loans',
      },
      {
        text: 'View transaction history, monthly trend, and request for account',
      },
      {
        text: 'View transaction history, monthly trend, and request for account statements',
      },
      {
        text: 'Open Save to Own Account.',
      },
      {
        text: 'Fixed deposit and Investment',
      },
      {
        text: 'Hide/show account balance',
      },
      {
        text: 'Manage Beneficiaries',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How do I register on AbbeyMobile?',
    subItem: [
      {
        text: 'There are two options to register as: ',
      },
      {
        text: 'New Customer',
      },
      {
        text: 'Exisitng Abbey customer',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'To register on AbbeyMobile as a New Customer?',
    subItem: [
      {
        text: 'Download and Install the AbbeyMobile app',
      },
      {
        text: 'Click on “Create Account” to register.',
      },
      {
        text: 'Select New Customer',
      },
      {
        text: 'Enter your phone number and date of birth (Referral code is optional).',
      },
      {
        text: 'Select either BVN or Bank Account (Bank account number with any other bank)',
      },
      {
        text: 'Enter your BVN Number or existing account number with any bank.',
      },
      {
        text: 'Accept Terms and Conditions.',
      },
      {
        text: 'An OTP would be sent to the mobile number linked to the BVN/Account Number inputted',
      },
      {
        text: 'Accept Terms and Conditions.',
      },
      {
        text: 'Enter OTP',
      },
      {
        text: 'Enter email address and create your preferred password.',
      },
      {
        text: 'Capture your Face ID and signature',
      },
      {
        text: 'Create a transaction PIN.',
      },
      {
        text: 'Registration is complete',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'To register on AbbeyMobile as an Existing Customer?',
    subItem: [
      {
        text: 'Download and Install the AbbeyMobile app',
      },
      {
        text: 'Click on “Create Account” to register.',
      },
      {
        text: 'Click on “Existing Account”.',
      },
      {
        text: 'Enter Abbey account number and Accept Terms and Conditions.',
      },
      {
        text: 'An OTP would be sent via SMS to the registered phone number.',
      },
      {
        text: 'Enter OTP',
      },
      {
        text: 'Enter email address and create your preferred password.',
      },
      {
        text: 'Capture your Face ID and signature',
      },
      {
        text: 'Create a transaction PIN.',
      },
      {
        text: 'Registration is complete',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is the mobile app secure?',
    subItem: [
      {
        content: `Yes, the Mobile App is secure. Firstly, AbbeyMobile enables 
          the use of Fingerprint and Face ID for secured authentication if enabled 
          on your mobile device. It is also username and password protected as it 
          requires theses details to login to the app. Secondly, transactions on 
          the Mobile App are validated by a transaction PIN which is known only to you.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Do I need an Abbey Mortgage Bank Account to transact on the app?',
    subItem: [
      {
        content: `You can enjoy seamless transaction on AbbeyMobile 
          without having an existing Abbey Account. A new account can easily 
          be created by using New Customers Registration steps.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can I access all my Accounts with this App?',
    subItem: [
      {
        content: `Yes, you can access all accounts linked to your customer ID 
          and you can also transact from any of them.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Will I be charged for this service?',
    subItem: [
      {
        content: `Abbey Mortgage Bank does not charge for using the Mobile App, 
          however standard transaction charges apply for transfers and other bills 
          payments.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What is the difference between transaction PIN and Password?',
    subItem: [
      {
        content: `Password is used to login for access to different activities on 
          the mobile app while transaction PIN is used to authenticate transactions 
          on the app (bills payment, transfers etc.). Both the password and PIN are 
          created at the sign-up process of the app.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can I login with another phone that is not my own device?',
    subItem: [
      {
        text: `Yes. Though each Mobile App is tied to a device, 
          you can however login on another device by following the 
          authentication procedure below:`,
      },
      {
        text: 'Input the login details (username and password).',
      },
      {
        text: 'Enter PIN to validate the details provided.',
      },
      {
        text: 'An OTP would be sent to your BVN registered mobile number.',
      },
      {
        text: `Input the OTP sent, and the device would be unlocked, 
          then you can proceed to login on the changed device.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How can I change my PIN?',
    subItem: [
      {
        text: `Login to the app and navigate to the Menu page,`,
      },
      {
        text: 'Select Change PIN',
      },
      {
        text: 'Input your Old PIN,',
      },
      {
        text: 'Input your new preferred PIN and re-input for confirmation.',
      },
      {
        text: `Your PIN would be successfully updated.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What happens if I forget my transaction PIN?',
    subItem: [
      {
        text: `Login to the app and navigate to the Menu page,`,
      },
      {
        text: 'Select Reset PIN,',
      },
      {
        text: 'Capture Face ID',
      },
      {
        text: 'Input your new preferred PIN and re-input for confirmation.',
      },
      {
        text: `Your New PIN would be created successfully.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can Abbey Mortgage Bank App be used outside Nigeria?',
    subItem: [
      {
        content: 'Yes',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can my daily limit be increased?',
    subItem: [
      {
        content: `Yes, it can be increased to a maximum of N1M upon submission of an 
          Indemnity for Limit Increase on your account.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What do I do if I forgot my login details?',
    subItem: [
      {
        content: `You can click on the forgot password button on the app 
          to carry out a self-password reset. A new password would be sent 
          to your registered email. You are advised to change your password 
          to your preferred password upon successfully login to the app.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How long will it take beneficiary to receive transfers initiated from AbbeyMobile?',
    subItem: [
      {
        content: 'Beneficiary receives value instantly',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can I view and share my transaction receipts to a third party from my AbbeyMobile app?',
    subItem: [
      {
        content: `Yes. You can view and share your transaction receipts to a third party
          on WhatsApp, Instagram, email etc.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can I hide my account balance on the app?',
    subItem: [
      {
        content: `Yes, on the account dashboard, 
          you can click on the toggle button to either hide or show account balance.`,
      },
    ],
  },
];
// End of Abbey Mobile

// Mortgage Banking
export const mortgageBankingQuestions = [
  {
    open: Plus,
    close: Close,
    text: 'What is Mortgage?',
    subItem: [
      {
        content: `A mortgage is a loan from a financial institution 
        that helps a borrower purchase a home. The collateral for 
        the mortgage is the home itself. That means if the borrower 
        doesn’t make monthly payments to the lender and defaults on 
        the loan, the lender can sell the home and recoup its money.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What security do I use to access a mortgage facility?',
    subItem: [
      {
        content: `Property with registered title or a perfectible title`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What is the maximum loan amount?',
    subItem: [
      {
        content: '80% of FSV of collateral subject to income/cash flow',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can Abbey finance properties across the country?',
    subItem: [
      {
        content: 'No, only in urban areas preferably Lagos and Abuja',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Will an applicant be required to make an equity contribution when applying for a loan?',
    subItem: [
      {
        content:
          'Yes, applicants will be required to make a minimum equity contribution of 30%',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Meaning of qualifying Income?',
    subItem: [
      {
        content: 'This is simply your net monthly income divided by 3.',
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What can Disqualify my Application?',
    subItem: [
      {
        content: `Negative credit check, encumbered property, 
        bad legal search report, High debt service ratio, 
        insufficient cash flow for repayment.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Who is Eligible to Access our Mortgage Product?',
    subItem: [
      {
        content: `Salaried employees, Self Employed individuals, 
        Business Owners, Affluent Professionals, High Net worth 
        Individuals, cooperatives etc`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How do I know how much I can Access?',
    subItem: [
      {
        content: `Mortgage payments are based on your income, 
        the loan interest rate, tenor of the loan, age of borrower.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What tenor can I get?',
    subItem: [
      {
        content: `Maximum of 10 years`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What Insurance do I need?',
    subItem: [
      {
        content: `Mortgage protection against life and 
        fire/special peril against property`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What does the Insurance cover?',
    subItem: [
      {
        content: `Mortgage protection insurance to cover against 
        the risk of death. Fire/special insurance to protect 
        against destruction of the property.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What is the LTV Ratio?',
    subItem: [
      {
        content: `LTV stands for loan to value and the ratio is the 
        amount of the mortgage expressed as a percentage of the 
        property's value. The lower the LTV, the greater the equity 
        in the property. Our maximum LTV is 70%`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is there a provision for self-employed individuals to participate?',
    subItem: [
      {
        content: `YES, self employed individuals can apply for 
        Abbey Loans as long as there is verifiable and sufficient 
        income to cover repayments, not excluding acceptable 
        security.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is it compulsory for an applicant to have an existing account with AMB before applying for a loan?',
    subItem: [
      {
        content: `Yes, every applicant is required to have or open 
        an account with AMB before applying for a loan facility`,
      },
    ],
  },
];
// End of Mortgage Banking

// Business Banking
export const businessBankingQuestions = [
  {
    open: Plus,
    close: Close,
    text: 'Can Business account holders download Abbey’s mobile app?',
    subItem: [
      {
        content: `Yes they can, If it is just a sole signatory account`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can Business account holders have access to internet banking and a soft token?',
    subItem: [
      {
        content: `Yes they can (without a soft token), but at the moment, access to Abbey internet banking is ON HOLD.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can instructions on the account be sent via email? Is it mandatory that a signed instruction be sent for each transaction?',
    subItem: [
      {
        content: `All transfer instructions should be signed based on the mandate. Any deviation must therefore 
        require Management’s approval and a clear instruction that indemnifies the bank`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can a business account holder have access to an overdraft for the business?',
    subItem: [
      {
        content: `All facilities granted by the bank must be within the Regulatory and the Bank’s Credit Policy 
        Guidelines`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is it possible to get a loan to buy a property for the business?',
    subItem: [
      {
        content: `If a company meets all the required mortgage criteria and a careful due diligence is conducted, 
          then they can get a facility.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What is the maximum loan amount a business can borrow?',
    subItem: [
      {
        content: `A business can borrow up to 20% of the Bank’s Share Holder’s Funds, 
        if they meet all criteria including capacity to pay back, etc.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Will there be charges on the account if there are no withdrawals but only lodgements?',
    subItem: [
      {
        content: `Charges on the accounts are usually based on the transactions done. 
        SMS fees will be charged when there are both withdrawals and lodgements on the accounts.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What are the various charges on the corporate/business account?',
    subItem: [
      {
        content: `Current accounts are charged for Current Account Maintenance (CAM) fee, in addition to SMS and 
        transfer charges whenever transfers are carried out on the account`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is SCUML compulsory for all business accounts?',
    subItem: [
      {
        content: `Not all businesses require SCUML! It is a compulsory requirement for account opening, only for 
        accounts that are enlisted as provided by regulators.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'If SCUML registration is INCOMPLETE. can the account be operated by the signatories?',
    subItem: [
      {
        content: `Any account with any outstanding document will be placed on PND/restriction until the outstanding 
        is provided or there is Management approval for a deferral`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can a third party or company representative be allowed free access to the company’s account information?',
    subItem: [
      {
        content: `A company must formally introduce any third party who will act as representative for their bank 
        transactions`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can Abbey’s cheques be presented through clearing?',
    subItem: [
      {
        content: `Yes, Abbey’s cheques can be presented through clearing`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What criteria qualifies for a suitable reference?',
    subItem: [
      {
        text: `For a reference to be suitable, it must meet the following criteria:`,
      },
      {
        text: `Must be a current and not a savings corporate account`,
      },
      {
        text: `Must have been actively operated in the last 6 months`,
      },
      {
        text: `Must not be a loan account`,
      },
      {
        text: `A company account cannot be referenced by a personal account holder`,
      },
      {
        text: `The signatories must not be closely related with the account 
        holder by same surname`,
      },
      {
        text: `The referenced account must have satisfactory references`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is there a minimum balance required on the corporate account?',
    subItem: [
      {
        content: `Not necessarily! The corporate account is not expected 
        to be on zero balance at any time`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is there a maximum limit or volume the account balance must not exceed?',
    subItem: [
      {
        content: `There is no limit to the volume/balance allowed in a business 
        account, but there are regulatory reporting expectations for certain 
        volumes of transactions.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How soon can transfer instructions be treated and how fast can the beneficiary get value?',
    subItem: [
      {
        content: `The application used is NIP which is INSTANT transfer. 
        The beneficiary is therefore expected to get IMMEDIATE value.`,
      },
    ],
  },
];
// End of Business Banking

// Personal Banking
export const personalBankingQuestions = [
  {
    open: Plus,
    close: Close,
    text: 'What is the interest on savings accounts in Abbey?',
    subItem: [
      {
        content: `Our Savings interest rate is currently 4.2% 
        per annum compound interest rate. This is, however, subject to Monetary Policy 
        Rate (MPR)`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Will I still enjoy interest if I withdraw as often as I want?',
    subItem: [
      {
        content: `No! To enjoy the Interest for the month, the number of withdrawals 
        must not exceed the number allowed by regulation`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What is the maximum number of withdrawals that can be made before one stops enjoying savings interest',
    subItem: [
      {
        content: `After 4 withdrawals in a month, interest will be forfeited`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Are there any charges on a personal account (Savings or Current)?',
    subItem: [
      {
        content: `Savings accounts are charged for SMS alerts and transfers when 
        transactions are done. Current accounts are charged CAM fee (Current Account 
          Maintenance), in addition to SMS and transfer charges whenever transfers are 
          carried out on the account`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What else can be used as recent utility bill if there are no LAWMA, PHCN or water bills for the address provided?',
    subItem: [
      {
        text: `Other documents that can pass as Utility bill includes recent customer’s 
        Bank statement, where the address on the statement is same as current address, 
        (Subject to address verification to confirm authenticity)`,
      },
      {
        text: `Tenancy agreement and Title documents in customer’s name`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How will KYC be conducted for addresses in the states where Abbey does not have presence?',
    subItem: [
      {
        content: `There is currently a KYC portal that takes care of address verification.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What are the criteria for a suitable reference?',
    subItem: [
      {
        text: `For a reference to be suitable, it must meet the following criteria:`,
      },
      {
        text: `Must be a current and not a savings account`,
      },
      {
        text: `Must have been actively operated in the last 6 months`,
      },
      {
        text: `Must not be a loan account`,
      },
      {
        text: `Must not be a salary account`,
      },
      {
        text: `A company account cannot be referenced by a personal account holder`,
      },
      {
        text: `Must not be closely related with the account holder by same surname`,
      },
      {
        text: `The referenced account must have satisfactory references`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can a savings account be dormant and What can make an account go dormant?',
    subItem: [
      {
        content: `Savings accounts are no longer expected to go dormant`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'For how long can an account be operated before there can have access to loan?',
    subItem: [
      {
        content: `Ideally, a customer is expected to operate an account for a 
        minimum of 6 months before access to loan if he meets all other conditions. 
        However, if a customer opens his accounts, meets all KYC and account opening 
        requirements, he may be considered for a loan after due diligence is conducted 
        on him without waiting for the 6 months period.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can a savings account holder access a loan/facility?',
    subItem: [
      {
        content: `No! For a savings account holder to access a loan/facility, 
        he is required to provide two satisfactory references, after which the 
        account will be converted to a current account`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can a salary earner use his salary as collateral to access loans?',
    subItem: [
      {
        content: `Special loans customized for salary earners may require that they 
        domicile their salaries as collateral`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'For children accounts, how can the child have access when he is of age (18 years)?',
    subItem: [
      {
        text: `The child is expected to provide his valid means of ID and BVN to access 
        his account, after which he will be included as signatory on the mandate`,
      },
      {
        text: `The account can also be converted to a savings account with the matured 
        child as signatory`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Is there a limit to the volume/balance allowed in a personal account?',
    subItem: [
      {
        text: `Tiers 1 and 2 accounts have a limited balance allowable in the account based on the minimal KYC information/documents required to be provided by the customers.`,
      },
      {
        text: `Tier 3 has no limit to the volume/balance allowed, but there are regulatory reporting expectations for certain volumes of transactions.`,
      },
    ],
  },
];
// End of Personal Banking

// Debit Card
export const debitCardQuestions = [
  {
    open: Plus,
    close: Close,
    text: 'How do I order a debit card?',
    subItem: [
      {
        text: `You can order for your Abbey Debit card from the following ways:`,
      },
      {
        text: `On our website - Access our website at www.abbeymortgagebankplc.com to 
        place a request for a new or replacement of your debit card`,
      },
      {
        text: `On your mobile device - If you're on a mobile device, Log in to the 
        mobile app to order a new or replacement card. 
        `,
      },
      {
        text: `Our Branches - Visit any of our branch closest to you to place your 
        request for a debit card`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How do I activate my debit card?',
    subItem: [
      {
        text: `You can activate your Abbey Debit card by exploring any of the following option:`,
      },
      {
        text: `Visit any of our branch closest to you for the activation of your card.`,
      },
      {
        text: `Login to your registered mobile banking app to activate your card.`,
      },
      {
        text: `You can activate your card from your registered number on our USSD 
        application.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Where can I use my debit card?',
    subItem: [
      {
        content: `You can use Abbey Debit card anywhere in the country to make 
        purchases at POS location, cash withdrawal from ATM as well as for online 
        payments.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How do I dispute a transaction on my debit card?',
    subItem: [
      {
        content: `You can walk into any of our branch to dispute any failed transaction 
        or call our customer experience Team on 01………….`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'What should I do if my debit card is lost or stolen or if I notice fraudulent transaction?',
    subItem: [
      {
        content: `Please call our customer experience team immediately on 01……. 
        You can also block your account with our USSD panic code by dialling *901*911# 
        on any phone`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Can I keep track of my debit card transactions?',
    subItem: [
      {
        content: `Yes. You can track your purchases and daily balance through our 
        mobile banking app. Plus, your monthly checking account statement will 
        detail all your debit card transactions, including where and when each 
        purchase was made.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'Are there any transaction fees for using my debit card?',
    subItem: [
      {
        content: `Abbey Mortgage Bank doesn't charge transaction fees when you use 
        your debit card to make purchases, but some merchants may impose a transaction 
        fee or surcharge. Also, a fee may be charged for web purchases and ATM cash 
        withdrawals.`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: 'How do I change my debit card PIN or request a new PIN?',
    subItem: [
      {
        content: `To change your PIN, visit any ATM location and select PIN change 
        from the operations menu. To request for a new PIN, kindly visit any of branch 
        closest to you or call or customer experience Team on 01…………`,
      },
    ],
  },
  {
    open: Plus,
    close: Close,
    text: `What should I do if my ATM or debit card PIN isn't working?`,
    subItem: [
      {
        content: `If your PIN isn't working and you're certain you're using the 
        correct PIN and entering it properly, contact us immediately by calling 
        our customer experience on 01……….`,
      },
    ],
  },
];
// End of Debit Card
