import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { 
  mainLinksReducer,
  sectionLinksReducer,
  mobileNavReducer
} = Reducers
export const MainLinkContext = createContext()
export const SectionLinkContext = createContext()
export const MobileNavContext = createContext()

const initialState = {
    loading: false,
    data: [],
    error: ''  
}

// Main Link
export const MainLinksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(mainLinksReducer, initialState)
  return (
    <MainLinkContext.Provider value={{ state, dispatch }}>
        {children}
    </MainLinkContext.Provider>
  )
}

// Section Link
export const SectionLinksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sectionLinksReducer, initialState)
  return (
    <SectionLinkContext.Provider value={{ state, dispatch }}>
        {children}
    </SectionLinkContext.Provider>
  )
}

// Mobile Nav
export const MobileNavContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mobileNavReducer, initialState)
return (
  <MobileNavContext.Provider value={{ state, dispatch }}>
      {children}
  </MobileNavContext.Provider>
)
}


// export default FooterLinks