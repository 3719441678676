import React, { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  MediaImg, MediaMobile,
} from '../../assets/img';
import { HelpersComponent, SliderComponent } from '../../components';
// import {  mediaItem } from '../../components/main';
// import { Footer } from '../../components/footer';
import { GalleryContext } from '../../contexts/gallery'
import { SLIDER_FAILURE, SLIDER_REQUEST, SLIDER_SUCCESS } from '../../constants';
import api from '../../services/api';
import dayjs from 'dayjs'
import { Link } from 'react-router-dom';
import { Screen } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const { ImageGallery } = SliderComponent
const ComponentLoader = HelpersComponent.dashboardLoader
const Media = () => {
  const { dispatch, state } = useContext(GalleryContext)
  const [youtube, setYoutube] = useState([])
  const mount = useRef()

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    // get sliders data
    const galleryImages = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: SLIDER_REQUEST })
          const result = await api.req('/api/document/gallery')
          dispatch({
            type: SLIDER_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        } 
      } catch (error) {
        dispatch({
          type: SLIDER_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        })
      }
    }

    //get youtube videos
    const getYoutubeVideos = async () => {
      const url = JSON.parse(localStorage.getItem('youtube'))
      try {
        const data = await api.req(url[0].link)
        let newObject;
        const data2 = data.items.map(obj => {
          if(obj.snippet.resourceId.kind === 'youtube#video'){
            newObject = {
              link: `https://www.youtube.com/embed/${obj.snippet.resourceId.videoId}`,
              title: obj.snippet.title,
              date: obj.snippet.publishedAt,
              watch: `https://www.youtube.com/watch?v=${obj.snippet.resourceId.videoId}` 
            }
          }
          return newObject;
        })
        setYoutube(data2)
      } catch (error) {
        // console.log(error)
      }
    }


    useEffect(() => {
      mount.current = true;
      galleryImages()
      getYoutubeVideos()
      return () => {
        mount.current = false;
      }
    }, [])

  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
      <Section className='hero'>
        <Container className='hero'>
          <Glass>
            <Content className='glass'>
              <h2>Media</h2>
              <p>
                Keep up with money management tips and investment opportunties
                as we explore diverse finance-related topics.
              </p>
            </Content>
          </Glass>
        </Container>
      </Section>

      {/* Watch */}
      <Section className='watch'>
        <Container className='watch'>
          <Header>
            Watch and <span>Learn</span>
          </Header>
          <CardContainer>
            {
              youtube.slice(0, 3).map((item, i) => (
                <>
                {
                  item !== undefined && (
                <Card key={i}>
                  <VideoCard>
                  <iframe width="100%" height="100%" src={item.link} title="video" frameBorder="0" allowFullScreen></iframe>
                  </VideoCard>
                  <VideoFooter>
                    <VideoLink to={item.watch} target='_blank' rel='noreferrer' style={{ textDecoration: 'none'}}>
                      <h4>{item.title}</h4>
                    </VideoLink>
                  <span>{dayjs(item.date).format("dddd, MMMM D, YYYY h:mm A")}</span>
                  </VideoFooter>
                </Card>

                  )
                }
                </>
              ))
            }
          </CardContainer>
        </Container>
      </Section>
      {/* End of Watch */}

      {/* Gallery */}
      <Section className='gallery'>
        <Container className='gallery'>
          <Header className='gallery'>Gallery</Header>
          <ImageGallery state={state.data} />
        </Container>
      </Section>
      {/* End of Gallery */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 100vh;
    background-image: url(${MediaImg});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 15rem;
    ${Screen.pad`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding-top: 5rem;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.watch {
    min-height: 100vh;
    padding-top: 5rem;
    ${Screen.miniLargeScreen`
    min-height: 90vh;
    `}
    ${Screen.pad`
    min-height: 110vh;
    `}
    ${Screen.mediumpc`
    min-height: 75vh;
    `}
    ${Screen.surfaceDuo`
    min-height: 100vh;
    `}
    ${Screen.smallpc`
    min-height: 90vh;
    `}
    ${Screen.tablet`
    min-height: 100vh;
    `}
  }

  &.gallery {
    min-height: 90vh;
    ${Screen.pad`
    min-height: 140vh
    `}
    ${Screen.mediumpc`
    min-height: 75vh
    `}
    ${Screen.smallpc`
    min-height: 50vh
    `}
    ${Screen.tablet`
    min-height: 60vh
    `}
    ${Screen.surfaceDuo`
    min-height: 80vh
    `}
    ${Screen.mediumPhone`
    min-height: 75vh;
    padding-top: 3rem;
    `}
    ${Screen.phone`
    min-height: 120vh;
    padding-top: 3rem;
    `}
    ${Screen.smallPhone`
    min-height: 120vh;
    padding-top: 3rem;
    `}
    ${Screen.galaxyFold`
    min-height: 120vh
    `}
  }
`;
const Container = styled.div`
  margin: 0 auto;
  &.hero {
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
     ${Screen.tablet`
    padding-top: 0;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  &.watch,
  &.gallery {
    width: 80%;
  }
  &.watch {
    margin-bottom: 0;
    ${Screen.pad`
    width: 90%;
    `}
    ${Screen.mediumpc`
    width: 80%;
    `}
    ${Screen.smallpc`
    width: 80%;
    `}
     ${Screen.tablet`
    width: 80%;
    `}
    ${Screen.surfaceDuo`
    width: 90%;
    `}
    ${Screen.smallPhone`
    width: 90%;
    `}
  }
  
  &.gallery {
    ${Screen.pad`
    width: 90%;
    `}
    ${Screen.mediumpc`
    width: 80%;
    `}
    ${Screen.smallpc`
    width: 80%;
    `}
    ${Screen.surfaceDuo`
    width: 90%;
    `}
    ${Screen.smallPhone`
    width: 90%;
    `}
  }
`;
const Glass = styled.div`
  width: 540px;
  height: 250px;
  background: rgba(255, 255, 255, 0.6);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const Content = styled.div`
  &.glass {
    padding: 0 2rem;
    ${Screen.miniLargeScreen`
  padding-right: 1rem;
  `}
  ${Screen.surfaceDuo`
  padding: 0 1rem;
  text-align: center;
  `}
    h2 {
      padding-top: 3rem;
      font-size: 45px;
      color: #002668;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
    }

    p {
      text-shadow: 4px 2px 3px #ccc;
      padding-top: 1rem;
      text-align: left;
      font-size: 18px;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
      ${Screen.mediumPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668;
      padding: .5rem 0;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
    }
  }
  &.watch {
    display: flex;
    justify-content: space-between;
    color: #000;
    span {
      padding-top: 0.5rem;
      padding-left: 0.5rem;
    }

    p {
    }
  }
`;
const Header = styled.h1`
  color: #002668;
  font-size: 1.5rem;
  ${Screen.surfaceDuo`
    text-align: center;
    padding-bottom: 1rem;
    `}
  ${Screen.mediumPhone`
    text-align: center;
    font-size: 20px
    `}
    ${Screen.phone`
    font-size: 18px;
    text-align: center;
    `}
    ${Screen.smallPhone`
    font-size: 18px;
    text-align: center;
    `}

  span {
    color: #c81e19;
  }
  &.gallery {
    ${Screen.surfaceDuo`
    padding-top: 3rem;
  `}
    ${Screen.mediumPhone`
  padding-bottom: 1rem;
  padding-top: 0;
  `}
  ${Screen.phone`
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  padding-bottom: 2rem;
  `}
  }
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 3rem;
  ${Screen.mediumpc`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px
  `}
  ${Screen.smallpc`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px
  `}
  ${Screen.surfaceDuo`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0;
  margin-top: 1rem;
  `}
  ${Screen.mediumPhone`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0;
  margin-top: 1rem;
  `}
  ${Screen.smallPhone`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0;
  margin-top: 1rem;
  `}
`;
const Card = styled.div`
  width: 300px;
  height: auto;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  /* box-shadow: 3px 3px #ccc; */
  ${Screen.smallpc`
  width: 90%;
  `}
   ${Screen.surfaceDuo`
  width: 60%;
  margin-bottom: 1.5rem;
  `}
  ${Screen.mediumPhone`
  width: 75%;
  margin-bottom: 1rem;
  `}
  ${Screen.phone`
  width: 80%;
  margin-bottom: 2rem;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin-bottom: 2rem;
  `}
`;
const VideoCard = styled.div`
  width: 100%;
  height: 250px;
  /* background-color: #d9d9d9; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    /* text-align: right; */
    padding-bottom: 1rem;
    padding-right: 1rem;
    font-size: 0.9rem;
  }
  ${Screen.mediumPhone`
  height: 200px
  `}
  ${Screen.phone`
  height: 200px
  `}
  ${Screen.smallPhone`
  height: 200px
  `}
`;
const VideoFooter = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  text-transform: uppercase;
  color: #002668;
  /* padding-bottom: 2rem; */

  span {
    font-size: 0.8rem;
    text-transform: none;
  }
`;
const VideoLink = styled(Link)`
text-decoration: none;
color: #002668;
padding-bottom: 10px;
 h4 {
  ${Screen.mediumPhone`
  font-size: 14px;
  `}
 }
`


export default Media;
