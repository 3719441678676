import { 
    DOC_REQUEST, 
    DOC_SUCCESS, 
    DOC_FAILURE
} from "../constants"



export const financialReducer = (state, action) => {
    switch(action.type){
        case DOC_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case DOC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case DOC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const noticeReducer = (state, action) => {
    switch(action.type){
        case DOC_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case DOC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case DOC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const formsReducer = (state, action) => {
    switch(action.type){
        case DOC_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case DOC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case DOC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const disclosureReducer = (state, action) => {
    switch(action.type){
        case DOC_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case DOC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case DOC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const cibReducer = (state, action) => {
    switch(action.type){
        case DOC_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case DOC_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case DOC_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}