import { useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { HelpersComponent } from '../../components';
import { Ways, WaysMobile } from '../../assets/img';
import { waysLinks } from '../../components/main';
import { Link } from 'react-router-dom';
// import { Footer } from '../../components/footer';
import { WaysHeroContext } from '../../contexts/hero'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS } from '../../constants';
import api from '../../services/api';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const App = () => {

  const mount = useRef()
  const { dispatch, state } = useContext(WaysHeroContext)
  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);

    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/ways')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()

    return () => {
      mount.current = false
    };
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>     
          <Section className='hero' bg={`url(${state.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Ways to Bank</h2>
                  <p>
                    Open your business to new opportunities, high quality
                    services and specialized innovative
                    solutions created to give your enterprise a competitive
                    edge
                  </p>
                </Content>
              </Glass>
            </Container>
          </Section>
    
          {/* Products Image */}
          <Section className='products'>
            <Container>
              <ImageContainer>
                {waysLinks.map(({ img, name, path }) => (
                  <div key={name} 
                  style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap'
                    }}>
                    <ImageLink to={path}>
                      <Image src={img} alt='products' className='products' />
                      <p style={{ paddingTop: '1rem', textTransform: 'uppercase'}}>{name}</p>
                    </ImageLink>
                  </div>
                ))}
              </ImageContainer>
            </Container>
          </Section>
          {/* End of Product Images */}
    
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 70%;
  margin: 0 auto!important;
  `}

  &.hero {
    width: 85%;
    display: flex;
    justify-content: flex-end;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
     ${Screen.smallpc`
    padding-top: 0;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
`;
const Image = styled.img`
  &.products {
    width: 23rem;
    ${Screen.miniLargeScreen`
    width: 17rem
    `}
    ${Screen.pad`
    width: 16rem
    `}
    ${Screen.mediumpc`
    width: 15rem
    `}
    ${Screen.smallpc`
    width: 14rem
    `}
    ${Screen.surfaceDuo`
    width: 15rem
    `}
    ${Screen.mediumPhone`
    width: 100%
    `}
  }
`;
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5rem;
  text-align: center;
  ${Screen.mediumpc`
  margin-top: 5rem
  `}
  ${Screen.surfaceDuo`
  display: flex;
  flex-direction: column;
  margin-top: 5rem
  `}
   ${Screen.mediumPhone`
  display: flex;
  flex-direction: column;
  margin-top: 5rem
  `}

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    ${Screen.mediumPhone`
    margin-bottom: 1rem;
    `}
  }
`;
const ImageLink = styled(Link)`
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  padding-top: 1rem;
  text-decoration: none;
  ${Screen.surfaceDuo`
  text-transform: uppercase;
  font-size: 1.1rem
  `}
  ${Screen.mediumPhone`
  font-size: 16px;
  `}
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 100vh;
    /* background-image: url(${Ways}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 12rem;
    ${Screen.miniLargeScreen`
    padding-top: 5rem
    `}
    ${Screen.pad`
    padding-top: 5rem
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.products {
    min-height: 50vh;
    ${Screen.mediumpc`
    min-height: 20vh
    `}
    ${Screen.smallpc`
    min-height: 20vh
    `}
  }
`;
const Content = styled.div`
  &.glass {
    text-align: right;
    padding-right: 2rem;
    ${Screen.mediumpc`
    padding: 1rem 2rem;
    `}
    ${Screen.smallpc`
    padding: 1rem 2rem;
    `}
    ${Screen.surfaceDuo`
    text-align: center;
    padding: 0 .5rem;
    `}
    ${Screen.galaxyFold`
    text-align: center!important;
    padding: 0 1rem;
    `}
    h2 {
      padding-top: 2rem;
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #ccc;
      ${Screen.mediumpc`
      font-size: 30px;
      padding-top: 3rem
      `}
      ${Screen.smallpc`
      padding-top: 3rem;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 2rem;
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.phone`
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      font-size: 24px;
      text-align: center;
      `}
    }

    p {
      color: #002668;
      padding-top: 0.5rem;
      font-size: 20px;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      font-size: 18px;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 16px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: 1rem;
      `}
      ${Screen.mediumPhone`
      font-size: 15px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: 1rem;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: 1rem;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: 1rem;
      `}
      ${Screen.galaxyFold`
      font-size: 12px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
    }
  }
  &.product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      color: #c81e19;
    }

    p {
      color: #002668;
      font-size: 1.1rem;
    }
  }

  &.quick {
    margin-top: 4rem;
    h4 {
      color: #c81e19;
      text-align: center;
      font-size: 1.5rem;
    }
    p {
      text-align: center;
      padding-top: 1rem;
      font-size: 1.2rem;
    }
  }
  &.abbey-mobile {
    p {
      color: #c81e19;
      text-align: left;
      font-weight: bold;
      padding-top: 0;
    }

    li {
      padding-top: 0.5rem;
    }
  }

  &.new {
    margin-top: 5rem;
    h4 {
      color: #c81e19;
      text-align: center;
      font-size: 1.5rem;
    }
    p {
      padding-top: 1rem;
      text-align: center;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      li {
        padding-top: 1rem;
      }
    }
  }
  &.corporate {
    text-align: center;
    padding-top: 5rem;
    i {
      font-weight: bold;
    }
  }
`;
const Glass = styled.div`
  width: 540px;
  height: 270px;
  background: rgba(255, 255, 255, 0.5);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.smallpc`
  height: 230px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
export default App;
