import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Decorate, NavButton } from '../../../styles';
import stateCountry from 'state-country';

const StepFive = ({ next, prev, values, handleChange }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    const getCountries = () => {
      setCountries(stateCountry.getAllCountries());
    };
    const getStates = () => {
      setStates(stateCountry.getAllStatesInCountry(values.country));
    };
    getCountries();
    getStates();
  }, [values]);
  // List of countries
  const listOfCountries =
    countries && countries.length
      ? countries.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;

  // List of states
  const listOfStates =
    states && states.length
      ? states.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;

  const validation =
    values.nextOfKin === '' ||
    values.relationship === '' ||
    values.kinMobile === '' ||
    values.kinAddress1 === '' ||
    values.country === '' ||
    values.state === '';
  return (
    <FormContainer>
      <FormHeader>Step 5 0f 6: Next of Kin Details</FormHeader>
      <Form autoComplete='off'>
        <FlexBox>
          <label>Next of Kin</label>
          <Input>
            <input
              type={'text'}
              name='nextOfKin'
              onChange={handleChange('nextOfKin')}
              value={values.nextOfKin}
              placeholder='Name of next of kin'
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Relationship</label>
          <Input>
            <input
              type={'text'}
              name='relationship'
              onChange={handleChange('relationship')}
              value={values.relationship}
              placeholder='Relationship with next of kin'
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Mobile</label>
          <Input>
            <input
              type={'text'}
              name='kinMobile'
              onChange={handleChange('kinMobile')}
              value={values.kinMobile}
              placeholder='Next of kin mobile number'
            />
          </Input>
        </FlexBox>
        <FlexBox mt='3rem'>
          <label>Residential Address</label>
          <InputContainer>
            <Input className='group'>
              <input
                type={'text'}
                name='kinAddress1'
                placeholder='Residential address here'
                onChange={handleChange('kinAddress1')}
                value={values.kinAddress1}
              />
            </Input>

            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='kinAddress2'
                placeholder='Address line #2'
                onChange={handleChange('kinAddress2')}
                value={values.kinAddress2}
              />
            </Input>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='kinCountry'
                onChange={handleChange('kinCountry')}
                value={values.kinCountry}
              >
                <option value='' disabled hidden>
                  Choose Country
                </option>
                <option></option>
                {listOfCountries}
              </select>
            </Select>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='kinState'
                value={values.kinState}
                onChange={handleChange('kinState')}
              >
                <option value='' disabled hidden>
                  Choose State
                </option>
                <option></option>
                {listOfStates}
              </select>
            </Select>
            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='kinArea'
                placeholder='Zip/Area Code'
                onChange={handleChange('kinArea')}
                value={values.kinArea}
              />
            </Input>
          </InputContainer>
        </FlexBox>
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
const Select = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  ${Decorate.select};
  .select-css {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
  }
  &.group {
    width: 100%;
    margin-left: -0.1rem;
  }
`;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  &.group {
    width: 100%;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
  }
`;
const InputContainer = styled.div`
  width: 50%;
  margin-top: ${({ mt }) => mt};
  .checkbox {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    label {
      white-space: nowrap;
      font-size: 0.9rem;
      padding-left: 1rem;
    }

    input {
      transform: scale(2);
      margin-top: 0.5rem;
      cursor: pointer;
      border-radius: 0;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepFive;
