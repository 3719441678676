// import headers from './headers';
// import api from 'utils/api';

import api from "../services/api";



const documents = {
  async downloadDocuments(filename) {
    try {
    //   const response = await api.get(`/api/document/download/${filename}`);
      const response = await api.req(`/api/document/download/${filename}`);
    //   console.log('response: ', response)
      return response.details;
    } catch (error) {
      return error.response ? error.response.data : {
        status: 'fail',
        responseCode: '01',
        responseMessage: 'Network Error'
      };
    }
  }
};

export default documents;
