import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, SoewuImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Soewu = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                  <Header>Air Vice Marshal Olufemi Soewu</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                </div>
                <Image src={SoewuImg} alt='soewu' />
                <Paragraph>
                  <p>
                  Air Vice Marshal Abiodun Olufemi Soewu (Rtd) graduated from the 
                  Nigerian Defense Academy and the Royal Air force College, UK in 
                  1973 and 1976 respectively. He also attended the administrative 
                  Staff College (ASCON), Badagry and University of Ibadan where he 
                  graduated with a certificate in Personnel Management and Master 
                  in Strategic Studies in 1982 and 2001 respectively.
                  </p>
                  <p>
                  He had a distinguished working career in the Nigerian Air Force. 
                  AVM Soewu (Rtd) occupied very prominent military positions at the 
                  Nigeria Defense Academy. He was Military Assistant to the Lagos State 
                  Military Governor (1984-1986), Project Coordinator, Nigerian Air Force 
                  Computerization Project (1989-1990) and Commander Nigerian Air Force, 
                  Makurdi (2001-2003) amongst others.
                  </p>
                  <p>
                A Fellow of the National War College, Abuja [2000-2001] and the Command and 
                Staff College, Jaji, Kaduna State, [1989-1990] AVM Soewu retired as Secretary 
                to the Nigerian Air Force [2006]. A versatile administrator par excellence. 
                He sits on the board of many other companies
                </p>
                </Paragraph>
              </FlexBox>
            ): (
              <>
              <FlexBox>
              <Image src={SoewuImg} alt='soewu' />
                <Content>
                  <Header>Air Vice Marshal Olufemi Soewu</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                  <p style={{ paddingTop: '2rem'}}>
                  Air Vice Marshal Abiodun Olufemi Soewu (Rtd) graduated from the 
                  Nigerian Defense Academy and the Royal Air force College, UK in 
                  1973 and 1976 respectively. He also attended the administrative 
                  Staff College (ASCON), Badagry and University of Ibadan where he 
                  graduated with a certificate in Personnel Management and Master 
                  in Strategic Studies in 1982 and 2001 respectively.
                  </p>
                  <p>
                  He had a distinguished working career in the Nigerian Air Force. 
                  AVM Soewu (Rtd) occupied very prominent military positions at the 
                  Nigeria Defense Academy. He was Military Assistant to the Lagos State 
                  Military Governor (1984-1986), Project Coordinator, Nigerian Air Force 
                  Computerization Project (1989-1990) and Commander Nigerian Air Force, 
                  Makurdi (2001-2003) amongst others.
                  </p>
                </Content>
              </FlexBox>
              <Content className='more'>
                <p>
                A Fellow of the National War College, Abuja [2000-2001] and the Command and 
                Staff College, Jaji, Kaduna State, [1989-1990] AVM Soewu retired as Secretary 
                to the Nigerian Air Force [2006]. A versatile administrator par excellence. 
                He sits on the board of many other companies
                </p>
              </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
  ${Screen.mediumpc`
  min-height: 20vh;
  `}
  ${Screen.smallpc`
  min-height: 50vh;
  `}
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 7rem;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 5rem;
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 18px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Soewu;
