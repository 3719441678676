import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, UmegoImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Umego = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                  <Header>Prof. Marius N. Umego</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                </div>
                <Image src={UmegoImg} alt='umego' />
                <Paragraph>
                  <p>
                  Professor Marius N. Umego is a Professor of Geophysics. He is a graduate 
                  of the University of Ibadan where he obtained a BSc in Physics. He bagged 
                  his MSc and PhD in Geophysics from Ahmadu Bello University. He is a prolific 
                  academic and author of many books and articles published in reputable journals. 
                  He is a member of many professional bodies both locally and internationally including 
                  Fellow of International Programme in Physical Sciences, Uppsala, Sweden and fellow of 
                  the Nigerian Institute of Physics.
                  </p>
                </Paragraph>
              </FlexBox>
            ): (

              <FlexBox>
              <Image src={UmegoImg} alt='umego' />
                <Content>
                  <Header>Prof. Marius N. Umego</Header>
                  <Title>NON-EXECUTIVE DIRECTOR</Title>
                  <p style={{ paddingTop: '2rem'}}>
                  Professor Marius N. Umego is a Professor of Geophysics. He is a graduate 
                  of the University of Ibadan where he obtained a BSc in Physics. He bagged 
                  his MSc and PhD in Geophysics from Ahmadu Bello University. He is a prolific 
                  academic and author of many books and articles published in reputable journals. 
                  He is a member of many professional bodies both locally and internationally including 
                  Fellow of International Programme in Physical Sciences, Uppsala, Sweden and fellow of 
                  the Nigerian Institute of Physics.
                  </p>
                </Content>
              </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }

  ${Screen.mediumpc`
  min-height: 20vh;
  `}
  ${Screen.smallpc`
  min-height: 50vh;
  `}
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 7rem;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 5rem;
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }
  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
  ${Screen.tablet`
  display: none;
  `}
`;
const Image = styled.img`
width: 30%;
height: 400px;
${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Umego;
