import { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { HelpersComponent, NavigationBar } from '../../components';
import { NavButton, Screen } from '../../styles';
// import api from '../../services/api';
// import { Footer } from '../../components/footer';
import {
  Mortgage,
  Refinancing,
  Commercial,
  EquityRelease,
  LoanBg,
  Wire
} from '../../assets/img';
import { Link, useNavigate } from 'react-router-dom';
import { LoanHeroContext } from '../../contexts/hero'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS } from '../../constants';
import api from '../../services/api';

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const Loans = () => {
  const navigate = useNavigate();
  const mount = useRef()
  const { dispatch, state } = useContext(LoanHeroContext)



  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);

    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/loan')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()

    return () => {
      mount.current = false
    };
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          
          <Section className='hero' bg={`url(${state.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Loans</h2>
                  <p>
                    Get access to mortgage opportunities
                    as you start your journey to getting
                    your dream home
                  </p>
                </Content>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <OpenAccount onClick={() => navigate('/loans/mortgage/calculator')}>Calculate Loan</OpenAccount>
                  <OpenAccount onClick={() => navigate('/loans/form')}>
                    Apply
                  </OpenAccount>
                </div>
              </Glass>
            </Container>
          </Section>

          <Container>
            {/* {
              mobile ? (
                <h1>Working on it...</h1>
              ) : (
                <> */}
                
                {/* Mortgages */}
                <Section className='products' id='mortgage-loan'>
                  <Image src={Mortgage} alt='mortgage-loan' className='mortgage' />
                  <Header>Mortgage Loans</Header>
                  <Content className='mortgage'>
                    <p>
                      Loans granted to both individual and corporate entities for
                      acquiring real estate properties subject to customers meeting to
                      lending criteria
                    </p>
                    <ButtonWrapper>
                      <MortgageLink to={'/loans/mortgage-loan'}>
                        <MortgageButton>Learn More</MortgageButton>
                      </MortgageLink>
                    </ButtonWrapper>
                  </Content>
                </Section>
                {/* End of Mortgages */}
                {/* Equity */}
                <Section className='equity'>
                  <Image
                    src={EquityRelease}
                    alt='equity-release'
                    className='mortgage'
                  />
                  <Header>Equity Release</Header>
                  <Content className='mortgage'>
                    <p>
                      These are short/medium term loans given to property owners,
                      who intend to access part of their property value.
                    </p>
                    <ButtonWrapper>
                      <MortgageLink to={'/loans/equity-release'}>
                        <MortgageButton>Learn More</MortgageButton>
                      </MortgageLink>
                    </ButtonWrapper>
                  </Content>
                </Section>
                {/* End of Equity */}
                {/* Refinancing */}
                <Section className='equity'>
                  <Image
                    src={Refinancing}
                    alt='mortgage-refinancing'
                    className='mortgage'
                  />
                  <Header>Mortgage Refinancing</Header>
                  <Content className='mortgage'>
                    <p>
                      Loans granted to both individual and corporate entities for
                      outright purchase of an existing mortgage from another
                      financial institution subject to meeting the Banks lending
                      criteria
                    </p>
                    <ButtonWrapper>
                      <MortgageLink to={'/loans/mortgage-refinancing'}>
                        <MortgageButton>Learn More</MortgageButton>
                      </MortgageLink>
                    </ButtonWrapper>
                  </Content>
                </Section>
                {/* End of Equity */}
                {/* Commercial */}
                <Section className='equity'>
                  <Image src={Commercial} alt='commercial' className='mortgage' />
                  <Header>Commercial Real Estate</Header>
                  <Content className='mortgage'>
                    <p>
                      Loans granted to corporate entities for the 
                      development of construction of units for residential
                      or commercial buildings for sale
                    </p>
                    <ButtonWrapper>
                      <MortgageLink to={'/loans/real-estate'}>
                        <MortgageButton>Learn More</MortgageButton>
                      </MortgageLink>
                    </ButtonWrapper>
                  </Content>
                </Section>
                {/* End of Commercial */}
                {/* Wire */}
                <Section className='equity'>
                  <Image src={Wire} alt='wire' className='mortgage' />
                  <Header>WIRE: Women In Real Estate</Header>
                  <Content className='mortgage'>
                    <p>
                      Loans granted to women-owned real estate companies to finance projects
                    </p>
                    <ButtonWrapper>
                      <MortgageLink to={'/loans/wire'}>
                        <MortgageButton>Learn More</MortgageButton>
                      </MortgageLink>
                    </ButtonWrapper>
                  </Content>
                </Section>
                {/* End of Wire */}
                {/* </>
              )
            } */}
          </Container>
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  &.hero {
    width: 85%;
    display: flex;
    justify-content: flex-end;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  width: 80%;
  margin: 0 auto;

  ${Screen.miniLargeScreen`
  width: 70%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 70%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 70%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  `}

`;

const Header = styled.h1`
  color: #c81e19;
  font-size: 2rem;
  padding-top: 1rem;
  text-align: center;
  ${Screen.smallpc`
  font-size: 1.5rem;
  padding-top: 0
  `}
  ${Screen.mediumPhone`
  font-size: 1.3rem;
  padding-top: 0
  `}
  ${Screen.smallPhone`
  font-size: 1.3rem;
  padding-top: 0
  `}
  ${Screen.surfaceDuo`
  font-size: 1.1rem;
  padding-top: 0
  `}
`;
const Image = styled.img`
  margin-bottom: 1rem;
  &.mortgage {
    margin: 1rem auto;
    ${Screen.miniLargeScreen`
    width: 600px;
    height: 300px
    `}
    ${Screen.pad`
    width: 500px;
    height: 200px
    `}
    ${Screen.mediumpc`
    width: 500px;
    height: 200px
    `}
    ${Screen.smallpc`
    width: 500px;
    height: 200px
    `}
    ${Screen.mediumPhone`
    width: 90%;
    height: 170px
    `}
    ${Screen.smallPhone`
    width: 90%;
    height: 170px
    `}
    ${Screen.surfaceDuo`
    width: 90%;
    height: 130px
    `}
  }
  &.loan {
    margin-top: 5rem;
    ${Screen.smallpc`
    width: 500px;
    height: 200px
    `}
    ${Screen.mediumPhone`
    width: 100%
    `}
    ${Screen.smallPhone`
    width: 100%
    `}
  }
  &.equity {
    width: 800px;
    height: 400px;
    margin: 1rem auto;
    ${Screen.mediumPhone`
    width: 100%
    `}
    ${Screen.smallPhone`
    width: 100%
    `}
  }
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2rem;
  border-radius: 30px;
  margin: 1rem 0 3rem;
  margin-left: 2rem;
  background-color: #48a7ff;
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 100vh;
    /* background-image: url(${LoanBg}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 12rem;
    ${Screen.pad`
    padding-top: 5rem
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.products,
  &.equity {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;
    ${Screen.miniLargeScreen`
    width: 100%;
    min-height: 10vh;
    margin-top: 1rem;
    `}
    ${Screen.pad`
    width: 100%;
    min-height: 20vh;
    margin-top: 1rem;
    `}
    ${Screen.mediumpc`
    width: 100%;
    min-height: 20vh;
    margin-top: 2rem;
    `}
    ${Screen.smallpc`
    width: 100%;
    min-height: 40vh;
    margin-top: 2rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    min-height: 40vh;
    margin-top: 2rem;
    `}
     ${Screen.phone`
    width: 100%;
    min-height: 50vh;
    margin-top: 2rem;

    `}
    ${Screen.smallPhone`
    width: 100%;
    min-height: 50vh;
    margin-top: 2rem;

    `}
  }
  &.products {
    ${Screen.miniLargeScreen`
    margin-top: 5rem;
    `}
    ${Screen.pad`
    margin-top: 5rem;
    `}
    ${Screen.mediumpc`
    margin-top: 5rem;
    `}
    ${Screen.tablet`
    margin-top: 5rem;
    `}
  }
  &.equity {
    margin-top: 3rem;
    ${Screen.smallpc`
    margin-top: -1rem;
    `}
    ${Screen.mediumPhone`
    margin-top: -1rem;
    `}
    ${Screen.smallPhone`
    margin-top: -1rem;
    `}
  }

  /* margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center; */

  /* p {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    @media screen and (max-width: 900px) {
      font-size: 1rem;
    }
  } */
`;
const MortgageLink = styled(Link)``;
const Content = styled.div`
  margin-top: 1rem;
  &.mortgage {
    /* margin-right: 3rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10rem;

    ${Screen.pad`
    padding: 0 5rem;
    `}
    ${Screen.surfaceDuo`
    padding: 0;
    `}
    p {
      font-size: 1.1rem;
      text-align: center;
      ${Screen.smallpc`
      font-size: 1rem
      `}
      ${Screen.mediumPhone`
      font-size: .8rem
      `}
      ${Screen.smallPhone`
      font-size: .8rem
      `}
    }
  }
  &.glass {
    text-align: right;
    padding-right: 2rem;
    ${Screen.surfaceDuo`
    text-align: center;
    padding-right: 0
    `}
    ${Screen.galaxyFold`
    text-aling: center!important;
    padding-right: 0!important
    `}
    h2 {
      padding-top: 1rem;
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 1rem;
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding-top: 1rem;
      font-size: 28px;
      text-align: center;
      `}
      ${Screen.phone`
      padding-top: 1rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding-top: 1rem;
      font-size: 24px;
      text-align: center;
      `}

    }

    p {
      color: #002668;
      padding-top: 0.5rem;
      font-size: 20px;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      font-size: 18px;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 16px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.mediumPhone`
      font-size: 16px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.galaxyFold`
      font-size: 12px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  ${Screen.smallPhone`
  align-items: center;
  `}
`;
const MortgageButton = styled(Button)`
  width: 8rem;
  background-color: #002668;
  box-shadow: 5px 3px 5px #ccc;
  margin-top: 1rem;
  margin-right: 3rem;
  ${Screen.miniLargeScreen`
  margin-right: 3rem!important;
  `}
  ${Screen.pad`
  margin-right: 3rem!important;
  `}
  ${Screen.mediumpc`
  margin-right: 2rem!important;
  `}
  ${Screen.smallpc`
  margin-right: 2rem!important;
  `}
  ${Screen.mediumPhone`
  margin-right: 2rem!important;
  margin-top: .5rem
  `}
  ${Screen.smallPhone`
  margin-right: 2rem!important;
  margin-top: .5rem
  `}
`;
const Glass = styled.div`
  width: 540px;
  height: 270px;
  background: rgba(255, 255, 255, 0.6);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.smallpc`
  height: 230px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const OpenAccount = styled(Button)`
  width: 10rem;
  height: 2.5rem;
  border-radius: 5px;
  margin: 2rem 0 0;
  margin-right: 2rem;
  font-size: 1rem;
  /* margin-right: ${({ mr }) => mr}; */
  background-color: #c81e19;
  ${Screen.mediumpc`
  margin: 2rem 2rem 0 2rem;
  `}
  ${Screen.tablet`
  margin: 2rem 2rem 0 2rem;
  `}
  ${Screen.surfaceDuo`
  width: max-content;
  height: 2rem;
  padding: .5rem 1rem;
  margin: .5rem;
  font-size: .9rem;
  font-weight: bold;
  box-shadow: 3px 1px 2px #ccc
  `}
  ${Screen.mediumPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem 1rem;
  margin: .5rem;
  font-size: .9rem;
  font-weight: bold;
  box-shadow: 3px 1px 2px #ccc
  `}
  ${Screen.phone`
  width: max-content;
  height: 2rem;
  padding: .5rem 1rem;
  margin: .5rem;
  font-size: .9rem;
  font-weight: bold;
  box-shadow: 3px 1px 2px #ccc
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem 1rem;
  margin: .5rem;
  font-size: .9rem;
  font-weight: bold;
  box-shadow: 3px 1px 2px #ccc
  `}
`;

export default Loans;
