import { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { 
  AbbeyLinkContext, 
  HelpLinkContext,
  OtherLinkContext 
} from '../../contexts/footer'
import {  
  icons 
} from './links';
import { Abbey, FooterOpen, FooterClose } from '../../assets/svg';
import { HashLink } from 'react-router-hash-link';
import { 
  ABBEY_LINKS_FAILURE, 
  ABBEY_LINKS_REQUEST, 
  ABBEY_LINKS_SUCCESS, 
  HELP_LINKS_FAILURE, 
  HELP_LINKS_REQUEST, 
  HELP_LINKS_SUCCESS, 
  OTHER_LINKS_FAILURE, 
  OTHER_LINKS_REQUEST,
  OTHER_LINKS_SUCCESS
} from '../../constants';
import api from '../../services/api';
import { Screen } from '../../styles';
import { Iso } from '../../assets/img';



const Footer = () => {
  const mount = useRef()

  const { dispatch: abbeyLinksDispatch, state: abbeyLinksState } = useContext(AbbeyLinkContext)
  const { dispatch: helpLinksDispatch, state: helpLinksState  } = useContext(HelpLinkContext)
  const { dispatch: otherLinksDispatch, state: otherLinksState  } = useContext(OtherLinkContext)
  const [mobile, setMobile] = useState(false)
  const [abbeyOpen, setAbbeyOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)

  const handleAbbeyToggle = () => {
    setAbbeyOpen(!abbeyOpen)
  }
  const handleHelpToggle = () => {
    setHelpOpen(!helpOpen)
  }

 

  // Abbey Links
  const getAbbeyLinks = async () => {
    try {
      if(abbeyLinksState.data.length < 1){
        abbeyLinksDispatch({ type: ABBEY_LINKS_REQUEST })
        const result = await api.req('/api/document/footer/abbeyLinks')
        abbeyLinksDispatch({
          type: ABBEY_LINKS_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
    } catch (error) {
      abbeyLinksDispatch({
        type: ABBEY_LINKS_FAILURE,
        payload: error.response && error.response.result.responseMessage 
        ? error.response.result.responseMessage : error.responseMessage
      }) 
    }
  }

  // Help Links
  const getHelpLinks = async () => {
    try {
      if(helpLinksState.data.length < 1){
        helpLinksDispatch({ type: HELP_LINKS_REQUEST })
        const result = await api.req('/api/document/footer/helpLinks')
        helpLinksDispatch({
          type: HELP_LINKS_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
    } catch (error) {
      helpLinksDispatch({
        type: HELP_LINKS_FAILURE,
        payload: error.response && error.response.result.responseMessage 
        ? error.response.result.responseMessage : error.responseMessage
      }) 
    }
  }

    // Other Links
    const getOtherLinks = async () => {
      try {
        if(otherLinksState.data.length < 1){
          otherLinksDispatch({ type: OTHER_LINKS_REQUEST })
          const result = await api.req('/api/document/footer/otherLinks')
          otherLinksDispatch({
            type: OTHER_LINKS_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        otherLinksDispatch({
          type: OTHER_LINKS_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }

    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false);
    }

    
  
  useEffect(() => {
    mount.current = true;
    responsive()
    window.addEventListener('resize', () => responsive());
    getAbbeyLinks()
    getHelpLinks()
    getOtherLinks()

    return () => {
      window.removeEventListener('resize', () => responsive());
      mount.current = false;
    }
  }, [])

const desktopView = () => {
  return (
      // <FooterContextProvider>
      <Container>
      
          <FlexBox>
            <LinksContainer>
              <div>
                <Header>Abbey</Header>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {abbeyLinksState.data.map(({ title, link }) => (
                    <AbbeyLinks to={link} key={title}>{title}</AbbeyLinks>
                  ))}
                </div>
              </div>
              <div>
                <Header>Help Centre</Header>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                {helpLinksState.data.map(({ title, link }) => {
                    return title === 'Customer Complaints Portal' ? (
                      <HelpLink href={link} target='_blank' rel='noreferrer' key={title}>
                        {title}
                      </HelpLink>
                    ) : (
                      <HashHelpLinks to={link} smooth key={title}>
                        {title}
                      </HashHelpLinks>
                    );
                  })}
                </div>
              </div>
            </LinksContainer>
            <div>
            <Image src={Abbey} alt='logo' className='logo' />
            <Text>Licensed by the Central Bank of Nigeria</Text>
            <License>RC 172093</License>
            <Image className='iso' src={Iso} alt='iso image' />
            </div>
          </FlexBox>
          <hr />
          <FooterContainer>
            <Icons>
              {icons.map(({ img, path }) => (
                <Link to={path}>
                <Image src={img} alt='icon' className='icon' key={img} />
                </Link>
              ))}
            </Icons>
            <OtherLinks>
              {otherLinksState.data.map(({ title, link }) => (
                <FooterLinks to={link} key={title}>{title}</FooterLinks>
              ))}
            </OtherLinks>
          </FooterContainer>
      </Container>
    
      // </FooterContextProvider>
    );
}  
const mobileView = () => {
  return (
    // <FlexBox>
      <>
    <MobileContainer>
      <AbbeyLinksContainer>
        <Header>Abbey</Header>
        <Image 
        className={abbeyOpen ? 'toggle-close': 'toggle-open'} 
        src={abbeyOpen ? FooterClose : FooterOpen} 
        alt='footer-icon'
        onClick={handleAbbeyToggle}
         />
      </AbbeyLinksContainer>
      <ul style={{ display: 'flex', flexDirection: 'column'}}>
        {
          abbeyOpen && abbeyLinksState.data.map(({ title, link }) => (
            <AbbeyLinks to={link} key={title}>{title}</AbbeyLinks>
        ))
        }
      </ul>
      <HelpLinksContainer>
      <Header>Help Center</Header>
        <Image 
        className={helpOpen ? 'toggle-close': 'toggle-open'} 
        src={helpOpen ? FooterClose : FooterOpen} 
        alt='footer-icon'
        onClick={handleHelpToggle}
         />
      </HelpLinksContainer>
      <ul style={{ display: 'flex', flexDirection: 'column'}}>
      {helpOpen && helpLinksState.data.map(({ title, link }) => {
        return title === 'Customer Complaints Portal' ? (
          <HelpLink href={link} target='_blank' rel='noreferrer' key={title}>
            {title}
          </HelpLink>
        ) : (
        <HashHelpLinks to={link} smooth key={title}>
          {title}
        </HashHelpLinks>
        );
      })}
      </ul>
      <hr />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div>
          <Image src={Abbey} alt='logo' className='logo' />
          <Text>Licensed by the Central Bank of Nigeria</Text>
          <License>RC 172093</License>
          <Image className='iso' src={Iso} alt='iso image' />
        </div>
      <Icons>
        {icons.map(({ img, path }) => (
          <Link to={path}>
            <Image src={img} alt='icon' className='icon' key={img} />
          </Link>
        ))}
      </Icons>
      </div>
    </MobileContainer>
      <BottomLinks>
      <OtherLinks>
        {otherLinksState.data.map(({ title, link }) => (
          <FooterLinks to={link} key={title}>{title}</FooterLinks>
        ))}
        </OtherLinks>
      </BottomLinks>
      
      </>
    // </FlexBox>
  )
}

return (
  <Box>
    {/* <Container> */}
      {mobile ? mobileView() : desktopView()}
    {/* </Container> */}
  </Box>
)
};

const Box = styled.div`
  width: 100%;
  min-height: 50vh;
  /* margin-top: 5rem; */
`;
const Container = styled.div`
  width: 90%;
  margin: 5rem auto 0;

  hr {
    color: #002668;
    margin-top: 3rem;
  }
`;
const MobileContainer = styled.div`
  width: 80%;
  margin: 5rem auto 0;

  hr {
    color: #002668;
    margin-top: 3rem;
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Screen.smallpc`
  width: 100%,
  flex-direction: column
  justify-content: space-between;
  `}
`;
const LinksContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  ${Screen.midScreen`
  div {
    &:nth-child(1){
      margin-right: 2rem
    }
  }
  `}
`;
const Header = styled.h3`
  color: #c81e19;
  font-size: 2rem;
  text-transform: uppercase;

  ${Screen.midScreen`
  font-size: 1.5rem
  `}
  ${Screen.smallpc`
  padding-top: 2rem
  `}
  ${Screen.mediumPhone`
  font-size: 20px!important
  `}
  ${Screen.phone`
  font-size: 18px!important
  `}
  ${Screen.smallPhone`
  font-size: 18px!important
  `}
`;
const AbbeyLinks = styled(Link)`
  text-decoration: none;
  color: #000;
  padding-top: 1rem;
  ${Screen.midScreen`
  font-size: .9rem
  `}
  /* ${Screen.phone`
  font-size: 12px;
  `} */
  ${Screen.smallPhone`
  font-size: 12px;
  `}
`;
const HelpLink = styled.a`
  text-decoration: none;
  color: #000;
  padding-top: 1rem;
  ${Screen.midScreen`
  font-size: .9rem
  `}
  ${Screen.smallPhone`
  font-size: 12px;
  `}
`;
const HashHelpLinks = styled(HashLink)`
  text-decoration: none;
  color: #000;
  padding-top: 1rem;
  ${Screen.midScreen`
  font-size: .9rem
  `}
  ${Screen.smallPhone`
  font-size: 12px;
  `}
`;
const FooterLinks = styled(Link)`
  text-decoration: none;
  color: #000;
  padding-left: 1.5rem;
  ${Screen.midScreen`
  font-size: 1rem
  `}
  ${Screen.smallpc`
  color: #fff;
  `}
  ${Screen.largePhone`
  font-size: 14px;
  padding-left: 16px
  `}
   ${Screen.mediumPhone`
  font-size: 12px;
  padding-left: 14px;
  `}
  ${Screen.phone`
  font-size: 12px;
  `}
  ${Screen.smallPhone`
  font-size: 11px;
  `}
  ${Screen.galaxyFold`
  font-size: 9px;
  `}
`;
const Image = styled.img`
  &.toggle-open {
    ${Screen.smallpc`
      margin-top: 2.5rem;
      cursor: pointer;
    `}
    ${Screen.surfaceDuo`
      margin-top: 2rem;
      cursor: pointer;
    `}
    ${Screen.mediumPhone`
      margin-top: 2rem;
      cursor: pointer;
    `}
    ${Screen.phone`
      margin-top: 1.8rem;
      cursor: pointer;
    `}
    ${Screen.smallPhone`
      margin-top: 1.8rem;
      cursor: pointer;
    `}
  }
  &.toggle-close {
    ${Screen.smallpc`
      margin-top: 1.8rem;
      cursor: pointer;
    `}
    ${Screen.surfaceDuo`
      margin-top: 1.6rem;
      cursor: pointer;
    `}
    ${Screen.mediumPhone`
      margin-top: 1.6rem;
      cursor: pointer;
    `}
    ${Screen.phone`
    margin-top: 1.4rem;
    cursor: pointer;
    `}
    ${Screen.smallPhone`
    margin-top: 1.4rem;
    cursor: pointer;
    `}
  }
  &.icon {
    padding-right: 1rem;
    cursor: pointer;
    ${Screen.smallpc`
    height: 3rem;
    width: 3rem
    `}
  }
  &.logo {
    /* margin-top: 1rem; */
    height: auto;
    ${Screen.midScreen`
    height: 10rem
  `}
   ${Screen.smallpc`
  height: 8rem;
  margin: 2rem 3rem 0;
  `}
  ${Screen.tablet`
    width: 250px;
    height: 170px;
    margin: 2rem 3rem 0;
  `}
  }

  &.iso {
  widht: 100px;
  height: 100px;
  margin: 10px 120px -50px;
  ${Screen.smallpc`
    width: 80px;
    height: 80px;
    margin: 10px 130px;
  `}
  }
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  /* text-align: center; */
`;
const Icons = styled.div`
  display: flex;
  margin-top: 1rem;

  ${Screen.smallpc`
  margin: 0;
  padding: 2rem 0;
  `}
`;
const OtherLinks = styled.div`
  display: flex;
  padding-top: 2rem;
  ${Screen.smallpc`
  padding-top: 1rem;
  justify-content: center;
  `}
  ${Screen.largePhone`
  font-size: .3rem;
  `}
  ${Screen.smallPhone`
  padding-top: 1rem;
  justify-content: center;
  `}
`;

/** Mobile */
const AbbeyLinksContainer = styled.div`
display: flex;
justify-content: space-between;
`;
const HelpLinksContainer = styled.div`
display: flex;
justify-content: space-between;
`;
const BottomLinks = styled.div`
width: 100%;
height: 3rem;
background-color: #002668;
`;
const Text = styled.p`
padding-top: 10px;
color: #002668;
font-weight: bold;
text-align: center;
  ${Screen.smallPhone`
  font-size: 14px;
  `}
`;
const License = styled.p`
color: #c81e19;
text-align: center;
font-weight: bold;
padding-top: 10px;
`
export default Footer;
