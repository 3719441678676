import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, OvalMobile } from '../../assets/img';
import { Grey2, Blue2 } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { Screen } from '../../styles'
import {
  auditComposition,
  auditPrinciples,
  breadcrumbMenu,
  composition,
  objectiveEight,
  objectiveEleven,
  objectiveFive,
  objectiveFour,
  objectiveNine,
  objectiveOne,
  objectiveSeven,
  objectiveSix,
  objectiveTen,
  objectiveThree,
  objectiveTwo,
  principles,
  scope,
  informationRequirements,
  boardScope,
  strategyScope,
  reporting,
} from '../../components/main/products';

// const Navigation = NavigationBar.Navigation;
const Responsibility = () => {
  const [mobile, setMobile] = useState(false)
  const location = useLocation();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of Hero Section */}
      {
        mobile ? (
          <BreadCrumb>
        {breadcrumbMenu.slice(0, 2).map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        ): (
      <BreadCrumb>
        {breadcrumbMenu.map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        )
      }

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <FlexBox>
              <MobileHeader>
                <Image src={OvalMobile} alt='oval' className='mobile' />
                <Content>
                  <h2>Board Responsibilities</h2>
                </Content>
              </MobileHeader>
              <Content>
              <p>
                Board Committees are sub-groups of the Board charged with making
                recommendations to the Board or taking decisions on behalf of
                the Board on issues of expediency that may arise outside the
                normal meeting schedule of the entire Board.
                The respective committees are easier to convene because
                fewer people are involved and this arrangement therefore
                facilitates speedy decision-making.
                <br />{' '}
              </p>
              <p>
                The Bank’s Board should have the following minimum committees.
              </p>{' '}
              <p>1. BOARD CREDIT AND RISK MANAGEMENT COMMITTEE</p>
              <p>
                There shall be established a Board Credit and Risk Management
                Committee (the “BCRMC”) to exercise the Board’s responsibilities
                on credit and risk matters. The BCRMC shall provide oversight on
                Management’s activities in managing credit risks of the Bank.
              </p>
              <p style={{ paddingTop: '3rem' }}>PRINCIPLES:</p>
              <ListOne type='upper-roman'>
                {principles.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='1rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p>
                However, to carry out these functions, the Board has instituted
                its Credit & Risk Management Committee (BCRM) to assist it in
                fulfilling its roles as it relates to risk management. Without
                prejudice to the roles of the BCRM, the full board shall retain
                ultimate responsibility for risk management.
              </p>
              <p style={{ paddingTop: '3rem' }}>SCOPE/ROLES:</p>
              <p>
                The Scope and Roles of the Board Credit and Risk Management
                Committee of the company shall be as follows:
              </p>
              <ListOne>
                {scope.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>COMPOSITION</p>
              <ListOne type='lower-alpha'>
                {composition.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETINGS
              </p>
              <p>
                The Board Credit and Risk Management Committee shall meet at
                least once every quarter after the commencement of each
                financial year. Additional meetings may be convened when major
                issues arise, which need to be resolved between scheduled
                meetings. It would be desirable if each meeting does not exceed
                an elapsed time of 4 to 5 hours.
              </p>
              <p>
                The quorum for the meeting of the committee shall be determined
                by the Board.
              </p>
              <p style={{ paddingTop: '3rem' }}>INFORMATION REQUIREMENTS:</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least once 7 days before each meeting including the following:
              </p>
              <p style={{ paddingTop: '3rem' }}>
                2. BOARD AUDIT AND COMPLIANCE COMMITTEE
              </p>
              <p>
                The Audit & Compliance Committee shall provide oversight of
                Management’s activities in managing statutory compliance,
                internal control functions, operational, legal and all other
                risks of the company. It also oversees the financial and
                reporting activities of the Bank.
              </p>
              <p style={{ paddingTop: '2rem' }}>PRINCIPLES</p>
              <p>The committee shall:</p>
              <ListOne type='lower-alpha'>
                {auditPrinciples.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>SCOPE/ROLES:</p>
              <p>
                The scope and roles of the Audit & Compliance Committee of the
                company shall be as follows:
              </p>
              <p>
                The Audit & Compliance Charter defines the fundamental
                principles, roles and responsibilities of the Compliance
                function within the organisation as well as its relationship
                with executive management, the Board of Directors, business and
                operational functions.
                <br />
                The Charter encompasses all current and future regulatory
                requirements in terms of Compliance, in particular the Central
                Bank of Nigeria and Anti-Money Laundering and Counter Terrorism
                Act aiming at ensuring the compliance risk management via the
                formal implementation of Compliance function within the
                organisation.
              </p>
              <p>
                Once approved, the Charter applies to Members of the Board and
                staff members as well as to external service providers of the
                organisation and all its subsidiaries and branches. The Charter
                shall be updated from time to time to reflect legal and
                regulatory evolution and shall be communicated to all staff.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.1. Objectives of the Compliance Function
              </p>
              <p style={{ paddingTop: '2rem' }}>
                a. Independent function with the objective to:
              </p>
              {objectiveOne.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p>
                The Compliance function intervenes at the level of controls
                performed by executive management on activities under their
                direct responsibility.
              </p>
              <p style={{ paddingTop: '2rem' }}>
                b. The compliance risk is defined as the risk the Bank may
                suffer as a result of its failure to comply with applicable
                laws, regulation, codes of conduct and standards of good
                practice and includes:
              </p>
              {objectiveTwo.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                c. Applicable laws, regulation and professional standards relate
                to:
              </p>
              {objectiveThree.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.2. Responsibilities and Competencies
              </p>
              <p>
                The responsibility of compliance within an organisation is
                defined at four levels:
              </p>
              <p style={{ paddingTop: '2rem' }}>
                a. Responsibilities of the Board of Directors
              </p>
              <ListOne>
                {objectiveFour.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '2rem' }}>
                b. Responsibilities of Executive Management (“four-eyes”)
              </p>
              {objectiveFive.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                  {items.subItems &&
                    items.subItems.map(({ item }) => (
                      <ListThree>
                        <ListItem>{item}</ListItem>
                      </ListThree>
                    ))}
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                c. Responsibilities and competencies of the Compliance function
              </p>
              <p>The Compliance function must:</p>
              {objectiveSix.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                d. Responsibilities of Line Managers, Staff Members and External
                Service providers at a functional level, the compliance function
                provides advisory, monitoring, training and control services.
                <br /> At an operational level, compliance with the
                organisation’s integrity rules is the responsibility of line
                management, all staff members and external service providers
                through adherence to applicable laws, regulations and the
                organisation’s policies, standards and procedures.
                <br /> In this context, it is important to stress that the line
                managers, staff members and external service providers remain
                personally responsible for:
              </p>
              {objectiveSeven.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.3. Independence of the Function
              </p>
              <p>
                The Compliance Officer and all staff of the Compliance function
                is independent from any commercial, administrative or control
                function within the organisation in order to allow them to carry
                out their work freely and objectively. Independence is achieved
                through organisational status and objectivity:
              </p>
              {objectiveEight.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.4. Prominence of the Function - In Line with CBN Directive
              </p>
              <p>
                Executive management is responsible for establishing a permanent
                Compliance function within the organisation. For this purpose,
                executive management formally appoints a Compliance Officer,
                whose name and subsequent changes thereto are communicated to
                the Central Bank of Nigeria, and grants sufficient human and
                technical resources to achieve the defined Compliance
                objectives.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.5. Relationship with Other Business Unit and Operational
                Functions
              </p>
              <p>
                Certain tasks may be delegated to or coordinated with other
                business and operational functions in the organisation. The list
                of such delegated tasks is as follows:
              </p>
              {objectiveNine.map((items, i) => (
                <ListUnstyle key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListUnstyle>
              ))}
              <p>
                In these cases, the Compliance function coordinates and
                structures the delegated compliance related tasks in accordance
                with the following principles:
              </p>
              {objectiveTen.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p>
                In order to strengthen the cooperation between the two functions
                and to foster the full implementation of the Compliance policy
                in all business and operational areas, the Compliance Officer
                undertakes to organise quarterly meetings with the responsible
                persons to whom tasks have been delegated.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.6. Relationship with Internal Audit
              </p>
              <p>
                The Compliance function is separate from the internal audit
                function. Where feasible, Compliance may consider delegating
                certain tasks to internal audit, or coordinating certain
                compliance matters. The work and performance of the Compliance
                function is subject to periodic review by internal audit.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.7. Access to Information and Confidentiality
              </p>
              <p>
                The staff members of the Compliance function shall have full and
                unrestricted access to any information at any time. All staff
                members of the Compliance function must comply with
                confidentiality requirements. Any confidential information
                received during the course of the exercise of their roles and
                responsibilities cannot be used for any personal gain or in any
                manner which would be contrary to law or detrimental to the
                interests of the organisation.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.8. Right to Initiate Investigations
              </p>
              <p>
                The Compliance function has the right to start investigations at
                its own initiative, when deemed necessary. In such case, the
                Compliance function may use the expertise from other functions
                within the organisation (e.g. legal department and internal
                audit). The Compliance function remains, however, responsible
                for coordinating the investigation and for presenting the
                outcome of the analysis to Managing Director and/or the Board of
                Directors, if appropriate.
              </p>
              <p style={{ paddingTop: '3rem' }}>1.9. Reporting Lines</p>
              <p>
                Considering the hierarchical link of the Compliance function to
                executive, a formal regular reporting line to the Board of
                Directors must be implemented. In addition, the Compliance
                Officer has an off the cuff reporting line to the Managing
                director who has formally been appointed as manager for
                Compliance.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.10. Right to Contact the Board of Directors
              </p>
              <p>
                The Compliance Officer shall have the right to discuss any
                relevant issue with the executive management. Furthermore, an
                escalation procedure must be defined and implemented in order to
                guarantee the Compliance Officer’s right to report significant
                incidents directly to the Board of Directors or its relevant
                Committees.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.11. Recourse to External Experts
              </p>
              <p>
                The Compliance function has recourse to the expertise or
                technical means of third parties outside the institution or
                within the same group, for certain specific or technical aspects
                (e.g.legal advice), but retains responsibility for outsourced or
                co-sourced assignments. Third party access to confidential info
                must be subject to approval of Executive Management, adequate
                supervision and / or execution of a confidentiality agreement.
              </p>
              <p>
                N.B: Escalation to the Board must be possible for the Chief
                Compliance Officer without any limitation or sanction. This is
                recommended as a guarantee of the independence of the Compliance
                function towards business and operational staff that may prevail
                with executive management. Institutions may consider carrying
                out a regular and timely compliance review at Board Level.
              </p>
              <ListOne type='lower-alpha'>
                {objectiveEleven.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>COMPOSITION:</p>
              <p>
                The Audit & Compliance Committee shall be composed as follows:
              </p>
              <ListOne type='lower-alpha'>
                {auditComposition.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Audit & Compliance Committee shall meet at least once every
                quarter after the commencement of each financial year. <br />
                Additional meetings maybe convened when major issues arise,
                which need to be resolved between scheduled meetings. It would
                be desirable if each meeting does not exceed an elapsed time of
                4 to 5 hours. The quorum shall be as determined by the Board.
              </p>
              <p style={{ paddingTop: '3rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                3. BOARD GOVERNANCE & REMUNERATION COMMITTEE
              </p>
              <p>
                This Committee is established to oversee the Bank’s corporate
                governance and cater for selection, compensation and performance
                management of Directors of the company and Senior Management.
              </p>
              <p style={{ paddingTop: '1rem' }}>PRINCIPLE:</p>
              <p>
                The Committee shall select, reward and manage the performance of
                the Directors of the Bankand Senior Management in such a manner
                as to guarantee the level of corporate governance necessary to
                achieve the Bank’s corporate vision.
              </p>
              <p style={{ paddingTop: '1rem' }}>SCOPE AND ROLE:</p>
              <ListOne type='lower-alpha'>
                {boardScope.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '1rem' }}>COMPOSITION:</p>
              <p>
                The Committee shall consist of such number of persons as the
                Board may determine.
              </p>
              <p style={{ paddingTop: '1rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Committee shall meet at least once every quarter after the
                commencement of each financial year. Apart from scheduled
                meetings, special meetings may be convened as needed.
                <br />
                It would be desirable if each meeting does not exceed an elapsed
                time of 4 to 5 hours. The quorum for the meeting of the
                Committee shall be determined by the Board.
              </p>
              <p style={{ paddingTop: '1rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                4. BOARD STRATEGY AND FINANCIAL ANALYSIS COMMITTEE PRINCIPLE:
              </p>
              <p>
                This Committee shall be the strategic power house of the Bank.
              </p>
              <p style={{ paddingTop: '1rem' }}>SCOPE AND ROLE:</p>
              <ListOne type='lower-alpha'>
                {strategyScope.map(({ item }) => (
                  <ListItem>{item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                5. ACCOUNTABILITY AND REPORTING FINANCIAL DISCLOSURE
              </p>
              <p style={{ paddingTop: '1rem' }}>PRINCIPLE:</p>
              <p>
                There shall be a high degree of accountability of Directors to
                shareholders and other stakeholders of the Bank and of the
                Management to the Directors.
              </p>
              <ListOne type='lower-alpha'>
                {reporting.map(({ item }) => (
                  <ListItem>{item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '1rem' }}>COMPOSITION:</p>
              <p>
                The Committee shall consist of such number of persons as the
                Board may determine.
              </p>
              <p style={{ paddingTop: '1rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Committee shall meet at least once every quarter after the
                commencement of each financial year. Special meetings may be
                convened as needed. <br />
                It would be desirable if each meeting does not exceed an elapsed
                time of 4 to 5 hours. The quorum for the meeting of the
                Committee shall be determined by the Board.
              </p>
              <p style={{ paddingTop: '1rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              </Content>
              </FlexBox> 
            ): (
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey2} alt='grey' className='grey' />
              <Image src={Blue2} alt='blue' className='blue' />
            </ImageWrapper>
            <Content>
              <h1>Board Responsibilities</h1>
              <p>
                Board Committees are sub-groups of the Board charged with making
                recommendations to the Board or taking decisions on behalf of
                the Board on issues of expediency that may arise outside the
                normal meeting schedule of the entire Board.
                <br /> The respective committees are easier to convene because
                fewer people are involved and this arrangement therefore
                facilitates speedy decision-making.
                <br />{' '}
              </p>
              <p>
                The Bank’s Board should have the following minimum committees.
              </p>{' '}
              <p>1. BOARD CREDIT AND RISK MANAGEMENT COMMITTEE</p>
              <p>
                There shall be established a Board Credit and Risk Management
                Committee (the “BCRMC”) to exercise the Board’s responsibilities
                on credit and risk matters. The BCRMC shall provide oversight on
                Management’s activities in managing credit risks of the Bank.
              </p>
              <p style={{ paddingTop: '3rem' }}>PRINCIPLES:</p>
              <ListOne type='upper-roman'>
                {principles.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='1rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p>
                However, to carry out these functions, the Board has instituted
                its Credit & Risk Management Committee (BCRM) to assist it in
                fulfilling its roles as it relates to risk management. Without
                prejudice to the roles of the BCRM, the full board shall retain
                ultimate responsibility for risk management.
              </p>
              <p style={{ paddingTop: '3rem' }}>SCOPE/ROLES:</p>
              <p>
                The Scope and Roles of the Board Credit and Risk Management
                Committee of the company shall be as follows:
              </p>
              <ListOne>
                {scope.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>COMPOSITION</p>
              <ListOne type='lower-alpha'>
                {composition.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETINGS
              </p>
              <p>
                The Board Credit and Risk Management Committee shall meet at
                least once every quarter after the commencement of each
                financial year. Additional meetings may be convened when major
                issues arise, which need to be resolved between scheduled
                meetings. It would be desirable if each meeting does not exceed
                an elapsed time of 4 to 5 hours.
              </p>
              <p>
                The quorum for the meeting of the committee shall be determined
                by the Board.
              </p>
              <p style={{ paddingTop: '3rem' }}>INFORMATION REQUIREMENTS:</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least once 7 days before each meeting including the following:
              </p>
              <p style={{ paddingTop: '3rem' }}>
                2. BOARD AUDIT AND COMPLIANCE COMMITTEE
              </p>
              <p>
                The Audit & Compliance Committee shall provide oversight of
                Management’s activities in managing statutory compliance,
                internal control functions, operational, legal and all other
                risks of the company. It also oversees the financial and
                reporting activities of the Bank.
              </p>
              <p style={{ paddingTop: '2rem' }}>PRINCIPLES</p>
              <p>The committee shall:</p>
              <ListOne type='lower-alpha'>
                {auditPrinciples.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>SCOPE/ROLES:</p>
              <p>
                The scope and roles of the Audit & Compliance Committee of the
                company shall be as follows:
              </p>
              <p>
                The Audit & Compliance Charter defines the fundamental
                principles, roles and responsibilities of the Compliance
                function within the organisation as well as its relationship
                with executive management, the Board of Directors, business and
                operational functions.
                <br />
                The Charter encompasses all current and future regulatory
                requirements in terms of Compliance, in particular the Central
                Bank of Nigeria and Anti-Money Laundering and Counter Terrorism
                Act aiming at ensuring the compliance risk management via the
                formal implementation of Compliance function within the
                organisation.
              </p>
              <p>
                Once approved, the Charter applies to Members of the Board and
                staff members as well as to external service providers of the
                organisation and all its subsidiaries and branches. The Charter
                shall be updated from time to time to reflect legal and
                regulatory evolution and shall be communicated to all staff.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.1. Objectives of the Compliance Function
              </p>
              <p style={{ paddingTop: '2rem' }}>
                a. Independent function with the objective to:
              </p>
              {objectiveOne.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p>
                The Compliance function intervenes at the level of controls
                performed by executive management on activities under their
                direct responsibility.
              </p>
              <p style={{ paddingTop: '2rem' }}>
                b. The compliance risk is defined as the risk the Bank may
                suffer as a result of its failure to comply with applicable
                laws, regulation, codes of conduct and standards of good
                practice and includes:
              </p>
              {objectiveTwo.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                c. Applicable laws, regulation and professional standards relate
                to:
              </p>
              {objectiveThree.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.2. Responsibilities and Competencies
              </p>
              <p>
                The responsibility of compliance within an organisation is
                defined at four levels:
              </p>
              <p style={{ paddingTop: '2rem' }}>
                a. Responsibilities of the Board of Directors
              </p>
              <ListOne>
                {objectiveFour.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '2rem' }}>
                b. Responsibilities of Executive Management (“four-eyes”)
              </p>
              {objectiveFive.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                  {items.subItems &&
                    items.subItems.map(({ item }) => (
                      <ListThree>
                        <ListItem>{item}</ListItem>
                      </ListThree>
                    ))}
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                c. Responsibilities and competencies of the Compliance function
              </p>
              <p>The Compliance function must:</p>
              {objectiveSix.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '2rem' }}>
                d. Responsibilities of Line Managers, Staff Members and External
                Service providers at a functional level, the compliance function
                provides advisory, monitoring, training and control services.
                <br /> At an operational level, compliance with the
                organisation’s integrity rules is the responsibility of line
                management, all staff members and external service providers
                through adherence to applicable laws, regulations and the
                organisation’s policies, standards and procedures.
                <br /> In this context, it is important to stress that the line
                managers, staff members and external service providers remain
                personally responsible for:
              </p>
              {objectiveSeven.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.3. Independence of the Function
              </p>
              <p>
                The Compliance Officer and all staff of the Compliance function
                is independent from any commercial, administrative or control
                function within the organisation in order to allow them to carry
                out their work freely and objectively. Independence is achieved
                through organisational status and objectivity:
              </p>
              {objectiveEight.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p style={{ paddingTop: '3rem' }}>
                1.4. Prominence of the Function - In Line with CBN Directive
              </p>
              <p>
                Executive management is responsible for establishing a permanent
                Compliance function within the organisation. For this purpose,
                executive management formally appoints a Compliance Officer,
                whose name and subsequent changes thereto are communicated to
                the Central Bank of Nigeria, and grants sufficient human and
                technical resources to achieve the defined Compliance
                objectives.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.5. Relationship with Other Business Unit and Operational
                Functions
              </p>
              <p>
                Certain tasks may be delegated to or coordinated with other
                business and operational functions in the organisation. The list
                of such delegated tasks is as follows:
              </p>
              {objectiveNine.map((items, i) => (
                <ListUnstyle key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListUnstyle>
              ))}
              <p>
                In these cases, the Compliance function coordinates and
                structures the delegated compliance related tasks in accordance
                with the following principles:
              </p>
              {objectiveTen.map((items, i) => (
                <ListTwo key={i}>
                  <ListItem>{items.item}</ListItem>
                </ListTwo>
              ))}
              <p>
                In order to strengthen the cooperation between the two functions
                and to foster the full implementation of the Compliance policy
                in all business and operational areas, the Compliance Officer
                undertakes to organise quarterly meetings with the responsible
                persons to whom tasks have been delegated.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.6. Relationship with Internal Audit
              </p>
              <p>
                The Compliance function is separate from the internal audit
                function. Where feasible, Compliance may consider delegating
                certain tasks to internal audit, or coordinating certain
                compliance matters. The work and performance of the Compliance
                function is subject to periodic review by internal audit.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.7. Access to Information and Confidentiality
              </p>
              <p>
                The staff members of the Compliance function shall have full and
                unrestricted access to any information at any time. All staff
                members of the Compliance function must comply with
                confidentiality requirements. Any confidential information
                received during the course of the exercise of their roles and
                responsibilities cannot be used for any personal gain or in any
                manner which would be contrary to law or detrimental to the
                interests of the organisation.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.8. Right to Initiate Investigations
              </p>
              <p>
                The Compliance function has the right to start investigations at
                its own initiative, when deemed necessary. In such case, the
                Compliance function may use the expertise from other functions
                within the organisation (e.g. legal department and internal
                audit). The Compliance function remains, however, responsible
                for coordinating the investigation and for presenting the
                outcome of the analysis to Managing Director and/or the Board of
                Directors, if appropriate.
              </p>
              <p style={{ paddingTop: '3rem' }}>1.9. Reporting Lines</p>
              <p>
                Considering the hierarchical link of the Compliance function to
                executive, a formal regular reporting line to the Board of
                Directors must be implemented. In addition, the Compliance
                Officer has an off the cuff reporting line to the Managing
                director who has formally been appointed as manager for
                Compliance.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.10. Right to Contact the Board of Directors
              </p>
              <p>
                The Compliance Officer shall have the right to discuss any
                relevant issue with the executive management. Furthermore, an
                escalation procedure must be defined and implemented in order to
                guarantee the Compliance Officer’s right to report significant
                incidents directly to the Board of Directors or its relevant
                Committees.
              </p>
              <p style={{ paddingTop: '3rem' }}>
                1.11. Recourse to External Experts
              </p>
              <p>
                The Compliance function has recourse to the expertise or
                technical means of third parties outside the institution or
                within the same group, for certain specific or technical aspects
                (e.g.legal advice), but retains responsibility for outsourced or
                co-sourced assignments. Third party access to confidential info
                must be subject to approval of Executive Management, adequate
                supervision and / or execution of a confidentiality agreement.
              </p>
              <p>
                N.B: Escalation to the Board must be possible for the Chief
                Compliance Officer without any limitation or sanction. This is
                recommended as a guarantee of the independence of the Compliance
                function towards business and operational staff that may prevail
                with executive management. Institutions may consider carrying
                out a regular and timely compliance review at Board Level.
              </p>
              <ListOne type='lower-alpha'>
                {objectiveEleven.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>COMPOSITION:</p>
              <p>
                The Audit & Compliance Committee shall be composed as follows:
              </p>
              <ListOne type='lower-alpha'>
                {auditComposition.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Audit & Compliance Committee shall meet at least once every
                quarter after the commencement of each financial year. <br />
                Additional meetings maybe convened when major issues arise,
                which need to be resolved between scheduled meetings. It would
                be desirable if each meeting does not exceed an elapsed time of
                4 to 5 hours. The quorum shall be as determined by the Board.
              </p>
              <p style={{ paddingTop: '3rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                3. BOARD GOVERNANCE & REMUNERATION COMMITTEE
              </p>
              <p>
                This Committee is established to oversee the Bank’s corporate
                governance and cater for selection, compensation and performance
                management of Directors of the company and Senior Management.
              </p>
              <p style={{ paddingTop: '1rem' }}>PRINCIPLE:</p>
              <p>
                The Committee shall select, reward and manage the performance of
                the Directors of the Bankand Senior Management in such a manner
                as to guarantee the level of corporate governance necessary to
                achieve the Bank’s corporate vision.
              </p>
              <p style={{ paddingTop: '1rem' }}>SCOPE AND ROLE:</p>
              <ListOne type='lower-alpha'>
                {boardScope.map((items, i) => (
                  <ListItem key={i}>{items.item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '1rem' }}>COMPOSITION:</p>
              <p>
                The Committee shall consist of such number of persons as the
                Board may determine.
              </p>
              <p style={{ paddingTop: '1rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Committee shall meet at least once every quarter after the
                commencement of each financial year. Apart from scheduled
                meetings, special meetings may be convened as needed.
                <br />
                It would be desirable if each meeting does not exceed an elapsed
                time of 4 to 5 hours. The quorum for the meeting of the
                Committee shall be determined by the Board.
              </p>
              <p style={{ paddingTop: '1rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                4. BOARD STRATEGY AND FINANCIAL ANALYSIS COMMITTEE PRINCIPLE:
              </p>
              <p>
                This Committee shall be the strategic power house of the Bank.
              </p>
              <p style={{ paddingTop: '1rem' }}>SCOPE AND ROLE:</p>
              <ListOne type='lower-alpha'>
                {strategyScope.map(({ item }) => (
                  <ListItem>{item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '3rem' }}>
                5. ACCOUNTABILITY AND REPORTING FINANCIAL DISCLOSURE
              </p>
              <p style={{ paddingTop: '1rem' }}>PRINCIPLE:</p>
              <p>
                There shall be a high degree of accountability of Directors to
                shareholders and other stakeholders of the Bank and of the
                Management to the Directors.
              </p>
              <ListOne type='lower-alpha'>
                {reporting.map(({ item }) => (
                  <ListItem>{item}</ListItem>
                ))}
              </ListOne>
              <p style={{ paddingTop: '1rem' }}>COMPOSITION:</p>
              <p>
                The Committee shall consist of such number of persons as the
                Board may determine.
              </p>
              <p style={{ paddingTop: '1rem' }}>
                FREQUENCY, TIMING AND QUORUM OF MEETING
              </p>
              <p>
                The Committee shall meet at least once every quarter after the
                commencement of each financial year. Special meetings may be
                convened as needed. <br />
                It would be desirable if each meeting does not exceed an elapsed
                time of 4 to 5 hours. The quorum for the meeting of the
                Committee shall be determined by the Board.
              </p>
              <p style={{ paddingTop: '1rem' }}>INFORMATION REQUIREMENTS</p>
              <p>
                In order to fulfill their responsibilities, committee members
                should have access to accurate, relevant and timely information
                and all reports must be delivered to the committee members at
                least 7 days before each meeting including the following:
              </p>
              <ListOne type='upper-alpha'>
                {informationRequirements.map((items, i) => (
                  <>
                    <ListItem key={i}>{items.item}</ListItem>
                    <ListTwo ml='2rem'>
                      {items.subItems &&
                        items.subItems.map((single, i) => (
                          <ListItem key={i}>{single.item}</ListItem>
                        ))}
                    </ListTwo>
                  </>
                ))}
              </ListOne>
            </Content>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 80%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  padding-top: 0;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto 0;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 0 auto;
  padding-top: 3rem
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 0 auto!important;
  padding-top: 3rem
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 85%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.phone`
  width: 85%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.smallPhone`
  width: 85%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.galaxyFold`
  width: 85%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
`;

const Content = styled.div`
  width: 80%;
  margin-left: 10rem;
  ${Screen.mediumpc`
  width: 90%;
  margin-left: 7rem!important;
  `}
  ${Screen.smallpc`
  width: 100%;
  margin-left: 0!important;
  `}

  h1 {
    color: #002668;
    /* padding-top: 5rem; */
  }

  p {
    padding-top: 1rem;
    font-size: 0.9rem;
    ${Screen.smallpc`
    padding-top: .5rem;
    `}
    ${Screen.mediumPhone`
    font-size: 14px;
    padding-top: .5rem;
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-top: .5rem;
    `}
    ${Screen.smallPhone`
    font-size: 13px;
    padding-top: .5rem;
    `}
  }

  h2 {
    ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 10rem;
      top: 8rem;
      color: #002668
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 8rem;
      top: 6rem
      `}
       ${Screen.mediumPhone`
      font-size: 20px;
      position: absolute;
      left: 4rem;
      top: 5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 4rem;
      top: 5rem
      `}
      ${Screen.samsungGalaxy`
      font-size: 20px;
      position: absolute;
      left: 3rem;
      top: 5rem
      `}
      ${Screen.smallPhone`
      font-size: 16px;
      position: absolute;
      left: 4rem;
      top: 5rem;
      `} 
       ${Screen.galaxyFold`
      font-size: 14px;
      position: absolute;
      left: 3rem;
      top: 5rem;
      `} 
  }
`;
const Image = styled.img`
  &.saving {
    margin-bottom: 3rem;
  }
  &.check {
    width: 25px;
    height: 25px;
  }
  &.grey {
    width: 250px;
    position: absolute;
    z-index: 1;
    ${Screen.miniLargeScreen`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 250px;
    position: absolute;
    top: 8rem;
    ${Screen.miniLargeScreen`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.pad`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 70vh;
  &.hero {
    min-height: 50vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;

  ${Screen.smallpc`
  flex-direction: column;
  margin: 0!important
  `}
`;

const ImageWrapper = styled.div`
  width: 20%;
  position: relative;
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }

  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 10px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  ${Screen.mediumPhone`
  padding-right: 5px!important;
  font-size: 13px;
  `}
  ${Screen.phone`
  padding-right: 5px!important;
  `}
  ${Screen.smallPhone`
  padding-right: 5px!important;
  `}
    ${Screen.galaxyFold`
    font-size: 12px
  `}
`;
const ListOne = styled.ol`
  list-style-type: ${({ type }) => type};
  font-size: 0.9rem;

  /* &.upper-roman {
    ${Screen.smallPhone`
    font-size
    `}
  } */
`;
const ListTwo = styled.ul`
  list-style-type: disc;
  margin-left: ${({ ml }) => ml};
  font-size: 0.9rem;
  ${Screen.mediumPhone`
  margin-left: 3rem!important
  `}
  ${Screen.phone`
  margin-left: 3rem!important
  `}
  ${Screen.smallPhone`
  margin-left: 3rem!important
  `}
`;
const ListThree = styled.ul`
  list-style-type: circle;
  margin-left: 1rem;
  font-size: 0.9rem;
  ${Screen.mediumPhone`
  margin-left: .5rem
  `}
  ${Screen.phone`
  margin-left: .5rem
  `}
  ${Screen.smallPhone`
  margin-left: .5rem
  `}
`;
const ListItem = styled.li`
  padding-top: 0.5rem;
  ${Screen.mediumPhone`
  font-size: 14px
  `}
  ${Screen.phone`
  font-size: 14px
  `}
  ${Screen.smallPhone`
  font-size: 13px
  `}
`;
const ListUnstyle = styled.ul`
  list-style-type: none;
  font-size: 0.9rem;
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
margin-top: 3rem;
`;

export default Responsibility;
