import styled from 'styled-components';

export default styled.form`
  input {
    margin-bottom: 30px;
    border: 1px solid #dbdbdb;
    padding: 10px 30px;
    border-radius: 15px;
    outline: none;
    width: 80%;
    background-color: transparent;
    color: ${({ theme }) => theme.inputText};
  }

  textarea {
    margin-bottom: 30px;
    border: 1px solid #dbdbdb;
    padding: 10px 30px;
    border-radius: 15px;
    outline: none;
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.inputText};
    resize: none;
    height: 200px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
