import { Facebook, Instagram, LinkedIn, Twitter } from '../../assets/svg';

export const icons = [
  {
    img: Facebook,
    path: 'https://www.facebook.com/AbbeyMortgageBankPlc'
  },
  {
    img: Instagram,
    path: 'https://www.instagram.com/abbeymortgagebankplc/'
  },
  {
    img: Twitter,
    path: 'https://twitter.com/Abbeymortgage'
  },
  {
    img: LinkedIn,
    path: 'https://www.linkedin.com/company/abbeymortgagebank/mycompany/'
  },
];
