import React from 'react';
import styled from 'styled-components';
import { Screen } from '../../styles'

const modal = ({ header, r, content }) => {
  return (
    <Modal>
      <ModalCard>
        <ModalContent>
          <h2>{header}</h2>
          <p dangerouslySetInnerHTML={{__html: content}} />
        </ModalContent>
        <Button onClick={() => r.closeModal()}>Close</Button>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  ${Screen.pad`
  padding-top: 50px;
  `}
  ${Screen.surfaceDuo`
  padding-top: 10px;
  `}
  ${Screen.mediumPhone`
  padding-top: 10px;
  `}
  ${Screen.phone`
  padding-top: 10px;
  `}
  ${Screen.smallPhone`
  padding-top: 10px;
  `}
`;
const ModalCard = styled.div`
  width: 400px;
  height: auto;
  margin: 10rem auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 3rem;

  ${Screen.surfaceDuo`
  width: 70%;
  padding: 2rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  `}
  ${Screen.phone`
  width: 90%;
  `}
  ${Screen.smallPhone`
  width: 90%;
  `}
  ${Screen.galaxyFold`
  width: 90%;
  `}
`;
const ModalContent = styled.div`
  text-align: center;
  h2 {
    color: #c81e19;
    font-size: 1.5rem;
    padding-bottom: 1rem;

    ${Screen.mediumPhone`
    font-size: 1rem;
    `}
    ${Screen.phone`
    font-size: 1rem;
    `}
    ${Screen.smallPhone`
    font-size: 1rem;
    `}
    ${Screen.galaxyFold`
    font-size: 1rem;
    `}
  }

  p {
    color: #002668;
    font-weight: 500;
    ${Screen.mediumPhone`
     text-align: center;
     word-spacing: .1rem;
    `}
    ${Screen.phone`
     text-align: center;
     word-spacing: .1rem;
    `}
    ${Screen.smallPhone`
     text-align: center;
     word-spacing: .1rem;
    `}
     ${Screen.galaxyFold`
     text-align: center;
     word-spacing: 0;
    `}
  }
`;

const Button = styled.div`
width: max-content;
margin-top: 2rem;
/* margin-bottom: 2rem; */
background-color: #002668;
/* box-shadow: 5px 3px 5px #ccc; */
color: #fff!important;
border-radius: 5px;
font-size: 1.2rem;
font-weight: bold;
padding: .5rem 1rem;
cursor: pointer;

${Screen.mediumPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
${Screen.phone`
padding: .7rem 1rem;
font-size: 1rem;
`}
${Screen.smallPhone`
padding: .7rem 1rem;
font-size: 1rem;
`}
`;
export default modal;
