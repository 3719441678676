import componentLoader from './componentLoader';
import screenLoader from './screenLoader';
import dashboardLoader from './dashboardLoader';
// import iconFill from './iconFill';
import Notify from './notify';
import Modal from './modal';
import Alert from './alert';
import IBModal from "./ibModal"
import DocModal from "./docModal"

const components = {
  componentLoader,
  screenLoader,
  dashboardLoader,
  DocModal,
  Notify,
  Modal,
  Alert,
  IBModal
};

export default components;
