import { useMemo, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';




const mapContainer = {
  width: '100%',
  height: '70vh',
};
const midContainer = {
  width: '100%',
  height: '30vh'
}
const mobileContainer = {
  width: '100%',
  height: '50vh'
}

const MapComp = ({ coordinate, apiKey }) => {
  const [mobile, setMobile] = useState(false)
  const [midMobile, setMidMobile] = useState(false)

  useEffect(() => {
    const responsive = () => {
      return window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
    }
    const midScreen = () => {
      return window.innerWidth <= 1000 ? setMidMobile(true) : setMidMobile(false)
    }
    responsive()
    midScreen()
    window.addEventListener('resize', () => responsive())
    window.addEventListener('resize', () => midScreen())

    return () => {
      window.removeEventListener('resize', () => responsive())
      window.removeEventListener('resize', () => midScreen())
    }
  }, [])
  // const key = localStorage.getItem('key')
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: apiKey,
    });
  if (!isLoaded) return <div>Loading...</div>;
  return <Maps coordinate={coordinate} mobile={mobile} midMobile={midMobile} />;
};

function Maps({ coordinate, mobile, midMobile }) {
  const center = useMemo(() => coordinate, [coordinate]);
  return (
    <>
      <GoogleMap
        mapContainerStyle={mobile ? mobileContainer : midMobile ? midContainer: mapContainer}
        zoom={17}
        center={center} // Lagos
        // onLoad={coordinate}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false
        }}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </>
  );
}

export default MapComp;
