import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormImg, LoanProducts } from '../../assets/img';
import { HelpersComponent } from '../../components';
// import { Footer } from '../../components/footer';
import { Decorate, Screen } from '../../styles';
import api from '../../services/api';
import { Tools } from '../../utils';

// const Navigation = NavigationBar.Navigation;
const Loader = HelpersComponent.componentLoader
const Modal = HelpersComponent.Alert
// const Notify = HelpersComponent.Notify;
const MortgageForm = () => {

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    propertyType: '',
    locationOfProperty: '',
    propertySalesPrice: '',
    downPayment: '',
    tenure: '',
    source: 'website',
    monthlyIncome: '',
    mortgage: '',
    message: ''
  })
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [modalHeader, setModalHeader] = useState("")

  const { 
    firstName, lastName, email, phoneNumber, propertyType, 
    locationOfProperty, propertySalesPrice, downPayment, tenure, 
    monthlyIncome, source, mortgage, message
  } = userData

  const validation = mortgage === 'mortgage' ? 
  Tools.validateInput([firstName, lastName, email, phoneNumber, propertyType, locationOfProperty, propertySalesPrice, downPayment, tenure, monthlyIncome]) :
  Tools.validateInput([mortgage, firstName, lastName, email, phoneNumber, message])
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const r = {
    onChange(e){
      setUserData({
        ...userData,
        [e.target.name]: e.target.value
      })
    },

    async handleMortgage(){
      try {
        const body = {
          firstName,
          lastName,
          email,
          phoneNumber,
          propertyType,
          locationOfProperty,
          propertySalesPrice,
          downPayment,
          tenure,
          source,
          monthlyIncome
        }

        const data = await api.req('/api/document/loan', 'POST', body)
        return data
      } catch (error) {
        
      }
    },

    async handleLoans(){
      try {

        const body = {
          to: "customerexperience@abbeymortgagebank.com",
          subject: `Application for ${mortgage}`,
          html: `
          <p>First Name: ${firstName}</p>
          <p>Last Name: ${lastName}</p>
          <p>Email: ${email}</p>
          <p>Phone Number: ${phoneNumber}</p>
          <p>Description: ${message}</p>
          `
        }

        const data = await api.req('/api/document/notification', 'POST', body)
        return data
      } catch (error) {
        
      }
    },

    async onSubmit(e){
      e.preventDefault()
      try {
        setLoading(true)
        const data = mortgage === 'mortgage' ? await r.handleMortgage() : await r.handleLoans()
        if(data.status === "success"){
          setShowModal(true)
          setModalMessage(`Your form has been successfully submitted! <br /> A customer experience
          representative will contact you soon for further processing.`)
          setModalHeader("Application Successful")
          setUserData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            propertyType: '',
            locationOfProperty: '',
            propertySalesPrice: '',
            downPayment: '',
            tenure: '',
            source: 'website',
            monthlyIncome: '',
            mortgage: '',
            message: ''
          })
        } else {
          setShowModal(true)
          setModalMessage(data.responseMessage);
          setModalHeader("Application Failed")
        }
        setLoading(false)
      } catch (error) {
        
      }
    },

    closeModal(){
      setShowModal(false)
    }
  }
  
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav    */}
      {/* <Banner>
          <h1>
          Learn More about <br /> <span>Abbey Mortgages</span>
          </h1>
          </Banner> */}

      <Section className='hero'></Section>
      <Container>
        {/* Form Section */}
        <Section>
          <Paragraph>
          Fill out the following form to be contacted by one of our 
          CUSTOMER SERVICE representatives
          </Paragraph>
          <FlexBox>
            <Image src={FormImg} alt='learn' />
            <FormContainer autoComplete='off'>
              <FlexBox className="names">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>First Name</label>
                  <input
                    name='firstName'
                    placeholder='Enter first name'
                    onChange={(e) => r.onChange(e)}
                    value={firstName}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Last Name</label>
                  <input
                    name='lastName'
                    placeholder='Enter last name'
                    onChange={(e) => r.onChange(e)}
                    value={lastName}
                  />
                </div>
              </FlexBox>
              <div style={{ marginTop: '1rem' }}>
                <label>Email Address</label>
                <input 
                    style={{ width: '100%' }} 
                    name='email'
                    placeholder='Enter email address'
                    onChange={(e) => r.onChange(e)}
                    value={email}
                 />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Phone Number</label>
                <input 
                  style={{ width: '100%' }} 
                  name='phoneNumber'
                  placeholder='Enter phone number'
                  onChange={(e) => r.onChange(e)}
                  value={phoneNumber}
                 />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Type of Loan you are interested in</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='mortgage'
                    onChange={(e) => r.onChange(e)}
                    value={mortgage}
                  >
                    <option></option>
                    <option value={'mortgage'}>Mortgage</option>
                    <option value={'Equity Release'}>Equity Release</option>
                    <option value={'Mortgage Refinancing'}>
                      Mortgage Refinancing
                    </option>
                    <option value={'Construction Finance'}>
                      Construction Finance
                    </option>
                  </select>
                </Select>
              </div>
              {
                mortgage === 'mortgage' && (
                  <>
                  
              <div style={{ marginTop: '1rem' }}>
                <label>Property Type</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='propertyType'
                    onChange={(e) => r.onChange(e)}
                    value={propertyType}
                  >
                    <option></option>
                    <option value={'apartment'}>Apartment</option>
                    <option value={'land'}>Land</option>
                    <option value={'flat'}>Flat</option>
                    <option value={'bungalow'}>
                      Bungalow
                    </option>
                    <option value={'semi-detached'}>
                      Semi Detached
                    </option>
                  </select>
                </Select>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Location of Property</label>
                <input 
                  style={{ width: '100%' }} 
                  name='locationOfProperty'
                  placeholder='Enter property location'
                  onChange={(e) => r.onChange(e)}
                  value={locationOfProperty}
                 />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Property Sales Price</label>
                <input 
                  style={{ width: '100%' }} 
                  name='propertySalesPrice'
                  placeholder='Enter property price e.g 1000000'
                  onChange={(e) => r.onChange(e)}
                  value={propertySalesPrice}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Initial Deposit</label>
                <input 
                  style={{ width: '100%' }}  
                  name='downPayment'
                  placeholder='Enter initial deposit e.g 50000'
                  onChange={(e) => r.onChange(e)}
                  value={downPayment}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Tenure</label>
                <input 
                  style={{ width: '100%' }}  
                  name='tenure'
                  placeholder='Enter loan tenure'
                  onChange={(e) => r.onChange(e)}
                  value={tenure}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Monthly Income</label>
                <input 
                  style={{ width: '100%' }} 
                  name='monthlyIncome' 
                  placeholder='Enter monthly income'
                  onChange={(e) => r.onChange(e)}
                  value={monthlyIncome}
                />
              </div>
                  </>
                )
              }
              {
                mortgage !== 'mortgage' && (
                  <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '2rem',
                }}
              >
                <label>Additional Message</label>
                <textarea 
                name='message' 
                onChange={(e) => r.onChange(e)}
                value={message}
                 />
              </div>
                )
              }
              <ButtonWrapper>
                {
                  loading ? 
                  <Button className='loading'><Loader color={'#fff'} /></Button> : 
                  <Button 
                  onClick={(e) => r.onSubmit(e)} 
                  className={validation ? 'disabled' : undefined}>Submit</Button>
                }
              </ButtonWrapper>
            </FormContainer>
          </FlexBox>
        </Section>
        {/* End of form section */}
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
      {showModal && <Modal header={modalHeader} content={modalMessage} r={r} />}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 95%;
  margin: 3rem auto!important;
  `}
  ${Screen.galaxyFold`
  width: 95%;
  margin: 3rem auto!important;
  `}

`;

const Paragraph = styled.p`
  color: #9a9494;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 3rem;
  font-size: 1.1rem;
  margin-top: 3rem;

  a {
    color: #0867BE;
    padding-top: 1rem
  }

  ${Screen.smallpc`
  margin-top: 5rem!important;
  `}
  ${Screen.tablet`
  font-size: 1.1rem;
  `}
  ${Screen.mediumPhone`
  font-size: .9rem;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  `}
  ${Screen.galaxyFold`
  font-size: .7rem;
  `}
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  &.names {
    /* margin: 0; */

    input {
      width: 300px;

      ${Screen.smallpc`
      width: 250px;
      `}

      ${Screen.surfaceDuo`
      width: 100%!important;
      `}
    }
  }

  ${Screen.surfaceDuo`
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  &.names {
    width: 100%!important
  }
  `}
  ${Screen.mediumPhone`
  width: 80%;
  &.names {
    width: 100%!important
  }
  `}
   ${Screen.phone`
  width: 90%;
  &.names {
    width: 100%!important
  }
  `}
  ${Screen.smallPhone`
  input {
    width: 100%!important;
    margin: 0;
  }
  `}
`;
const Section = styled.section`
width: 100%;
  &.hero {
    width: 100%;
    min-height: 50vh;
    background: url(${LoanProducts});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  /* margin-top: 5rem; */
`;
const Image = styled.img`
  margin-right: 5rem;
  height: 600px;
  ${Screen.midScreen`
  display: none
  `}
`;
const FormContainer = styled.form`
  width: 100%;
  height: auto;
  background-color: white;

  h1 {
    font-size: 13px;
    font-weight: 800;
    font-family: 'Roboto Flex', sans-serif;
    align-self: left;
    color: #0040a4;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  label {
    font-size: 15px;
    font-weight: 400;
    /* align-self: center; */
    color: #002668;
    margin: 0;
    text-transform: uppercase;

    /* ${Screen.smallPhone`
    font-size: 13px;
    `} */
  }

  input {
    /* width: 300px; */
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    /* color: #0a3d94; */
    color: #9a9494;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  textarea {
    margin-top: 10px;
    border: 1px solid #dbd9d9;
    padding: 10px 30px;
    border-radius: 5px;
    outline: none;
    background-color: #f5f5f5;
    color: #9a9494;
    resize: none;
    height: 150px;
  }
`;
const Select = styled.div`
  width: 100%;
  ${Decorate.select};

  .select-css {
    border-color: #dbd9d9;
    color: #9a9494;
  }

  .select-css option {
    font-size: 18px;
    margin-top: 1rem!important;

    &:nth-child(1){
      background-color: #002668!important
    }
  }

  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;

  p {
    padding: 3px 0px 0px 10px;
    margin: 0;
  }
`;


const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #c81e19;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  font-weight: bold;

  &.disabled {
    pointer-events: none;
    background-color: #ccc;
    color: #c81e19;
  }

  &.loading {
    height: 50px;
    width: 30px;
  }
`;
const ButtonWrapper = styled.div`
display: flex;
justify-content: end;

${Screen.surfaceDuo`
justify-content: start
`}
`

// const Text = styled.h1`
// padding-top: 5rem;
// text-align: center;
// `

export default MortgageForm;
