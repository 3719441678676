import React, { useEffect, useState, useContext, useRef } from 'react'
import styled from 'styled-components';
import { Screen } from '../../styles'
import { HelpersComponent } from '../../components' 
import { StoryBg } from '../../assets/img';
// import { Footer } from '../../components/footer';
import { SecurityComponents } from '../../components'
// import { disclosure } from '../../components/main/products';
import { DisclosureContext } from '../../contexts/doc';
import { DOC_FAILURE, DOC_REQUEST, DOC_SUCCESS } from '../../constants';
import api from '../../services/api';
import SecurityDoc from '../../assets/AMB_public_Information_Security_Policy.pdf'

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader
const { PolicyCard } = SecurityComponents;
const Security = () => {
    const [mobile, setMobile] = useState(false);
    const { dispatch, state } = useContext(DisclosureContext)
    let mounted = useRef(false)

    useEffect(() => {
      mounted.current = true
        window.scrollTo(0, 0);
        
        const responsive = () => {
            return window.innerWidth < 768 ? setMobile(true) : setMobile(false)
        }
        responsive()

        const getDisclosure = async () => {
          try {
            if(state.data.length < 1){
              dispatch({ type: DOC_REQUEST })
              const result = await api.req('/api/document/relations/disclosure')
              dispatch({
                type: DOC_SUCCESS,
                payload: [...JSON.parse(result.details.data), {link: SecurityDoc, title: 'AMB Information Security Policy'}]
              })
            }
          } catch (error) {
            dispatch({
              type: DOC_FAILURE,
              payload: error.response && error.response.result.responseMessage 
              ? error.response.result.responseMessage : error.responseMessage
            }) 
          }
        }
      getDisclosure()

        window.addEventListener("resize", () => responsive())

        return () => {
            window.removeEventListener("resize", () => responsive())
            mounted.current = false
        }
      }, []);

  return (
    <Box>
        {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}

      {/* Hero */}
        <Section className='hero'>
            <Container className='hero'>
              <Glass>
                <Content>
                    {
                        mobile ? (
                            <h2>Security &amp; <br /> Compliance</h2>
                        ): (
                            <h2>Security <br /> and Compliance</h2>
                        )
                    }
                </Content>
              </Glass>
            </Container>
        </Section>
        {/* End of Hero */}

        {/* Files */}
        <Section>
            <Container>
              {
                state.loading ? <ComponentLoader /> : (
                  <>
           <Header className='disclosure'>Regulatory Disclosures</Header>
           <CardContainer>
            {
                state?.data?.length && state.data.map((item, i) => (
                    <PolicyCard item={item} key={i} />
                ))
            }
           </CardContainer>  
                  </>
                )
              }

            </Container>
        </Section>
        {/* End of Files */}

        {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  margin: 5rem auto 0;
  &.hero {
    margin: 3rem auto;
    display: flex;
    justify-content: flex-start;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 5rem auto 0!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  padding-top: 2rem;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
`;

const Section = styled.section`
  width: 100%;
  min-height: 20vh;
  &.hero {
    min-height: 100vh;
    background-image: url(${StoryBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    padding-top: 13rem;
    ${Screen.miniLargeScreen`
    min-height: 70vh!important;
    padding-top: 2rem;
    `}
    ${Screen.pad`
    min-height: 80vh!important;
    padding-top: 2rem;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    padding-top: 0;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  ${Screen.mediumpc`
  min-height: 10vh;
  `}
  /* ${Screen.smallpc`
  min-height: 75vh;
  `}
  ${Screen.mediumPhone`
  min-height: 80vh;
  `}
  ${Screen.phone`
  min-height: 100vh;
  `} */
  `;
const Content = styled.div`
padding: 3rem;
    ${Screen.surfaceDuo`
    padding: 0 1rem 1rem;
    `}
    ${Screen.mediumPhone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.phone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.smallPhone`
    padding: 0 1rem 1rem;
    `}
    h2 {
    font-size: 45px;
    color: #002668;
    text-shadow: 3px 1px 3px #666;
    ${Screen.mediumpc`
      font-size: 30px;
      padding-top: 0rem;
    `}
    ${Screen.smallpc`
      font-size: 30px;
      padding-top: 0;
    `}
    ${Screen.tablet`
      font-size: 30px;
      padding-top: 0;
    `}
    ${Screen.surfaceDuo`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
    `}
    ${Screen.mediumPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
    `}
    ${Screen.phone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
    `}
    ${Screen.smallPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
    `}
    ${Screen.galaxyFold`
      padding: 1rem 0 .5rem!important;
      font-size: 22px;
      text-align: center;
    `}
}`;

const Glass = styled.div`
  width: 540px;
  height: 200px;
  background: rgba(255, 255, 255, 0.7);
  ${Screen.mediumpc`
  height: 200px;
  `}
  ${Screen.smallpc`
  width: 400px;
  height: 150px;
  `}
  ${Screen.tablet`
  height: 150px;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  height: 150px;
  margin: 0 auto;
  padding: 2rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  `}
  ${Screen.phone`
  width: 80%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 80%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;

const Header = styled.h2`
font-size: 32px;
color: #002668;

${Screen.smallpc`
padding-bottom: 2rem
`}
${Screen.surfaceDuo`
font-size: 24px;
padding-bottom: 1rem
`}
${Screen.mediumPhone`
font-size: 18px;
padding-bottom: 1rem
`}
`;

const CardContainer = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 5rem;
`


export default Security;