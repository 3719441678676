import { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import {
  HelpImg,
  Faqs,
  TouchImg,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  HelpMobile,
  PhoneIcon,
  WhatsappIcon,
  EmailIcon
} from '../../assets/img';
import { MapComponent, HelpersComponent } from '../../components';
// import { Footer } from '../../components/footer';
import { Faq, mapLinks } from '../../components/main';
import {
  helpLinks,
  abbeyMobileQuestions,
  mortgageBankingQuestions,
  businessBankingQuestions,
  personalBankingQuestions,
  debitCardQuestions,
} from '../../components/faqs';
import api from '../../services/api';
import { GoogleContext } from '../../contexts/google'
import { ContactHeroContext } from '../../contexts/hero';
import { GOOGLE_FAILURE, GOOGLE_REQUEST, GOOGLE_SUCCESS, HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS } from '../../constants';
import { Screen } from '../../styles'
import { Link } from 'react-router-dom';

// const Navigation = NavigationBar.Navigation;
const Maps = MapComponent.MapComp;
const ComponentLoader = HelpersComponent.dashboardLoader;
const Help = () => {
  const [mobile, setMobile] = useState(false)


  const [coordinate, setCoordinate] = useState({
    lat: 6.429740702222439,
    lng: 3.4253751637646426,
  });
  const mount = useRef()
  const [faqs, setFaqs] = useState('Abbey Mobile');

  const selectedLocation = (loc) => {
    setCoordinate(loc);
  };

  const activeFaqs = (faq) => {
    setFaqs(faq);
  };
  const { dispatch, state } = useContext(GoogleContext)
  const { dispatch: contactDispatch, state: contactState } = useContext(ContactHeroContext)
  const responsive = () => {
    return window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
  }
  // get api key
  const getKey = async () => {
  try {
    if(state.data === ''){
      dispatch({ type: GOOGLE_REQUEST})
      const result = await api.req('/api/document/google')
      dispatch({
        type: GOOGLE_SUCCESS,
        payload: result.details.data
      })
    }
  } catch (error) {
    dispatch({
      type: GOOGLE_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    })
    
  }
}

// get Hero
const getHero = async () => {
  try {
    if(contactState.data.length < 1){
      contactDispatch({ type: HERO_REQUEST })
      const result = await api.req('/api/document/hero/contact')
      contactDispatch({
        type: HERO_SUCCESS,
        payload: JSON.parse(result.details.data)
      })
    }
  } catch (error) {
    contactDispatch({
      type: HERO_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    }) 
  }
}


useEffect(() => {
  mount.current = true;
  //Scroll to top on page load
    window.scrollTo(0, 0);
    responsive()
    window.addEventListener('resize', () => responsive())
  getKey()
  getHero()

  return () => {
    window.removeEventListener('resize', () => responsive())
    mount.current = false;
  }

  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav    */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          
          {/* Hero Section */}
          <Section className='hero' bg={`url(${contactState.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Help Center</h2>
                </Content>
              </Glass>
            </Container>
          </Section>
          {/* End of Hero Section */}
    
          {/* Touch */}
          <Section className='touch'>
            {
              mobile ? (
                <MobileContainer className='touch'>
                  <Card>
                  <Header className='touch'>Get in touch with us</Header>
                  <h3>Head Office</h3>
                  <p>
                    No 23 Karimu Kotun Street
                    Victoria Island, Lagos, Nigeria
                  </p>
                  {/* <div style={{ margin: '1rem' }}>
                    <p>01-227-1695 </p>
                  </div> */}
                  <ContactWrapperMobile>
                    {/* <div style={{ display: 'flex'}}>
                    <ImageIcon icon={`url(${PhoneIcon})`} />
                    <label>02012271695</label>
                    </div> */}
                    {/* <FlexBox> */}
                    {/* <ImageIcon icon={`url(${PhoneIcon})`} /> */}
                    <div>
                    <h4>Hotline</h4>
                    <p>02012271695</p>
                    </div>
                    {/* </FlexBox> */}
                    {/* <FlexBox> */}
                    {/* <ImageIcon icon={`url(${WhatsappIcon})`} /> */}
                    <div>
                    <h4>Whatsapp</h4>
                    <p>+234 9134444840</p>
                    </div>
                    {/* </FlexBox> */}
                    {/* <FlexBox> */}
                    {/* <ImageIcon icon={`url(${EmailIcon})`} /> */}
                    <div>
                    <h4>Email</h4>
                    <p>customerexperience@abbeymortgagebank.com</p>
                    <p>enquiries@abbeymortgagebank.com</p>
                    </div>
                    {/* </FlexBox> */}
                    {/* <div style={{ display: 'flex'}}>
                    <label>+234 9134444840</label>
                    </div>
                    <div style={{ display: 'flex'}}>
                    <ImageIcon icon={`url(${EmailIcon})`} />
                    <label>customerexperience@abbeymortgagebank.com <br /> enquiries@abbeymortgagebank.com</label>
                    </div> */}
                  </ContactWrapperMobile>
                  <div>
                  <Link to={'https://www.facebook.com/AbbeyMortgageBankPlc'}>
                    <Image src={Facebook} alt='facebook' className='social'  />
                    </Link>
                    <Link to={'https://www.instagram.com/abbeymortgagebankplc/'}>
                    <Image src={Instagram} alt='instagram' className='social' />
                    </Link>
                    <Link to={'https://twitter.com/Abbeymortgage'}>
                    <Image src={Twitter} alt='twitter' className='social' />
                    </Link>
                    <Link to={'https://www.linkedin.com/company/abbeymortgagebank/mycompany/'}>
                    <Image src={LinkedIn} alt='linkedin' className='social' />
                    </Link>
                  </div>
                </Card>
                <ImageBackground bg={`url(${TouchImg})`}></ImageBackground>
                </MobileContainer>
              ): (
                <Container>
              <FlexBox>
                <ImageWrapper>
                  <Image src={TouchImg} alt='touch' className='touch' />
                </ImageWrapper>
                {/* <div> */}
                <Card>
                  <Header className='touch'>Get in touch with us</Header>
                  <h3>Head Office</h3>
                  <p style={{ padding: '0.5rem 1rem' }}>
                    No 23 Karimu Kotun Street
                    <br />Victoria Island, Lagos, Nigeria
                  </p>
                  {/* <div style={{ margin: '1rem' }}>
                    <p>02012271695</p>
                  </div> */}
                  <ContactWrapper>
                    <div style={{ display: 'flex'}}>
                    <ImageIcon icon={`url(${PhoneIcon})`} />
                    <label>02012271695</label>
                    </div>
                    <div style={{ display: 'flex'}}>
                    <ImageIcon icon={`url(${WhatsappIcon})`} />
                    <label>+234 9134444840</label>
                    </div>
                    <div style={{ display: 'flex'}}>
                    <ImageIcon icon={`url(${EmailIcon})`} />
                    <label>customerexperience@abbeymortgagebank.com <br /> enquiries@abbeymortgagebank.com</label>
                    </div>
                  </ContactWrapper>
                  <div style={{ display: 'flex', marginLeft: '1rem', marginTop: '1rem' }}>
                    <Link to={'https://www.facebook.com/AbbeyMortgageBankPlc'}>
                    <Image src={Facebook} alt='facebook' className='social'  />
                    </Link>
                    <Link to={'https://www.instagram.com/abbeymortgagebankplc/'}>
                    <Image src={Instagram} alt='instagram' className='social' />
                    </Link>
                    <Link to={'https://twitter.com/Abbeymortgage'}>
                    <Image src={Twitter} alt='twitter' className='social' />
                    </Link>
                    <Link to={'https://www.linkedin.com/company/abbeymortgagebank/mycompany/'}>
                    <Image src={LinkedIn} alt='linkedin' className='social' />
                    </Link>
                  </div>
                </Card>
                {/* </div> */}
              </FlexBox>
            </Container>
              )
            }
          </Section>
          {/* End of Touch */}
    
          {/* Map */}
          <Section className='map' id='location'>
            {
              mobile ? (
                <MobileContainer className='map'>
                  <Header className='map'>
                    Come <span>Find</span> Us
                  </Header>
                  <Maps coordinate={coordinate} apiKey={state.data} />
                  {
                    mapLinks.map(({ address, coord }) => (
                      coordinate === coord && <AddressCard>{address}</AddressCard>
                    ))
                  }
                  <CardText>Please select one of the following locations to see the address in the map above</CardText>
                  <CardContainer>
                    {mapLinks.map(({ item, coord }) => (
                      <MapCard key={item}>
                        <MapLink
                          onClick={() => selectedLocation(coord)}
                          className={coordinate === coord ? 'active' : null}
                          // style={{ color: coordinate === coord && '#C81E19' }}
                        >
                          {item}
                        </MapLink>
                      </MapCard>
                    ))}
                  </CardContainer>
                </MobileContainer>
              ): (
                <Container className='map'>
                  <Header className='map'>
                    Come <span>Find</span> Us
                  </Header>
                  <Maps coordinate={coordinate} apiKey={state.data} />
                  {
                    mapLinks.map(({ address, coord }) => (
                      coordinate === coord && <AddressCard>{address}</AddressCard>
                    ))
                  }
                  <CardContainer>
                    {mapLinks.map(({ item, coord }) => (
                      <MapCard key={item}>
                        <MapLink
                          onClick={() => selectedLocation(coord)}
                          className={coordinate === coord ? 'active' : null}
                          // style={{ color: coordinate === coord && '#C81E19' }}
                        >
                          {item}
                        </MapLink>
                      </MapCard>
                    ))}
                  </CardContainer>
                </Container>
              )
            }
          </Section>
          {/* End of Map */}
    
          {/* Faqs */}
          <Section className='faqs' id='faqs'>
            {/* {
              mobile ? (
                <h1>Working on it...</h1>
              ): ( */}
                <> 
                <Background>
                  <Header className='faqs'>
                    <span>Frequently</span> Asked Questions
                  </Header>
                  <p>
                    Please select the relevant subject area to see the get the answers to
                    the Frequently asked questions
                  </p>
                  <LinkContainer>
                    {helpLinks.map(({ name }) => {
                      return (
                        <HelpCard className={faqs === name ? 'active' : null}>
                          <HelpLink
                            className={faqs === name ? 'active' : null}
                            onClick={() => activeFaqs(name)}
                            key={name}
                          >
                            {name}
                          </HelpLink>
                        </HelpCard>
                      );
                    })}
                  </LinkContainer>
                </Background>
                <QuestionContainer>
                  <Questions>
                    {faqs === 'Abbey Mobile' &&
                      abbeyMobileQuestions.map((item, i) => (
                        <Faq item={item} key={i} />
                      ))}
                    {faqs === 'Mortgage Banking' &&
                      mortgageBankingQuestions.map((item, i) => (
                        <Faq item={item} key={i} />
                      ))}
                    {faqs === 'Business Banking' &&
                      businessBankingQuestions.map((item, i) => (
                        <Faq item={item} key={i} />
                      ))}
                    {faqs === 'Personal Banking' &&
                      personalBankingQuestions.map((item, i) => (
                        <Faq item={item} key={i} />
                      ))}
                    {faqs === 'Debit Cards' &&
                      debitCardQuestions.map((item, i) => <Faq item={item} key={i} />)}
                  </Questions>
                </QuestionContainer>
                </>
              {/* )
           } */}
           
          </Section>
          {/* End of Faqs */}
          {/* </Container> */}
    
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 6rem auto 0;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 70%;
  margin: 0 auto!important;
  `}

  &.hero {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 5rem;
    `}
     ${Screen.smallpc`
    padding-top: 0;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }

  &.map {
    position: relative;
  }
`;
const Glass = styled.div`
  width: 540px;
  height: 130px;
  background: rgba(255, 255, 255, 0.5);
  ${Screen.pad`
  margin-top: 2rem;
  `}
   ${Screen.mediumpc`
  margin-top: 0;
  `}
  ${Screen.smallpc`
  background: rgba(255, 255, 255, 0);
  `}
`;
const Content = styled.div`
  &.glass {
    padding: 3rem;
    h2 {
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      text-transform: uppercase;
      ${Screen.smallpc`
      color: #fff;
      padding-left: 10rem;
      padding-top: 12rem;
      font-size: 35px;
      `}
      ${Screen.tablet`
      color: #fff;
      padding-left: 10rem;
      padding-top: 4rem;
      font-size: 35px;
      `}
      ${Screen.surfaceDuo`
      color: #fff;
      padding-left: 4rem;
      padding-top: 1rem;
      font-size: 35px;
      font-size: 24px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      color: #fff;
      padding-left: 3rem;
      padding-top: 0;
      `}
      ${Screen.phone`
      font-size: 24px;
      color: #fff;
      padding-left: 2rem;
      padding-top: 0;
      `}
      ${Screen.smallPhone`
      font-size: 24px;
      color: #fff;
      padding-top: 0;
      padding-left: 1rem;
      `}
      ${Screen.galaxyFold`
      font-size: 20px;
      color: #fff;
      padding-top: 0;
      `}
    }
  }
`;
const Card = styled.div`
  width: 460px;
  height: 400px;
  background-color: #fff;
  border: 3px solid #002668;
  flex-grow: 1;
  z-index: 1;
  margin-top: 4rem;
  padding-top: 3rem;
  ${Screen.pad`
  width: 400px;
  height: 450px;
  margin-top: 2rem;
  flex-grow: 0;
  padding-top: 2rem
  `}
  ${Screen.mediumpc`
  width: 320px;
  height: 350px;
  margin-top: 2rem;
  flex-grow: 0;
  padding-top: 2rem
  `}
  ${Screen.smallpc`
  width: 450px;
  height: 450px;
  margin-top: 2rem;
  flex-grow: 0;
  padding-top: 2rem
  `}
  ${Screen.tablet`
  width: 70%;
  height: 450px;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -2.5rem;
  position: absolute;
  `}
  ${Screen.surfaceDuo`
  width: 60%;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -2.5rem;
  position: absolute;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 450px;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  padding-top: 2rem
  `}
  ${Screen.phone`
  width: 90%;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  padding-top: 2rem
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  padding-top: 2rem
  `}
   ${Screen.galaxyFold`
  width: 90%;
  margin-top: 1rem!important;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  padding-top: 2rem
  `}

  h3 {
    color: #c81e19;
    text-transform: uppercase;
    padding-left: 1rem;
    padding-top: 2rem;
    ${Screen.smallpc`
    padding: 2rem 0 0;
    text-align: center
    `}
    ${Screen.mediumPhone`
    padding: 2rem 0 0;
    text-align: center
    `}
    ${Screen.phone`
    padding: 2rem 0 0;
    text-align: center
    `}
    ${Screen.smallPhone`
    padding: 2rem 0 0;
    text-align: center
    `}
  }

  p {
    ${Screen.tablet`
    text-align: center;
    padding-top: 1rem
    `}
    ${Screen.phone`
    font-size: 14px;
    padding: 0 1rem;
    `}

  }

  div {
    ${Screen.smallpc`
    margin-top: 1rem!important;
    text-align: center;
    justify-content: center
    `}
    ${Screen.tablet`
    margin-top: 1rem!important;
    p {
      padding-top: 0
    }
    `}
    ${Screen.mediumPhone`
    margin-top: 1rem!important;
    `}
    ${Screen.phone`
    margin-top: 1rem!important;
    `}
    ${Screen.smallPhone`
    margin-top: 1rem!important;
    `}
  }
`;
const ImageWrapper = styled.div`
  width: 67%;
  ${Screen.mediumpc`
  width: 60%
  `}
  ${Screen.smallpc`
  width: 60%
  `}
`;
const Image = styled.img`
  &.touch {
    /* width: 100%; */
    width: 930px;
    ${Screen.pad`
    width: 750px;
    height: 400px
    `}
    ${Screen.mediumpc`
    width: 600px;
    height: 400px
    `}
    ${Screen.smallpc`
    width: 600px;
    height: 400px
    `}
  }
  &.social {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  &.question {
    width: 550px;
    height: 770px;
    margin-left: -18rem;
    /* position: absolute; */
    /* z-index: -1; */
  }
`;
const CardContainer = styled.div`
  width: 90%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30rem;
  left: 4rem;
  ${Screen.miniLargeScreen`
  top: 34rem;
  left: 1rem;
  `}
  ${Screen.pad`
  top: 25rem;
  left: 1rem;
  `}
  ${Screen.mediumpc`
  top: 25rem;
  left: 1rem;
  `}
  ${Screen.smallpc`
  top: 20rem;
  left: 1rem;
  `}
    ${Screen.tablet`
  position: static;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  left: 0;
  right: 0;
  margin: 1rem auto;
  `}
   ${Screen.mediumPhone`
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center!important;
  align-items: center!important;
  left: 0;
  right: 0;
  margin: 1rem auto;
  `}
    ${Screen.phone`
  position: static;
  flex-direction: column;
  justify-content: center!important;
  align-items: center!important;
  left: 0;
  right: 0;
  margin: 1rem auto;
  `}
  ${Screen.smallPhone`
  position: static;
  flex-direction: column;
  justify-content: center!important;
  align-items: center!important;
  left: 0;
  right: 0;
  margin: 1rem auto;
  `}
`;

const MapCard = styled.div`
  width: 220px;
  height: 120px;
  background-color: #fff;
  box-shadow: 1px 3px 3px #ccc;
  text-align: center;
  margin-right: 1rem;
  padding-top: 3rem;
  ${Screen.pad`
  width: 100%!important;
  margin-right: 10px;
  `}
  ${Screen.mediumpc`
  width: 100%!important;
  margin-right: 10px;
  `}
  ${Screen.smallpc`
  width: 100%!important;
  margin-right: 10px;
  `}


  ${Screen.tablet`
  width: 80%!important;
  height: 50px;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 2rem 0;
  `}
${Screen.mediumPhone`
  width: 70%!important;
  height: 50px;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 2rem 0;
  `}
${Screen.phone`
  width: 70%!important;
  height: 50px;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 2rem 0;
  `}
  ${Screen.smallPhone`
  width: 80%!important;
  height: 50px;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 2rem 0;
  `}
  /* z-index: 1; */

  P {
    color: #002668;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    ${Screen.pad`
    font-size: 14px;
    `}
    ${Screen.mediumpc`
    font-size: 13px;
    `}
    ${Screen.smallpc`
    font-size: 12px;
    `}
    ${Screen.tablet`
    font-size: 14px
    `}
    ${Screen.mediumPhone`
    font-size: 14px
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }
`;

const AddressCard = styled.div`
position: absolute;
width: 300px;
height: 100px;
background: rgba(255, 255, 255, 0.8);
padding: 2rem 1rem;
left: 30%;
font-weight: bold;
top: 10rem;

${Screen.pad`
top: 8rem;
`}

${Screen.mediumpc`
top: 7rem;
font-size: 14px
`}
${Screen.smallpc`
top: 6rem;
font-size: 14px
`}
${Screen.tablet`
height: auto;
left: 30%;
top: 15rem;
font-size: 14px;
text-align: center;
padding: 1rem;
`}
${Screen.surfaceDuo`
top: 10rem;
left: 20%;
`}
${Screen.mediumPhone`
height: auto;
left: 15%;
top: 10rem;
font-size: 14px;
text-align: center;
padding: 1rem;
`}
${Screen.phone`
height: auto;
left: 10%;
top: 8rem;
font-size: 14px;
text-align: center;
padding: 1rem;
`}
${Screen.smallPhone`
width: 250px;
height: auto;
left: 10%;
top: 8rem;
font-size: 14px;
text-align: center;
padding: 1rem;
`}
${Screen.galaxyFold`
width: 250px;
height: auto;
left: 5%;
top: 8rem;
font-size: 14px;
text-align: center;
padding: 1rem;
`}
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 50vh;
    /* background-image: url(${HelpImg}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 5rem
    `}
    ${Screen.pad`
    padding-top: 5rem;
    min-height: 60vh;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.touch,
  &.map,
  &.faqs {
    padding-top: 5rem;
    min-height: 100vh;
    /* position: relative; */
  }
  &.feedback {
    padding-top: 7rem;
    min-height: 70vh;
  }

  &.map {
    ${Screen.pad`
    padding-top: 3rem;
    min-height: 110vh;
    `}
    ${Screen.mediumpc`
    padding-top: 3rem;
    min-height: 50vh;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    min-height: 20vh;
    `}
     ${Screen.tablet`
    padding-top: 3rem;
    min-height: 20vh;
    position: relative;
    `}
    ${Screen.mediumPhone`
    padding-top: 3rem;
    `}
    ${Screen.phone`
    padding-top: 3rem;
    `}
    ${Screen.smallPhone`
    padding-top: 3rem;
    `}
  }

  &.touch {
    ${Screen.miniLargeScreen`
    margin: 0!important;
    min-height: 90vh;
    `}
    ${Screen.mediumpc`
    min-height: 40vh;
    margin: 0!important;
    `}
    ${Screen.smallpc`
    min-height: 50vh;
    margin: 0!important;
    `}
    ${Screen.tablet`
    min-height: 60vh;
    margin: 0!important;
    `}
    ${Screen.surfaceDuo`
    min-height: 90vh;
    margin: 0!important;
    `}
    ${Screen.mediumPhone`
    min-height: 70vh;
    margin: 0!important;
    `}
    ${Screen.phone`
    min-height: 90vh;
    margin: 0!important;
    `}
    ${Screen.smallPhone`
    min-height: 90vh;
    margin: 0!important;
    `}
  }
`;
const Header = styled.h1`
  &.touch,
  &.map {
    color: #000a66;
    /* text-align: center; */
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: 700;
    /* padding-top: 3rem; */
    span {
      color: #c81e19;
    }
  }

  &.map {
    text-align: center;
    padding-bottom: 2rem;
    padding-left: 0;

    ${Screen.mediumPhone`
    font-size: 22px;
    `}
    ${Screen.phone`
    font-size: 22px;
    `}
    ${Screen.smallPhone`
    font-size: 22px;
    `}
    /* padding-top: 10rem; */
  }
  &.faqs,
  &.feedback {
    text-align: center;
    color: #000a66;
    font-size: 2rem;

    span {
      color: #c81e19;
    }
  }

  &.faqs {
    ${Screen.mediumPhone`
    font-size: 22px
    `}
    ${Screen.phone`
    font-size: 22px
    `}
    ${Screen.smallPhone`
    font-size: 20px
    `}
    ${Screen.galaxyFold`
    font-size: 18px
    `}
  }

  &.touch {
    ${Screen.pad`
    font-size: 26px;
    padding-left: 1rem
    padding: 0;
    `}
    ${Screen.mediumpc`
    font-size: 26px;
    padding-left: 2rem
    padding: 0;
    `}
    ${Screen.smallpc`
    font-size: 24px;
    text-align: center;
    padding: 0;
    `}
    ${Screen.mediumPhone`
    font-size: 20px;
    text-align: center;
    padding: 0;
    `}
    ${Screen.phone`
    font-size: 20px;
    text-align: center;
    padding: 0;
    `}
    ${Screen.smallPhone`
    font-size: 20px;
    text-align: center;
    padding: 0;
    `}
  }
`;

const Background = styled.div`
  width: 100%;
  height: 300px;
  padding-top: 4rem;
  background-image: url(${Faqs});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  p {
    padding-top: 2rem;
    text-align: center;
    font-size: 1.2rem;

    ${Screen.mediumpc`
    font-size: 16px;
    padding: 1rem 2rem;
    `}
    ${Screen.smallpc`
    font-size: 16px;
    padding: 1rem 2rem;
    `}
    ${Screen.mediumPhone`
    font-size: 14px;
    padding: 1rem;
    `}
    ${Screen.phone`
    font-size: 14px;
    padding: 1rem;
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding: 1rem;
    `}
  }
`;
const MapLink = styled.p`
  &.active {
    color: #c81e19;
  }
`;
const LinkContainer = styled.div`
  /* width: 65%; */
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  ${Screen.mediumpc`
  width: 100%;
  margin-top: 2rem;
  `}
  ${Screen.smallpc`
  width: 100%;
  margin-top: 2rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  scroll-snap-type: x mandatory;
  overflow: auto;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  scroll-snap-type: x mandatory;
  overflow: auto;
  `}
  ${Screen.phone`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  scroll-snap-type: x mandatory;
  overflow: auto;
  `}
  ${Screen.smallPhone`
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 2rem;
  scroll-snap-type: x mandatory;
  overflow: auto;
  `}
`;
const HelpLink = styled.p`
  &.active {
    color: #c81e19;
  }
  color: #002668;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding-right: 1rem;
  font-weight: bold;
  cursor: pointer;
  ${Screen.mediumpc`
  font-size: 16px!important;
  word-break: keep-all!important;
  padding-right: 0;
  `}
  ${Screen.smallpc`
  font-size: 16px!important;
  word-break: keep-all!important;
  padding-right: 0;
  `}
  ${Screen.surfaceDuo`
  font-size: 14px!important;
  word-break: keep-all!important;
  `}
  ${Screen.mediumPhone`
  font-size: 14px!important;
  word-break: keep-all!important;
  `}
  ${Screen.phone`
  font-size: 14px!important;
  word-break: keep-all!important;
  `}
  ${Screen.smallPhone`
  font-size: 14px!important;
  word-break: keep-all!important;
  `}
`;
const QuestionContainer = styled.div`
  width: 65%;
  height: auto;
  background-color: #fff;
  box-shadow: 5px 3px 3px #ccc, -5px -3px 3px #ccc;
  padding: 2rem 2rem;
  margin: 0 auto;
  margin-top: -1rem;
  ${Screen.smallpc`
  width: 80%;
  padding: 2rem 10px;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  padding: 2rem 10px;
  margin-top: -2rem;
  `}
  ${Screen.phone`
  width: 90%;
  padding: 2rem 10px;
  margin-top: -2rem;
  `}
  ${Screen.smallPhone`
  width: 90%;
  padding: 2rem 10px;
  margin-top: -2rem;
  `}
`;
const Questions = styled.div`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  /* justify-content: ; */
`;
const HelpCard = styled.div`
${Screen.surfaceDuo`
scroll-snap-align: start;
`}
${Screen.mediumPhone`
scroll-snap-align: start;
`}
${Screen.phone`
scroll-snap-align: start;
`}
${Screen.smallPhone`
scroll-snap-align: start;
`}
  &.active {
    width: auto;
    height: 5rem;
    background-color: #fff;
    ${Screen.mediumpc`
    background-color: transparent;
    width: auto;
    height: auto;
    `}
    ${Screen.smallpc`
    background-color: transparent;
    width: auto;
    height: auto;
    `}
    ${Screen.mediumPhone`
    background-color: transparent;
    width: auto;
    height: auto;
    `}
    ${Screen.phone`
    background-color: transparent;
    width: auto;
    height: auto;
    `}
    ${Screen.smallPhone`
    background-color: transparent;
    width: auto;
    height: auto;
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;
  &.feedback {
    justify-content: space-between;
  }
`;
const MobileContainer = styled.div`
&.touch {
  width: 100%;
  position: relative;
}
&.map {
  width: 100%;

  ${Screen.smallPhone`
  postion: relative;

  `}
}
`;
const ImageBackground = styled.div`
${Screen.tablet`
width: 100%;
height: 200px;
background-image: ${({ bg }) => bg};
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: absolute;
top: 19rem;
`}
${Screen.mediumPhone`
width: 100%;
height: 200px;
background-image: ${({ bg }) => bg};
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: absolute;
top: 18rem;
`}
${Screen.phone`
width: 100%;
height: 200px;
background-image: ${({ bg }) => bg};
background-repeat: no-repeat;
background-position: center;
background-size: contain;
position: absolute;
top: 19rem;
`}
${Screen.smallPhone`
width: 100%;
height: 200px;
background-image: ${({ bg }) => bg};
background-repeat: no-repeat;
background-position: center;
background-size: contain;
position: absolute;
top: 18rem;
`}
`;

const CardText = styled.p`
text-align: center; 
font-size: 14px; 
padding: 2rem 1rem;
`;

const ContactWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 1rem;
`;

const ContactWrapperMobile = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
margin-right: 0;

`;

const ImageIcon = styled.div`
content: ${({ icon }) => icon};
width: 2rem;
height: 2rem;
margin-right: 10px;

${Screen.smallPhone`
width: 100%;
`}
`
export default Help;
