import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Learn } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { Decorate, NavButton } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const ConstructionForm = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav    */}
      <Container>
        <Banner>
          <h1>
            Learn More about <br /> <span>Construction Finance</span>
          </h1>
        </Banner>
        <Paragraph>
          Fill out the following form to be contacted by one of our <br />{' '}
          CUSTOMER SERVICE representatives
        </Paragraph>

        {/* Form Section */}
        <Section>
          <FlexBox>
            <Image src={Learn} alt='learn' />
            <FormContainer autoComplete='off'>
              <FlexBox>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>First Name</label>
                  <input
                    style={{ width: '300px' }}
                    type={'text'}
                    name='firstName'
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Last Name</label>
                  <input
                    style={{ width: '300px' }}
                    type={'text'}
                    name='lastName'
                  />
                </div>
              </FlexBox>
              <div style={{ marginTop: '2rem' }}>
                <label>Email Address</label>
                <input style={{ width: '100%' }} type={'text'} name='email' />
              </div>
              <div style={{ marginTop: '2rem' }}>
                <label>Type of Mortgage you are interested in</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='mortgage'
                  >
                    <option></option>
                    <option value={'mortgage1'}>Mortgage 1</option>
                    <option value={'mortgage2'}>Mortgage 2</option>
                    <option value={'mortgage3'}>Mortgage 3</option>
                  </select>
                </Select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '2rem',
                }}
              >
                <label>Additional Message</label>
                <textarea name='message' />
              </div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button>Submit</Button>
              </div>
            </FormContainer>
          </FlexBox>
        </Section>
        {/* End of form section */}
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 10rem auto 0;
  /* margin-bottom: ${({ mb }) => mb}; */
  @media screen and (max-width: 900px) {
    width: 90%;
  }
`;

const Banner = styled.div`
  width: 100%;
  height: 12rem;
  background-color: #002668;
  /* display: flex; */
  /* justify-content: flex-start; */
  padding-top: 3rem;
  text-align: center;
  /* padding-left: 2rem; */
  /* position: relative; */

  h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    /* padding-left: 1rem; */
    span {
      text-transform: uppercase;
    }
  }
`;
const Paragraph = styled.p`
  color: #9a9494;
  text-transform: uppercase;
  text-align: center;
  padding-top: 2rem;
  font-size: 1.1rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Section = styled.section`
  margin-top: 5rem;
`;
const Image = styled.img`
  margin-right: 5rem;
`;
const FormContainer = styled.form`
  /* display: flex; */
  width: 100%;
  height: auto;
  /* flex-direction: column; */
  /* padding: 50px 50px; */
  background-color: white;
  /* border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-bottom: 50px; */

  h1 {
    font-size: 13px;
    font-weight: 800;
    font-family: 'Roboto Flex', sans-serif;
    align-self: left;
    color: #0040a4;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  /* p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  } */

  label {
    font-size: 15px;
    font-weight: 400;
    /* align-self: center; */
    color: #002668;
    margin: 0;
    text-transform: uppercase;
  }

  input {
    /* width: 300px; */
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #0a3d94;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  textarea {
    margin-top: 10px;
    border: 1px solid #dbd9d9;
    padding: 10px 30px;
    border-radius: 5px;
    outline: none;
    background-color: #f5f5f5;
    color: #0a3d94;
    resize: none;
    height: 150px;
  }
`;
const Select = styled.div`
  width: 100%;
  ${Decorate.select};

  .select-css {
    border-color: #dbd9d9;
  }
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;

  p {
    padding: 3px 0px 0px 10px;
    margin: 0;
  }
`;
const Button = styled(NavButton)`
  width: 10rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-top: 2rem;
  background-color: #c81e19;
  font-weight: bold;
`;

export default ConstructionForm;
