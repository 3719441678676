import emailjs from "@emailjs/browser"


export const hasRole = (role, module, roleArray) => {
  if (!roleArray) return false;
  if (!roleArray.length) return false;
  if (!role) return false;
  if (!module) return false;
  const [filteredModule] = roleArray.filter(item => item.module === module);
  // console.log(filteredModule);
  const hasRole = filteredModule.roles.includes(role) ? true : false;
  // console.log('hasRole', hasRole);
  // console.log('roles', filteredModule.roles);
  return hasRole;
};

export function toText(node) {
  let tag = document.createElement("div");
  tag.innerHTML = node;
  node = tag.innerText;
  return node;
}

export function shortenText(text, startingPoint, maxLength) {
  return text.length > maxLength
    ? text.slice(startingPoint, maxLength)
    : text;
}

// export async function sendEmail(serviceId, templateId, currentItem, publicKey){
//   try {
//     const response = await emailjs.sendForm(serviceId, templateId, currentItem, publicKey);
//     return response
//   } catch (error) {
//     console.log(error.text)
//   }
// }

export function validateInput(items) {
  let result = false;
  items.map((item) => {
    if (!item){
      result =  true
    } else {
      result = false
    }
  })
  return result;
}