import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Basics from './basics';
import Screen from './screen'

export const Nav = styled.nav`
  width: 100%;
  height: 80px;
  background-color: #f3f3f3;
  position: fixed;
  top: 0;
  /* overflow-y: hidden; */
  z-index: 3;
  ${Screen.smallpc`
  background-color: #ffffff;
  border-bottom: 1px solid red
  `}
  .active {
    visibility: visible;
    transition: ${Basics.transition};
  }
  .hidden {
    visibility: hidden;
    transition: ${Basics.transition};
    transform: translate(0, -100%);
  }
`;
export const NavContainer = styled.div`
  margin: 0 auto;
  // width: 90%;
  width: 100%;
  // padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  transition: ${Basics.transition};
  padding-left: 5rem;

  ${Screen.smallpc`
  padding-left: 1.5rem;
  `}
`;
export const Logo = styled.img`
  border-right: 1px solid #949494;
  padding-right: 2rem;
  cursor: pointer;
  ${Screen.tablet`
  border: none
  `}
`;
export const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: ${({ mr }) => mr };
  margin-left: ${({ ml }) => ml };
  padding-top: 1rem;
`;
export const NavListMobile = styled.ul`
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    /* height: calc(100vh - 60px); */
    min-height: 100vh;
    /* background-color: #ffffff; */
    background: rgba(255, 255, 255, 0.9);
    /* border: 1px solid black; */
    padding-top: 3rem;
    padding-left: 3rem;
    list-style: none;
    z-index: 1;
  }
`;
export const NavListItem = styled.li`
  padding-left: 1.5rem;
  text-align: center;
  padding-top: 1rem;
  font-size: 0.8rem;
  ${Screen.customScreen`
 font-size: 0.67rem 
 `}
  ${Screen.pc`
  font-size: 0.6rem 
  `}
  ${Screen.mediumpc`
  font-size: 0.55rem 
  `}
`;
export const NavListItemMobile = styled.li`
  @media screen and (max-width: 1000px) {
    /* text-align: center; */
    font-weight: bold;
    font-size: 0.9rem;
    color: #002668;
    padding-bottom: 1.5rem;
    &:nth-child(1) {
      padding-top: 1rem;
    }
  }
`;
export const NavLink = styled(Link)`
  text-decoration: none;
  color: #002668;
  &.active {
    position: relative;
    text-decoration: none;
    color: #002668;
    padding-bottom: 0.5rem;

    &::after {
      content: '';
      width: 20px;
      height: 3px;
      position: absolute;
      top: 1.2rem;
      background-color: #c81e19;
      left: 0;
    }
    /* border-bottom: 3px solid #c81e19; */
  }
  &.login {
    color: #fff;
  }
`;
export const NavButton = styled.button`
  width: 5rem;
  height: 2rem;
  background-color: #c81e19;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-left: 2rem;
  // box-shadow: 5px 3px 5px #ccc;
  cursor: pointer;
`;
