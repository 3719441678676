import React, { useState } from 'react';
import styled from 'styled-components';
import { Screen } from '../../styles'

const Faq = ({ item }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Container onClick={item.subItem && toggle}>
        <Image
          src={open ? item.close : item.open}
          alt='plus'
          className='plus'
        />
        <Text>{item.text}</Text>
      </Container>
      <ul>
        {open &&
          item.subItem.map((single, i) => (
            <List key={i}>
              {single.content && <ListItem>{single.content}</ListItem>}
              {single.text && <ListItem>{single.text}</ListItem>}
            </List>
          ))}
      </ul>
    </>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;
const Image = styled.img`
  width: 2rem;
  height: 2rem;
  ${Screen.phone`
  width: 1.5rem;
  height: 1.5rem
  `}
  ${Screen.smallPhone`
  width: 1.5rem;
  height: 1.5rem
  `}
`;
const Text = styled.p`
  font-weight: bold;
  padding-left: 0.5rem;
  padding-top: 0.3rem;
  /* text-decoration: underline; */
  /* white-space: nowrap; */
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  ${Screen.phone`
  font-size: 14px!important;
  `}
  ${Screen.smallPhone`
  font-size: 14px!important;
  `}
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  margin-left: 3rem;
  &:nth-child(1) {
    list-style-type: none;
  }
`;
const ListItem = styled.li`
  padding-bottom: 1rem;
  font-size: 0.9rem;
  ${Screen.phone`
  font-size: 14px!important;
  `}
  ${Screen.smallPhone`
  font-size: 14px!important;
  `}
`;

export default Faq;
