import { 
    BLOGS_FAILURE, 
    BLOGS_REQUEST, 
    BLOGS_SUCCESS, 
    PRESS_FAILURE, 
    PRESS_LANDING_FAILURE, 
    PRESS_LANDING_REQUEST, 
    PRESS_LANDING_SUCCESS, 
    PRESS_REQUEST, PRESS_SUCCESS,
    BLOG_LINKS_FAILURE, 
    BLOG_LINKS_REQUEST, 
    BLOG_LINKS_SUCCESS 
 } from "../constants"



export const blogLinksReducer = (state, action) => {
    switch(action.type){
        case BLOG_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case BLOG_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case BLOG_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}




export const blogsReducer = (state, action) => {
    switch(action.type){
        case BLOGS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case BLOGS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case BLOGS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const pressReducer = (state, action) => {
    switch(action.type){
        case PRESS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case PRESS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case PRESS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const pressLandingReducer = (state, action) => {
    switch(action.type){
        case PRESS_LANDING_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case PRESS_LANDING_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case PRESS_LANDING_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const footprintReducer = (state, action) => {
    switch(action.type){
        case PRESS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case PRESS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case PRESS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}