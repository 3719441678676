import { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { HelpersComponent } from '../../components';
import { NavButton } from '../../styles';
// import api from '../../services/api';
import { Footer } from '../../components/footer';
import { BlogImg, BlogMobile, SearchImg } from '../../assets/img';
import { categoriesItem } from '../../components/main';
import { Link } from 'react-router-dom';
import { Medium } from '../../components/blog';
import api from '../../services/api';
import { BlogsContext } from '../../contexts/blog';
import { BLOGS_FAILURE, BLOGS_REQUEST, BLOGS_SUCCESS } from '../../constants';
import { Screen } from '../../styles';
// import { BlogsContext } from '../../contexts/blog'

// import { Link } from 'react-router-dom';

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader
const Blog = () => {
  const [mobile, setMobile] = useState(false)
  const responsive = () => {
    return window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
  }
  const mount = useRef()

  const { dispatch, state } = useContext(BlogsContext)
  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);

  useEffect(() => {
    mount.current = true
    const getBlogs = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: BLOGS_REQUEST})
          const result = await api.req('/api/document/blogs')
          dispatch({
            type: BLOGS_SUCCESS,
            payload: JSON.parse(result.details.data)
          }) 
        }
        
      } catch (error) {
        dispatch({
          type: BLOGS_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getBlogs()

    return () => {
      mount.current = false
    }
  }, [])

  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}

      {
        state.loading ? <ComponentLoader /> : (
          <>
          {/* Hero section */}
          <Section className='hero'>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Abbey Blog</h2>
                  <p>
                  Keep up with money management tips and 
                  investment opportunities as we explore diverse 
                  finance-related topics.
                  </p>
                </Content>
                {/* {
                  !mobile && <Subscribe>Subscribe</Subscribe>
                } */}
              </Glass>
            </Container>
          </Section>
          {/* End of hero section */}
    
          {/* Article */}
          <Section>
            {
              mobile ? (
                <Container className='article'>
                  <Articles>
                  {
                    state.data.slice(0, 5).reverse().map((item, i) => (
                      <Medium item={item} key={i} />
                    ))
                  }
                  <Link style={{ textDecoration: 'none'}} to={'https://medium.com/@abbeymortgagebank'} target='_blank' rel='noreferrer'>
                  <ArticleButton>View more articles</ArticleButton>
                  </Link>
              </Articles>
                </Container>
              ): (
            <FlexBox>
              <Articles>
                <Container className='article'>
                  {
                    state.data.slice(0, 5).reverse().map((item, i) => (
                      <Medium item={item} key={i} />
                    ))
                  }
                  <Link style={{ textDecoration: 'none'}} to={'https://medium.com/@abbeymortgagebank'} target='_blank' rel='noreferrer'>
                  <ArticleButton>View more articles</ArticleButton>
                  </Link>
                </Container>
              </Articles>
              <Categories>
                <div>
                  <Header>Categories</Header>
                  <List>
                    {categoriesItem.map(({ item }) => (
                      <div style={{ marginLeft: '2rem' }}>
                        <ListItem>{item}</ListItem>
                      </div>
                    ))}
                  </List>
                </div>
              </Categories>
            </FlexBox>
              )
            }
          </Section>
          {/* End of article */}
    
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }

    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  &.hero {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
     ${Screen.tablet`
    padding-top: 0;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  &.article {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 3rem auto 0;
    ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 0 auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 50%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  margin: 5rem auto 0!important;
  `}
  ${Screen.phone`
  width: 70%;
  margin: 5rem auto 3rem!important;
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 5rem auto 3rem!important;
  `}
  }
  /* margin: 10rem auto 0; */
`;
const Button = styled(NavButton)`
  width: 8rem;
  height: 2rem;
  border-radius: 5px;
  margin: 1.5rem 0;
  margin-right: ${({ mr }) => mr};
  background-color: #002668;
`;
const ArticleButton = styled.div`
  width: 600px;
  height: 3rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding-top: 1rem;
  background-color: #c81e19;
  ${Screen.mediumpc`
  width: 100%;
  `}
  ${Screen.smallpc`
  width: 100%;
  `}
  ${Screen.tablet`
  width: 100%;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  `}
  ${Screen.phone`
  width: 100%;
  `}
  ${Screen.smallPhone`
  width: 100%;
  `}
`;
const Subscribe = styled(Button)`
  width: 10rem;
  height: 2.5rem;
  border-radius: 5px;
  margin: 2rem 0 0;
  margin-left: 2rem;
  /* margin-right: ${({ mr }) => mr}; */
  background-color: #c81e19;
  box-shadow: 5px 3px 5px #666;
`;

const Section = styled.section`
  width: 100%;
  min-height: 100vh;
  &.hero {
    background-image: url(${BlogImg});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 15rem;
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding-top: 5rem;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  ${Screen.mediumpc`
  min-height: 40vh;
  `}
  ${Screen.smallpc`
  min-height: 30vh;
  `}
  ${Screen.mediumPhone`
  min-height: 30vh
  `}
`;
const Glass = styled.div`
  width: 540px;
  height: 250px;
  background: rgba(255, 255, 255, 0.6);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const Content = styled.div`
  &.glass {
    padding-left: 2rem;
    ${Screen.miniLargeScreen`
  padding-right: 1rem;
  `}
  ${Screen.surfaceDuo`
  padding: 0 1rem;
  text-align: center;
  `}
    h2 {
      padding-top: 4rem;
      font-size: 45px;
      color: #002668;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding-top: 2rem;
      font-size: 24px;
      text-align: center;
      `}
    }

    p {
      color: #000;
      padding-top: 1rem;
      text-align: left;
      font-size: 18px;
      text-shadow: 5px 3px 3px #ccc;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
      ${Screen.mediumPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668;
      padding: .5rem 0;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem 0;
      `}
    }
  }
  &.article {
    width: 55%;
    height: 100%;
    padding: 3rem 1rem;
    /* text-align: center */
    /* padding: 0.5rem 1rem; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    /* span {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.9rem;
    }
    hr {
      margin-top: 1rem;
    } */
  }
`;
const FlexBox = styled.div`
  display: flex;
  height: 100%;
`;
const Articles = styled.div`
  width: 80%;
  padding-left: 5rem;
  padding-top: 5rem;

  ${Screen.pad`
  padding-left: 0;
  padding-top: 3rem;
  `}
  ${Screen.mediumpc`
  padding-left: 0;
  `}
  ${Screen.smallpc`
  padding-left: 0;
  `}
   ${Screen.tablet`
  width: 100%;
  padding-left: 0;
  padding-top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin: 0 auto;
  column-gap: 50px;
  `}
   ${Screen.surfaceDuo`
 width: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 0;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  padding-left: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  column-gap: 0;
  `}
  ${Screen.phone`
  width: 100%;
  padding-left: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  `}
  ${Screen.smallPhone`
  width: 100%;
  padding-left: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  `}
  /* flex-grow: 1; */
`;
const Categories = styled.div`
  width: 30%;
  height: auto;
  background-color: #f5f5f5;
  padding-top: 8rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${Screen.mediumpc`
  padding-left: 2rem;
  `}
  ${Screen.smallpc`
  padding-left: 1rem;
  `}
  /* align-items: center; */
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;

  &.article {
    font-size: 1.3rem;
  }
`;
const Card = styled.div`
  width: 800px;
  height: 200px;
  border: 2px solid #000;
  margin-bottom: 2rem;
  display: flex;
`;
const Image = styled.img`
  &.article {
    width: 45%;
    height: 100%;
  }
  &.icon {
    width: 20px;
    height: 20px;
    margin-top: 1rem;
  }
  &.search {
    height: 25px;
    margin-right: 1rem;
  }
`;
const Circle = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #d9d9d9;
  border-radius: 50%;
`;
const ReadMore = styled(Link)`
  color: #c81e19;
  font-size: 0.8rem;
  padding-top: 1rem;
`;
const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.3rem;

  &:nth-child(1) {
    padding-top: 1rem;
  }
`;
const CardBody = styled.div``;
const List = styled.ul`
  list-style: square;
`;
const ListItem = styled.li`
  font-weight: 600;
  font-size: 1rem;
  padding-top: 1.5rem;
  ${Screen.smallpc`
  font-size: 14px;
  `}

  &::before {
    content: '▪';
    color: #c81e19;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;
const Search = styled.div`
  width: 80%;
  height: 3rem;
  border-radius: 20px;
  margin: 3rem 0;
  /* background-color: #d9d9d9; */
  display: flex;
  justify-content: flex-start;
  padding-left: 2rem;
  align-items: center;
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -1px -1px 4px #d9d9d9;

  input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #000;
    padding: 20px 10px 20px 5px;
    &::placeholder {
      margin-left: 2rem;
      color: #000;
    }
  }
`;

export default Blog;
