import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AboutLeadership, DipoImg, Dips, InvestorMobile } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { Link } from 'react-router-dom';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'


// const Navigation = NavigationBar.Navigation;
const Dipo = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  useEffect(() => {
    //Scroll to top on page load
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                <Header>Dipo Adeoye</Header>
              <Title>EXECUTIVE DIRECTOR, TREASURY & BUSINESS GROWTH</Title>
                </div>
                <Image src={Dips} alt='dipo' />
                <Paragraph>
                <p>
                Dipo Adeoye is an astute, well-rounded finance 
              professional, with over a fifteen years experience in 
              financial services sector which span across fixed 
              income trading, treasury management, investment banking 
              and private equity investing. Dipo has been involved in 
              a broad spectrum of financial and banking 
              responsibilities, including mergers and acquisitions, 
              finance, treasury management, project management, 
              business turnaround and strategic planning. He is a 
              natural solution provider and self-starter with excellent 
              leadership, analytical, communication and interpersonal 
              skills. Dipo is a second class upper Civil engineering 
              graduate from the Federal University of Technology Akure 
              and he practiced engineering for a year before delving 
              into banking.
              </p>
              <p>
            He started his banking career at Access Bank Plc, a top 
            tier Nigerian bank, with an outstanding performance at 
            the Access Bank School of Banking Excellence when he 
            emerged as the overall (all time) best graduating 
            candidate. At Access, he worked in Banking Operations, 
            Customer Services and later moved to Treasury where he 
            rose to become the Head, Fixed Income Trading Unit of the 
            Bank within a five year period.
            </p>
            <p>
            In 2011, Dipo joined Heirs Holdings,an African proprietary 
            investment company, where he handled primarily fixed income 
            trading and private equity investments. During his stay at 
            Heirs, He was involved in a range of private equity investments 
            across Africa in a range of selected sectors including financial 
            services, oil and gas, agriculture, healthcare, real estate and 
            hospitality. He also handled the impact investing/Access to 
            Finance portfolio of the Tony Elumelu Foundation through which 
            capital wasinvested in businesses to facilitate both financial 
            and social returns. He was the pioneer project manager for the 
            Tony Elumelu Entrepreneurship Programme (TEEP), which today is 
            making significant impact across Africa.
            </p>
            <p>
              He had a stint at the United Bank of Africa, where he 
              was the Manager in charge of the fixed income trading 
              team before Joining the VFD Group Plc as the pioneer 
              Group Treasurer in 2018. At VFD Group, he was responsible 
              for creating the Treasury structure and framework that 
              handled asset and liability management, liquidity management 
              structured products and investment management for the Group 
              and he also held the position of the Chief Operating Officer 
              of VFD Group before he joined Abbey Mortgage Bank in May 2020.
              Dipo is currently the Executive Director in charge of Treasury, 
              and Business Growth for the bank. He is an associate of the Institute 
              of Directors of Nigeria, a non-Executive Director of Atiat Limited and 
              the Chairman of the Board of Trustees of The Initiative for Impactful 
              Learning (The Real Impact)
            </p>
                </Paragraph>
              </FlexBox>
            ): (
              <>
          <FlexBox>
            <Image src={Dips} alt='dipo' />
            <Content>
              <Header>Dipo Adeoye</Header>
              <Title>EXECUTIVE DIRECTOR, TREASURY & BUSINESS GROWTH</Title>
              <p>
              Dipo Adeoye is an astute, well-rounded finance 
              professional, with over a fifteen years experience in 
              financial services sector which span across fixed 
              income trading, treasury management, investment banking 
              and private equity investing. Dipo has been involved in 
              a broad spectrum of financial and banking 
              responsibilities, including mergers and acquisitions, 
              finance, treasury management, project management, 
              business turnaround and strategic planning. He is a 
              natural solution provider and self-starter with excellent 
              leadership, analytical, communication and interpersonal 
              skills. Dipo is a second class upper Civil engineering 
              graduate from the Federal University of Technology Akure 
              and he practiced engineering for a year before delving 
              into banking.
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
            <p>
            He started his banking career at Access Bank Plc, a top 
            tier Nigerian bank, with an outstanding performance at 
            the Access Bank School of Banking Excellence when he 
            emerged as the overall (all time) best graduating 
            candidate. At Access, he worked in Banking Operations, 
            Customer Services and later moved to Treasury where he 
            rose to become the Head, Fixed Income Trading Unit of the 
            Bank within a five year period.
            </p>
            <p>
            In 2011, Dipo joined Heirs Holdings,an African proprietary 
            investment company, where he handled primarily fixed income 
            trading and private equity investments. During his stay at 
            Heirs, He was involved in a range of private equity investments 
            across Africa in a range of selected sectors including financial 
            services, oil and gas, agriculture, healthcare, real estate and 
            hospitality. He also handled the impact investing/Access to 
            Finance portfolio of the Tony Elumelu Foundation through which 
            capital wasinvested in businesses to facilitate both financial 
            and social returns. He was the pioneer project manager for the 
            Tony Elumelu Entrepreneurship Programme (TEEP), which today is 
            making significant impact across Africa.
            </p>
            <p>
              He had a stint at the United Bank of Africa, where he 
              was the Manager in charge of the fixed income trading 
              team before Joining the VFD Group Plc as the pioneer 
              Group Treasurer in 2018. At VFD Group, he was responsible 
              for creating the Treasury structure and framework that 
              handled asset and liability management, liquidity management 
              structured products and investment management for the Group 
              and he also held the position of the Chief Operating Officer 
              of VFD Group before he joined Abbey Mortgage Bank in May 2020.
              Dipo is currently the Executive Director in charge of Treasury, 
              and Business Growth for the bank. He is an associate of the Institute 
              of Directors of Nigeria, a non-Executive Director of Atiat Limited and 
              the Chairman of the Board of Trustees of The Initiative for Impactful 
              Learning (The Real Impact)
            </p>
          </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;

${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }

  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Dipo;
