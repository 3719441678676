import { 
    HERO_FAILURE, 
    HERO_REQUEST, 
    HERO_SUCCESS 
} from "../constants"



export const personalHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const businessHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const waysHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const loanHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const sustainHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const investorHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const aboutHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const contactHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const wireHeroReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const wireMobileReducer = (state, action) => {
    switch(action.type){
        case HERO_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HERO_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HERO_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}
