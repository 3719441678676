import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Decorate, NavButton } from '../../../styles';
import stateCountry from 'state-country';

const StepThree = ({ next, prev, values, handleChange }) => {
  const validation =
    values.address1 === '' || values.country === '' || values.state === '';
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [checked, setChecked] = useState(false);
  const checkValue = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const getCountries = () => {
      setCountries(stateCountry.getAllCountries());
    };
    const getStates = () => {
      setStates(stateCountry.getAllStatesInCountry(values.country));
    };
    getCountries();
    getStates();
  }, [values]);

  // List of countries
  const listOfCountries =
    countries && countries.length
      ? countries.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;

  // List of states
  const listOfStates =
    states && states.length
      ? states.map((value, i) => (
          <option key={i} value={value.name}>
            {value.name}
          </option>
        ))
      : null;
  return (
    <FormContainer>
      <FormHeader>Step 3 0f 6: Personal Details</FormHeader>
      <Form autoComplete='off'>
        <FlexBox mt='3rem'>
          <label>Residential Address</label>
          <InputContainer>
            <Input className='group'>
              <input
                type={'text'}
                name='address1'
                placeholder='Residential address here'
                onChange={handleChange('address1')}
                value={values.address1}
              />
            </Input>

            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='address2'
                placeholder='Address line #2'
                onChange={handleChange('address2')}
                value={values.address2}
              />
            </Input>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='country'
                onChange={handleChange('country')}
                value={values.country}
              >
                <option value='' disabled hidden>
                  Choose Country
                </option>
                <option></option>
                {listOfCountries}
              </select>
            </Select>
            <br />
            <Select className='group'>
              <select
                className='select-css'
                name='state'
                value={values.state}
                onChange={handleChange('state')}
              >
                <option value='' disabled hidden>
                  Choose State
                </option>
                <option></option>
                {listOfStates}
              </select>
            </Select>
            <Input className='group' mt='2rem'>
              <input
                type={'text'}
                name='area'
                placeholder='Zip/Area Code'
                onChange={handleChange('area')}
                value={values.area}
              />
            </Input>
            <div className='checkbox'>
              <input
                type={'checkbox'}
                readOnly
                checked={checked}
                onClick={checkValue}
              />
              <label>
                My residential address is the same as my mailing address
              </label>
            </div>
          </InputContainer>
        </FlexBox>
        {!checked && (
          <FlexBox>
            <label style={{ paddingTop: '2rem' }}>Mailing Address</label>
            <InputContainer mt='2rem'>
              <Input className='group'>
                <input
                  type={'text'}
                  name='mailingAddress1'
                  placeholder='Residential address here'
                  onChange={handleChange('mailingAddress1')}
                  value={values.mailingAddress1}
                />
              </Input>
              <Input className='group' mt='2rem'>
                <input
                  type={'text'}
                  name='mailingAddress2'
                  placeholder='Address line #2'
                  onChange={handleChange('mailingAddress2')}
                  value={values.mailingAddress2}
                />
              </Input>
              <br />
              <Select className='group'>
                <select
                  className='select-css'
                  name='mailingCountry'
                  onChange={handleChange('mailingCountry')}
                  value={values.mailingCountry}
                >
                  <option value='' disabled hidden>
                    Choose Country
                  </option>
                  <option></option>
                  {listOfCountries}
                </select>
              </Select>
              <br />
              <Select className='group'>
                <select
                  className='select-css'
                  name='mailingState'
                  value={values.mailingState}
                  onChange={handleChange('mailingState')}
                >
                  <option value='' disabled hidden>
                    Choose State
                  </option>
                  <option></option>
                  {listOfStates}
                </select>
              </Select>
              <Input className='group' mt='2rem'>
                <input
                  type={'text'}
                  name='mailingArea'
                  placeholder='Zip/Area Code'
                  onChange={handleChange('mailingArea')}
                  value={values.mailingArea}
                />
              </Input>
            </InputContainer>
          </FlexBox>
        )}
        <FlexBox mt='2rem'>
          <label>Home Number</label>
          <Input>
            <input
              type={'text'}
              name='home'
              onChange={handleChange('home')}
              value={values.home}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Education Level</label>
          <Input>
            <input
              type={'text'}
              name='education'
              onChange={handleChange('education')}
              value={values.education}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>School/Institution</label>
          <Input>
            <input
              type={'text'}
              name='school'
              onChange={handleChange('school')}
              value={values.school}
            />
          </Input>
        </FlexBox>
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
const Select = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  ${Decorate.select};
  .select-css {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
  }
  &.group {
    width: 100%;
    margin-left: -0.1rem;
  }
`;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;
  margin-top: ${({ mt }) => mt};

  &.group {
    width: 100%;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
  }
`;
const InputContainer = styled.div`
  width: 50%;
  margin-top: ${({ mt }) => mt};
  .checkbox {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    label {
      white-space: nowrap;
      font-size: 0.9rem;
      padding-left: 1rem;
    }

    input {
      transform: scale(2);
      margin-top: 0.5rem;
      cursor: pointer;
      border-radius: 0;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepThree;
