import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { NavigationBar } from '../components';
import { Footer } from '../components/footer';


const Navigation = NavigationBar.Navigation;
const NotFound = () => {
  return (
    <Box>
        {/* Nav */}
        <Navigation />
        {/* End of Nav */}
        {/* <Section>

        </Section> */}
        <Container>
          <Section>
            <Header>404 Error</Header>
            {/* <Message>Oops! The page you're looking for does not exist</Message> */}
            <Message>Oops! You didn't break the internet, but we can't find what you're looking for</Message>
            <p>Click <Link to='/' style={{ color: '#c81e19', textDecoration: 'none', fontWeight: 'bold'}}>here</Link> to head back to <span>Homepage</span></p>

          </Section>
        </Container>

        {/* Footer */}
        <Footer />
    </Box>
  )
}

export default NotFound

/** STYLES */
const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
`;
const Container = styled.div`
  width: 85%;
  margin: 0 auto;
`;
const Section = styled.div`
width: 100%;
height: 100%;
padding: 10rem 0;
text-align: center;

p {
  color:  #002668;
  padding-top: 1rem;

  span {
    font-weight: bold;
  }
}
`
const Message = styled.h1`
color:  #002668;
font-size: 1.5rem;
/* text-align: center; */
`
const Header = styled.h1`
font-size: 4rem;
color: #c81e19;
padding-bottom: 1rem;
`