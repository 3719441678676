import { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../slider.css';
import { NavButton } from '../../styles';
import { Link, useNavigate } from 'react-router-dom';
import { Screen } from '../../styles'
// import { AbbeyIcon } from '../../assets/svg';

const SlideTwo = ({ state, wireState }) => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false)
  const [contentMobile, setContentMobile] = useState(false)

  useEffect(() => {
    const contentResponsive = () => {
      return window.innerWidth <= 600 ? setContentMobile(true) : setContentMobile(false)
    }
    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    contentResponsive()
    window.addEventListener('resize', () => responsive())
    window.addEventListener('resize', () => contentResponsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
      window.removeEventListener('resize', () => contentResponsive())
    }
  }, [])

  return (
    <Carousel
      width={'100%'}
      animationHandler='fade'
      infiniteLoop={true}
      autoPlay={true}
      showIndicators={true}
      showStatus={false}
      showThumbs={false}
    >
      <Box bgImg={`url(${state[0]})`} className='slideOne'></Box>
      <Box bgImg={`url(${state[1]})`} className='slideTwo'></Box>
      <Box bgImg={`url(${state[2]})`} className='slideThree'>
      <Container>
        <ButtonWrapper className='slideThree'>
        <Button onClick={() => navigate('/business/fixed-deposit')} className='button-one'>
              Learn More
            </Button>
        </ButtonWrapper>
        </Container>
      </Box>
      <Box bgImg={`url(${state[3]})`} className='slideFour'>
        <Container>
          <ButtonWrapper className='slideFour'>
        <Button onClick={() => navigate('/personal-form')} className='button-two'>
              Get Started
            </Button>
            <Button onClick={() => navigate('/personal/bloom-kidz')}>
              Learn More
            </Button>
          </ButtonWrapper>
        </Container>
      </Box>
      <Box bgImg={`url(${state[4]})`} className='slideFive'>
        <Container>
          <ButtonWrapper className='slideFive'>
            <Button className='button-three'>
              <Link style={{ color: '#fff', textDecoration: 'none'}} to={'https://corporate.abbeymortgagebank.com'}>Get Started</Link>
            </Button>
          </ButtonWrapper>
        </Container>
      </Box>
      <Box bgImg={`url(${state[5]})`} className='slideTwo'></Box>
    </Carousel>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: ${({ bgImg }) => bgImg};
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ${Screen.miniLargeScreen`
    min-height: 70vh;
    background-size: 100% 70vh; 
    margin-top: 0; 
    &.slideThree, &.slideFour, &.slideFive {
      background-size: 100% 70vh; 
      margin-top: -8rem;
    }
  `}

  ${Screen.pad`
    min-height: 70vh;
    background-size: 100% 70vh; 
    margin-top: 0; 
    &.slideThree, &.slideFour, &.slideFive {
      background-size: 100% 75vh; 
      margin-top: -5rem;
    }
  `}

   ${Screen.mediumpc`
    min-height: 35vh;
    background-size: 100% 35vh; 
    margin-top: 0; 
    &.slideThree, &.slideFour, &.slideFive {
      background-size: 100% 35vh; 
      margin-top: -28rem;
    }
  `}
  ${Screen.smallpc`
    min-height: 35vh;
    background-size: 100% 35vh; 
    margin-top: 5rem; 
    &.slideThree, &.slideFour, &.slideFive {
      background-size: 100% 35vh; 
      margin-top: -19rem;
    }
  `}

  ${Screen.tablet`
    min-height: 40vh;
    background-size: 100% 40vh; 
    margin-top: 5rem; 
    &.slideThree, &.slideFour, &.slideFive {
      margin-top: -16em;
    }
  `}

  ${Screen.surfaceDuo`
    min-height: 50vh;
    background-size: 100% 50vh;
    &.slideThree, &.slideFour, &.slideFive {
    min-height: 60vh;
    background-size: 100% 50vh;
      margin-top: -6.5rem;
    }
  `}
  ${Screen.mediumPhone`
    min-height: 40vh;
    background-size: 100% 40vh; 
    margin-top: 5rem; 
    overflow-x: hidden!important;

     &.slideThree, &.slideFour, &.slideFive {
      margin-top: -12.5rem;
    }
  `}

  ${Screen.phone`
    background-size: 100% 50vh; 
    margin-top: 5rem; 

    &.slideThree, &.slideFour, &.slideFive {
      margin-top: -6.5rem;
    }
  `}

  ${Screen.samsungGalaxy`
    min-height: 50vh; 
    background-size: 100% 50vh; 
    margin-top: 5rem; 

    &.slideThree, &.slideFour, &.slideFive {
      min-height: 50vh; 
      background-size: 100% 50vh; 
      margin-top: -5rem;
    }
  `}
`;

const Button = styled.div`
  width: max-width;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #c81e19;
  white-space: no-wrap!important;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
`

const Container = styled.div`
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
display: flex;
margin-top: auto;
margin-bottom: 250px;
margin-left: 6rem;
gap: 40px;

${Screen.miniLargeScreen`
  margin-left: 4rem; 
  gap: 10px;
`}
${Screen.pad`
  margin-left: 4rem; 
  margin-bottom: 150px; 
  gap: 10px;
`}
${Screen.mediumpc`
  margin-left: 3rem; 
  margin-bottom: 550px; 
`}
${Screen.smallpc`
  margin-left: 3rem; 
  margin-bottom: 500px; 
  gap: 10px;
`}
${Screen.tablet`
  margin-left: 3rem; 
  margin-bottom: 400px; 
`}

${Screen.surfaceDuo`
  margin-bottom: 250px; 
`}
${Screen.mediumPhone`
  margin-left: 1.5rem; 
  margin-bottom: 350px; 
`}

${Screen.phone`
  margin-left: 10px;  

  &.slideThree, &.slideFour, &.slideFive {
    margin-bottom: 250px;
    margin-left: 1.5rem;
  }
`}

${Screen.samsungGalaxy`
  margin-left: 10px;  

  &.slideThree, &.slideFour, &.slideFive {
    margin-bottom: 200px;
  }
`}
`;

export default SlideTwo;