import { useEffect, useState, useContext } from 'react';
// import { sections, categories } from './navlinks';
import {  AbbeySmall, OpenMenu, Info, CloseMenu } from '../../assets/svg';
import styled from 'styled-components';
import {
  GlobalStyle,
  // Screen,
  Nav,
  NavContainer,
  NavList,
  NavListItem,
  NavLink,
  NavListMobile,
  Logo,
  NavListItemMobile,
} from '../../styles';
import { Link, useLocation } from 'react-router-dom';
// import api from '../../services/api';
import { Lock } from '../../assets/img';
import api from '../../services/api';
import { MainLinkContext, SectionLinkContext, MobileNavContext } from '../../contexts/navbar'
import { 
  MAIN_LINKS_FAILURE, 
  MAIN_LINKS_REQUEST, 
  MAIN_LINKS_SUCCESS, 
  MOBILE_NAV_FAILURE, 
  MOBILE_NAV_REQUEST, 
  MOBILE_NAV_SUCCESS, 
  SECTION_LINKS_FAILURE, 
  SECTION_LINKS_REQUEST, 
  SECTION_LINKS_SUCCESS 
} from '../../constants';
import { useNavigate } from 'react-router-dom';
// import { Info } from '../../assets/img';


const Navigation = ({ r }) => {
  const location = useLocation();
  // const [logoImg, setLogoImg] = useState('');
  const [mobile, setMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate()

  const handleScroll = () => {
    setScrollPosition(document.body.getBoundingClientRect().top);
    setShow(document.body.getBoundingClientRect().top > scrollPosition);
  };

  const { dispatch: mainLinksDispatch, state: mainLinksState } = useContext(MainLinkContext)
  const { dispatch: sectionLinksDispatch, state: sectionLinksState } = useContext(SectionLinkContext)
  const { dispatch: mobileLinksDispatch, state: mobileLinksState } = useContext(MobileNavContext)

  /** Responsive */
  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false);
  };

  /** Fetch main links */
  const getMainLinks = async () => {
    try {
      if(mainLinksState.data.length < 1){
        mainLinksDispatch({ type: MAIN_LINKS_REQUEST })
        const result = await api.req('/api/document/navbar/mainLinks')
        mainLinksDispatch({
          type: MAIN_LINKS_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
    } catch (error) {
      mainLinksDispatch({
        type: MAIN_LINKS_FAILURE,
        payload: error.response && error.response.result.responseMessage 
        ? error.response.result.responseMessage : error.responseMessage
      }) 
    }
  }

  /** Fetch section links */
  const getSectionLinks = async () => {
    try {
      if(sectionLinksState.data.length < 1){
        sectionLinksDispatch({ type: SECTION_LINKS_REQUEST })
        const result = await api.req('/api/document/navbar/sectionLinks')
        sectionLinksDispatch({
          type: SECTION_LINKS_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
    } catch (error) {
      sectionLinksDispatch({
        type: SECTION_LINKS_FAILURE,
        payload: error.response && error.response.result.responseMessage 
        ? error.response.result.responseMessage : error.responseMessage
      }) 
    }
  }

  /** Fetch mobile links */
  const getMobileLinks = async () => {
    try {
      if(mobileLinksState.data.length < 1){
        mobileLinksDispatch({ type: MOBILE_NAV_REQUEST })
        const result = await api.req('/api/document/navbar/mobile-nav')
        mobileLinksDispatch({
          type: MOBILE_NAV_SUCCESS,
          payload: JSON.parse(result.details.data)
        })
      }
    } catch (error) {
      mobileLinksDispatch({
        type: MOBILE_NAV_FAILURE,
        payload: error.response && error.response.result.responseMessage 
        ? error.response.result.responseMessage : error.responseMessage
      }) 
    }
  }

  useEffect(() => {
    responsive();
    window.addEventListener('resize', () => responsive());
    window.addEventListener('scroll', handleScroll);

    getMainLinks()
    getSectionLinks()
    getMobileLinks()
    // fetchNavImage();

    //Cleanup function
    return () => {
      window.removeEventListener('resize', () => responsive());
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };
  const desktopView = () => {
    return (
      <>
        {/* Navigation */}
        <Nav className={show ? 'active' : 'hidden'}>
          <NavContainer>
            <div style={{ display: 'flex' }}>
              <LogoLink to={'/'}>
                <Logo src={AbbeySmall} alt='logo' />
              </LogoLink>
              <NavList>
                {mainLinksState.data.map(({ title, link }) => (

                  <NavListItem key={title}>
                    <NavLink
                      to={link}
                      className={
                        location.pathname === link ||
                        location.pathname.split('/')[1] === link.split('/')[1]
                          ? 'active'
                          : null
                      }
                    >
                      {title}
                    </NavLink>
                  </NavListItem>
                ))}
              </NavList>
            </div>

            <div style={{ display: 'flex'}}>
            <NavList mr={"3rem"}>
              {sectionLinksState.data.map(({ title, link }) => (
                <NavListItem key={title}>
                  <NavLink
                    to={link}
                    className={
                      location.pathname === link ||
                      location.pathname.split('/')[1] === link.split('/')[1]
                        ? 'active'
                        : null
                    }
                  >
                    {title}
                  </NavLink>
                </NavListItem>
              ))}
            </NavList>
          <InternetBanking onClick={() => r.openModal()}>
            <Img src={Lock} alt='internet-banking' />
            <p>Internet Banking</p>
            </InternetBanking>
            </div>
          </NavContainer>
        </Nav>
        {/* End of navigation */}
      </>
    );
  };

  const mobileView = () => {
    return (
      <Nav>
        <NavContainer>
            <LogoLink to='/'>
            <Logo src={AbbeySmall} alt='logo' />
            </LogoLink>
          <div style={{ display: 'flex'}}>
            <Image src={Info} alt='info' className='info' onClick={() => navigate('/help')} />
            {mobile && open ? (
              <IconWrapper onClick={handleToggle}>
                <Image src={CloseMenu} alt='mobile-icon' />
              </IconWrapper>
            ) : (
              <IconWrapper onClick={handleToggle}>
                <Image src={OpenMenu} alt='mobile-icon' />
              </IconWrapper>
            )}
            {mobile && open && (
              <NavListMobile>
                {mobileLinksState.data.map(({ title, link }) => (
                  <NavListItemMobile key={title}>
                    <NavLink to={link}>{title}</NavLink>
                  </NavListItemMobile>
                ))}
                <InternetBanking onClick={() => r.openModal()}>
                  <Img src={Lock} alt='internet-banking' />
                  <p>Internet Banking</p>
                </InternetBanking>
              </NavListMobile>
            )}
          </div>
        </NavContainer>
      </Nav>
    );
  };
  return (
    <div>
      <GlobalStyle />
      {mobile ? mobileView() : desktopView()}
    </div>
  );
};

const IconWrapper = styled.div`
  margin-top: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;
`;

const Image = styled.img`
&.info {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 1.5rem;
}
`

const InternetBanking = styled.div`
display: flex;
justify-content: center;
width: 200px;
height: 80px;
background-color: #C81E19;
color: #fff;
white-space: unwrap;
text-align: center;
padding-top: 1.9rem;
text-transform: uppercase;
font-size: .8rem;
cursor: pointer;
font-weight: bold;

p {
  padding-top: 3px;
  padding-left: 5px;
}

&:hover {
  opacity: .9
}
`

const LogoLink = styled(Link)`
margin-top: 1rem;
`;

const Img = styled.img`
width: 20px;
height: 20px;
// align-self: center;
// padding-right: 10px;
`
export default Navigation;
