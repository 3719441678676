import { useState } from 'react';
import styled from 'styled-components';
import { Download } from '../../assets/svg';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { Documents } from '../../utils';
import HelpersComponent from '../helpers';
import { Screen } from '../../styles';
import { useNavigate } from 'react-router-dom';


const Loader  = HelpersComponent.componentLoader


const PolicyCard = ({ item }) => {
  const [loading, setLoading] = useState(false)
  const downloadDocs = async (filename) => {
    setLoading(true);
    const data = await Documents.downloadDocuments(filename);
    const blob = b64toBlob(data, "application/zip");
    saveAs(blob, 'document.zip');
    setLoading(false);
  }
  const navigate = useNavigate()
  function show(){
    localStorage.setItem('doc-link', item?.link)
    navigate('/security-policy')
  }
  return (
    <Card>
        <Content>
          <p>{item.title}</p>
        </Content>
        <Icon 
            onClick={item?.title === 'AMB Information Security Policy' ? () => show(`/security-policy`) : () => downloadDocs(item?.link)} 
            content={!loading ? `url(${Download})` : null}>
            {loading && <Loader color={'#fff'} />}
        </Icon>
    </Card>
  )
}

const Card = styled.div`
  width: 60%;
  height: 4rem;
  background-color: #f5f5f5;
  display: flex;
  margin-top: 2rem;
  ${Screen.pad`
  width: 80%;
  margin-top: 1rem;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 1rem;
  `}
  ${Screen.smallpc`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  `}
  ${Screen.smallPhone`
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  `}
`;
const Content = styled.div`
  width: 92%;
  height: 100%;
  padding-top: 1.5rem;
  padding-left: 2rem;
  ${Screen.surfaceDuo`
  width: 90%;
  padding: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 87%;
  padding: 1rem;
  `}
   ${Screen.phone`
  width: 87%;
  padding: 1rem;
  `}
  ${Screen.smallPhone`
  width: 85%;
  padding: 1rem;
  `}

  p {
    ${Screen.mediumPhone`
    font-size: 14px;
    `}
    ${Screen.phone`
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    font-size: 13px;
    `}
  }
`;
const Icon = styled.div`
  width: 8%;
  height: 100%;
  background-color: #002668;
  /* content: url(${Download}); */
  content: ${({ content }) => content};
  cursor: pointer;
  padding: 0.5rem;
  ${Screen.surfaceDuo`
  width: 10%;
  `}
  ${Screen.mediumPhone`
  width: 13%;
  `}
   ${Screen.phone`
  width: 13%;
  `}
  ${Screen.smallPhone`
  width: 15%;
  `}

  &:hover {
    box-shadow: 5px 0px 20px rgb(199, 199, 199, 0.9);
  }
`;
export default PolicyCard;
