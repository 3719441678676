// import lightLogo from '../assets/images/logo-light.png';
// import darkLogo from '../assets/images/logo-dark.png';

export const lightTheme = {
  body: '#fff',
  // logoImage: lightLogo,
  subtitle: 'color',
  color: '#000',
  link: 'black',
  text: '#000',
  selectIcon: '000',
  border: '#e3e3e3;',
  inputText: '#000',
  toggle: '#000',
  loader: '#0040A4',
  background: '#F2F5FF',
  tablehover: '#ebf3ff',
  buttons: '#17274F',
  oddtable: '#d9e6ff',
  hover: '2px 0px 20px rgb(199, 199, 199, 0.9)',
  instrumentColor: '#2d3852',
};

export const darkTheme = {
  body: '#000',
  // logoImage: darkLogo,
  subtitle: 'color',
  color: '#fff',
  link: 'white',
  text: '#D9D9D9',
  selectIcon: 'white',
  border: '#141414',
  inputText: '#dbdbdb',
  toggle: '#AEAEAE',
  loader: '#fff',
  background: '#2F3035',
  tablehover: '#154ea3',
  buttons: '#4A98FF',
  oddtable: '#002e87',
  hover: '2px 0px 25px rgb(6, 6, 6, 0.8)',
  instrumentColor: '#fff',
};
