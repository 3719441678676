import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoanMobile, LoanProducts, OvalMobile } from '../../assets/img';
import { Grey, Blue } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { estateItems } from '../../components/main/products';
import { NavButton, Screen } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const Estate = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false)

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of Hero Section */}

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <FlexBox>
              <MobileHeader>
                <Image src={OvalMobile} alt='oval' className='mobile' />
                <Content className='description'>
                <h2>
                <span>Commercial</span> Real Estate
              </h2>
                </Content>
              </MobileHeader>
              <Paragraph>
              <p>
              This is a product that provides 
              funding to Developers for construction of 
              units of residential or commercial buildings for sale to subscribers. 
              The facility could also be used for site and service of plots of land 
              to be sold to other purchaser.
              </p>
              </Paragraph>
                <em>Key features</em>
              <List>
              {estateItems.map(({ icon, item }) => (
                <div style={{ display: 'flex', paddingBottom: '.5rem' }}>
                  <Image src={icon} alt='save' className='check' />
                  <ListItem>{item}</ListItem>
                </div>
              ))}
            </List>
            <Button onClick={() => navigate('/loans/form')}>Apply</Button>
            </FlexBox>
            ): (
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey} alt='grey' className='grey' />
              <Image src={Blue} alt='blue' className='blue' />
            </ImageWrapper>
            <Content className='description'>
              <h2>
                <span>Commercial</span> Real Estate
              </h2>
              <p>
              This is a product that provides funding to Developers for construction of units of residential or commercial buildings for sale to subscribers. The facility could also be used for site and service of plots of land to be sold to other purchaser.
              </p>

              <List>
                <p>Key features:</p>
                {estateItems.map(({ icon, item }) => (
                  <div style={{ display: 'flex', paddingBottom: '1rem' }}>
                    <Image src={icon} alt='save' className='check' />
                    <ListItem>{item}</ListItem>
                  </div>
                ))}
              </List>
              <Button onClick={() => navigate('/loans/form')}>Apply</Button>
            </Content>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  &.hero {
    margin: 0 auto;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
     ${Screen.tablet`
    padding-top: 0;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.galaxyFold`
  width: 95%;
  margin: 3rem auto!important;
  `}
`;
const Content = styled.div`
  &.description {
    h2 {
      font-size: 2.5rem;
      padding-top: 1rem;
      text-shadow: 3px 3px 3px #ccc;
      color: #002668;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 10rem;
      top: 6rem
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 8rem;
      top: 4rem
      `}
       ${Screen.mediumPhone`
      font-size: 22px;
      position: absolute;
      left: 7rem;
      top: 2.5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 6rem;
      top: 2.5rem
      `}
      ${Screen.smallPhone`
      font-size: 18px;
      position: absolute;
      left: 6rem;
      top: 3rem
      `}
      ${Screen.galaxyFold`
      font-size: 16px;
      position: absolute;
      left: 5rem;
      top: 3rem
      `}
      

      span {
        color: #c81e19;
        /* text-transform: uppercase; */
      }
    }

    p {
      /* font-size: 1.1rem; */
      padding-top: 1rem;
    }
  }
`;
const Button = styled(NavButton)`
  background-color: #002668;
  width: 7rem;
  margin-left: 0;
  margin-top: 2rem;
  text-transform: uppercase;
  ${Screen.tablet`
  width: 7rem;
  margin-left: .5rem;
  margin-top: 1rem;
  padding: .2rem 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 7rem;
  margin-left: .5rem;
  margin-top: 1rem;
  padding: .2rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 7rem;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: .2rem 1rem;
  `}
  ${Screen.phone`
  width: 7rem;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: .2rem 1rem;
  `}
  ${Screen.smallPhone`
  width: 7rem;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: .2rem 1rem;
  `}
`;
const Image = styled.img`
  &.saving {
    width: 32rem;
    height: 350px;
    /* margin-right: -10rem; */
    margin-bottom: 3rem;
  }
  &.check {
    width: 25px;
    height: 25px;
  }
  &.grey {
    width: 15rem;
    height: 15rem;
    position: absolute;
    z-index: 1;
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 8rem;
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 50vh;
  &.hero {
    background-image: url(${LoanProducts});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    /* padding-top: 12rem; */
  }

  &.description {
    ${Screen.mediumpc`
    min-height: 30vh
    `}
    /* min-height: 20vh; */
  }
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
   em {
    ${Screen.smallpc`
    font-style: italic;
    color: #c81e19;
    font-size: 1rem;
    padding-bottom: .5rem;
    padding-top: 2rem;
    font-weight: 500
    `}
    ${Screen.tablet`
    font-style: italic;
    color: #c81e19;
    font-size: 1rem;
    padding-bottom: .5rem;
    padding-top: 2rem;
    font-weight: 500
    `}
    ${Screen.surfaceDuo`
    font-style: italic;
    color: #c81e19;
    font-size: 1rem;
    padding-bottom: .5rem;
    padding-top: 2rem
    `}
    ${Screen.mediumPhone`
    font-style: italic;
    color: #c81e19;
    font-size: 1rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    `}
    ${Screen.phone`
    font-style: italic;
    color: #c81e19;
    font-size: .9rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-weight: 500
    `}
   }
  /* justify-content: space-between; */
`;

const List = styled.ul`
  list-style-type: none;
  margin-top: 1.5rem;
  ${Screen.mediumPhone`
  margin-top: 1rem;
  margin-left: 1rem;
  `}
  ${Screen.phone`
  margin-top: 1rem;
  margin-left: 1rem;
  `}
  ${Screen.smallPhone`
  margin-top: .5rem;
  margin-left: 1rem;
  `}

  p {
    color: #c81e19;
    padding-bottom: 1rem;
    font-weight: bold;
  }
`;
const ListItem = styled.li`
  padding-left: 0.5rem;
  padding-top: 0.3rem;
  ${Screen.mediumPhone`
  font-size: .9rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  `}
`;
const ImageWrapper = styled.div`
  width: 30%;
  position: relative;
  margin-right: 10rem;
  ${Screen.pad`
  margin-right: 15rem
`} 
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: .9rem;
  padding: .5rem 1rem 1rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  padding: .5rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  padding: .5rem 1rem 1rem;
  `}
}`
export default Estate;
