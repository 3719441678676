import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ShortMobile, WayShort } from '../../assets/img';
import { Grey, Blue } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { cardItems } from '../../components/main/products';
import { NavButton, Screen } from '../../styles';
import { useNavigate } from 'react-router-dom';

// const Navigation = NavigationBar.Navigation;
const AbbeyCards = () => {
  const [mobile, setMobile] = useState(false)

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  const navigate = useNavigate();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'>
        <h1>Abbey Cards</h1>
      </Section>
      {/* End of Hero Section */}

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <ContentContainer className='description'>
                <Paragraph>
                <p>
                With your Abbey cards, you have easy access to your money
                whenever you want, wherever in the country. Keep tabs on
                your finances with our Debit cards,
                have your favourite picture on your card with our RY (Reflect
                Yourself) cards, and control your spending with our
                Prepaid cards
              </p>
                </Paragraph>
                <CardContainer>
                {
                  cardItems.map(({ img, header, content }) => (
                    <Wrapper>
                      <Image src={img} alt='card' className='cards' />
                      <h2>{header}</h2>
                      <p>{content}</p>
                      <Button
                        onClick={() => navigate('/ways-to-bank/request-card')}
                      >
                        Request Card
                      </Button>
                    </Wrapper>
                  ))
                }
                </CardContainer>
              </ContentContainer>
            ): (

          <FlexBox>
            <ImageWrapper>
              <Image src={Grey} alt='grey' className='grey' />
              <Image src={Blue} alt='blue' className='blue' />
            </ImageWrapper>
            <ContentContainer className='description'>
              <p>
                With your Abbey cards, you have easy access to your money
                whenever you want, wherever in the country. Keep tabs on
                your finances with our Debit cards,
                have your favourite picture on your card with our RY (Reflect
                Yourself) cards, and control your spending with our
                Prepaid cards
              </p>

              <Content className='description'>
                {cardItems.map(({ img, header, content }) => (
                  <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <Image src={img} alt='card' className='cards' />
                    <div
                      style={{
                        marginLeft: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h2>{header}</h2>
                      <p>{content}</p>
                      <Button
                        onClick={() => navigate('/ways-to-bank/request-card')}
                      >
                        Request Card
                      </Button>
                    </div>
                  </div>
                ))}
              </Content>
            </ContentContainer>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  &.hero {
    margin: 0 auto;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 80%!important;
  margin: 0 auto!important;
  `}
   ${Screen.galaxyFold`
  width: 90%!important;
  margin: 0 auto!important;
  `}
`;
const Content = styled.div`
  &.description {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    h2 {
      color: #c81e19;
      padding-bottom: 1rem;
      ${Screen.mediumpc`
      font-size: 18px;
      `}

    }
  }
`;
const ContentContainer = styled.div`
  &.description {
    p {
      color: #002668;
      font-size: 1.05rem;
      ${Screen.mediumpc`
      font-size: 14px;
      `}
    }
  }
`;

const Image = styled.img`
  &.grey {
    width: 15rem;
    height: 15rem;
    position: absolute;
    z-index: 1;
  }
  &.blue {
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 8rem;
  }
  &.cards {
    width: 15rem;
    height: 13rem;
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 50vh;
  &.hero {
    background-image: url(${WayShort});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-right: 5rem;
    padding-top: 10rem;
    /* ${Screen.miniLargeScreen`
    padding-top: 7rem
    `} */
    ${Screen.pad`
    padding-top: 7rem
    `}
    ${Screen.mediumpc`
    min-height: 20vh!important;
    padding-top: 7rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}


    h1 {
      text-align: right;
      text-transform: uppercase;
      font-size: 40px;
      color: #002668;
      ${Screen.surfaceDuo`
      font-size: 30px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      font-size: 24px;
      margin-left: 3rem;
      text-align: center;
      `}
      ${Screen.smallPhone`
      font-size: 22px;
      text-align: center;
      `}
      ${Screen.galaxyFold`
      font-size: 20px;
      text-align: center;
      `}
    }
    /* padding-top: 12rem; */
  }

  &.description {
    min-height: 100vh;
    margin-top: -3rem;
    ${Screen.pad`
    margin-top: 5rem;
    `}
    ${Screen.mediumpc`
    margin-top: 5rem;
    min-height: 60vh;
    `}
    ${Screen.smallpc`
    margin-top: 5rem;
    min-height: 100vh;
    `}
    ${Screen.mediumPhone`
    margin-top: 3rem;
    min-height: 100vh;
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  width: 40%;
  position: relative;
  margin-right: 15rem;
  top: 15rem;
`;
const Button = styled(NavButton)`
  width: 7rem;
  height: 2rem;
  border-radius: 5px;
  margin-left: 0;
  background-color: #002668;

  ${Screen.smallpc`
  margin-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 7rem;
  border-radius: 30px;
  margin: 1rem auto!important;
  font-size: 12px;
  `}
  ${Screen.mediumPhone`
  width: 7rem;
  border-radius: 30px;
  margin: 1rem auto!important;
  font-size: 12px;
  `}
  ${Screen.smallPhone`
  width: 7rem;
  border-radius: 30px;
  margin: 1rem auto!important;
  font-size: 12px;
  `}
`;
const Paragraph = styled.div`
p {
  color: #000!important;
  ${Screen.smallpc`
  font-size: 16px!important;
  `}
  ${Screen.surfaceDuo`
  font-size: 14px!important;
  `}
   ${Screen.galaxyFold`
  font-size: 13px!important;
  `}
}
`;
const CardContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 3rem;
`
const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
${Screen.smallpc`
margin-bottom: 5rem;
`}
${Screen.surfaceDuo`
margin-bottom: 3rem
`}
h2 {
  ${Screen.smallpc`
  font-size: 20px;
  color: #C81E19;
  text-align: center;
  padding-top: .5rem;
  `}
  ${Screen.mediumPhone`
  font-size: 18px;
  color: #C81E19;
  text-align: center;
  padding-top: .5rem;
  `}
}

p {
  ${Screen.smallpc`
  color: #000!important;
  font-size: 16px!important;
  padding-top: .5rem;
  `}
  ${Screen.surfaceDuo`
  color: #000!important;
  font-size: 14px!important;
  padding-top: .5rem;
  `}
  ${Screen.mediumPhone`
  color: #000!important;
  font-size: 14px!important;
  padding-top: .5rem;
  `}
   ${Screen.galaxyFold`
  font-size: 13px!important;
  `}
}
`;
export default AbbeyCards;
