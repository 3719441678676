import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../assets/img';
import { Decorate } from '../../styles';

const Notify = ({ cancel }) => {
  return (
    <Message
      style={{
        backgroundColor: '#4BB543',
      }}
    >
      <p style={{ paddingRight: '1rem' }}>
        Your form has been successfully submitted! A customer experience
        representative will contact you soon
      </p>
      <Icon onClick={cancel} />
    </Message>
  );
};

export default Notify;

const Message = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;
  font-size: 15px;
  background-color: #ff6b62;
  padding: 20px;
  border: 1px solid #ff6b62;
  border-radius: 5px;
  color: white;
  ${Decorate.animate.slideInRight(0.5)}
  display: flex;
`;
const Icon = styled.div`
  content: url(${CloseIcon});
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;
