import { 
    GOOGLE_FAILURE, 
    GOOGLE_REQUEST, 
    GOOGLE_SUCCESS
} from "../constants"



export const googleApiReducer = (state, action) => {
    switch(action.type){
        case GOOGLE_REQUEST:
            return {
                loading: true,
                data: '',
                error: ''
            }
        case GOOGLE_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case GOOGLE_FAILURE:
            return {
                loading: false,
                data: '',
                error: action.error,
            }
        default:
            return state
    }
}