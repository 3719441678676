import React from 'react'
import styled from 'styled-components'
import { ChatComponent } from '../components'

const chat = () => {
  return (
    <App>
        <ChatComponent.Hoducc />
    </App>
  )
}

const App = styled.div`
width: 100%;
min-height: 100vh;
padding-top: 10rem;
background-color: aliceblue;
`;

export default chat