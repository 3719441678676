import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Screen } from '../../styles';


const investor = ({ press }) => {

  // const arr = press.reverse()
  return (
    <Box>
      <Header>Investor News</Header>
      <CardContainer>
        {press.slice(0, 3).reverse().map((item) => (
          
          <Card>
            <Image src={item.imageUrl} alt='press' className='press' />
            <CardBody>
              <p>{item.title}</p>
              <CardLink to={item.link}>Read More</CardLink>
            </CardBody>
          </Card>
      ))}
      </CardContainer>
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
`;

const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  ${Screen.pad`
  font-size: 24px;
  `}
  ${Screen.mediumpc`
  font-size: 24px;
  `}
  ${Screen.smallpc`
  font-size: 24px;
  `}
  ${Screen.mediumPhone`
  font-size: 18px;
  padding-left: 1rem;
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-left: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-left: 1rem;
  `}
`;
const CardContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  ${Screen.smallpc`
  justify-content: space-around;
  align-items: center;
  `}
  ${Screen.miniTablet`
  flex-direction: column;
  justify-content: center;
  align-items: center
  `}
`;

const Card = styled.div`
  width: 21rem;
  height: auto;
  background-color: #f5f5f5;
  margin-right: 2rem;
  ${Screen.pad`
  width: 22rem;
  margin-right: 1rem;
  `}
  ${Screen.mediumpc`
  width: 22rem;
  margin-right: 1rem;
  `}
  ${Screen.smallpc`
  width: 22rem;
  margin-right: 1rem;
  `}
  ${Screen.tablet`
  width: 22rem;
  margin-right: 1rem;
  `}
   ${Screen.surfaceDuo`
  width: 18rem;
  margin-right: 0;
  margin-bottom: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 17rem;
  margin-right: 0;
  margin-bottom: 1rem;
  `}
  ${Screen.phone`
  width: 17rem;
  margin-right: 0;
  margin-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  width: 15rem;
  margin-right: 0;
  margin-bottom: 1rem;
  `}
`;
const Image = styled.img`
  width: 100%;
  ${Screen.mediumpc`
  height: 9rem;
  `}
  ${Screen.surfaceDuo`
  height: 9rem;
  `}
`;
const CardBody = styled.div`
  padding: 2rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: bold;
    padding-bottom: 2rem;
    ${Screen.mediumpc`
    font-size: 14px
    `}
    ${Screen.surfaceDuo`
    font-size: 15px
    `}
    ${Screen.mediumPhone`
    font-size: 14px
    `}
    ${Screen.phone`
    font-size: 14px
    `}
  }
`;
const CardLink = styled(Link)`
  color: #c81e19;
  font-size: 0.9rem;
  font-weight: bold;
`;

export default investor;
