import React from 'react';
import styled from 'styled-components';
import { eventItems } from '../main/products';
import { Screen } from '../../styles';
import { Link } from 'react-router-dom';

const events = () => {
  return (
    <Box>
      <Header>Events</Header>
      <Container>
        {
          eventItems.map(({ item, path }) => (
        <Card>
        <Content>
          <p>{item}</p>
        </Content>
        <Icon>
          <IconLink to={path}><p>View</p></IconLink>
        </Icon>
      </Card>

          ))
        }
      </Container>
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  ${Screen.pad`
  font-size: 24px;
  `}
  ${Screen.mediumpc`
  font-size: 24px;
  `}
  ${Screen.smallpc`
  font-size: 24px;
  `}
   ${Screen.mediumPhone`
  font-size: 24px;
  padding-left: 1rem;
  `}
  ${Screen.mediumPhone`
  font-size: 18px;ContentContainer
  padding-left: 1rem;
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-left: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-left: 1rem;
  `}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  ${Screen.smallpc`
  width: 90%;
  margin: 1rem 1rem;
  `}

  ${Screen.surfaceDuo`
  width: 90%;
  margin: 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  ${Screen.phone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  ${Screen.smallPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  /* justify-content: space-between; */
`;
const Card = styled.div`
  width: 80%;
  height: 4rem;
  background-color: #f5f5f5;
  display: flex;
  margin-top: 2rem;
  ${Screen.mediumpc`
  width: 90%;
  margin-top: 1rem;
  `}
  ${Screen.smallpc`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.smallPhone`
  width: 100%;
  margin-top: 1rem;
  `}
`;
const Content = styled.div`
  width: 92%;
  height: 100%;
  padding-top: 1.5rem;
  padding-left: 2rem;
  ${Screen.surfaceDuo`
  width: 90%;
  padding: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 87%;
  padding: 1rem;
  `}
   ${Screen.phone`
  width: 87%;
  padding: 1rem;
  `}
  ${Screen.smallPhone`
  width: 85%;
  padding: 1rem;
  `}

  p {
    ${Screen.mediumPhone`
    font-size: 14px;
    `}
    ${Screen.phone`
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    font-size: 13px;
    `}
  }
`;
const Icon = styled.div`
  width: 8%;
  height: 100%;
  background-color: #002668;
  content: ${({ content }) => content};
  cursor: pointer;
  padding: 0.5rem;
  ${Screen.surfaceDuo`
  width: 10%;
  `}
  ${Screen.mediumPhone`
  width: 13%;
  `}
   ${Screen.phone`
  width: 13%;
  `}
  ${Screen.smallPhone`
  width: 15%;
  `}

  &:hover {
    box-shadow: 5px 0px 20px rgb(199, 199, 199, 0.9);
  }
`;

const IconLink = styled(Link)`
color: #fff;
text-decoration: none;

p {
  text-align: center;
  font-weight: bold;
  padding-top: 1rem;
  font-size: .8rem;
  ${Screen.surfaceDuo`
  font-size: 14px;
  `}
  ${Screen.mediumPhone`
  font-size: 12px;
  `}
  ${Screen.phone`
  font-size: 12px;
  `}
  ${Screen.smallPhone`
  font-size: 12px;
  `}
  ${Screen.galaxyFold`
  font-size: 10px;
  `}
}

&:hover {
    box-shadow: 5px 0px 20px rgb(199, 199, 199, 0.9);
  }
`
export default events;
