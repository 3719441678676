import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Screen } from '../../styles'

const Chat = () => {
    const chatRef = useRef()
    const mounted = useRef(false)
    const location = useLocation()

    useEffect(() => {
        mounted.current = true
        let HoduCCPluginIFrame = document.getElementById("HoduCCPluginIFrame");
        let eventMethod=window.addEventListener?"addEventListener":"attachEvent",
        eventer=window[eventMethod],
        messageEvent="attachEvent"===eventMethod?"onMessage":"message";
        eventer(messageEvent, function(e){
        let t = e.data; 
        var r = t["type"];
        // eslint-disable-next-line no-undef
        for(const key in t){
        // eslint-disable-next-line no-undef
        HoduCCPluginIFrame&&( (r === "css")? HoduCCPluginIFrame.style[key]=t[key]:HoduCCPluginIFrame.setAttribute(key,t[key]))
      }
    })

    return () => {
      mounted.current = false
    }

    }, [])

  return (
    <>
    {
      location.pathname === '/chat' ? (
        <ChatFrame
        src='https://cx.abbeymortgagebank.com/cc_plugin/index.php?token=0eb2b152ae3508de7b8b76c97dc60e28' 
        title='customer experience' 
        ref={chatRef}
        allow='geolocation microphone camera'
        id='HoduCCPluginIFrame'
        allowFullScreen={true}
        webkitAllowFullScreen={true}
        mozAllowFullScreen={true}>
        </ChatFrame>
      ) : (
        <IFrame 
        src='https://cx.abbeymortgagebank.com/cc_plugin/index.php?token=0eb2b152ae3508de7b8b76c97dc60e28' 
        title='customer experience' 
        ref={chatRef}
        allow='geolocation microphone camera'
        id='HoduCCPluginIFrame'
        allowFullScreen={true}
        webkitAllowFullScreen={true}
        mozAllowFullScreen={true}>
        </IFrame>
      )
    }
    </>
  )
}
const IFrame = styled.iframe`
position: fixed;
top: 6rem;
right: 3rem;
height: 500px;
z-index: 9999;
border: none;

${Screen.miniLargeScreen`
right: 5rem;
`}
${Screen.ipadAir`
right: 1rem;
`}
${Screen.smallPhone`
right: .5rem;
`}
${Screen.galaxyFold`
right: 0
`}
`;

const ChatFrame = styled.iframe`
position: fixed;
top: 20rem;
left: 50%;
right: 50%;
transform: translate(-50%, -50%);
z-index: 9999;
height: 500px;
border: none;
`;

export default Chat