import styled from 'styled-components';
import { Decorate, NavButton } from '../../../styles';

const StepTwo = ({ next, prev, values, handleChange }) => {
  const validation =
    values.branch === '' ||
    values.maiden === '' ||
    values.gender === '' ||
    values.marital === '' ||
    values.origin === '' ||
    values.lga === '';
  return (
    <FormContainer>
      <FormHeader>Step 2 0f 6: Bio Data</FormHeader>
      <Form autoComplete='off'>
        <FlexBox>
          <label>Preferred Branch</label>
          <Select>
            <select
              className='select-css'
              name='branch'
              onChange={handleChange('branch')}
              value={values.branch}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Victoria Island'}>Victoria Island</option>
              <option value={'Festac'}>Festac</option>
              <option value={'Apapa'}>Apapa</option>
              <option value={'Okota'}>Okota</option>
              <option value={'Iba'}>Iba</option>
              <option value={'Abuja'}>Abuja</option>
              <option value={'Baze'}>Baze</option>
              <option value={'Asaba'}>Asaba</option>
            </select>
          </Select>
        </FlexBox>

        <FlexBox mt='3rem'>
          <label>Mother's Maiden Name</label>
          <Input>
            <input
              type={'text'}
              name='maiden'
              placeholder="Please enter mother's maiden name"
              onChange={handleChange('maiden')}
              value={values.maiden}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Gender</label>
          <Select>
            <select
              className='select-css'
              name='gender'
              onChange={handleChange('gender')}
              value={values.gender}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Male'}>Male</option>
              <option value={'Female'}>Female</option>
            </select>
          </Select>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Marital Status</label>
          <Select>
            <select
              className='select-css'
              name='marital'
              onChange={handleChange('marital')}
              value={values.marital}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Single'}>Single</option>
              <option value={'Engaged'}>Engaged</option>
              <option value={'Married'}>Married</option>
              <option value={'Divorced'}>Divorced</option>
              <option value={'Widowed'}>Widowed</option>
            </select>
          </Select>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>State of Origin</label>
          <Input>
            <input
              type={'text'}
              name='origin'
              placeholder='Please enter state'
              onChange={handleChange('origin')}
              value={values.origin}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Local Government Area</label>
          <Input>
            <input
              type={'text'}
              name='lga'
              placeholder='Please enter your local government'
              onChange={handleChange('lga')}
              value={values.lga}
            />
          </Input>
        </FlexBox>
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
const Select = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

  ${Decorate.select};
  .select-css {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
  }
`;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepTwo;
