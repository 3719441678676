import React, { 
  useState, 
  useEffect, 
  useContext, 
  useRef
 } from 'react';
import styled from 'styled-components';
import { RelationComponents, HelpersComponent } from '../../components';
import {  StoryBg, InvestorMobile, Background } from '../../assets/img';
// import { Footer } from '../../components/footer';
import { announcement, relations } from '../../components/main';
import { RelationsHeroContext } from '../../contexts/hero'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS, PRESS_FAILURE, PRESS_REQUEST, PRESS_SUCCESS } from '../../constants';
import api from '../../services/api';
import { PressContext } from '../../contexts/blog';
import { Screen } from '../../styles'

const Investor = RelationComponents.Investor;
const Corporate = RelationComponents.Corporate;
const Financial = RelationComponents.Financial;
const Forms = RelationComponents.Forms;
const Events = RelationComponents.Events;
// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const Relation = () => {
  const mount = useRef()
  const { dispatch, state } = useContext(RelationsHeroContext)
  const { dispatch: pressDispatch, state: pressState } = useContext(PressContext)
  const [step, setStep] = useState('Investor News');
  const [mobile, setMobile] = useState(false);

  const activeStep = (text) => {
    setStep(text);
  };

  useEffect(() => {
    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, [])
  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);

    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/relations')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()


    return () => {
      mount.current = false
    };
  }, []);
  useEffect(() => {
    // get press articles
 const getPress = async () => {
  try {
    
    if(pressState.data.length < 1){
      pressDispatch({ type: PRESS_REQUEST})
      const result = await api.req('/api/document/press')
      pressDispatch({
        type: PRESS_SUCCESS,
        payload: JSON.parse(result.details.data)
      }) 
    }
    
  } catch (error) {
    pressDispatch({
      type: PRESS_FAILURE,
      payload: error.response && error.response.result.responseMessage 
      ? error.response.result.responseMessage : error.responseMessage
    }) 
  }
  }
  getPress()
  
  }, [])


  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          <Section className='hero' bg={`url(${state.data[0]})`}>
            <Container className='hero'>
              <Glass>
                <Content className='glass'>
                  <h2>Investor Relations</h2>
                  <p>
                  With meaningful relationships built on trust 
                  and rewards, our transformative growth has 
                  flourished over time.
                  </p>
                </Content>
              </Glass>
            </Container>
          </Section>
    
          {/* Browse */}
          <Section className='browse'>
            {
              mobile ? (
                <>
              <Header>Browse</Header>
              <CardBackground>
              <CardContainer>
                  {relations.map(({ img, link }) => (
                    <Card key={link}>
                      <CardHeader className={step === link ? 'active' : null}>
                        <Image
                          src={img}
                          alt='icon'
                          className='icon'
                          onClick={() => activeStep(link)}
                        />
                      </CardHeader>
                      <CardBody>
                        <p onClick={() => activeStep(link)}>{link}</p>
                      </CardBody>
                    </Card>
                  ))}
                </CardContainer>
                <AnnouncementContainer>
                  {announcement.map(({ img, name }) => (
                    <FlexBox className='announcement'>
                      <Image
                        src={img}
                        alt='announcement'
                        className='announcement'
                      />
                      <h4
                    
                      >
                        {name}
                      </h4>
                    </FlexBox>
                  ))}
                </AnnouncementContainer>
              </CardBackground>
              <ContentContainer className='browse'>
                  <Content>{step === 'Investor News' && <Investor press={pressState.data} />}</Content>
                  <Content>
                    {step === 'Corporate Governance' && <Corporate />}
                  </Content>
                  <Content>
                    {step === 'Financial Information' && <Financial />}
                  </Content>
                  <Content>{step === 'Forms' && <Forms />}</Content>
                  <Content>{step === 'Events' && <Events />}</Content>
                </ContentContainer>
                </>
              ) : (
                <FlexBox>
              <Browse>
                <Header>Browse</Header>
                <CardContainer>
                  {relations.map(({ img, link }) => (
                    <Card key={link}>
                      <CardHeader className={step === link ? 'active' : null}>
                        <Image
                          src={img}
                          alt='icon'
                          className='icon'
                          onClick={() => activeStep(link)}
                        />
                      </CardHeader>
                      <CardBody>
                        <p onClick={() => activeStep(link)}>{link}</p>
                      </CardBody>
                    </Card>
                  ))}
                </CardContainer>
                <ContentContainer className='browse'>
                  <Content>{step === 'Investor News' && <Investor press={pressState.data} />}</Content>
                  <Content>
                    {step === 'Corporate Governance' && <Corporate />}
                  </Content>
                  <Content>
                    {step === 'Financial Information' && <Financial />}
                  </Content>
                  <Content>{step === 'Forms' && <Forms />}</Content>
                  <Content>{step === 'Events' && <Events />}</Content>
                </ContentContainer>
              </Browse>
              <Announcement>
                <Header className='announcement'>Announcements</Header>
                <AnnouncementContainer>
                  {announcement.map(({ img, name }) => (
                    <FlexBox className='announcement'>
                      <Image
                        src={img}
                        alt='announcement'
                        className='announcement'
                      />
                      <h4>
                        {name}
                      </h4>
                    </FlexBox>
                  ))}
                </AnnouncementContainer>
                {/* <Search>
                  <Image src={SearchImg} alt='search' className='search' />
                  <input type='text' placeholder='Search' />
                </Search> */}
              </Announcement>
            </FlexBox>
              )
            }
          </Section>
            
          {/* End of Browse */}
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  margin: 10rem auto 0;
  &.hero {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
     ${Screen.tablet`
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
`;
const Glass = styled.div`
  width: 540px;
  height: 270px;
  background: rgba(255, 255, 255, 0.7);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const Browse = styled.div`
  width: 70%;
  padding-left: 10rem;
  flex-grow: 1;
  z-index: 1;
  ${Screen.pad`
  width: 75%;
  padding-left: 2rem;
  `}
  ${Screen.mediumpc`
  width: 75%;
  padding-left: 2rem
  `}
  ${Screen.smallpc`
  display: none
  `}
`;
const CardContainer = styled.div`
  margin-top: 3rem;
  margin-left: 6rem;
  display: flex;
  justify-content: space-between;
  /* z-index: 1; */
  ${Screen.miniLargeScreen`
  margin-left: 0;
  margin-top: 2rem;
  `}
  ${Screen.pad`
  margin-left: 0;
  margin-top: 2rem;
  `}
  ${Screen.mediumpc`
  margin-left: 0;
  margin-top: 2rem;
  `}
  ${Screen.smallpc`
  position: absolute;
  top: -6rem!important;
  margin-left: 3rem!important;
  `}
   ${Screen.surfaceDuo`
  position: absolute;
  top: -2rem!important;
  margin-left: 1rem!important;
  `}
  ${Screen.mediumPhone`
  position: absolute;
  top: -2rem!important;
  margin-left: 1rem!important;
  `}
  ${Screen.phone`
  position: absolute;
  top: -2rem!important;
  margin-left: 1rem!important;
  `}
  ${Screen.smallPhone`
  position: absolute;
  top: -2rem!important;
  margin-left: .5rem!important;
  `}
  ${Screen.galaxyFold`
  position: absolute;
  top: -2rem!important;
  margin-left: 1px!important;
  `}
`;

const Image = styled.img`
  &.icon {
    width: 1.5rem;
    height: 1.5rem;
    ${Screen.mediumPhone`
    width: 20px;
    height: 20px
    `}
    ${Screen.phone`
    width: 18px;
    height: 18px
    `}
    ${Screen.smallPhone`
    width: 16px;
    height: 16px
    `}
  }
  &.search {
    height: 25px;
    margin-right: 1rem;
  }
  &.announcement {
    ${Screen.pad`
     height: 35px;
      width: 35px;
    `}
    ${Screen.mediumpc`
     height: 35px;
      width: 35px;
    `}
    ${Screen.smallpc`
     height: 35px;
      width: 35px;
      margin: 0 auto!important
    `}
    ${Screen.mediumPhone`
     height: 27px;
      width: 27px;
      margin: 0 auto!important
    `}
    ${Screen.phone`
     height: 25px;
      width: 25px;
      margin: 0 auto!important
    `}
    ${Screen.smallPhone`
     height: 25px;
      width: 25px;
      margin: 0 auto!important
    `}
    /* display: flex!important;
    justify-content: center!important;
    align-items: center!important */
  }
`;
const ContentContainer = styled.div`
  margin-top: 10rem;
  ${Screen.pad`
  margin-top: 7rem
  `}
  ${Screen.mediumpc`
  margin-top: 7rem
  `}
  ${Screen.smallpc`
  padding-left: 2rem;
  `}
   ${Screen.tablet`
  margin-top: 7rem;
  padding-left: 2rem;
  `}
  ${Screen.mediumPhone`
  margin-top: 5rem;
  padding-left: 0
  `}
  ${Screen.phone`
  margin-top: 5rem;
  `}
  ${Screen.smallPhone`
  margin-top: 5rem;
  `}
`;
const Section = styled.section`
  width: 100%;
  min-height: 100vh;
  &.hero {
    /* background-image: url(${StoryBg}); */
    background-image: ${({ bg }) => bg};
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 13rem;
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 50vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.browse {
    ${Screen.mediumpc`
    min-height: 60vh!important
    `}
    ${Screen.smallpc`
    min-height: 70vh!important
    `}
  }
  &.banner {
    width: 100%;
    min-height: 60vh;
    margin-top: 5rem;
    margin-bottom: 0;
  }

  &.announcement {
    width: 100%;
    min-height: 40vh;
    margin-top: 5rem;
  }
  &.governance {
    width: 100%;
    min-height: 100vh;
    margin: 3rem 0 0 5rem;
  }
  &.analyst,
  &.financial,
  &.shareholder {
    width: 100%;
    min-height: 100vh;
    margin: 3rem 0 0 5rem;
  }
`;
const FlexBox = styled.div`
  display: flex;
  min-height: auto;
  &.announcement {
    margin-bottom: 1rem;
    /* ${Screen.mediumpc`
    flex-direction: column;
    margin-right: 1.5rem;
    `} */
    ${Screen.smallpc`
    flex-direction: column;
    margin-right: 1.5rem;
    `}
    ${Screen.mediumPhone`
    flex-direction: column;
    margin-right: 1rem;
    `}
    ${Screen.phone`
    flex-direction: column;
    margin-right: 1rem;
    `}
    ${Screen.smallPhone`
    flex-direction: column;
    margin-right: .5rem;
    `}

    h4 {
      padding-top: 1rem;
      font-size: 1.3rem;
      padding-left: .5rem;
      ${Screen.pad`
      font-size: 1.1rem!important;
      padding-top: .5rem;
      `}
      ${Screen.mediumpc`
      font-size: 1.1rem!important;
      padding-top: .5rem;
      `}
      ${Screen.smallpc`
      font-size: 1rem!important;
      padding-top: .5rem 0 1rem!important;
      `}
      ${Screen.mediumPhone`
      font-size: 1rem!important;
      padding-top: .5rem 0 1rem!important;
      `}
      ${Screen.iphone`
      font-size: .9rem!important;
      padding-top: 1rem!important;
      `}
      ${Screen.phone`
      font-size: .9rem!important;
      padding-top: 1rem!important;
      `}
      ${Screen.samsungGalaxy`
      font-size: .8rem!important;
      padding-top: .5rem!important;
      `}
      ${Screen.smallPhone`
      font-size: .8rem!important;
      padding-top: .5rem!important;
      `}
       ${Screen.galaxyFold`
      font-size: .7rem!important;
      padding-top: .5rem!important;
      `}
    }

  }
  &.governance {
    margin-left: -5rem;
    margin-top: 5rem;
  }
  &.financial {
    margin-top: 2rem;
  }
  ${Screen.smallPhone`
  flex-direction: column;
  `}
`;
const Announcement = styled.div`
  width: 30%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20rem;
  /* padding-bottom: 3rem; */
  ${Screen.pad`
  padding-top: 10rem;
  `}
  ${Screen.mediumpc`
  padding-top: 13rem;
  `}
  ${Screen.pad`
  width: 25%;
  `}
  ${Screen.mediumpc`
  width: 25%;
  `}
`;
const AnnouncementContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* ${Screen.mediumpc``} */
  ${Screen.smallpc`
  position: absolute;
  width: 90%;
  top: 7rem!important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.surfaceDuo`
  position: absolute;
  width: 90%;
  top: 7rem!important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  position: absolute;
  width: 90%;
  top: 5rem!important;
  flex-direction: row;
  left: 1rem;
  `}
   ${Screen.phone`
  position: absolute;
  width: 90%;
  top: 5rem!important;
  flex-direction: row;
  left: 1rem;
  `}
  ${Screen.smallPhone`
  position: absolute;
  width: 90%;
  top: 5rem!important;
  flex-direction: row;
  left: 1rem;
  `}
  /* justify-content: space-between; */
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  padding-top: 7rem;
  ${Screen.pad`
  font-size: 1.5rem
  `}
  ${Screen.mediumpc`
  font-size: 1.5rem
  `}
  ${Screen.smallpc`
  font-size: 1.5rem;
  padding-left: 3rem;
  padding-top: 5rem
  `}
  ${Screen.tablet`
  font-size: 1.5rem;
  padding-left: 3rem;
  padding-top: 1rem
  `}
  ${Screen.mediumPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-top: 3rem;
  padding-left: 10px
  `}

  &.announcement {
    ${Screen.pad`
    font-size: 1.3rem
    `}
    ${Screen.mediumpc`
    font-size: 1.3rem
    `}
  }
`;

// const ConstructionContainer = styled.div`
//   display: flex;
//   justify-content: center;
// `;
const Content = styled.div`
  &.announcement {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #002668;
    margin-left: 3rem;
    ${Screen.smallPhone`
    margin-left: 0
    `}
  }
  &.glass {
    padding: 3rem;
    ${Screen.surfaceDuo`
    padding: 0 1rem 1rem;
    `}
    ${Screen.mediumPhone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.phone`
    padding: 0 1rem 1rem;
    `}
    ${Screen.smallPhone`
    padding: 0 1rem 1rem;
    `}
    h2 {
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      ${Screen.mediumpc`
      font-size: 30px;
      padding-top: 0rem;
      `}
       ${Screen.smallpc`
      font-size: 30px;
      padding-top: 0;
      `}
      ${Screen.tablet`
      font-size: 30px;
      padding-top: 0;
      `}
      ${Screen.surfaceDuo`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding: 1rem 0 .5rem!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.galaxyFold`
      padding: 1rem 0 .5rem!important;
      font-size: 22px;
      text-align: center;
      `}
    }

    p {
      color: #002668;
      font-size: 1.2rem;
      padding-top: 1rem;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 18px;
      padding-top: 2rem
      `}
      ${Screen.tablet`
        color: #002668;
      text-shadow: none;
      font-size: 16px;
      padding-top: 2rem
      `}
      ${Screen.surfaceDuo`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding-top: 1rem;
      `}
      ${Screen.mediumPhone`
       font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      `}
    }
  }
`;
// const Search = styled.div`
//   width: 70%;
//   height: 3rem;
//   border-radius: 20px;
//   margin: 3rem 0;
//   display: flex;
//   justify-content: flex-start;
//   padding-left: 2rem;
//   align-items: center;
//   box-shadow: inset 10px 10px 8px #d9d9d9, inset -1px -1px 4px #d9d9d9;

//   input {
//     width: 100%;
//     border: none;
//     outline: none;
//     background: none;
//     font-size: 18px;
//     color: #000;
//     padding: 20px 10px 20px 5px;
//     &::placeholder {
//       margin-left: 2rem;
//       color: #000;
//     }
//   }
// `;
const Card = styled.div`
  width: 10rem;
  height: 10rem;
  background-color: #f3f3f3;
  box-shadow: 5px 3px 5px #ccc;
  margin-right: 2rem;
  border-radius: 5px;
  ${Screen.mediumpc`
  margin-right: 1rem;
  `}
  ${Screen.smallpc`
  margin-right: 1rem;
  width: 8rem;
  `}
  ${Screen.surfaceDuo`
  width: 120px;
  height: 120px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.mediumPhone`
  width: 90px;
  height: 100px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.iphone`
  width: 85px;
  height: 100px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.phone`
  width: 80px;
  height: 100px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.samsungGalaxy`
  width: 80px;
  height: 100px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.smallPhone`
  width: 70px;
  height: 90px;
  margin-right: .5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
  ${Screen.galaxyFold`
  width: 65px;
  height: 90px;
  margin-right: .3rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  `}
`;
const CardHeader = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #002668;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${Screen.mediumPhone`
  height: 30px
  `}
   ${Screen.phone`
  height: 30px
  `}
  ${Screen.smallPhone`
  height: 30px
  `}

  &.active {
    background-color: #c81e19;
  }
`;
const CardBody = styled.div`
  width: 100%;
  height: 7rem;

  p {
    text-align: center;
    padding: 3rem 0;
    font-size: 0.8rem;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    ${Screen.surfaceDuo`
    font-size: 0.8rem;
    padding: 1rem 0
    `}
    ${Screen.mediumPhone`
    font-size: 0.7rem;
    padding: 1rem 0
    `}
    ${Screen.phone`
    font-size: 0.7rem;
    padding: 1rem 0
    `}
    ${Screen.smallPhone`
    font-size: 0.6rem;
    padding: 1rem 0
    `}
  }
`;
const CardBackground = styled.div`
${Screen.smallpc`
width: 100%;
height: 250px;
position: relative;
background-color: #F5F5F5;
margin-top: 6rem;
`}
${Screen.surfaceDuo`
width: 100%;
height: 250px;
position: relative;
background-color: #F5F5F5;
margin-top: 5rem;
scroll-snap-type: x mandatory;
overflow: auto;
`}
${Screen.mediumPhone`
width: 100%;
height: 200px;
position: relative;
background-color: #F5F5F5;
margin-top: 3rem;
`}
/* ${Screen.phone`
width: 100%;
height: 170px;
position: relative;
background-color: #F5F5F5;
margin-top: 5rem;
`} */
`

export default Relation;
