import { useEffect } from 'react';
import styled from 'styled-components';
// import { NavigationBar } from '../../components';
import { Mortgage, Finance } from '../../assets/img';
import { NavButton } from '../../styles';
import { Footer } from '../../components/footer';
import { Link, useNavigate } from 'react-router-dom';

// const Navigation = NavigationBar.Navigation;
const Construction = () => {

  const navigate = useNavigate()
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      <Container>
        <Banner>
          <h1>
            Construction <br /> Finance
          </h1>
          <Image className='construction' src={Mortgage} alt='construction' />
        </Banner>
        <div style={{ marginTop: '1rem' }}>
          <Paragraph className='construction'>
            <p>
              Lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit,
              sed do <br /> eiusmod tempor incididunt ut labore <br /> et dolore
              magna aliqua.
            </p>
          </Paragraph>
          <Button onClick={() => navigate('/personal-form')}>Apply for Mortgage</Button>
        </div>

        {/* Commercial Real Estate */}
        <Section className='commercial'>
          <Header>Commercial Real Estate</Header>
          <ConstructionContainer>
            <Content className='description'>
              <p>
                Loan Granted to both individual and corporate entities for{' '}
                <br />
                outright purchase of an existing mortgage from another
                <br /> financial institution subject to meeting the Banks
                lending <br />
                criteria
              </p>
              <ButtonWrapper>
                <ConstructionLink
                  to={'/construction-finance/form'}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ConstructionButton mr='1rem'>Apply</ConstructionButton>
                </ConstructionLink>
                <ConstructionLink
                  to={'/construction-finance/form'}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ConstructionButton>Learn More</ConstructionButton>
                </ConstructionLink>
              </ButtonWrapper>
            </Content>
            <Content className='features'>
              <i>Key feature are: </i>
              <ul>
                <li>Equity Contribution: 30% equity required</li>
                <li>Tenor: Up to 10 years</li>
                <li>Joint spouse borrowing allowed for Individuals</li>
              </ul>
            </Content>
          </ConstructionContainer>
          <Image src={Finance} alt='finance' className='finance' />
        </Section>
        {/* End of Commercial Real Estate */}
      </Container>
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 10rem auto 0;
  /* @media screen and (max-width: 900px) {
    width: 90%;
  } */
`;
const Banner = styled.div`
  width: 100%;
  height: 12rem;
  background-color: #002668;
  display: flex;
  justify-content: space-around;
  padding-top: 4rem;
  /* position: relative; */
  /* @media screen and (max-width: 900px) {
    display: flex;
    justify-content: space-between;
  } ; */
  h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    margin-left: -2rem;
    /* padding-top: 1rem; */
    word-spacing: 0.3rem;
    @media screen and (max-width: 1440px) {
      margin-left: 1rem;
    }
    @media screen and (max-width: 900px) {
      margin-left: 1rem;
      font-size: 2.5rem;
    }
  }
`;
const Paragraph = styled.div`
  &.construction {
    p {
      font-size: 1.1rem;
      padding-top: 1rem;
      margin-left: 2rem;
    }
  }

  &.quick {
    p {
      font-size: 1.2rem;
    }
  }

  &.abbey-mobile {
    /* text-align: left !important; */
    padding-top: 3rem;
    span {
      font-size: 1.2rem;
    }
  }
  /* @media screen and (max-width: 900px) {
    font-size: 16px;
  } */
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2rem;
  border-radius: 30px;
  margin: 2rem 0 0;
  margin-left: 2rem;
  background-color: #48a7ff;
  /* font-weight: bold; */
`;
const ConstructionButton = styled(Button)`
  width: 8rem;
  background-color: #002668;
  box-shadow: 5px 3px 5px #ccc;
  margin-top: 1rem;
  margin-right: ${({ mr }) => mr};
`;
const Image = styled.img`
  &.construction {
    width: 35rem;
    height: 20rem;
    margin-right: -1rem;
    /* margin-top: 5rem; */
  }
  &.finance {
    margin-top: 3rem;
  }
`;

const Section = styled.section`
  margin: 5rem 0;
  width: 100%;
  min-height: 100vh;

  &.abbey-card {
    text-align: center;

    p {
      font-size: 1.2rem;
      padding-top: 1rem;
    }
  }
  &.abbey-mobile {
    p {
      font-size: 1.2rem;
      padding-top: 2rem;
      text-align: center;
    }
  }
  &.corporate {
    text-align: center;
    i {
      font-weight: bold;
      margin-top: 2rem !important;
    }
  }
`;
const Header = styled.h1`
  color: #c81e19;
  font-size: 2rem;
  /* text-transform: uppercase; */
  text-align: center;
  padding-top: 5rem;
`;

const ConstructionContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  margin-top: 1rem;
  &.description {
    margin-right: 3rem;
    p {
      font-size: 1.05rem;
      text-align: right;
    }
  }
  &.features {
    ul {
      margin-top: 1rem;
      list-style-type: none;

      li {
        padding-bottom: 0.5rem;
      }
    }
  }
`;

const ConstructionLink = styled(Link)``;

export default Construction;
