import Main from './main';
import PersonalForm from './personal';
import BusinessForm from './business';
import CardRequest from './cardRequest';

const components = {
  Main,
  PersonalForm,
  BusinessForm,
  CardRequest,
};

export default components;
