import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AboutLeadership, FelixImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { Link } from 'react-router-dom';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'


// const Navigation = NavigationBar.Navigation;
const App = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  useEffect(() => {
    //Scroll to top on page load
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                <Header>Felix Omodayo-Owotuga</Header>
                <Title>Group Head, Retail Sales & E-business</Title>
                </div>
                <Image src={FelixImg} alt='profile picture' />
                <Paragraph>
                <p>
                Felix is a seasoned banker with a wealth of experience in retail banking sales and digital product management. 
                Holding an MBA in marketing and certifications as a Certified Scrum Product Manager, Product Owner, and SAFe Agile certified 
                Product Manager, Felix combines his academic background with practical expertise to drive customer engagement and achieve strategic objectives.
              </p>
              <p>
                Currently serving as the Group Head of Retail Sales & E-Business (Senior Product Manager) at Abbey Mortgage Bank Plc, 
                Felix has introduced agile methodologies to address challenges in product development, resulting in significant improvements in acquisition, 
                retention, and customer satisfaction metrics across all e-business products. 
              </p>
              <p>
              His leadership has led to the successful execution of deposit growth strategies, 
              yielding higher customer deposits and boosting Abbey's mortgage market share.
            </p>
            <p>
              Prior to his role at Abbey Mortgage Bank, Felix held key positions at Keystone Bank Limited and Access Bank Plc, 
              where he played instrumental roles in developing and managing innovative digital solutions. At Keystone Bank Limited, 
              he utilized the Scrum framework to increase accounts acquisition and retail digital products, resulting in higher market share and profitability. 
              Similarly, at Access Bank Plc, Felix spearheaded projects such as the Card Management System (TUGA) and the Instant PIN Project, driving product performance and market share growth.
            </p>
            <p>
              Felix's strategic mindset, problem-solving skills, and extensive experience in retail banking and digital product management make him a valuable asset in Abbey. His ability to translate 
              business requirements into actionable strategies, coupled with his track record of successful product launches and customer-centric offerings, positions him as a leader in driving innovation and 
              growth within Abbey Mortgage Bank Plc.
            </p>
                </Paragraph>
              </FlexBox>
            ): (
              <>
          <FlexBox>
            <Image src={FelixImg} alt='profile picture' />
            <Content>
            <Header>Felix Omodayo-Owotuga</Header>
            <Title>Group Head, Retail Sales & E-business</Title>
              <p>
                Felix is a seasoned banker with a wealth of experience in retail banking sales and digital product management. 
                Holding an MBA in marketing and certifications as a Certified Scrum Product Manager, Product Owner, and SAFe Agile certified 
                Product Manager, Felix combines his academic background with practical expertise to drive customer engagement and achieve strategic objectives.
              </p>
              <p>
                Currently serving as the Group Head of Retail Sales & E-Business (Senior Product Manager) at Abbey Mortgage Bank Plc, 
                Felix has introduced agile methodologies to address challenges in product development, resulting in significant improvements in acquisition, 
                retention, and customer satisfaction metrics across all e-business products. 
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
            <p>
              His leadership has led to the successful execution of deposit growth strategies, 
              yielding higher customer deposits and boosting Abbey's mortgage market share.
            </p>
            <p>
              Prior to his role at Abbey Mortgage Bank, Felix held key positions at Keystone Bank Limited and Access Bank Plc, 
              where he played instrumental roles in developing and managing innovative digital solutions. At Keystone Bank Limited, 
              he utilized the Scrum framework to increase accounts acquisition and retail digital products, resulting in higher market share and profitability. 
              Similarly, at Access Bank Plc, Felix spearheaded projects such as the Card Management System (TUGA) and the Instant PIN Project, driving product performance and market share growth.
            </p>
            <p>
              Felix's strategic mindset, problem-solving skills, and extensive experience in retail banking and digital product management make him a valuable asset in Abbey. His ability to translate 
              business requirements into actionable strategies, coupled with his track record of successful product launches and customer-centric offerings, positions him as a leader in driving innovation and 
              growth within Abbey Mortgage Bank Plc.
            </p>
          </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;

${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }

  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default App;
