import React from 'react'
import SliderContextProvider from './slider'
    // MobileSliderContext

import GalleryContextProvider from './gallery'
import GoogleContextProvider from './google'
import {
    AbbeyLinksContextProvider, 
    HelpLinksContextProvider,
    OtherLinksContextProvider
} from './footer'
import { 
    MainLinksContextProvider,
    SectionLinksContextProvider,
    MobileNavContextProvider
} from './navbar'
import {
    PersonalHeroContextProvider,
    BusinessHeroContextProvider,
    LoanHeroContextProvider,
    WaysHeroContextProvider,
    SustainabilityHeroContextProvider,
    RelationsHeroContextProvider,
    AboutHeroContextProvider,
    ContactHeroContextProvider,
    WireHeroContextProvider,
    WireMobileContextProvider
} from './hero'
import { 
    BlogLinksContextProvider,
     BlogsContextProvider,
     PressContextProvider,
     PressLandingContextProvider,
     YoutubeLinksContextProvider,
     FootprintContextProvider
} from './blog'
import {
    FinancialContextProvider,
    NoticeContextProvider,
    FormsContextProvider,
    DisclosureContextProvider,
    CibContextProvider
} from './doc'

import { EgmContextProvider, AgmContextProvider }  from './meeting'


const wrapper = ({ children }) => {
  return (
    <SliderContextProvider>
        <GalleryContextProvider>
            <GoogleContextProvider>
                <AbbeyLinksContextProvider>
                    <HelpLinksContextProvider>
                        <OtherLinksContextProvider>
                            <MainLinksContextProvider>
                                <SectionLinksContextProvider>
                                    <PersonalHeroContextProvider>
                                        <BusinessHeroContextProvider>
                                            <LoanHeroContextProvider>
                                                <WaysHeroContextProvider>
                                                    <SustainabilityHeroContextProvider>
                                                        <RelationsHeroContextProvider>
                                                            <AboutHeroContextProvider>
                                                                <ContactHeroContextProvider>
                                                                    <BlogLinksContextProvider>
                                                                        <YoutubeLinksContextProvider>
                                                                            <BlogsContextProvider>
                                                                                <PressContextProvider>
                                                                                <PressLandingContextProvider>
                                                                                    <MobileNavContextProvider>
                                                                                        <FinancialContextProvider>
                                                                                            <NoticeContextProvider>
                                                                                                <FormsContextProvider>
                                                                                                    <DisclosureContextProvider>
                                                                                                        <CibContextProvider>
                                                                                                            <WireHeroContextProvider>
                                                                                                                <WireMobileContextProvider>
                                                                                                                    <EgmContextProvider>
                                                                                                                        <AgmContextProvider>
                                                                                                                            <FootprintContextProvider>
                                                                                                                              
                                                                                                                                    {children}
                        
                                                                                                                            </FootprintContextProvider>
                                                                                                                        </AgmContextProvider>
                                                                                                                    </EgmContextProvider>
                                                                                                                </WireMobileContextProvider>
                                                                                                            </WireHeroContextProvider>
                                                                                                        </CibContextProvider>
                                                                                                    </DisclosureContextProvider>
                                                                                                </FormsContextProvider>
                                                                                            </NoticeContextProvider>
                                                                                        </FinancialContextProvider>
                                                                                    </MobileNavContextProvider>
                                                                                </PressLandingContextProvider>
                                                                                </PressContextProvider>
                                                                            </BlogsContextProvider>
                                                                        </YoutubeLinksContextProvider>
                                                                    </BlogLinksContextProvider>
                                                                </ContactHeroContextProvider>
                                                            </AboutHeroContextProvider>
                                                        </RelationsHeroContextProvider>
                                                    </SustainabilityHeroContextProvider>
                                                </WaysHeroContextProvider>
                                            </LoanHeroContextProvider>
                                        </BusinessHeroContextProvider>
                                    </PersonalHeroContextProvider>
                                </SectionLinksContextProvider>
                            </MainLinksContextProvider>
                        </OtherLinksContextProvider>
                    </HelpLinksContextProvider>
                </AbbeyLinksContextProvider>
            </GoogleContextProvider>
        </GalleryContextProvider>
    </SliderContextProvider>
  )
}

export default wrapper