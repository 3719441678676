import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { 
  personalHeroReducer,
  businessHeroReducer,
  waysHeroReducer,
  loanHeroReducer,
  sustainHeroReducer,
  investorHeroReducer,
  aboutHeroReducer,
  contactHeroReducer,
  wireHeroReducer,
  wireMobileReducer
 } = Reducers
const personalState = {
    loading: false,
    data: [],
    error: ''  
}
const businessState = {
  loading: false,
  data: [],
  error: ''  
}
const waysState = {
  loading: false,
  data: [],
  error: ''  
}
const loanState = {
  loading: false,
  data: [],
  error: ''  
}
const aboutState = {
  loading: false,
  data: [],
  error: ''  
}
const sustainState = {
  loading: false,
  data: [],
  error: ''  
}
const investorState = {
  loading: false,
  data: [],
  error: ''  
}
const contactState = {
  loading: false,
  data: [],
  error: ''  
}
const wireState = {
  loading: false,
  data: [],
  error: ''  
}
const wireMobileState = {
  loading: false,
  data: [],
  error: ''  
}
export const PersonalHeroContext = createContext()
export const BusinessHeroContext = createContext()
export const LoanHeroContext = createContext()
export const WaysHeroContext = createContext()
export const SustainabilityHeroContext = createContext()
export const RelationsHeroContext = createContext()
export const AboutHeroContext = createContext()
export const ContactHeroContext = createContext()
export const WireHeroContext = createContext()
export const WireMobileContext = createContext()


export const PersonalHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(personalHeroReducer, personalState)
  return (
    <PersonalHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </PersonalHeroContext.Provider>
  )
}
export const BusinessHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(businessHeroReducer, businessState)
  return (
    <BusinessHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </BusinessHeroContext.Provider>
  )
}

export const LoanHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer( loanHeroReducer, loanState)
  return (
    <LoanHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </LoanHeroContext.Provider>
  )
}

export const WaysHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(waysHeroReducer, waysState)
  return (
    <WaysHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </WaysHeroContext.Provider>
  )
}

export const SustainabilityHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sustainHeroReducer, sustainState)
  return (
    <SustainabilityHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </SustainabilityHeroContext.Provider>
  )
}

export const RelationsHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(investorHeroReducer, investorState)
  return (
    <RelationsHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </RelationsHeroContext.Provider>
  )
}

export const AboutHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(aboutHeroReducer, aboutState)
  return (
    <AboutHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </AboutHeroContext.Provider>
  )
}

export const ContactHeroContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(contactHeroReducer, contactState)
  return (
    <ContactHeroContext.Provider value={{ state, dispatch }}>
        {children}
    </ContactHeroContext.Provider>
  )
}

export const WireHeroContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(wireHeroReducer, wireState)
return (
  <WireHeroContext.Provider value={{ state, dispatch }}>
      {children}
  </WireHeroContext.Provider>
)
}

export const WireMobileContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(wireMobileReducer, wireMobileState)
return (
  <WireMobileContext.Provider value={{ state, dispatch }}>
      {children}
  </WireMobileContext.Provider>
)
}