import { useState } from 'react';
import styled from 'styled-components';
import { Screen } from '../../styles'

const Members = ({ item }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <Container onClick={item && toggle}>
        <Image src={open ? item.close : item.open} alt='icon' />
        <Text>{item.name}</Text>
      </Container>
      <FlexBox>
        <ListContainer className='members'>
          {open &&
            item.members.map((single, i) => (
              <List key={i} className='members'>
                {single.name && (
                  <ListItem className='members'>{single.name}</ListItem>
                )}
              </List>
            ))}
        </ListContainer>
        {item.name !== 'Statutory Audit Committee' && (
          <ListContainer className='duties'>
            {open &&
              item.duties.map((single, i) => (
                <List key={i} className='duties'>
                  {single.item && (
                    <ListItem className='duties'>{single.item}</ListItem>
                  )}
                </List>
              ))}
          </ListContainer>
        )}
      </FlexBox>
    </>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
  /* ${Screen.smallPhone`
  margin-bottom: 5px
  `} */
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListContainer = styled.div`
  margin-left: 3rem !important;
  ${Screen.mediumPhone`
  margin-left: 2rem!important;
  `}
  ${Screen.phone`
  margin-left: 2rem!important;
  `}
  ${Screen.smallPhone`
  margin-left: 2rem!important;
  `}

  &.duties {
    margin-top: 1rem;
  }
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: disc;

  &.members {
    list-style-type: none;
    &:nth-child(1) {
      color: #c81e19;
      font-weight: bold;
      font-size: 2rem;
    }
    ${Screen.mediumPhone`
      font-size: 14px
      `}
    ${Screen.phone`
      font-size: 14px
      `}
    ${Screen.smallPhone`
      font-size: 14px
      `}
      ${Screen.galaxyFold`
      font-size: 13px
      `}
  }
  &.duties {
    &:nth-child(1) {
      list-style-type: none;
      padding-bottom: 1rem;
    }

    ${Screen.mediumPhone`
      font-size: 14px
      `}
    ${Screen.phone`
      font-size: 14px
      `}
    ${Screen.smallPhone`
      font-size: 14px
      `}
      ${Screen.galaxyFold`
      font-size: 13px
      `}
  }
`;
const ListItem = styled.li`
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
  ${Screen.mediumPhone`
  font-size: 14px;
  `}
  ${Screen.phone`
  font-size: 14px;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  `}
   ${Screen.galaxyFold`
  font-size: 13px;
  `}
`;
const Image = styled.img`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
`;
const Text = styled.p`
  color: #002668;
  font-size: 1.2rem;
  padding-top: 0.3rem;
  padding-left: 1rem;
  white-space: nowrap;

  ${Screen.mediumPhone`
  font-size: 14px;
  padding-left: 10px;
  padding-top: .5rem
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-left: 10px;
  padding-top: .5rem
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-left: 5px;
  padding-top: .5rem
  `}
  ${Screen.galaxyFold`
  font-size: 13px;
  padding-left: 5px;
  padding-top: .5rem
  `}
`;

export default Members;
