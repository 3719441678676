import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, OvalMobile } from '../../assets/img';
import { Grey2, Blue2, Grey, Blue } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import {
  auditItems,
  committee,
  creditItems,
  governanceItems,
  statutoryItems,
  strategyItems,
} from '../../components/main/products';
import { Members } from '../../components/main';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Committee = () => {
  const [mobile, setMobile] = useState(false)
  const location = useLocation();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);

  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of Hero Section */}
      {
        mobile ? (
          <BreadCrumb>
        {committee.slice(0, 2).map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        ): ( 
      <BreadCrumb>
        {committee.map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        )
      }

      {/* Description Section */}
      <Section className='description'>
          {
            mobile ? (
              <>
              
                <Container>
              <FlexBox>
                <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content>
                    <h2>Governance Structure</h2>
                  </Content>
                </MobileHeader>
                <Content>
                  <p>
                    The Board has established four committees: Audit, Risk,
                Remuneration, and
                Nomination & Corporate Governance. These committees
                support the Board
                to discharge its responsibilities under the law,
                regulation, and corporate
                governance best practice.
                  </p>
                  <p>
                    Each committee operates under its own terms of reference which
                are subject
                to periodic review.
                  </p>
                  <p>
                    Below you can find further details on the membership and
                responsibilities of
                each committee.
                  </p>
                </Content>
              </FlexBox>
              </Container>
              <ListBox>
                <MembersContainer>
              {auditItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {strategyItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {creditItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {governanceItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {statutoryItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
            </MembersContainer>
              </ListBox>
              </>
            ): (
              <Container>
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey2} alt='grey' className='grey2' />
              <Image src={Blue2} alt='blue' className='blue2' />
            </ImageWrapper>
            <Content>
              <h1>Board Committees</h1>
              <p>
                The Board has established four committees: Audit, Risk,
                Remuneration, and
                Nomination & Corporate Governance. These committees
                support the Board
                to discharge its responsibilities under the law,
                regulation, and corporate
                governance best practice.
              </p>
              <p>
                Each committee operates under its own terms of reference which
                are subject
                to periodic review.
              </p>{' '}
              <p>
                Below you can find further details on the membership and
                responsibilities of
                each committee.
              </p>
            </Content>
          </FlexBox>
          <FlexBox className='list'>
            <MembersContainer>
              {auditItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {strategyItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {creditItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {governanceItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
              {statutoryItems.map((item, i) => (
                <Members item={item} key={i} />
              ))}
            </MembersContainer>
            <ImageWrapper className='list'>
              <Image src={Grey} alt='grey' className='grey' />
              <Image src={Blue} alt='blue' className='blue' />
            </ImageWrapper>
          </FlexBox>
        </Container>
            )
          }
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  padding-top: 0;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 3rem auto 0;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 0 auto;
  padding-top: 3rem
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 0 auto!important;
  padding-top: 3rem
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.galaxyFold`
  width: 90%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
`;

const Content = styled.div`
  padding-top: 1rem;
  ${Screen.smallpc`
  padding-top: 0
  `}
  h1 {
    color: #002668;
    padding-top: 3rem;
    ${Screen.miniLargeScreen`
    padding-top: 0
    `}
  }

  h2 {
    ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 10rem;
      top: 8rem;
      color: #002668
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 8rem;
      top: 6rem
      `}
       ${Screen.mediumPhone`
      font-size: 20px;
      position: absolute;
      left: 4rem;
      top: 5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 4rem;
      top: 5rem
      `}
      ${Screen.samsungGalaxy`
      font-size: 20px;
      position: absolute;
      left: 3rem;
      top: 5rem
      `}
      ${Screen.smallPhone`
      font-size: 18px;
      position: absolute;
      left: 3rem;
      top: 5rem;
      `} 
       ${Screen.galaxyFold`
      font-size: 16px;
      position: absolute;
      left: 3rem;
      top: 5rem;
      `} 
  }

  p {
    padding-top: 2rem;

    ${Screen.smallpc`
    padding-top: 1rem;
    `}
    ${Screen.mediumPhone`
    font-size: 14px;
    padding-top: 1rem;
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-top: 1rem;
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-top: 1rem;
    `}
  }
`;
const Image = styled.img`
  &.saving {
    margin-bottom: 3rem;
  }
  &.check {
    width: 25px;
    height: 25px;
  }
  &.grey2 {
    width: 250px;
    position: absolute;
    z-index: 1;
    ${Screen.miniLargeScreen`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue2 {
    width: 250px;
    position: absolute;
    top: 8rem;
    ${Screen.miniLargeScreen`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.pad`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.grey {
    width: 15rem;
    height: 15rem;
    position: absolute;
    z-index: 1;
    ${Screen.miniLargeScreen`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 6rem;
    ${Screen.miniLargeScreen`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.pad`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 70vh;
  &.hero {
    min-height: 50vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
     ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.description {
    ${Screen.mediumpc`
    min-height: 60vh;
    // background-color: #000
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;

  ${Screen.mediumpc`
  padding-top: 3rem
  `}
  ${Screen.smallpc`
  padding-top: 0
  `}

  &.list {
    margin-top: 15rem;
    justify-content: space-between;

    ${Screen.pad`
    margin-top: 7rem
    `}
    ${Screen.mediumpc`
    margin-top: 5rem
    `}
  }

  ${Screen.smallpc`
  flex-direction: column;
  `}
`;

const ImageWrapper = styled.div`
  width: 30%;
  position: relative;
  margin-right: 15rem;

  ${Screen.miniLargeScreen`
  margin-right: 15rem;
  `}
  ${Screen.pad`
  margin-right: 15rem;
  `}
  ${Screen.mediumpc`
  margin-right: 10rem;
  `}
  &.list {
    margin-left: 5rem;
    ${Screen.pad`
    margin-left: 5rem
    `}
    ${Screen.mediumpc`
    margin-left: 5rem
    `}
  }
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }
  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 10px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  &.active {
    color: #c81e19;
  }

  ${Screen.mediumPhone`
  padding-right: 5px!important;
  font-size: 13px;
  `}
  ${Screen.phone`
  padding-right: 5px!important;
  `}
  ${Screen.smallPhone`
  padding-right: 5px!important;
  `}
    ${Screen.galaxyFold`
    font-size: 12px
  `}
`;
const MembersContainer = styled.ul`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  ${Screen.smallpc`
  width: 70%!important;
  margin: 1rem auto;
  `}
  ${Screen.mediumPhone`
  width: 80%!important;
  margin: 1rem auto;
  `}
  ${Screen.phone`
  width: 80%!important;
  margin: 1rem auto;
  `}
  ${Screen.smallPhone`
  width: 90%!important;
  margin: 1rem auto;
  `}
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
margin-top: 3rem;
`;

const ListBox = styled.div`
width: 100%;
background-color: #FAF9F9;
padding: 1rem 0;
margin-top: 2rem;
`

export default Committee;
