import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, Member4 } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Geoff = () => {
  const [mobile, setMobile] = useState(false);

  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
              <Header>Geoff O. Amaghereonu</Header>
              <Title>LEGAL ADVISOR/COMPANY SECRETARY</Title>
              </div>
              <Image src={Member4} alt='geoff' />
              <Paragraph>
                <p>
                A quintessential corporate attorney with a passion for
                excellence, Geoffrey was admitted to the Nigeria Bar in 1987. He
                has had a prodigious experience in contentious advocacy and
                corporate law practice. He was the Deputy Head of Chambers in an
                upscale law firm on Broad Street, Lagos for many years. He has
                headed the company and legal advisory secretariat of Abbey
                Mortgage Bank Plc, the premier largest non-aligned mortgage bank
                in Nigeria for 23 years. He was actively involved in the
                transformational relaunch of the company which has firmly
                established it as a top brand and market leader in Nigeria. In
                the course of his job of overseeing all the company’s legal,
                regulatory and compliance architecture, he has added immense
                value to the organization. His mastery of complex legal matters
                assisted in the crystallization of direct foreign investment in
                Abbey by Africinvest of Tunisia, Shelter Afrique of Kenya and
                FMO of the Netherlands. Geoffrey served as a member of the
                Committee of the Mortgage Banking Association of Nigeria set up
                in 1996 to make recommendations to the Federal Mortgage Bank of
                Nigeria for the improvement of the operations of Primary
                Mortgage Banks and the overhauling of the National Housing Fund
                Scheme.
              </p>
              </Paragraph>
              </FlexBox>
            ): (
          <FlexBox>
            <Image src={Member4} alt='geoff' />
            <Content>
              <Header>Geoff O. Amaghereonu</Header>
              <Title>LEGAL ADVISOR/COMPANY SECRETARY</Title>
              <p>
                A quintessential corporate attorney with a passion for
                excellence, Geoffrey was admitted to the Nigeria Bar in 1987. He
                has had a prodigious experience in contentious advocacy and
                corporate law practice. He was the Deputy Head of Chambers in an
                upscale law firm on Broad Street, Lagos for many years. He has
                headed the company and legal advisory secretariat of Abbey
                Mortgage Bank Plc, the premier largest non-aligned mortgage bank
                in Nigeria for 23 years. He was actively involved in the
                transformational relaunch of the company which has firmly
                established it as a top brand and market leader in Nigeria. In
                the course of his job of overseeing all the company’s legal,
                regulatory and compliance architecture, he has added immense
                value to the organization. His mastery of complex legal matters
                assisted in the crystallization of direct foreign investment in
                Abbey by Africinvest of Tunisia, Shelter Afrique of Kenya and
                FMO of the Netherlands. Geoffrey served as a member of the
                Committee of the Mortgage Banking Association of Nigeria set up
                in 1996 to make recommendations to the Federal Mortgage Bank of
                Nigeria for the improvement of the operations of Primary
                Mortgage Banks and the overhauling of the National Housing Fund
                Scheme.
              </p>
            </Content>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
  ${Screen.mediumpc`
  min-height: 20vh!important
  `}
  ${Screen.smallpc`
  min-height: 100vh
  `}
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 7rem
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 5rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
  height: 450px;
  /* height: 400px; */
  ${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 60%;
  margin-left: 3rem;
  padding-top: 1.5rem;
  

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
    ${Screen.mediumpc`
    font-size: 14px
    `}
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
   ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Geoff;
