import AbbeySave from './abbeySave';
import AbbeyPlus from './abbeyPlus';
import AbbeyZoomers from './abbeyZoomers';
import BloomTeens from './bloomTeens';
import FixedDeposit from './fixedDeposit';
import BloomKidz from './bloomKidz';
import CurrentAccount from './currentAccount';
import SavingsAccount from './savingsAccount';
import AbbeyBiz from './abbeyBiz';
import BizExtra from './bizExtra';
import MortgageForm from './mortgageForm';
import ConstructionForm from './constructionForm';
import Mortgage from './mortgage';
import Equity from './equity';
import Refinancing from './refinancing';
import Estate from './estate';
import AbbeyCards from './abbeyCards';
import QuickDial from './dial';
import AbbeyMobile from './abbeyMobile';
import Governance from './governance';
import Board from './board';
import Leadership from './leadership';
import GovernanceStructure from './governanceStructure.js';
import BoardCommitte from './committee';
import Management from './management';
import Responsibility from './responsibility';
import Wire from './wire'

const ports = {
  AbbeySave,
  AbbeyPlus,
  AbbeyZoomers,
  BloomTeens,
  FixedDeposit,
  BloomKidz,
  CurrentAccount,
  SavingsAccount,
  AbbeyBiz,
  BizExtra,
  MortgageForm,
  ConstructionForm,
  Mortgage,
  Equity,
  Refinancing,
  Estate,
  AbbeyCards,
  QuickDial,
  AbbeyMobile,
  Governance,
  Board,
  Leadership,
  GovernanceStructure,
  BoardCommitte,
  Management,
  Responsibility,
  Wire
};

export default ports;
