import { 
    ABBEY_LINKS_REQUEST, 
    ABBEY_LINKS_SUCCESS, 
    ABBEY_LINKS_FAILURE,
    HELP_LINKS_REQUEST,
    HELP_LINKS_SUCCESS,
    HELP_LINKS_FAILURE,
    OTHER_LINKS_REQUEST,
    OTHER_LINKS_SUCCESS,
    OTHER_LINKS_FAILURE
} from "../constants"



export const abbeyLinksReducer = (state, action) => {
    switch(action.type){
        case ABBEY_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case ABBEY_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case ABBEY_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const helpLinksReducer = (state, action) => {
    switch(action.type){
        case HELP_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case HELP_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case HELP_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const otherLinksReducer = (state, action) => {
    switch(action.type){
        case OTHER_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case OTHER_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case OTHER_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}