import { 
    SLIDER_FAILURE, 
    SLIDER_REQUEST, 
    SLIDER_SUCCESS
} from "../constants"



export const slidersReducer = (state, action) => {
    switch(action.type){
        case SLIDER_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case SLIDER_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case SLIDER_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}
