import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { egmReducer, agmReducer } = Reducers
export const EgmContext = createContext()
export const AgmContext = createContext()
const EgmContextProvider = ({ children }) => {
    const initialState = {
        loading: false,
        data: '',
        error: ''  
    }
    const [state, dispatch] = useReducer(egmReducer, initialState)
  return (
    <EgmContext.Provider value={{ state, dispatch }}>
        {children}
    </EgmContext.Provider>
  )
}


const AgmContextProvider = ({ children }) => {
    const initialState = {
        loading: false,
        data: '',
        error: ''  
    }
    const [state, dispatch] = useReducer(agmReducer, initialState)
  return (
    <AgmContext.Provider value={{ state, dispatch }}>
        {children}
    </AgmContext.Provider>
  )
}


export { EgmContextProvider, AgmContextProvider }