import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavButton } from '../../../styles';

const StepFour = ({ next, prev, values }) => {
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);

  const validation = values.ways.length < 1;

  const handleCheck2 = () => {
    setChecked2(!checked2);
    localStorage.setItem('check2', JSON.stringify(!checked2));
  };
  const handleCheck3 = () => {
    setChecked3(!checked3);
    localStorage.setItem('check3', JSON.stringify(!checked3));
  };
  const handleCheck4 = () => {
    setChecked4(!checked4);
    localStorage.setItem('check4', JSON.stringify(!checked4));
  };
  const handleCheck5 = () => {
    setChecked5(!checked5);
    localStorage.setItem('check5', JSON.stringify(!checked5));
  };
  const handleChange = (e) => {
    if (values.ways.indexOf(e.target.value) === -1) {
      values.ways.push(e.target.value);
    } else {
      values.ways.splice(values.ways.indexOf(e.target.value), 1);
    }
  };

  useEffect(() => {
    const persist2 = JSON.parse(localStorage.getItem('check2'));
    const persist3 = JSON.parse(localStorage.getItem('check3'));
    const persist4 = JSON.parse(localStorage.getItem('check4'));
    const persist5 = JSON.parse(localStorage.getItem('check5'));
    setChecked2(persist2);
    setChecked3(persist3);
    setChecked4(persist4);
    setChecked5(persist5);
  }, []);
  return (
    <FormContainer>
      <FormHeader>Step 4 0f 5: Ways to Bank</FormHeader>
      <Form autoComplete='off'>
        <CheckContainer>
          {/* <Check>
            <input
              type={'checkbox'}
              name='mobileBanking'
              value={'mobileBanking'}
              onChange={handleChange}
              checked={checked1}
              onClick={handleCheck1}
            />
            <label>Mobile Banking</label>
          </Check> */}
          <Check>
            <input
              type={'checkbox'}
              name='debitCard'
              value={'debitCard'}
              onChange={handleChange}
              checked={checked2}
              onClick={handleCheck2}
            />
            <label>Debit Card</label>
          </Check>
          <Check>
            <input
              type={'checkbox'}
              name='emailStatement'
              value={'emailStatement'}
              onChange={handleChange}
              checked={checked3}
              onClick={handleCheck3}
            />
            <label>Email Statement</label>
          </Check>
          <Check>
            <input
              type={'checkbox'}
              name='emailAlert'
              value={'emailAlert'}
              onChange={handleChange}
              checked={checked4}
              onClick={handleCheck4}
            />
            <label>Email Alert</label>
          </Check>
          <Check>
            <input
              type={'checkbox'}
              name='smsAlert'
              value={'smsAlert'}
              onChange={handleChange}
              checked={checked5}
              onClick={handleCheck5}
            />
            <label>SMS Alert</label>
          </Check>
        </CheckContainer>
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Check = styled.div`
  margin-top: 1rem;
  margin-left: 10rem;
  input {
    transform: scale(2);
    cursor: pointer;
  }
  label {
    padding-left: 1rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepFour;
