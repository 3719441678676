import { createContext, useReducer } from 'react'
import Reducers from '../reducers'


const { slidersReducer } = Reducers
export const SliderContext = createContext()
export const MobileSliderContext = createContext()
const Slider = ({ children }) => {
    const initialState = {
        loading: false,
        data: [],
        error: ''  
    }
    const [state, dispatch] = useReducer(slidersReducer, initialState)
  return (
    <SliderContext.Provider value={{ state, dispatch }}>
        {children}
    </SliderContext.Provider>
  )
}

// const MobileSlider = ({ children }) => {
//   const initialState = {
//       loading: false,
//       data: [],
//       error: ''  
//   }
//   const [state, dispatch] = useReducer(mobileSlidersReducer, initialState)
// return (
//   <MobileSliderContext.Provider value={{ state, dispatch }}>
//       {children}
//   </MobileSliderContext.Provider>
// )
// }

export default Slider;