import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LoanProducts, RequestCard, WayShort, WaysMobile } from '../../assets/img';
import { HelpersComponent } from '../../components';
// import { Footer } from '../../components/footer';
import { Decorate, Screen } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const Notify = HelpersComponent.Notify;
const CardRequest = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [submit, setSubmit] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
  };
  const cancelAlert = () => {
    setSubmit(false);
  };
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav    */}
      {/* <Banner>
          <h1>
          Learn More about <br /> <span>Abbey Mortgages</span>
          </h1>
          </Banner> */}

      <Section className='hero'></Section>
      <Container>
        {/* Form Section */}
        <Section>
          <Paragraph>
          To complete your request, contact us at <br />
          <a href='mailto:vibranch@abbeymortgagebank.com'>vibranch@abbeymortgagebank.com</a>
          </Paragraph>
          {/* <FlexBox>
            <Image src={RequestCard} alt='learn' />
            <FormContainer autoComplete='off'>
              <FlexBox>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Bvn</label>
                  <input
                    style={{ width: '300px' }}
                    type={'text'}
                    name='firstName'
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Account Number</label>
                  <input
                    style={{ width: '300px' }}
                    type={'text'}
                    name='lastName'
                  />
                </div>
              </FlexBox>
              <div style={{ marginTop: '1rem' }}>
                <label>Account Name</label>
                <input style={{ width: '100%' }} type={'text'} name='email' />
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Card Type</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='mortgage'
                  >
                    <option></option>
                    <option value={'debit'}>Debit Card</option>
                    <option value={'reflect-yourself'}>
                      Reflect Yourself Card
                    </option>
                    <option value={'prepaid'}>Prepaid Cards</option>
                  </select>
                </Select>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <label>Card Type</label>
                <Select>
                  <select
                    style={{ width: '100%', height: '45px' }}
                    className='select-css'
                    name='mortgage'
                  >
                    <option></option>
                    <option value={'card-lost'}>Card Lost</option>
                    <option value={'new-card'}>New Card</option>
                    <option value={'other'}>other</option>
                  </select>
                </Select>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={onSubmit}>Request Card</Button>
              </div>
            </FormContainer>
          </FlexBox> */}
        </Section>
        {/* End of form section */}
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
      {submit && <Notify cancel={cancelAlert} />}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.galaxyFold`
  width: 95%;
  margin: 3rem auto!important;
  `}


`;

const Paragraph = styled.p`
  color: #9a9494;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 3rem;
  font-size: 1.1rem;
  margin-top: 3rem;

  a {
    color: #0867BE;
    padding-top: 1rem
  }

  ${Screen.smallpc`
  margin-top: 5rem!important;
  `}
  ${Screen.tablet`
  font-size: 1.1rem;
  `}
  ${Screen.mediumPhone`
  font-size: .9rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  `}
  ${Screen.galaxyFold`
  font-size: .7rem;
  `}
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Section = styled.section`
  width: 100%;
  /* min-height: 100vh; */
  &.hero {
    min-height: 50vh;
    background: url(${WayShort});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
  }
  /* margin-top: 5rem; */
`;
const Image = styled.img`
  margin-right: 5rem;
  height: auto;
`;
const FormContainer = styled.form`
  width: 100%;
  height: auto;
  background-color: white;

  h1 {
    font-size: 13px;
    font-weight: 800;
    font-family: 'Roboto Flex', sans-serif;
    align-self: left;
    color: #0040a4;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  label {
    font-size: 15px;
    font-weight: 400;
    /* align-self: center; */
    color: #002668;
    margin: 0;
    text-transform: uppercase;
  }

  input {
    /* width: 300px; */
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    /* color: #0a3d94; */
    color: #9a9494;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  textarea {
    margin-top: 10px;
    border: 1px solid #dbd9d9;
    padding: 10px 30px;
    border-radius: 5px;
    outline: none;
    background-color: #f5f5f5;
    color: #9a9494;
    resize: none;
    height: 150px;
  }
`;
const Select = styled.div`
  width: 100%;
  ${Decorate.select};

  .select-css {
    border-color: #dbd9d9;
    color: #9a9494;
  }

  .select-css option {
    &:hover {
      background-color: #002668 !important;
      cursor: pointer;
    }
  }
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;

  p {
    padding: 3px 0px 0px 10px;
    margin: 0;
  }
`;
const Button = styled.div`
  width: 15rem;
  height: 3rem;
  border-radius: 5px;
  margin-top: 2rem;
  background-color: #c81e19;
  font-weight: bold;
  text-align: center;
  color: #fff;
  padding: 1rem 0;
  cursor: pointer;
  box-shadow: 5px 3px 3px #ccc;
`;
const Text = styled.h1`
padding-top: 5rem;
text-align: center;
`

export default CardRequest;
