import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SaveBg, SaveMobile } from '../../assets/img';
import { Grey, Blue, OvalMobile } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { save } from '../../components/main/products';
import { NavButton, Screen } from '../../styles';
import { useNavigate } from 'react-router-dom';
// const Navigation = NavigationBar.Navigation;
const AbbeySave = () => {
  const [mobile, setMobile] = useState(false)

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);

  const navigate = useNavigate();
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'>
        <Container className='hero'>
          <Glass>
            <Content className='glass'>
              <h2>Abbey Save</h2>
              <p>
              Start saving today and open access to rewarding benefits for the future.
              </p>
            </Content>
            <OpenAccount onClick={() => navigate('/personal-form')}>
              Open an account
            </OpenAccount>
          </Glass>
        </Container>
      </Section>
      {/* End of Hero Section */}

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content className='description'>
                    <h2>Why abbey save?</h2>
                  </Content>
                </MobileHeader>
                <List>
                {save.map(({ img, item }) => (
                  <div style={{ display: 'flex', paddingBottom: '.5rem' }}>
                    <Image src={img} alt='save' className='check' />
                    <ListItem>{item}</ListItem>
                  </div>
                ))}
              </List>
              </FlexBox>
            ) : (
              <FlexBox>
            <ImageWrapper>
              <Image src={Grey} alt='grey' className='grey' />
              <Image src={Blue} alt='blue' className='blue' />
            </ImageWrapper>
            <Content className='description'>
              <h2>Why abbey save?</h2>
              <List>
                {save.map(({ img, item }) => (
                  <div style={{ display: 'flex', paddingBottom: '.5rem' }}>
                    <Image src={img} alt='save' className='check' />
                    <ListItem>{item}</ListItem>
                  </div>
                ))}
              </List>
            </Content>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  &.hero {
    margin: 0 auto;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.smallpc`
    padding-top: 0;
    `}
     ${Screen.tablet`
    padding-top: 0;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 7rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  `}
`;
const Glass = styled.div`
  width: 540px;
  height: 270px;
  background: rgba(217, 217, 217, 0.5);
  ${Screen.mediumpc`
  height: 250px;
  `}
  ${Screen.tablet`
  height: 230px;
  `}
  ${Screen.surfaceDuo`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.phone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
`;
const Content = styled.div`
  padding-left: 2rem;
  ${Screen.miniLargeScreen`
  padding-right: 1rem;
  `}
  ${Screen.surfaceDuo`
  padding: 0 1rem;
  text-align: center;
  `}
  &.glass {
    h2 {
      padding-top: 2rem;
      font-size: 45px;
      color: #002668;
      text-shadow: 3px 5px 3px #ccc;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.tablet`
      font-size: 30px;
      `}
      ${Screen.surfaceDuo`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      padding-top: 2rem;
      padding-left: 0!important;
      font-size: 24px;
      text-align: center;
      `}
    }

    p {
      color: #002668;
      padding-top: 1rem;
      text-align: left;
      font-size: 18px;
      text-shadow: 3px 5px 3px #ccc;
      ${Screen.mediumpc`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.smallpc`
      color: #002668;
      text-shadow: none;
      font-size: 16px;
      `}
      ${Screen.tablet`
      color: #002668;
      text-shadow: none;
      `}
      ${Screen.surfaceDuo`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.mediumPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668;
      padding: .5rem;
      `}
      ${Screen.phone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      text-align: center;
      text-shadow: none;
      color: #002668 ;
      padding: .5rem;
      `}
    }
  }

  &.description {
    h2 {
      font-size: 2.5rem;
      font-style: italic;
      color: #c81e19;
      text-transform: uppercase;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 10rem;
      top: 8rem
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 8rem;
      top: 6rem
      `}
       ${Screen.mediumPhone`
      font-size: 22px;
      position: absolute;
      left: 5rem;
      top: 5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 5rem;
      top: 5rem
      `}
      ${Screen.smallPhone`
      font-size: 18px;
      position: absolute;
      left: 5rem;
      top: 5rem
      `}
    }

    p {
      /* font-size: 1.1rem; */
      padding-top: 1rem;
    }
  }
`;
const Button = styled(NavButton)`
  width: 7rem;
  height: 2rem;
  border-radius: 5px;
  margin: 1.5rem 0;
  margin-right: ${({ mr }) => mr};
  background-color: #002668;
`;
const OpenAccount = styled(Button)`
  width: 10rem;
  height: 2.5rem;
  border-radius: 5px;
  margin: 2rem 0 0;
  margin-left: 2rem;
  background-color: #c81e19;
  box-shadow: 1px 2px 5px #666;
  font-weight: bold;
  ${Screen.mediumpc`
  margin: 2rem 0 0 2rem;
  `}
  ${Screen.tablet`
  margin: 2rem 0 0 2rem;
  `}
  ${Screen.surfaceDuo`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.mediumPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.phone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}
  ${Screen.smallPhone`
  width: max-content;
  height: 2rem;
  padding: .5rem;
  margin: .5rem 0;
  `}

`;
const Image = styled.img`
  &.saving {
    width: 32rem;
    height: 350px;
    /* margin-right: -10rem; */
    margin-bottom: 3rem;
  }
  &.check {
    width: 25px;
    height: 25px;
  }
  &.grey {
    width: 15rem;
    height: 15rem;
    position: absolute;
    z-index: 1;
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 8rem;
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 100vh;
  &.hero {
    background-image: url(${SaveBg});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 12rem;
    ${Screen.tablet`
    min-height: 50vh!important;
    `}
    ${Screen.mediumpc`
    min-height: 50vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.description {
    min-height: 30vh;
  }
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
  /* justify-content: space-between; */
`;
const List = styled.ul`
  list-style-type: none;
  margin-top: 1.5rem;
   ${Screen.mediumPhone`
  margin-top: 1rem;
  margin-left: 1rem;
  `}
  ${Screen.phone`
  margin-top: 1rem;
  margin-left: 1rem;
  `}
  ${Screen.smallPhone`
  margin-top: 1rem;
  margin-left: 1rem;
  `}
`;
const ListItem = styled.li`
  padding-left: 0.5rem;
  padding-top: 0.3rem;
   ${Screen.mediumPhone`
  font-size: .9rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  `}
`;
const ImageWrapper = styled.div`
  width: 30%;
  position: relative;
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
`;
export default AbbeySave;
