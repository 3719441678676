import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, OvalMobile } from '../../assets/img';
import { Grey2, Blue2 } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { breadcrumbMenu } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Governance = () => {
  const [mobile, setMobile] = useState(false)

  const location = useLocation();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of Hero Section */}
      {
        mobile ? (
          <BreadCrumb>
        {breadcrumbMenu.slice(0, 2).map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        ): (
          <BreadCrumb>
        {breadcrumbMenu.map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        )
      }

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content>
                    <h2>Governance Structure</h2>
                  </Content>
                </MobileHeader>
                <Content>
                  <p>
                  The governance structure of the Bank is driven principally by the Board of
                Directors, whose members are equipped with the requisite academic
                qualifications and relevant industry experience and tools
                to discharge their roles in the Bank.
                The governance policies adopted by the Board are designed
                to ensure
                long-term shareholders’ value. It is the primary
                responsibility of the Board
                to deliver sustainable shareholders' wealth through its
                oversight functions.
                  </p>
                </Content>
              </FlexBox>
            ) : (
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey2} alt='grey' className='grey' />
              <Image src={Blue2} alt='blue' className='blue' />
            </ImageWrapper>
            <Content>
              <h1>Governance Structure</h1>
              <p>
                The governance structure of the Bank is driven principally by
                the Board of
                Directors, whose members are equipped with the requisite
                academic
                qualifications and relevant industry experience and tools
                to discharge their roles in the Bank.
                The governance policies adopted by the Board are designed
                to ensure
                long-term shareholders’ value. It is the primary
                responsibility of the Board
                to deliver sustainable shareholders' wealth through its
                oversight functions.
              </p>
            </Content>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  padding-top: 0;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 0 auto;
  padding-top: 3rem
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 3rem
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.galaxyFold`
  width: 90%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
`;

const Content = styled.div`
margin-left: 20rem;

${Screen.miniLargeScreen`
margin-left: 15rem;
`}

${Screen.smallpc`
margin-left: 0
`}
  h1 {
    color: #002668;
    padding-top: 5rem;
    ${Screen.miniLargeScreen`
    padding-top: 0
    `}
  }

  h2 {
    ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 10rem;
      top: 8rem;
      color: #002668
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 8rem;
      top: 6rem
      `}
       ${Screen.mediumPhone`
      font-size: 20px;
      position: absolute;
      left: 4rem;
      top: 5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 3rem;
      top: 5rem
      `}
      ${Screen.smallPhone`
      font-size: 18px;
      position: absolute;
      left: 3rem;
      top: 5rem;
      `} 
       ${Screen.galaxyFold`
      font-size: 16px;
      position: absolute;
      left: 3rem;
      top: 5rem;
      `} 
  }

  p {
    padding-top: 2rem;
    font-size: 1.1rem;
    ${Screen.mediumPhone`
    font-size: 16px;
    `}
    ${Screen.phone`
    font-size: 15px;
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }


`;
const Image = styled.img`
  &.saving {
    margin-bottom: 3rem;
  }
  &.check {
    width: 25px;
    height: 25px;
  }
  &.grey {
    width: 250px;
    position: absolute;
    z-index: 1;
    ${Screen.miniLargeScreen`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 250px;
    position: absolute;
    top: 8rem;
    ${Screen.miniLargeScreen`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.pad`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 70vh;
  &.hero {
    min-height: 50vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.description {
    padding-top: 2rem;

    ${Screen.miniLargeScreen`
    padding-top: 2rem;
    min-height: 60vh
    `}
    ${Screen.mediumpc`
    padding-top: 5rem;
    min-height: 30vh
    `}

    ${Screen.smallpc`
    min-height: 50vh;
    padding-top: 0
    `}
    ${Screen.mediumPhone`
    min-height: 50vh
    `}
    ${Screen.phone`
    min-height: 70vh
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;
  ${Screen.pad`
  margin: 0;
  `}
  ${Screen.smallpc`
  flex-direction: column;
  margin: 2rem 0;
  `}
`;

const ImageWrapper = styled.div`
  width: 30%;
  position: relative;

  /* ${Screen.mediumpc`
  width: 50%
  `} */
  /* margin-right: 20rem */

  ${Screen.smallpc`
  display: none;
  `}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }

  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 10px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  &.active {
    color: #c81e19;
  }
  ${Screen.mediumPhone`
  padding-right: 5px!important;
  font-size: 13px;
  `}
  ${Screen.phone`
  padding-right: 5px!important;
  `}
  ${Screen.smallPhone`
  padding-right: 5px!important;
  `}
    ${Screen.galaxyFold`
    font-size: 12px
  `}
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
`;

export default Governance;
