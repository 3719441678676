export const MAIN_LINKS_REQUEST = 'MAIN_LINKS_REQUEST'
export const MAIN_LINKS_SUCCESS = 'MAIN_LINKS_SUCCESS'
export const MAIN_LINKS_FAILURE = 'MAIN_LINKS_FAILURE'

export const SECTION_LINKS_REQUEST = 'SECTION_LINKS_REQUEST'
export const SECTION_LINKS_SUCCESS = 'SECTION_LINKS_SUCCESS'
export const SECTION_LINKS_FAILURE = 'SECTION_LINKS_FAILURE'

export const MOBILE_NAV_REQUEST = 'MOBILE_NAV_REQUEST'
export const MOBILE_NAV_SUCCESS = 'MOBILE_NAV_SUCCESS'
export const MOBILE_NAV_FAILURE = 'MOBILE_NAV_FAILURE'