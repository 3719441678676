import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AboutLeadership, EbenezerImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { Link } from 'react-router-dom';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'


// const Navigation = NavigationBar.Navigation;
const App = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  useEffect(() => {
    //Scroll to top on page load
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                <Header>Ebenezer Afolabi</Header>
                <Title>Head, Internal Audit</Title>
                </div>
                <Image src={EbenezerImg} alt='profile picture' />
                <Paragraph>
                <p>
                  Ebenezer Afolabi is a detail-oriented and highly skilled Auditor with 17 years of experience in Internal Auditing, 
                  boasting a profound understanding of audit methodologies. He holds a Bachelor's degree in Accounting from the 
                  University of Ado-Ekiti and an MBA with a specialization in Finance from Ladoke Akintola University, Ogbomoso. 
                  Ebenezer is a distinguished member of professional bodies such as ISACA and IIA, underscoring his commitment to excellence in his field.
                </p>
              <p>
                Ebenezer embarked on his professional journey at United Bank for Africa (UBA) in 2006, where he served in the Audit and Control Group (ACG) until 2008. 
                Subsequently, he transitioned to Ecobank Nigeria Ltd, where he spent 15 years sharpening his skills in the internal audit department. 
              </p>
              <p>
                In 2023, he brought his extensive expertise to Abbey Mortgage Bank as the Head of Internal Audit.
              </p>
              <p>
                With a precise approach to auditing and a deep understanding of financial systems, Ebenezer plays a pivotal role in ensuring compliance, identifying risks, 
                and enhancing operational efficiency within the organization. His wealth of experience and dedication to maintaining the highest standards of integrity make him a valuable contributor to Abbey Mortgage Bank, 
                where he continues to drive excellence in internal auditing practices.
              </p>
                </Paragraph>
              </FlexBox>
            ): (
              <>
          <FlexBox>
            <Image src={EbenezerImg} alt='profile picture' />
            <Content>
            <Header>Ebenezer Afolabi</Header>
            <Title>Head, Internal Audit</Title>
                <p>
                  Ebenezer Afolabi is a detail-oriented and highly skilled Auditor with 17 years of experience in Internal Auditing, 
                  boasting a profound understanding of audit methodologies. He holds a Bachelor's degree in Accounting from the 
                  University of Ado-Ekiti and an MBA with a specialization in Finance from Ladoke Akintola University, Ogbomoso. 
                  Ebenezer is a distinguished member of professional bodies such as ISACA and IIA, underscoring his commitment to excellence in his field.
                </p>
                <p>
                Ebenezer embarked on his professional journey at United Bank for Africa (UBA) in 2006, where he served in the Audit and Control Group (ACG) until 2008. 
                Subsequently, he transitioned to Ecobank Nigeria Ltd, where he spent 15 years sharpening his skills in the internal audit department. 
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
              <p>
                In 2023, he brought his extensive expertise to Abbey Mortgage Bank as the Head of Internal Audit.
              </p>
              <p>
                With a precise approach to auditing and a deep understanding of financial systems, Ebenezer plays a pivotal role in ensuring compliance, identifying risks, 
                and enhancing operational efficiency within the organization. His wealth of experience and dedication to maintaining the highest standards of integrity make him a valuable contributor to Abbey Mortgage Bank, 
                where he continues to drive excellence in internal auditing practices.
              </p>
          </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;

${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }

  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default App;
