import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { googleApiReducer } = Reducers
export const GoogleContext = createContext()
const GoogleApi = ({ children }) => {
    const initialState = {
        loading: false,
        data: '',
        error: ''  
    }
    const [state, dispatch] = useReducer(googleApiReducer, initialState)
  return (
    <GoogleContext.Provider value={{ state, dispatch }}>
        {children}
    </GoogleContext.Provider>
  )
}

export default GoogleApi