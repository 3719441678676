import { useEffect } from 'react';
import styled from 'styled-components';
import { AboutGroup, CorporateGovernance, Leadership } from '../../assets/img';
import { Blue2, Grey2 } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { HashLink } from 'react-router-hash-link';

// const Navigation = NavigationBar.Navigation;
const Governance = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}

      {/* Governance */}
      <Section className='governance'>
        <Container className='governance'>
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey2} alt='grey' className='grey' />
              <Image src={Blue2} alt='blue' className='blue' />
            </ImageWrapper>
            <Content>
              <Header className='introduction'>Introduction</Header>
              <p>
                Abbey Mortgage Bank Plc recognizes the fact that effective
                governance system is
                <br /> essential to retaining public trust and confidence in the
                way and manner we do our
                <br /> business. Our governance policies are structured to
                ensure maximum compliance with
                <br /> the provisions of the various laws and codes on the
                subject.
              </p>
              <p>
                These include the Nigerian Code of Corporate Governance 2018,
                the Central Bank of
                <br /> Nigeria Code of Corporate Governance of May 2014, the SEC
                Code of Corporate Governance
                <br /> dated 1 April, 2011, the Post Listing requirements of
                Nigerian Exchange Group (NGX) together
                <br /> with the amendments thereto, our internal Code of
                Corporate Governance and international
                <br /> best practices. .
              </p>
              <p>
                Abbey's Code of Corporate Governance is targeted at achieving
                the highest standards
                <br /> of transparency, accountability and good corporate
                behaviour in line with international
                <br /> best practices. The governance structures and processes
                are primed for the satisfaction
                <br />
                of the various stakeholders including employees, shareholders,
                creditors, host communities <br /> and regulatory authorities.
                Abbey's corporate ethos include accountability, transparency,
                <br /> integrity, fairness, discipline, communication, social
                and environmental responsibility, service
                <br /> excellence, responsible lending and stakeholder-rights'
                recognition. Directors and employees <br /> are expected to act
                honestly, in good faith and in the best interest of the Bank in
                all transactions.
              </p>
              <Header className='governance'>Governance Structure</Header>
              <p>
                The governance structure of the Bank is driven principally by
                the Board of Directors, whose members
                <br /> are equipped with the requisite academic qualifications
                and relevant industry experience and tools to <br />
                discharge their roles in the Bank. The governance policies
                adopted by the Board are designed to ensure
                <br /> long-term shareholders’ value. It is the primary
                responsibility of the Board to deliver sustainable shareholders'
                wealth through its oversight functions.
              </p>
            </Content>
          </FlexBox>
        </Container>
      </Section>
      {/* End of Governance */}

      {/* Management Team */}
      <Section className='team'>
        <Header className='team'>Management Team</Header>
        <Container className='team'>
          <TeamContainer>
            <Team>
              <Image src={Leadership} alt='team' className='team' />
              <CardLink to={'/about/board#board'}>Board of Directors</CardLink>
            </Team>
            <Team>
              <Image src={Leadership} alt='team' className='team' />
              <CardLink to={'/about/board#management'}>
                Management Team
              </CardLink>
            </Team>
          </TeamContainer>
        </Container>
      </Section>
      {/* End of Management Team */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${CorporateGovernance});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    position: relative;
  }
  &.governance {
    min-height: 100vh;
  }
  &.team {
    min-height: 70vh;
    background-image: url(${AboutGroup});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 5rem;
  }
`;
const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  &.team {
    width: 75%;
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
`;
const ImageWrapper = styled.div`
  width: 40%;
  position: relative;
  /* padding-top: 1rem; */
`;
const Image = styled.img`
  &.grey {
    width: 250px;
    position: absolute;
    top: 4rem;
    z-index: 1;
  }
  &.blue {
    width: 250px;
    position: absolute;
    top: 12rem;
  }
  &.team {
    width: 35rem;
  }
`;
const Content = styled.div`
  p {
    padding-top: 2rem;
  }
`;
const Header = styled.h1`
  color: #002668;
  font-size: 2rem;
  /* text-align: center; */
  &.governance {
    padding-top: 3rem;
  }
  &.team {
    padding-top: 5rem;
    padding-left: 5rem;
  }
`;
const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 12rem;
`;
const Team = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
`;
const CardLink = styled(HashLink)`
  color: #002668;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 2rem;
  text-decoration: none;
`;
export default Governance;
