import Mobolaji from './mobolaji';
import Dipo from './dipo';
import Geoff from './geoff';
import Lolita from './lolita';
import Obinna from './obinna';
import Oni from './oni';
import Chika from './chika';
import Soewu from './soewu';
import Umego from './umego';
import Jewel from './jewel';
import Christabel from './christabel';
import Adenike from "./adenike"
// import Jewel from './jewel'
// import Chika from './chika';
import Obasa from './obasa';
import Lasisi from './lasisi';
import Olusola from './shola';
import Toyin from './toyin';
import Tosin from './tosin';
import Felix from './felix';
import Bukola from './bukola';
import Funmi from './funmi';
import Tomi from './tomi';
import Idongesit from './idongesit';
import Ebenezer from './ebenezer';


const components = {
  Mobolaji,
  Dipo,
  Geoff,
  Lolita,
  Obinna,
  Oni,
  Chika,
  Soewu,
  Umego,
  Adenike,
  Christabel,
  Jewel,
  Obasa,
  Lasisi,
  Olusola,
  Toyin,
  Tosin,
  Felix,
  Bukola,
  Funmi,
  Tomi,
  Idongesit,
  Ebenezer
};

export default components;
