import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, OniImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Oni = () => {
  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                <Header>High Chief Samuel Oni</Header>
                <Title>NON-EXECUTIVE DIRECTOR</Title>
                </div>
                <Image src={OniImg} alt='oni' />
                <Paragraph>
                <p>
              High Chief Samuel Oni is a Fellow of both the Association 
              of Certified and Chartered Accountants London [1980] and 
              the Institute of Chartered Accountants of Nigeria. He holds 
              an MBA degree from the University of Ilorin. [1990]
              </p>
              <p>
                He started his professional career as Principal Accountant 
                at Defense Industries of Nigeria in 1979. In 1982, he joined 
                Kwara Breweries as Chief Accountant, from where he joined a 
                World Bank Project, Kwara State Agricultural Program as Financial 
                Controller. He transferred his services to the Central Bank of 
                Nigeria in 1993 as Assistant Director. Upon the completion of 
                several on-the-job training sessions in and outside Nigeria, he 
                was given the role of Team Leader for on-site examination of both 
                commercial and merchant banks.
              </p>
              <p>
            He successfully led many teams to various banks for routine, maiden 
            and special examinations. His reports received several commendations 
            from the management of CBN. In recognition of his hard work and diligence, 
            he was promoted to Deputy Director in 1998. In April 2004, he was appointed 
            Director of Bank Examination, a position he held till 2009 when he was appointed 
            Director of Banking Supervision. He was also Chairman of the Committee set up by 
            the CBN to midwife the establishment of Asset Management <br />
            Corporation of Nigeria (AMCON). He retired from CBN in 2011. 
            Since then, he has been in private business and consultancy services. 
            He also sits on the Board of United Bank for Africa Plc.<br />
            His core competences include Risk Management, Audit, Regulatory Compliance and 
            Corporate Governance.
            </p>
                </Paragraph>
              </FlexBox>
            ): (
              <>   
          <FlexBox>
          <Image src={OniImg} alt='oni' />
            <Content>
              <Header>High Chief Samuel Oni</Header>
              <Title>NON-EXECUTIVE DIRECTOR</Title>
              <p style={{ paddingTop: '2rem'}}>
              High Chief Samuel Oni is a Fellow of both the Association 
              of Certified and Chartered Accountants London [1980] and 
              the Institute of Chartered Accountants of Nigeria. He holds 
              an MBA degree from the University of Ilorin. [1990]
              </p>
              <p>
                He started his professional career as Principal Accountant 
                at Defense Industries of Nigeria in 1979. In 1982, he joined 
                Kwara Breweries as Chief Accountant, from where he joined a 
                World Bank Project, Kwara State Agricultural Program as Financial 
                Controller. He transferred his services to the Central Bank of 
                Nigeria in 1993 as Assistant Director. Upon the completion of 
                several on-the-job training sessions in and outside Nigeria, he 
                was given the role of Team Leader for on-site examination of both 
                commercial and merchant banks.
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
            <p>
            He successfully led many teams to various banks for routine, maiden 
            and special examinations. His reports received several commendations 
            from the management of CBN. In recognition of his hard work and diligence, 
            he was promoted to Deputy Director in 1998. In April 2004, he was appointed 
            Director of Bank Examination, a position he held till 2009 when he was appointed 
            Director of Banking Supervision. He was also Chairman of the Committee set up by 
            the CBN to midwife the establishment of Asset Management <br />
            Corporation of Nigeria (AMCON). He retired from CBN in 2011. 
            Since then, he has been in private business and consultancy services. 
            He also sits on the Board of United Bank for Africa Plc.<br />
            His core competences include Risk Management, Audit, Regulatory Compliance and 
            Corporate Governance.
            </p>
          </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
  ${Screen.mediumpc`
  min-height: 20vh;
  `}
  ${Screen.smallpc`
  min-height: 50vh;
  `}
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin-top: 7rem;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem;
  margin-top: 5rem;
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 400px;
${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;
  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Oni;
