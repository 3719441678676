import { slidersReducer } from './slider'
import { galleryReducer } from './gallery' 
import { googleApiReducer } from './google';
import { 
    mainLinksReducer, 
    sectionLinksReducer,
    mobileNavReducer
} from './navbar'
import { 
    abbeyLinksReducer, 
    helpLinksReducer,
    otherLinksReducer
 } from './footer';
 import { 
    personalHeroReducer,
    businessHeroReducer,
    waysHeroReducer,
    loanHeroReducer,
    sustainHeroReducer,
    investorHeroReducer,
    aboutHeroReducer,
    contactHeroReducer,
    wireHeroReducer,
    wireMobileReducer

 } from './hero'
 import { 
    blogLinksReducer, 
    blogsReducer, 
    pressLandingReducer, 
    pressReducer,
    footprintReducer 
} from './blog'
import { 
    financialReducer, 
    noticeReducer, 
    formsReducer,
    disclosureReducer,
    cibReducer
} from './doc';
import { egmReducer, agmReducer } from './meeting';

const reducers = {
    slidersReducer,
    galleryReducer,
    googleApiReducer,
    abbeyLinksReducer,
    helpLinksReducer,
    otherLinksReducer,
    mainLinksReducer,
    sectionLinksReducer,
    personalHeroReducer,
    businessHeroReducer,
    waysHeroReducer,
    loanHeroReducer,
    sustainHeroReducer,
    investorHeroReducer,
    aboutHeroReducer,
    contactHeroReducer,
    blogLinksReducer,
    blogsReducer,
    pressLandingReducer,
    pressReducer,
    mobileNavReducer,
    financialReducer,
    noticeReducer,
    formsReducer,
    disclosureReducer,
    cibReducer,
    wireHeroReducer,
    wireMobileReducer,
    agmReducer,
    egmReducer,
    footprintReducer
}

export default reducers;