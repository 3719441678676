import { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { NavigationBar } from '../../components';
import { WhistleBlower, WhistleMobile } from '../../assets/img';
import { Footer } from '../../components/footer';
import { Blue2, Grey2 } from '../../assets/svg';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Whistle = () => {
  const [mobile, setMobile] = useState(false)
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of Nav */}

      {/* Hero Section */}
      <Section className='hero'>
        <Container>
          <Header className='hero'>Whistleblower</Header>
        </Container>
      </Section>
      {/* End of Hero Section */}

      <Section>
      <Container>
        {
          mobile ? (
          <Content>
            <p>
              In line with global business practice, kindly report suspected
              improper activities
              or misconduct by our Staff and Management. You do not
              necessarily need to
              disclose your identity. If you have experienced any
              improper activity such as
              unethical practices, gross misconduct, incompetence, or
              inefficiency, please
              report by clicking on the link below to enable us to investigate
              and address your
              concerns.
            </p>
            <p>
              This whistleblowing report does not cover private grievances,
              which may include
              individual employment matters such as performance evaluation and
              discriminatory work assignment.
              Your confidentiality wishes shall be fully respected, all
              information provided shall
              be treated with due diligence.
            </p>
            <p>
              You can also blow the whistle by sending an e-mail to <br />
              <TextLink href='mailto:whistleblower@abbeymortgagebank.com'>
                whistleblower@abbeymortgagebank.com
              </TextLink>{' '}
              <br />
              Or Call: 07083985266
            </p>
          </Content>
          ): (
        <FlexBox>
          <ImageWrapper>
            <Image src={Grey2} alt='grey' className='grey' />
            <Image src={Blue2} alt='blue' className='blue' />
          </ImageWrapper>
          <Content>
            <p>
              In line with global business practice, kindly report suspected
              improper activities
              or misconduct by our Staff and Management. You do not
              necessarily need to
              disclose your identity. If you have experienced any
              improper activity such as
              unethical practices, gross misconduct, incompetence, or
              inefficiency, please
              report by clicking on the link below to enable us to investigate
              and address your
              concerns.
            </p>
            <p>
              This whistleblowing report does not cover private grievances,
              which may include
              individual employment matters such as performance evaluation and
              discriminatory work assignment.
              Your confidentiality wishes shall be fully respected, all
              information provided shall
              e treated with due diligence.
            </p>
            <p>
              You can also blow the whistle by sending an e-mail to{' '}
              <TextLink href='mailto:whistleblower@abbeymortgagebank.com'>
                whistleblower@abbeymortgagebank.com
              </TextLink>{' '}
              Or Call: 07083985266
            </p>
          </Content>
        </FlexBox>
          )
        }
      </Container>
      </Section>
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Section = styled.div`
  width: 100%;
  min-height: 50vh;
  ${Screen.mediumpc`
  min-height: 40vh;
  `}
  ${Screen.smallpc`
  min-height: 40vh;
  `}
  &.hero {
    min-height: 50vh;
    background-image: url(${WhistleBlower});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 20vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  ${Screen.galaxyFold`
  width: 90%;
  `}
`;
const Header = styled.h1`
  text-align: right;
  color: #fff;
  padding-top: 10rem;
  text-transform: uppercase;
  font-size: 2rem;
  text-transform: 10px 5px 3px #fff;
  ${Screen.smallpc`
  text-align: center;
  padding-top: 15rem
  `}
  ${Screen.tablet`
  text-align: center;
  padding-top: 12rem
  `}
  ${Screen.surfaceDuo`
  text-align: center;
  padding-top: 8rem
  `}
  ${Screen.mediumPhone`
  font-size: 24px;
  text-align: center;
  padding-top: 8rem;
  `}
  ${Screen.phone`
  font-size: 24px;
  text-align: center;
  padding-top: 8rem;
  `}
  ${Screen.smallPhone`
  font-size: 24px;
  text-align: center;
  padding-top: 8rem;
  `}
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7rem;
`;
const ImageWrapper = styled.div`
  width: 40%;
  position: relative;
  margin-right: 20rem;
  ${Screen.miniLargeScreen`
  margin-right: 20rem;
  `}
  ${Screen.pad`
  margin-right: 20rem;
  `}
  ${Screen.mediumpc`
  margin-right: 15rem;
  `}
`;
const Image = styled.img`
  &.grey {
    width: 250px;
    position: absolute;
    z-index: 1;
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 250px;
    position: absolute;
    top: 8rem;
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
`;
const Content = styled.div`
${Screen.smallpc`
padding-top: 5rem
`}
${Screen.mediumPhone`
padding-top: 5rem
`}
${Screen.phone`
padding-top: 5rem
`}
${Screen.smallPhone`
padding-top: 5rem
`}
  p {
    padding-bottom: 2rem;
    font-size: 1.1rem;
    ${Screen.mediumPhone`
    font-size: 14px
    `}
    ${Screen.phone`
    font-size: 14px
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }
`;
const TextLink = styled.a`
  color: #48a7ff;
  ${Screen.mediumPhone`
  word-break: break-all
  `}
  ${Screen.phone`
  word-break: break-all
  `}
  ${Screen.smallPhone`
  word-break: break-all
  `}
`;

export default Whistle;
