import { createGlobalStyle } from 'styled-components';
import * as Fonts from './fonts';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratBlackTTF}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratBlackItalicTTF}) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratExtraBoldTTF}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratExtraBoldItalicTTF}) format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratBoldItalicTTF}) format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratSemiBoldItalicTTF}) format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratMediumItalicTTF}) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratItalicTTF}) format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratLightItalicTTF}) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratExtraLightTTF}) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratExtraLightItalicTTF}) format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratThinTTF}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${Fonts.MontserratThinItalicTTF}) format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: auto;
  }
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Montserrat';
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
    /* background-color: #FAFCFF; */
}
`;

export default GlobalStyle;
