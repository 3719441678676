import React from 'react';
import styled from 'styled-components';
import { Screen } from '../../styles';
import { Loader } from '../../assets/svg';

const LoaderContainer = styled.div`
  z-index: 99;
  margin-top: 40%;
  margin-left: 35%;
  ${Screen.largePhone`
    top: 40%;
    left: 44%;
  `};
`;
const LoaderWrapper = styled.div`
  height: 50%;
  width: 50%;
`;
const componentLoader = ({ color }) => (
  <>
    <LoaderContainer>
      <LoaderWrapper>
        <Loader color={color} />
      </LoaderWrapper>
    </LoaderContainer>
  </>
);

export default componentLoader;
