export const ABBEY_LINKS_REQUEST = 'ABBEY_LINKS_REQUEST'
export const ABBEY_LINKS_SUCCESS = 'ABBEY_LINKS_SUCCESS'
export const ABBEY_LINKS_FAILURE = 'ABBEY_LINKS_FAILURE'

export const HELP_LINKS_REQUEST = 'HELP_LINKS_REQUEST'
export const HELP_LINKS_SUCCESS = 'HELP_LINKS_SUCCESS'
export const HELP_LINKS_FAILURE = 'HELP_LINKS_FAILURE'

export const OTHER_LINKS_REQUEST = 'OTHER_LINKS_REQUEST'
export const OTHER_LINKS_SUCCESS = 'OTHER_LINKS_SUCCESS'
export const OTHER_LINKS_FAILURE = 'OTHER_LINKS_FAILURE'