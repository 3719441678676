import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormMain } from '../../assets/img';
// import { NavigationBar } from '../../components';
import { NavButton } from '../../styles';
// import { Footer } from '../../components/footer';
import { useNavigate } from 'react-router-dom';

// const Navigation = NavigationBar.Navigation;
const Main = () => {
  // const [mobile, setMobile] = useState(false)
  const navigate = useNavigate();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    // const responsive = () => {
    //   return window.innerWidth <= 9
    // }
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      <FlexBox>
        <Image src={FormMain} alt='form' className='form' />
        <ButtonWrapper>
          <Button onClick={() => navigate('/personal-form')}>
            Open a Personal Account
          </Button>
          <Button onClick={() => navigate('/business-form')}>
            Open a Business Account
          </Button>
        </ButtonWrapper>
      </FlexBox>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const FlexBox = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
`;
const Image = styled.img`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Button = styled(NavButton)`
  width: 30rem;
  height: 5rem;
  margin: 0 0 2rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export default Main;
