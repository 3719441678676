import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { WayShort } from '../../assets/img';
import { Grey2, Blue2, Arrows, OvalMobile, Download } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { abbeyMobileExisting, cib, whatsNew } from '../../components/main';
import { Screen } from '../../styles';
import { Documents } from '../../utils';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { HelpersComponent } from '../../components';
import { CibContext } from '../../contexts/doc'
import { DOC_FAILURE, DOC_REQUEST, DOC_SUCCESS } from '../../constants';
import api from '../../services/api';

const Loader  = HelpersComponent.componentLoader
// const Navigation = NavigationBar.Navigation;
const AbbeyMobile = () => {
  const [mobile, setMobile] = useState(false)
  const [loading, setLoading] = useState(false)

  const downloadDocs = async (filename) => {
    setLoading(true);
    const data = await Documents.downloadDocuments(filename);
    const blob = b64toBlob(data, "application/zip");
    saveAs(blob, 'document.zip');
    setLoading(false);
  }

  let mounted = useRef(false)
  const { dispatch, state } = useContext(CibContext)
  useEffect(() => {
    mounted.current = true
    //get financial doc
    const getDoc = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: DOC_REQUEST })
          const result = await api.req('/api/document/cib')
          dispatch({
            type: DOC_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: DOC_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getDoc()

    return () => {
      mounted.current = false
    }
  }, [])

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'>
        <h1>Abbey Mobile</h1>
      </Section>
      {/* End of Hero Section */}

      {/* Existing Customer Section */}
      <Section className='existing'>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <Paragraph>
                  <p>
                  AbbeyMobile is a mobile payment application offering a
                convenient,
                secure, and speedy digital banking experience. Apart from
                being easy to
                navigate its fund management features, our customers can
                apply for a mortgage to start the process of home
                ownership.
                  </p>
                </Paragraph>
                <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content className='description'>
                    <h2>To register on AbbeyMobile as an EXISTING Abbey Customer:</h2>
                  </Content>
                </MobileHeader>
                <List>
                {abbeyMobileExisting.map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </List>
              </FlexBox>
            ): (
          <FlexBox>
            <ImageWrapper>
              <Image src={Grey2} alt='grey' className='grey' />
              <Image src={Blue2} alt='blue' className='blue' />
            </ImageWrapper>
            <ContentContainer>
              <p>
                AbbeyMobile is a mobile payment application offering a
                convenient,
                secure, and speedy digital banking experience. Apart from
                being easy to
                navigate its fund management features, our customers can
                apply for a mortgage to start the process of home
                ownership.
              </p>
              <Header>
                To register on AbbeyMobile as an EXISTING Abbey Customer:
              </Header>

              <List>
                {abbeyMobileExisting.map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </List>
            </ContentContainer>
          </FlexBox>
            )
          }
        </Container>
      </Section>
      {/* End of existing customer section */}

      {/* New Customer Section */}
      <Section className='new'>
        <Container className='new'>
          <Card>
            <h2>What's New</h2>
            <p>Abbey Mobile allows you to:</p>
            <List className='new'>
              {whatsNew.map(({ name }) => (
                <li key={name}>{name}</li>
              ))}
            </List>

            <Header>Corporate Internet Banking</Header>
            <p
              style={{
                paddingTop: '1rem',
                fontWeight: 'inherit'
                // paddingBottom: '3rem',
              }}
            >
              Our Corporate Internet Banking Platform, Abbey CIB is now live! Take control of your business finances with Abbey CIB.
            </p>
            <List className='cib'>
                {
                  cib.map(({ item }) => (
                    <li key={item}>{item}</li>
                  ))
                }
            </List>
            <TextLink href='https://corporate.abbeymortgagebank.com' target='_blank' rel='noreferrer'>Go to Abbey CIB</TextLink>
            <DownloadContainer>
              {
                state?.data?.length > 0 && state.data.map((item) => (
                  <DownloadCard>
                    <DownloadContent>
                    <p>{item.title}</p>
                    </DownloadContent>
                    <Icon onClick={() => downloadDocs(item.link)} content={!loading ? `url(${Download})` : null}>{loading && <Loader color={'#fff'} />}</Icon>
                  </DownloadCard>
                ))
              }
            </DownloadContainer>
          </Card>
        </Container>
      </Section>
      {/* End of New Customer Section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 5rem auto 0;
  `}
  ${Screen.smallpc`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%!important;
  margin: 0 auto!important;
  `}
   ${Screen.galaxyFold`
  width: 90%!important;
  margin: 0 auto!important;
  `}

  &.new {
    ${Screen.mediumPhone`
    width: 90%!important;
    `}
  }
`;
const ContentContainer = styled.div`
  p {
    color: #002668;
    font-size: 1.1rem;
    /* font-weight: bold; */
  }
`;

const Image = styled.img`
  &.grey {
    width: 20rem;
    height: 20rem;
    position: absolute;
    z-index: 1;
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 15rem;
    height: 15rem;
    position: absolute;
    top: 8rem;
    ${Screen.pad`
    width: 12rem;
    height: 12rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.cards {
    width: 15rem;
    height: 13rem;
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 50vh;
  &.hero {
    background-image: url(${WayShort});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-right: 5rem;
    padding-top: 10rem;
    ${Screen.pad`
    padding-top: 7rem
    `}
    ${Screen.mediumpc`
    min-height: 20vh!important;
    padding-top: 7rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}

    h1 {
      text-align: right;
      text-transform: uppercase;
      font-size: 40px;
      color: #002668;
      ${Screen.surfaceDuo`
      font-size: 30px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      font-size: 24px;
      margin-left: 3rem;
      text-align: center;
      `}
      ${Screen.smallPhone`
      font-size: 22px;
      text-align: center;
      `}
      ${Screen.galaxyFold`
      font-size: 20px;
      text-align: center;
      `}
    }
    /* padding-top: 12rem; */
  }

  &.existing {
    min-height: 40vh;
    margin-top: -3rem;
    ${Screen.miniLargeScreen`
    margin-top: 0;
    `}



${Screen.miniLargeScreen`
    min-height: 40vh!important;
    margin-bottom: 0!important;
    `}

${Screen.pad`
    min-height: 40vh!important;
    margin-bottom: 0!important;
    `}

    ${Screen.mediumpc`
    min-height: 40vh!important;
    margin-bottom: 0!important;
    `}
    ${Screen.smallpc`
    min-height: 50vh!important;
    margin-bottom: 0!important;
    `}

  }

  &.new {
    min-height: 100vh;
    background-image: url(${Arrows});
    background-position: center;
    background-repeat: no-repeat;
    /* margin: 0 !important; */
    background-size: contain;
    padding-top: 5rem;
    ${Screen.miniLargeScreen`
    padding-top: 0;
    min-height: 45vh;
    margin-top: 0!important;
    `}
    ${Screen.pad`
    padding-top: 0;
    min-height: 45vh;
    margin-top: 0!important;
    `}
    ${Screen.mediumpc`
    padding-top: 0;
    min-height: 45vh;
    margin-top: 0!important;
    `}
    ${Screen.smallpc`
    padding-top: 5rem;
    min-height: 55vh;
    `}
    ${Screen.mediumPhone`
    padding-top: 3rem;
    min-height: 70vh;
    `}
    ${Screen.phone`
    padding-top: 3rem;
    min-height: 90vh;
    `}
    ${Screen.smallPhone`
    padding-top: 3rem;
    min-height: 90vh;
    `}
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  ${Screen.smallpc`
  flex-direction: column;
  justify-content: center;
  align-items: center
  `}
`;

const ImageWrapper = styled.div`
  width: 40%;
  position: relative;
  margin-right: 15rem;
  ${Screen.miniLargeScreen`
  margin-right: 20rem;
  `}
  ${Screen.pad`
  margin-right: 15rem;
  `}
  ${Screen.mediumpc`
  margin-right: 12rem;
  `}
  /* margin-right: 15rem; */
`;
const Card = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 5px 3px 5px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 10rem; */
  ${Screen.smallpc`
  padding-left: 2rem;
  `}
  ${Screen.mediumPhone`
  padding: 0 5px;
  `}
  ${Screen.phone`
  padding: 0 5px;
  `}
  ${Screen.smallPhone`
  padding: 0 5px;
  `}

  h2 {
    color: #c81e19;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
    ${Screen.phone`
    font-size: 18px
    `}
    ${Screen.smallPhone`
    font-size: 18px
    `}
  }
  p {
    ${Screen.mediumPhone`
    font-size: 14px;
    font-weight: bold
    `}
    ${Screen.phone`
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    `}
  }
`;
const Header = styled.h3`
  color: #c81e19;
  padding-top: 2rem;
  ${Screen.mediumPhone`
  padding-top: 1rem;
  font-size: 16px;
  `}
  ${Screen.phone`
  padding-top: 1rem;
  font-size: 16px;
  `}
  ${Screen.smallPhone`
  padding-top: 1rem;
  font-size: 16px;
  `}
`;
const List = styled.ul`
  margin-top: 1.5rem;
  ${Screen.mediumPhone`
  margin-left: 1rem
  `}
  ${Screen.phone`
  margin-left: 1rem
  `}
  ${Screen.smallPhone`
  margin-left: 1rem;
  margin-bottom: 1rem
  `}

  li {
    color: #002668;
    padding-bottom: 0.5rem;
    ${Screen.mediumPhone`
    color: #002668;
    font-size: 14px;
    `}
    ${Screen.phone`
    color: #002668;
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    color: #002668;
    font-size: 14px;
    `}
  }

  &.new, &.cib {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${Screen.mediumPhone`
    margin-top: 0;
    `}
    ${Screen.phone`
    margin-top: 0;
    margin-left: 1rem;
    list-style-type: none;
    `}
    ${Screen.smallPhone`
    margin-top: 0;
    margin-left: 0;
    list-style-type: none;
    `}

    li {
      color: #000;
      ${Screen.mediumPhone`
      font-size: 13px;
      padding-bottom: 0.5!important;
      `}
      ${Screen.phone`
      font-size: 13px;
      padding-bottom: 0.5!important;
      `}
      ${Screen.smallPhone`
      font-size: 13px;
      padding-bottom: 0.5!important;
      `}
      ${Screen.galaxyFold`
      font-size: 12px;
      padding-bottom: 0!important;
      `}
    }
  }

  &.cib {
    ${Screen.mediumPhone`
    margin-top: 2rem;
    `}
  }
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
margin-top: 1rem;
`;
const Content = styled.div`
&.description {
    h2 {
      font-size: 2.5rem;
      font-style: italic;
      color: #c81e19;
      text-transform: inherit;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 20px;
      position: absolute;
      left: 10rem;
      top: 8rem
      `}
       ${Screen.surfaceDuo`
      font-size: 16px;
      position: absolute;
      left: 8rem;
      top: 6rem
      `}
       ${Screen.mediumPhone`
      font-size: 16px;
      position: absolute;
      left: 5rem;
      top: 4rem
      `}
      ${Screen.phone`
      font-size: 14px;
      position: absolute;
      left: 5rem;
      top: 4rem
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      position: absolute;
      left: 5rem;
      top: 4rem
      `}
      ${Screen.galaxyFold`
      font-size: 12px;
      position: absolute;
      left: 5rem;
      top: 4rem
      `}
    }
  }
`;
const Paragraph = styled.div`
margin-top: 5rem;
p {
  ${Screen.smallpc`
  color: #002668;
  `}
  ${Screen.mediumPhone`
  font-size: 14px;
  color: #002668
  `}
  ${Screen.phone`
  font-size: 14px;
  color: #002668
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  color: #002668
  `}
}
`;

const TextLink = styled.a`
color: #002668;
font-weight: bold;
text-decoration: none;
text-align: center;
font-size: 18px;
margin-top: 2rem;
`;

const DownloadCard = styled.div`
  width: 100%;
  height: 4rem;
  background-color: #f5f5f5;
  display: flex;
  margin-top: 2rem;
  ${Screen.smallpc`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.smallPhone`
  width: 100%;
  margin-top: 1rem;
  `}
`;

const DownloadContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  margin-bottom: 5rem;

  ${Screen.largeScreen`
  width: 50%;
  margin: 1rem 1rem;
  margin-bottom: 5rem;
  `}
  ${Screen.mediumpc`
  width: 70%;
  margin: 1rem 1rem;
  margin-bottom: 5rem;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 1rem 1rem;
  margin-bottom: 5rem;
  `}

  ${Screen.surfaceDuo`
  width: 90%;
  margin: 1rem 1rem 5rem;

  `}
  ${Screen.mediumPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 5rem;
  `}
  ${Screen.phone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 5rem;
  `}
  ${Screen.smallPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 5rem;
  `}
`;

const Icon = styled.div`
  width: 8%;
  height: 100%;
  background-color: #002668;
  content: ${({ content }) => content};
  cursor: pointer;
  padding: 0.5rem;
  ${Screen.surfaceDuo`
  width: 10%;
  `}
  ${Screen.mediumPhone`
  width: 13%;
  `}
   ${Screen.phone`
  width: 13%;
  `}
  ${Screen.smallPhone`
  width: 15%;
  `}

  &:hover {
    box-shadow: 5px 0px 20px rgb(199, 199, 199, 0.9);
  }
  `;

const DownloadContent = styled.div`
  width: 92%;
  height: 100%;
  padding-top: 1.5rem;
  padding-left: 2rem;
  ${Screen.surfaceDuo`
  width: 90%;
  padding: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 87%;
  padding: 1rem;
  `}
   ${Screen.phone`
  width: 87%;
  padding: 1rem;
  `}
  ${Screen.smallPhone`
  width: 85%;
  padding: 1rem;
  `}

  p {
    ${Screen.mediumPhone`
    font-size: 14px;
    `}
    ${Screen.phone`
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    font-size: 13px;
    `}
  }
`;
export default AbbeyMobile;
