import styled from 'styled-components';
import { Decorate, NavButton } from '../../../styles';

const StepOne = ({ next, values, handleChange }) => {
  const validation =
    values.account === '' ||
    values.bvn === '' ||
    values.dob === '' ||
    values.mobile === '' ||
    values.mobile.length < 11;
  return (
    <FormContainer>
      <FormHeader>Step 1 0f 5: Choose an Account</FormHeader>
      <Form autoComplete='off'>
        <FlexBox>
          <label>What kind of account would you like to open?</label>
          <Select>
            <select
              className='select-css'
              name='account'
              onChange={handleChange('account')}
              value={values.account}
            >
              <option value='' disabled hidden>
                Select
              </option>
              <option></option>
              <option value={'Abbey Biz'}>Abbey Biz</option>
              <option value={'Biz Extra'}>Biz Extra</option>
              <option value={'Fixed Deposit'}>Fixed Deposit</option>
            </select>
          </Select>
        </FlexBox>
        {values.account !== '' && (
          <>
            {/* Bvn validation */}
            <FormHeader pt='5rem'>Step 1: BVN Validation</FormHeader>
            <FlexBox mt='3rem'>
              <label>Bvn</label>
              <Input>
                <input
                  type={'text'}
                  name='bvn'
                  placeholder='Please input 0 if you do not have BVN'
                  onChange={handleChange('bvn')}
                  value={values.bvn}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Date of Birth</label>
              <Input>
                <input
                  type={'date'}
                  name='dob'
                  placeholder='dd-mm-yyyy'
                  onChange={handleChange('dob')}
                  value={values.dob}
                />
              </Input>
            </FlexBox>
            <FlexBox mt='2rem'>
              <label>Mobile Number</label>
              <Input>
                <input
                  type={'text'}
                  name='mobile'
                  placeholder='Please enter mobile number'
                  onChange={handleChange('mobile')}
                  value={values.mobile}
                />
              </Input>
            </FlexBox>
            <ButtonWrapper>
              <Button onClick={next} className={validation && 'disabled'}>
                Validate
              </Button>
            </ButtonWrapper>
          </>
        )}
        {/* End of Bvn validation */}
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
const Select = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

  ${Decorate.select};
  .select-css {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
  }
`;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
    /* cursor: pointer; */
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
export default StepOne;
