import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PageRoutes from './routes';
import { DarkMode, Toggle, Theme } from './utils';
import { GlobalStyle } from './styles';
// import { SliderContext } from './contexts/slider'
// import { HelperComponents } from './components';
import { ContextWrapper } from './contexts';
// import NotFound from './pages/notFound';

// const { 
//   GalleryContextProvider, 
//   SliderContextProvider, 
//   GoogleContextProvider,
//   AbbeyLinksContextProvider,
//   HelpLinksContextProvider,
//   OtherLinksContextProvider,
//   MainLinksContextProvider,
//   SectionLinksContextProvider,
//   PersonalHeroContextProvider
//  } =
//   Contexts;

// const Layout = HelperComponents.Layout;
const Root = () => {
  const { theme, toggleTheme, componentMounted } = DarkMode();
  const themeMode = theme === 'light' ? Theme.lightTheme : Theme.darkTheme;
  
  if (!componentMounted) return <div />;

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
          <ContextWrapper>
            <Router>
              <Routes>
                <Route
                  path='/*'
                  element={<PageRoutes.MainRoutes theme={themeMode} />}
                />
                {/* <Route path='*' element={<NotFound />} /> */}
                {/* <Route path='*' element={<Navigate to='/404' replace />} /> */}
              </Routes>
            </Router>
          </ContextWrapper>
      {/* <Toggle toggleTheme={toggleTheme} /> */}
    </ThemeProvider>
  );
};

export default Root;
