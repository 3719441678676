import styled from "styled-components"
import { ValImg, ValMobile } from "../assets/img"
import screen from "./screen";

export const Background = styled.div`
background-image: ${({ bg }) => bg};
background-attachment: fixed;
background-size: cover;
background-position: center;
background-repeat: no-repeat;

${screen.tablet`
background-image: ${({ mobile }) => mobile}
`}
`;