import React, { useEffect } from 'react';
import styled from 'styled-components';
// import { NavigationBar } from '../../components';
import { InvestorMobile, StoryBg } from '../../assets/img';
// import { Footer } from '../../components/footer';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Story = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      <Section className='hero'>
        <Container className='hero'>
          <Card className='hero'>
            <Content>
            <h2>Our Story</h2>
            <p style={{ paddingTop: '1rem' }}>
              Understanding the many challenges faced by most Nigerians when it
              comes to homeownership,
              Rose Ada Okwechime founded the bank in 1991. Then known as
              Abbey Building Society Plc, the
              bank was established with a mission to connect well-meaning
              and hopeful homeowners to
              affordable housing solutions.
            </p>
            <p style={{ paddingTop: '2rem' }}>
              This goal inspired the bank’s original vision: ‘Making Homes
              Happen which has since defined the
              business that stands till this day.
            </p>
            <p style={{ paddingTop: '2rem' }}>
              Today, Abbey Mortgage Bank is one of seven national Primary
              Mortgage Banks licensed by the
              Central Bank of Nigeria, and the Federal Mortgage Bank of
              Nigeria (FMBN).
            </p>
            </Content>
          </Card>
        </Container>
      </Section>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  &.hero {
    position: relative;
    ${Screen.miniLargeScreen`
  margin: 5rem auto;
  `}
  ${Screen.pad`
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  margin: 3rem auto;
  `}
  ${Screen.tablet`
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  `}
  }
`;

const Section = styled.section`
  width: 100%;

  &.hero {
    min-height: 100vh;
    background-image: url(${StoryBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 15rem;
    ${Screen.miniLargeScreen`
    min-height: 50vh!important;
    margin-bottom: 20rem;
    `}
    ${Screen.pad`
    min-height: 50vh!important;
    margin-bottom: 25rem;
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    margin-bottom: 20rem;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    margin-bottom: 30rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.story {
    margin-top: 15rem;
  }
`;
const Card = styled.div`
  &.hero {
    width: 100%;
    height: 400px;
    background: rgba(0, 36, 104, 0.9);
    position: absolute;
    /* padding: 3rem 10rem; */
    top: 400px;
    ${Screen.miniLargeScreen`
    top: 150px;
    height: 450px;
    `}
    ${Screen.pad`
    top: 100px;
    height: 500px;
    `}
    ${Screen.mediumpc`
    top: 230px;
    height: 500px;
    `}
    ${Screen.smallpc`
    top: 130px;
    height: 500px;
    `}
    ${Screen.tablet`
    top: 150px;
    height: 500px;
    `}
    ${Screen.surfaceDuo`
    top: 150px;
    `}
    ${Screen.mediumPhone`
    top: 100px;
    height: 500px;
    `}
    ${Screen.phone`
    top: 70px;
    height: 500px;
    `}
    ${Screen.smallPhone`
    top: 70px;
    height: 500px;
    `}
    ${Screen.galaxyFold`
    top: 70px;
    height: 530px;
    `}

    h2 {
      color: #fff;
      font-size: 2.5rem;
      ${Screen.miniLargeScreen`
      font-size: 32px;
      padding-bottom: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.pad`
      font-size: 32px;
      padding-bottom: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.mediumpc`
      font-size: 32px;
      padding-bottom: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      padding-bottom: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.surfaceDuo`
      font-size: 26px;
      padding-bottom: 1rem;
      padding-top: .5rem;
      text-align: center;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      text-align: center;
      padding-bottom: 1rem;
      padding-top: 1rem;
      `}
      ${Screen.phone`
      font-size: 24px;
      text-align: center;
      padding-bottom: 1rem;
      padding-top: .5rem
      `}
      ${Screen.smallPhone`
      font-size: 24px;
      text-align: center;
      padding-bottom: 1rem;
      `}
    }

    p {
      color: #fff;
      font-size: 1.1rem;
      word-spacing: 0.1rem;
      ${Screen.mediumpc`
      font-size: 1rem;
      word-spacing: 0;
      `}
      ${Screen.smallpc`
      font-size: 1rem;
      word-spacing: 0;
      `}
      ${Screen.surfaceDuo`
      font-size: 15px;
      word-spacing: 0;
      padding-top: 1rem!important;
      `}
      ${Screen.mediumPhone`
      font-size: 14px;
      word-spacing: 0;
      padding-top: 1rem!important;
      `}
      ${Screen.phone`
      font-size: 14px;
      word-spacing: 0;
      padding-top: 1rem!important;
      `}
      ${Screen.smallPhone`
      font-size: 14px;
      word-spacing: 0;
      padding-top: 1rem!important;
      `}
    }
  }
  &.brand {
    width: 20rem;
    height: auto;
    background-color: #fff;
    box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${({ padding }) => padding};
    transition-duration: 0.5s;

    div {
      &.content {
        display: none;
      }
    }

    &:hover {
      div {
        display: none;
      }
      div {
        &.content {
          display: inline;
          padding: 4rem 1rem 0;
          font-size: 0.9rem;
          color: #002668;
          /* font-size: 1.1rem; */
        }
      }
    }
  }

  &.investor {
    width: 20rem;
    height: auto;
    background-color: #fff;
    box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${({ padding }) => padding};
  }
`;
const Content = styled.div`
width: 80%;
margin: 3rem auto;
${Screen.mediumPhone`
width: 90%;
margin: 2rem auto;
`}
${Screen.phone`
width: 90%;
margin: 2rem auto;
`}
${Screen.smallPhone`
width: 90%;
margin: 2rem auto;
`}
`
export default Story;
