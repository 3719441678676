import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { galleryReducer } = Reducers
export const GalleryContext = createContext()
const Slider = ({ children }) => {
    const initialState = {
        loading: false,
        data: [],
        error: ''  
    }
    const [state, dispatch] = useReducer(galleryReducer, initialState)
  return (
    <GalleryContext.Provider value={{ state, dispatch }}>
        {children}
    </GalleryContext.Provider>
  )
}

export default Slider