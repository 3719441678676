import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { NavigationBar } from '../../components';
import { Close, Open, PolicyMobile, PrivacyImg } from '../../assets/img';
import { Link } from 'react-router-dom';
import { privacyThree, privacyFour, privacyFive } from '../../components/main';
// import { Footer } from '../../components/footer';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Policy = () => {
  //   const [open, setOpen] = useState(false);
  const [policyOne, setPolicyOne] = useState(false);
  const [policyTwo, setPolicyTwo] = useState(false);
  const [policyThree, setPolicyThree] = useState(false);
  const [policyFour, setPolicyFour] = useState(false);
  const [policyFive, setPolicyFive] = useState(false);

  const [cookiesOne, setCookiesOne] = useState(false);
  const [cookiesTwo, setCookiesTwo] = useState(false);
  const [cookiesThree, setCookiesThree] = useState(false);
  const [cookiesFour, setCookiesFour] = useState(false);
  const [cookiesFive, setCookiesFive] = useState(false);

  const togglePolicyOne = () => {
    setPolicyOne(!policyOne);
  };
  const togglePolicyTwo = () => {
    setPolicyTwo(!policyTwo);
  };
  const togglePolicyThree = () => {
    setPolicyThree(!policyThree);
  };
  const togglePolicyFour = () => {
    setPolicyFour(!policyFour);
  };
  const togglePolicyFive = () => {
    setPolicyFive(!policyFive);
  };

  const toggleCookiesOne = () => {
    setCookiesOne(!cookiesOne);
  };
  const toggleCookiesTwo = () => {
    setCookiesTwo(!cookiesTwo);
  };
  const toggleCookiesThree = () => {
    setCookiesThree(!cookiesThree);
  };
  const toggleCookiesFour = () => {
    setCookiesFour(!cookiesFour);
  };
  const toggleCookiesFive = () => {
    setCookiesFive(!cookiesFive);
  };

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {/* Hero Section */}
      <Section className='hero'>
        <Container className='hero'>
          <Glass>
            <Content className='glass'>
              <h2>Privacy Policy</h2>
            </Content>
          </Glass>
        </Container>
      </Section>
      {/* End of Hero Section */}

      <Container>
        {/* Privacy Policy */}
        <Section className='policy'>
          <Header>Privacy Policy</Header>
          {/* Policy */}
          <SubHeader>
            <p>Your information, what you need to know</p>
            <Image
              src={policyOne ? Close : Open}
              onClick={togglePolicyOne}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {policyOne && (
            <Content>
              <p>
                This privacy policy explains why we collect information about
                you, how that information may be used, how we keep it safe and
                confidential and what your rights are in relation to this.
              </p>
              <p>
                <TextLink to='#' color='#000'>
                  www.abbeymortgagebank.com
                </TextLink>{' '}
                is not an email service, but a corporate website. Registration
                is not required on this corporate website but is available to
                visitors interested in receiving our newsletters and email
                updates.
              </p>
              <p>
                This Online Privacy Policy explains what information we collect,
                how we collect, share, use, and protect your personal
                information when you visit or use this site and other services
                offered by Abbey Mortgage Bank Plc. As an Abbey Mortgage Bank
                customer, be assured that we will keep protecting your
                information as we have always done.
              </p>
              <p>
                Abbey Mortgage Bank Plc customers have the opportunity to make
                choices about how their personal information may be shared, and
                we encourage you to make choices that enable us to provide you
                with quality products and services that help you meet your
                financial needs.
              </p>
            </Content>
          )}
          {/* End of Policy */}

          {/* Security */}
          <SubHeader>
            <p>Security of personal information</p>
            <Image
              src={policyTwo ? Close : Open}
              onClick={togglePolicyTwo}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {policyTwo && (
            <Content>
              <p>
                Safeguarding your personal information is our main concern. We
                maintain physical, electronic, and procedural safeguards that
                comply with applicable federal laws to secure your information
                from unauthorized access and use, accidental or unlawful
                alteration and destruction, and other unlawful or unauthorized
                forms of processing. We engage in the continuous training of our
                employees in the proper management of personal information. When
                we use other companies to provide services, we require them to
                protect the confidentiality of personal information they
                receive.
              </p>
              <p>
                <b>At Abbey Mortgage Bank Plc</b>, we take data privacy very
                seriously and we provide our customers with all necessary data
                security to protect such personal information from unauthorized
                access. We require any third parties who carry out any work on
                our behalf to comply with appropriate compliance standards to
                protect your information. We are pleased to announce that we
                comply with global best practices and requirements of Nigeria
                Data Protection Regulation (NDPR).
              </p>
            </Content>
          )}
          {/* End of Security */}

          {/* Information we collect */}
          <SubHeader>
            <p>The information we collect</p>
            <Image
              src={policyThree ? Close : Open}
              onClick={togglePolicyThree}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {policyThree && (
            <Content className='third'>
              <p>
                Abbey Mortgage Bank Plc will only collect your information in
                line with relevant regulations and laws.
              </p>
              <b>How do we collect information?</b>
              <p style={{ paddingTop: '2rem' }}>
                We may collect information from a range of sources, and it may
                relate to any of our products or services we currently provide
                or may have provided in the past.
                <br /> We collect your personal information when,
              </p>
              <List>
                {privacyThree.map(({ item }) => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </List>
              <p style={{ paddingTop: '2rem' }}>
                Note that we may collect information about your computer (or
                mobile device), including where available your IP address,
                operating system, and browser type, for system administration or
                for our own commercial purposes. This is statistical data about
                our users' browsing actions and patterns and does not identify
                any individual.
              </p>
            </Content>
          )}
          {/* End of Information we collect */}

          {/* How we use information */}
          <SubHeader>
            <p>How we use your information?</p>
            <Image
              src={policyFour ? Close : Open}
              onClick={togglePolicyFour}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {policyFour && (
            <Content>
              <List>
                {privacyFour.map(({ item }) => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </List>
            </Content>
          )}
          {/* End of how we use information */}

          {/* Who we share information with */}
          <SubHeader>
            <p>Who we share information with?</p>
            <Image
              src={policyFive ? Close : Open}
              onClick={togglePolicyFive}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {policyFive && (
            <Content>
              <p>
                The Bank, (Abbey Mortgage Bank Plc) in efforts to provide you
                with excellent products and services may need to outsource its
                product delivery, this will be done in line with relevant
                regulations and law. We may share your informaion in order to
                provide you with products and services you have requested.
                <br />
                We may also share your information where we have a public or
                legal duty to do so, when we need it to conclude regulatory
                reporting and when we have requested and received your
                permission to share it.
                <br />
                Individual rights
              </p>
              <NewList>
                {privacyFive.map(({ header, content }) => (
                  <div key={header} style={{ paddingBottom: '1.5rem' }}>
                    <h4>{header}</h4>
                    <NewListItem>{content}</NewListItem>
                  </div>
                ))}
              </NewList>
            </Content>
          )}
          {/* End of who we share information with */}
          <Header pt='3rem' id='cookies'>Cookies Policy</Header>

          {/* use of cookies */}
          <SubHeader>
            <p>Our Use Of Cookies</p>
            <Image
              src={cookiesOne ? Close : Open}
              onClick={toggleCookiesOne}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {cookiesOne && (
            <Content>
              <p>
                We use cookies on our websites (our website, or our websites)
                for a number of purposes. They help us provide you with a good
                experience when you browse our website and allow us to improve
                our site. By continuing to browse our website, you are agreeing
                to our use of cookies.
              </p>
              <p>
                This Cookies Policy (Policy) explains how we use cookies and the
                choices you have. Except as otherwise stated in this Policy, our
                Privacy Policy will apply to our processing of data that we
                collect via cookies.
              </p>
              <p>
                By using our website (by clicking, navigating, or scrolling),
                you are consenting to our use of cookies in accordance with this
                Policy. If you do not agree to our use of cookies, you can
                prevent the installation of cookies through the settings of your
                browser or not use our website at all. However, if you disable
                the use of cookies, this might cause some parts of this website
                not to function properly for you and it may impact your user
                experience on this site.
              </p>
            </Content>
          )}
          {/* End of use of cookies */}
          {/* What are cookies */}
          <SubHeader>
            <p>What are cookies?</p>
            <Image
              src={cookiesTwo ? Close : Open}
              onClick={toggleCookiesTwo}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {cookiesTwo && (
            <Content>
              <p>
                Cookies are messages or small files that are placed on your web
                browser when you visit an internet site. Cookies are useful and
                do a lot of different things, including allowing a website to
                recognize a user's device, improving online experience etc.
              </p>
            </Content>
          )}
          {/* End of what are cookies */}
          {/* Why we use cookies */}
          <SubHeader>
            <p>How and why we use cookies?</p>
            <Image
              src={cookiesThree ? Close : Open}
              onClick={toggleCookiesThree}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {cookiesThree && (
            <Content>
              <p>
                We use cookies when you access our websites and our online
                products and services to make the site work better and to help
                us understand how you use our site. Cookies enable us to offer
                tailored products and to understand the information we receive
                about you, including information about your use of other
                websites and apps, whether or not you have an account with us.{' '}
                <br />
                Cookies help us provide, protect, and improve our products and
                services, by personalizing, tailoring, and measuring the
                services and products we provide for satisfactory and safe
                experience. We also use them to help authentication of user,
                assess performance and functionality of our online products and
                services, and analytical research.
              </p>
            </Content>
          )}
          {/* End of why we use cookies */}
          {/* Individual responsibility */}
          <SubHeader>
            <p>Individual Responsibility</p>
            <Image
              src={cookiesFour ? Close : Open}
              onClick={toggleCookiesFour}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {cookiesFour && (
            <Content>
              <p>
                Individuals are responsible for making sure the information
                provided to the Bank is accurate and should inform the Bank on
                any changes as it occurs, this will enable us to update your
                information with us.
              </p>
              <p>
                If you would like further information on anything included in
                this Privacy Policy, contact us at 23,{' '}
                <b>Karimu Kotun Street, Victoria Island, Lagos,</b>
                <br />
                <TextLink to='#' color='#0867BE'>
                  dataprotectionofficer@abbeymortgagebank.com
                </TextLink>
              </p>
            </Content>
          )}
          {/* End of individual responsibility */}

          {/* Changes to policy */}
          <SubHeader>
            <p>Changes to the Policy</p>
            <Image
              src={cookiesFive ? Close : Open}
              onClick={toggleCookiesFive}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {cookiesFive && (
            <Content>
              <p>
                This Privacy policy may be updated from time to time and the
                most recent version can be found on{' '}
                <TextLink to='#' color='#0867BE'>
                  www.abbeymortgagebank.com
                </TextLink>
              </p>
            </Content>
          )}
          {/* End of changes to policy */}
        </Section>
      </Container>

      {/* footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 6rem auto 0;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  `}
${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
   ${Screen.galaxyFold`
  width: 85%;
  margin: 0 auto!important;
  `}
  &.hero {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 5rem;
    `}
     ${Screen.smallpc`
    padding-top: 0;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem
    `}
  }

  p {
    font-size: 1.2rem;
  }
`;

const Image = styled.img`
  &.policy {
    margin: 3rem 0;
  }

  &.icon {
    cursor: pointer;
    ${Screen.mediumPhone`
    width: 24px;
    height: 24px;
    `}
    ${Screen.phone`
    width: 22px;
    height: 22px;
    `}
    ${Screen.smallPhone`
    width: 20px;
    height: 20px;
    `}
  }
`;
const Glass = styled.div`
  width: 540px;
  height: 130px;
  background: rgba(255, 255, 255, 0.7);
  ${Screen.pad`
  margin-top: 2rem;
  `}
   ${Screen.mediumpc`
  margin-top: 0;
  `}
  ${Screen.smallpc`
  background: rgba(255, 255, 255, 0);
  `}
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${PrivacyImg});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 5rem
    `}
    ${Screen.pad`
    padding-top: 5rem;
    min-height: 60vh;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.policy {
    margin: 5rem 0;
    min-height: 100vh;
    padding: 0 7rem;
    ${Screen.mediumpc`
    min-height: 50vh;
    `}
    ${Screen.smallpc`
    min-height: 60vh;
    `}
    ${Screen.surfaceDuo`
    padding: 0;
    min-height: 60vh;
    `}
    ${Screen.mediumPhone`
    padding: 0;
    margin-top: 3rem;
    min-height: 60vh;
    `}
    ${Screen.phone`
    padding: 0;
    margin-top: 3rem;
    min-height: 70vh;
    `}
    ${Screen.smallPhone`
    padding: 0;
    margin-top: 3rem;
    min-height: 70vh;
    `}
  }
`;
const Header = styled.header`
  font-size: 3rem;
  color: #002668;
  font-weight: bold;
  padding-bottom: 1.5rem;
  padding-top: ${({ pt }) => pt};
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 22px
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 22px
  `}
`;
const SubHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #c81e19;
    padding-right: 1rem;
    ${Screen.mediumPhone`
    font-size: 16px;
    padding-right: 0
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-right: 0
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-right: 0
    `}
  }
`;
const Content = styled.div`
  &.glass {
    padding: 3rem;
    h2 {
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      text-transform: uppercase;
      ${Screen.smallpc`
      padding-left: 5rem;
      padding-top: 12rem;
      font-size: 35px;
      `}
      ${Screen.tablet`
      padding-left: 5rem;
      padding-top: 4rem;
      font-size: 35px;
      `}
      ${Screen.surfaceDuo`
      padding-left: 4rem;
      padding-top: 1rem;
      font-size: 35px;
      font-size: 24px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      padding-left: 3rem;
      padding-top: 0;
      `}
      ${Screen.phone`
      font-size: 24px;
      padding-left: 2rem;
      padding-top: 0;
      `}
      ${Screen.smallPhone`
      font-size: 24px;
      padding: 0;
      `}
      ${Screen.galaxyFold`
      font-size: 20px;
      `}
    }
  }
  p {
    padding-bottom: 2rem;
    font-size: 1.05rem;
    word-spacing: 0.1rem;
    ${Screen.mediumPhone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}
    ${Screen.phone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}
  ${Screen.smallPhone`
    font-size: 13px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}

    &:last-child {
      padding-bottom: 2rem;
    }
  }

  b {
    ${Screen.mediumPhone`
    font-size: 13px!important
    `}
    ${Screen.phone`
    font-size: 13px!important
    `}
    ${Screen.smallPhone`
    font-size: 13px!important
    `}
  }
`;
const TextLink = styled(Link)`
  color: ${({ color }) => color};
  ${Screen.mediumPhone`
  word-break: break-all
  `}
  ${Screen.phone`
  word-break: break-all
  `}
  ${Screen.smallPhone`
  word-break: break-all
  `}
`;
const List = styled.ul``;
const NewList = styled(List)`
  list-style-type: none;
  ${Screen.mediumPhone`
    h4 {
      font-size: 14px!important;
    }
  `}
  ${Screen.phone`
    h4 {
      font-size: 14px!important;
    }
  `}
  ${Screen.smallPhone`
    h4 {
      font-size: 13px!important;
    }
  `}
`;
const ListItem = styled.li`
  padding-top: 0.5rem;
  ${Screen.mediumPhone`
  font-size: 14px!important
  `}
  ${Screen.phone`
  font-size: 14px!important
  `}
  ${Screen.smallPhone`
  font-size: 13px!important
  `}
  &:last-child {
    padding-bottom: 2rem
  }
`;
const NewListItem = styled.li`
  padding-top: 0.3rem;
  ${Screen.mediumPhone`
  font-size: 13px!important;
  padding-bottom: 0!important;
  `}
  ${Screen.phone`
  font-size: 13px!important;
  padding-bottom: 0!important;
  `}
  ${Screen.smallPhone`
  font-size: 13px!important;
  padding-bottom: 0!important;
  `}
  &:last-child {
    padding-bottom: 2rem
  }

  
`;
export default Policy;
