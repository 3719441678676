export const BLOG_LINKS_REQUEST = 'BLOG_LINKS_REQUEST'
export const BLOG_LINKS_SUCCESS = 'BLOG_LINKS_SUCCESS'
export const BLOG_LINKS_FAILURE = 'BLOG_LINKS_FAILURE'

export const BLOGS_REQUEST = 'BLOGS_REQUEST'
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS'
export const BLOGS_FAILURE = 'BLOGS_FAILURE'

export const PRESS_REQUEST = 'PRESS_REQUEST'
export const PRESS_SUCCESS = 'PRESS_SUCCESS'
export const PRESS_FAILURE = 'PRESS_FAILURE'

export const PRESS_LANDING_REQUEST = 'PRESS_LANDING_REQUEST'
export const PRESS_LANDING_SUCCESS = 'PRESS_LANDING_SUCCESS'
export const PRESS_LANDING_FAILURE = 'PRESS_LANDING_FAILURE'