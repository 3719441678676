import React from 'react';
import styled from 'styled-components';
import { Screen } from '../../styles';
import { FormMain, Internet, CloseModal } from '../../assets/img';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const App = ({ r, setModal }) => {
  const navigate = useNavigate()
  return (
    <Modal>
      <ModalCard>
        <CardHeader><Img src={Internet} alt='internet' /><p>Internet Banking</p></CardHeader>
        <ModalContent>
            <ButtonWrapper>
                <Button onClick={() => {
                  navigate('/personal-form')
                  setModal(false)
                  }}>Personal</Button>
                {/* <Button 
                onClick={() => {
                  navigate('/business-form')
                  setModal(false)
                  }}>Business
                </Button> */}
                <Button>
                  <Link to={'https://corporate.abbeymortgagebank.com'}>Business</Link>
                </Button>
            </ButtonWrapper>
            <ImageWrapper>
                <Img src={CloseModal} alt='close' className='close' onClick={() => r.closeModal()} />
            </ImageWrapper>
        </ModalContent>
      </ModalCard>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  ${Screen.pad`
  padding-top: 50px;
  `}
  ${Screen.surfaceDuo`
  padding-top: 10px;
  `}
  ${Screen.mediumPhone`
  padding-top: 10px;
  `}
  ${Screen.phone`
  padding-top: 10px;
  `}
  ${Screen.smallPhone`
  padding-top: 10px;
  `}
`;
const ModalCard = styled.div`
  width: 500px;
  height: 500px;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 10px;
//   padding: 5rem 2rem 2rem;


  ${Screen.pad`
  margin-top: 3rem;
  `}
  ${Screen.surfaceDuo`
  width: 400px;
  height: auto;
  margin-top: 5rem;
//   padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 350px;
  height: auto;
//   padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.phone`
  width: 350px;
  height: auto;
//   padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  width: 300px;
  height: auto;
//   padding-top: 2rem 1rem 1rem;
  `}
  ${Screen.galaxyFold`
  width: 270px;
  height: auto;
//   padding-top: 2rem .5rem 1rem;
  `}
`;
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${FormMain});
  background-size: cover;
  background-position: center;
  h2 {
    color: #c81e19;
    font-size: 2rem;
    padding-bottom: 1rem;

    ${Screen.mediumPhone`
    font-size: 1.5rem;
    `}
    ${Screen.phone`
    font-size: 1.5rem;
    `}
    ${Screen.smallPhone`
    font-size: 1.5rem;
    `}
    ${Screen.galaxyFold`
    font-size: 1.3rem;
    `}
  }

  p {
    color: #002668;
    ${Screen.mediumPhone`
     text-align: start;
     word-spacing: .1rem;
    `}
    ${Screen.phone`
     text-align: start;
     word-spacing: .1rem;
    `}
    ${Screen.smallPhone`
     text-align: start;
     word-spacing: .1rem;
    `}
     ${Screen.galaxyFold`
     text-align: start;
     word-spacing: 0;
    `}
  }
`;
const Button = styled.div`
height: 100px;
width: 300px;
// margin-top: 3rem;
margin-bottom: 2rem;
background-color: #002668;
box-shadow: 5px 3px 5px #ccc;
color: #fff!important;
border-radius: 5px;
font-size: 1.2rem;
font-weight: bold;
text-align: center;
padding-top: 2.5rem;
text-transform: uppercase;
cursor: pointer;

a {
  color: #fff;
  text-decoration: none
}

&:hover {
  opacity: .9;
}

${Screen.galaxyFold`
width: 200px;
`}
`;

const ButtonWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// margin-top: 3rem;
`
const CardHeader = styled.div`
width: 100%;
height: 80px;
background-color: #ccc;
display: flex;
justify-content: center;
padding-top: 1rem;
cursor: pointer;

p {
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1rem;
    ${Screen.galaxyFold`
    font-size: 18px;
    `}
}

${Screen.galaxyFold`
justify-content: flex-between;
`}
`
const Img = styled.img`
width: 40px;
height: 40px;
margin-top: .5rem;
margin-right: 1rem;

&.close {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}`;
const ImageWrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 3rem;
${Screen.surfaceDuo`
margin-top: 1rem;
padding-bottom: 2rem;
`}
`
export default App;
