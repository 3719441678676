import { useState } from 'react';
import styled from 'styled-components';
import { NavButton } from '../../../styles';
import HelpersComponent from '../../helpers';

const Modal = HelpersComponent.Modal;
const StepFive = ({ prev, values }) => {
  const [display, setDisplay] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleSubmit = () => {
    localStorage.removeItem('check2');
    localStorage.removeItem('check3');
    localStorage.removeItem('check4');
    localStorage.removeItem('check5');
    setDisplay(true);
  };
  const closeModal = () => {
    setDisplay(false);
  };
  const handleCheck = () => {
    setChecked(!checked);
  };
  return (
    <FormContainer>
      <FormHeader>Step 5 0f 5: Confirmation</FormHeader>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Business Name</label>
          <input value={values.businessName} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Business Start Date</label>
          <input value={values.startDate} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Business Reg No</label>
          <input value={values.regNumber} readOnly disabled />
        </div>
      </Form>
      <h4 style={{ paddingTop: '2rem' }}>Signatory Details</h4>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Surname</label>
          <input value={values.surname} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Firstname</label>
          <input value={values.firstname} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Middle Name</label>
          <input value={values.middlename} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Date of Birth</label>
          <input value={values.dob} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Gender</label>
          <input value={values.gender} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Marital Status</label>
          <input value={values.marital} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Email</label>
          <input value={values.email} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Country</label>
          <input value={values.country} readOnly disabled />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>State of Origin</label>
          <input value={values.state} readOnly disabled />
        </div>
        <div style={{ marginTop: '3rem' }}>
          <input
            className='checkbox'
            type={'checkbox'}
            onClick={handleCheck}
            checked={checked}
          />
          <label className='checkbox'>
            I accept the <span>Terms and Conditions</span>
          </label>
        </div>
      </Form>
      <ButtonWrapper>
        <PrevButton onClick={prev}>Back</PrevButton>
        <Button onClick={handleSubmit} className={!checked && 'disabled'}>
          Submit
        </Button>
      </ButtonWrapper>
      <Modal close={closeModal} display={display} />
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    font-size: 15px;
    font-weight: 400;
    margin: 0;

    &.checkbox {
      padding-left: 1rem;

      span {
        font-weight: bold;
      }
    }
  }

  input {
    /* width: 300px; */
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #f5f5f5;
    /* color: #0a3d94; */
    color: #9a9494;

    &.checkbox {
      transform: scale(2);
      cursor: pointer;
    }
  }
`;
const FormHeader = styled.h4`
  color: #002668;
`;
const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 3rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepFive;
