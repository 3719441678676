import { useEffect, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { HelpersComponent } from '../../components';
import { AboutImg, Arrow, AboutGroup, AboutMobile, OvalMobile } from '../../assets/img';
import { brand, moreItems } from '../../components/main';
import { Link, useNavigate } from 'react-router-dom';
// import { Footer } from '../../components/footer';
import { NavButton, Screen } from '../../styles';
import { Blue2, Grey2 } from '../../assets/svg';
import { AboutHeroContext } from '../../contexts/hero'
import { HERO_FAILURE, HERO_REQUEST, HERO_SUCCESS } from '../../constants';
import api from '../../services/api';
import Carousel from "@itseasy21/react-elastic-carousel"

// const Navigation = NavigationBar.Navigation;
const ComponentLoader = HelpersComponent.dashboardLoader;
const About = () => {
  const mount = useRef()
  const { dispatch, state } = useContext(AboutHeroContext)
  const [mobile, setMobile] = useState(false)
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  let resetTimeout;
  
  useEffect(() => {
    const responsive = () => {
      return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, [])
  useEffect(() => {
    mount.current = true
    //Scroll to top on page load
    window.scrollTo(0, 0);


    // get Hero
    const getHero = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: HERO_REQUEST })
          const result = await api.req('/api/document/hero/about')
          dispatch({
            type: HERO_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: HERO_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getHero()

    return () => {
      mount.current = false
    };
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {
        state.loading ? <ComponentLoader /> : (
          <>
          <Section className='hero' bg={`url(${state.data[0]})`}></Section>
          {/* Story */}
          <Section className='story'>
            <Container>
              {
                mobile ? (
                  <>
                <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content className='description'>
                    <h2>About <span>Us</span></h2>
                  </Content>
                </MobileHeader>
                <Content className='story-mobile'>
                <p>
                    Incorporated in August, 1991 and licensed to carry on business
                    as a primary mortgage institution in January, 1992, we
                    have in the last 30 years
                    developed strong resilience and brand stability.
                  </p>
                  <p>
                    In 2020, we added a strategic investor to our family, which has
                    boosted
                    and diversified investment opportunities and secured our
                    future growth.
                  </p>
                  <p>
                    Within a year, we grew our customer deposit by 250%, from N6bn
                    to
                    N21bn in 2021. Our horizon for continuous growth and
                    profitability looks
                    pretty bright!
                  </p>
                  <Button onClick={() => navigate('/help')}>Contact Us</Button>
                </Content>
                  </>
                ): (
                  <>
              <Header className='story'>
                About <span>Us</span>
              </Header>
              <FlexBox>
                <ImageWrapper>
                  <Image src={Grey2} alt='grey' className='grey' />
                  <Image src={Blue2} alt='blue' className='blue' />
                </ImageWrapper>
                <Content className='story'>
                  <p>
                    Incorporated in August, 1991 and licensed to carry on business
                    as a primary mortgage institution in January, 1992, we
                    have in the last 30 years
                    developed strong resilience and brand stability.
                  </p>
                  <p style={{ paddingTop: '3rem' }}>
                    In 2020, we added a strategic investor to our family, which has
                    boosted
                    and diversified investment opportunities and secured our
                    future growth.
                  </p>
                  <p style={{ paddingTop: '3rem' }}>
                    Within a year, we grew our customer deposit by 250%, from N6bn
                    to
                    N21bn in 2021. Our horizon for continuous growth and
                    profitability looks
                    pretty bright!
                  </p>
                  <Button onClick={() => navigate('/help')}>Contact Us</Button>
                </Content>
              </FlexBox>
                  </>
                )
              }
            </Container>
          </Section>
    
          {/* Core Values */}
          <Section className='values'>
            <Container className='values'>
              <BrandContainer>
                {brand.map(({ img, title, content, list }) => (
                  <BrandCard>
                    <div className='image'>
                      {img ? (
                        <Image src={img} alt='brand' className='brand' />
                      ) : (
                        <Text>S.P.I.C.E</Text>
                      )}
                    </div>
                    <div className='content'>
                      {list && (
                        <List>
                          {list.map((item, i) => (
                            <ListItem key={i}>{item}</ListItem>
                          ))}
                        </List>
                      )}
                      {content && <p>{content}</p>}
                    </div>
                    <CardContent>{title}</CardContent>
                  </BrandCard>
                ))}
              </BrandContainer>
            </Container>
          </Section>
          {/* End of core values */}
    
          {/* Learn More */}
          <Section className='learn'> 
            <Header className='learn'>
              Learn <span>more</span> about Abbey
            </Header>
            <Container className='learn'>
              {
                mobile ? (
                  <CardContainer>
                    <Carousel 
                enableAutoPlay={true} 
                pagination={false}
                ref={carouselRef}
                isRTL={false}
                autoPlaySpeed={4000}
                onNextEnd={() => {
                  clearTimeout(resetTimeout);
                  resetTimeout = setTimeout(() => {
                      carouselRef?.current?.goTo(0);
                  }, 4000);
              }}
                >
                  {moreItems.map(({ img, header, content, path }) => (
                  <MoreCard>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image src={img} alt='more' className='more' />
                  </div>
  
                  <h4>{header}</h4>
                  <p>{content}</p>
                  <CardLink to={path}>Learn More</CardLink>
                </MoreCard>
                ))}
                </Carousel>
                  </CardContainer>
                ): (
              <CardContainer>
                {moreItems.map(({ img, header, content, path }) => (
                  <MoreCard>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image src={img} alt='more' className='more' />
                    </div>
    
                    <h4>{header}</h4>
                    <p>{content}</p>
                    <CardLink to={path}>Learn More</CardLink>
                  </MoreCard>
                ))}
              </CardContainer>
                )
              }
            </Container>
          </Section>
          {/* End of Learn More */}
          {/* Footer */}
          {/* <Footer /> */}
          {/* End of Footer */}
          </>
        )
      }
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  
  &.values {
    ${Screen.smallpc`
    width: 90%;
    `}
    ${Screen.surfaceDuo`
    width: 80%;
    `}
  }

  &.learn {
    ${Screen.miniLargeScreen`
    width: 90%!important;
    `}
    ${Screen.smallpc`
    width: 70%!important;
    `}
    ${Screen.tablet`
    width: 80%!important;
    `}
    ${Screen.mediumPhone`
    width: 90%!important;
    `}
    ${Screen.smallPhone`
    width: 95%!important;
    `}
  }

  &.more {
    width: 85%;
  }
`;
const Button = styled(NavButton)`
  background-color: #c81e19;
  box-shadow: none;
  margin-left: 3rem;
  margin: 3rem 0;
  width: 8rem;
  height: 3rem;
  box-shadow: 5px 3px 3px #ccc;

  ${Screen.mediumpc`
  margin: 2rem 0;
  `}
  ${Screen.smallpc`
  margin: 2rem 0;
  `}
  ${Screen.tablet`
  margin: 1rem 0;
  `}
  ${Screen.mediumPhone`
  margin: 0;
  width: max-content;
  height: 2rem;
  padding: 0 .5rem;
  `}
  ${Screen.phone`
  margin: 0;
  width: max-content;
  height: 2rem;
  padding: 0 .5rem;
  `}
  ${Screen.smallPhone`
  margin: 0;
  width: max-content;
  height: 2rem;
  padding: 0 .5rem;
  `}
`;
const ImageWrapper = styled.div`
  width: 40%;
  position: relative;
  margin-right: 15rem;
  ${Screen.pad`
  margin-top: 3rem;
  margin-right: 15rem;
  `}
  ${Screen.mediumpc`
  margin-top: 3rem;
  margin-right: 10rem;
  `}
`;
const Image = styled.img`
  &.grey {
    width: 300px;
    /* height: 600px; */
    position: absolute;
    z-index: 1;
    ${Screen.pad`
    width: 15rem;
    height: 15rem
    `}
    ${Screen.mediumpc`
    width: 12rem;
    height: 12rem
    `}
  }
  &.blue {
    width: 300px;
    /* height: 330px; */
    position: absolute;
    top: 8rem;
    /* right: -2rem; */
    ${Screen.pad`
    width: 13rem;
    height: 13rem;
    top: 6rem;
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 10rem;
    top: 6rem;
    `}
  }
  &.more {
    width: 5rem;
    height: 5rem;
    margin-top: -2rem;
    ${Screen.miniLargeScreen`
    width: 4rem;
    height: 4rem;
    margin-top: -1rem
    `}
    ${Screen.pad`
    width: 4rem;
    height: 4rem;
    margin-top: -1rem
    `}
    ${Screen.mediumpc`
    width: 4rem;
    height: 4rem;
    margin-top: -1rem
    `}
    ${Screen.smallpc`
    width: 5rem;
    height: 5rem;
    margin-top: 0
    `}
    ${Screen.mediumPhone`
    width: 3rem;
    height: 3rem;
    margin-top: 0
    `}
    ${Screen.smallPhone`
    width: 3rem;
    height: 3rem;
    `}
  }
  &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
  &.brand {
    ${Screen.mediumpc`
    width: 12rem;
    `}
    ${Screen.smallpc`
    width: 10rem;
    `}
    ${Screen.mediumPhone`
    width: 10rem;
    `}
    ${Screen.phone`
    width: 10rem;
    `}
    ${Screen.smallPhone`
    width: 10rem;
    `}
  }
`;
const MoreCard = styled.div`
  width: 400px;
  height: 400px;
  background-color: #fff;
  padding: 0 3rem;
  box-shadow: 5px 3px 3px #ccc, -5px -1px 3px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-bottom: 5rem; */
  ${Screen.miniLargeScreen`
  width: 330px!important;
  height: 350px;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 1.5rem;
  `}
  ${Screen.pad`
  width: 280px!important;
  height: 300px;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 1rem;
  `}
  ${Screen.mediumpc`
  width: 260px!important;
  height: 300px;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 1rem;
  `}
  ${Screen.smallpc`
  width: 330px!important;
  // height: auto;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 1.5rem;
  `}
   ${Screen.tablet`
  width: 310px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 1.5rem;
  // height: auto;
  `}
   ${Screen.surfaceDuo`
  width: 300px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  `}
  ${Screen.mediumPhone`
  width: 260px!important;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 15px;
  // height: auto;
  `}
  ${Screen.phone`
  width: 250px!important;
  // height: auto;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 15px;
  `}
  ${Screen.smallPhone`
  width: 230px!important;
  // height: auto;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 10px;
  `}
  ${Screen.galaxyFold`
  width: 190px!important;
  // height: auto;
  box-shadow: 3px 1px 3px #ccc, -3px 1px 3px #ccc;
  padding: 0 10px;
  `}

  h4 {
    font-size: 1.5rem;
    text-align: center;
    color: #c81e19;
    padding: 5rem 0 1rem;
    ${Screen.miniLargeScreen`
    padding: 1rem 0;
    `}
    ${Screen.pad`
    padding: 1rem 0;
    font-size: 20px;
    `}
    ${Screen.mediumpc`
    padding: 1rem 0;
    font-size: 18px;
    `}
    ${Screen.smallpc`
    padding: 1rem 0;
    `}
    ${Screen.mediumPhone`
    padding: 1rem 0;
    font-size: 18px;
    `}
    ${Screen.phone`
    padding: 1rem 0;
    font-size: 18px;
    `}
    ${Screen.smallPhone`
    padding: 1rem 0;
    font-size: 18px;
    `}
  }
  p {
    font-size: 1rem;
    ${Screen.miniLargeScreen`
    padding-top: 1rem;
    `}
    ${Screen.pad`
    padding-top: 1rem;
    font-size: 14px;
    `}
    ${Screen.mediumpc`
    padding-top: 1rem;
    font-size: 14px;
    `}
    ${Screen.smallpc`
    padding-top: 1rem;
    `}
    ${Screen.mediumPhone`
    padding-top: 1rem;
    font-size: 14px
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-top: 1rem
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-top: 1rem
    `}
  }

  &:nth-child(1),
  &:nth-child(2) {
    margin-right: 2rem;
    ${Screen.tablet`
    margin-right: 0;
    `}
  }
`;
const BrandCard = styled.div`
  width: 300px;
  height: 300px;
  background-color: #fff;
  box-shadow: 5px 3px 5px #ccc, -5px 3px 5px #ccc;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 2rem 0;
  ${Screen.pad`
  width: 280px;
  height: 300px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.mediumpc`
  width: 250px;
  height: 250px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.smallpc`
  width: 230px;
  height: 220px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.tablet`
  width: 220px;
  height: 220px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.surfaceDuo`
  width: 60%;
  height: 220px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  height: 220px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.phone`
  width: 70%;
  height: 210px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.smallPhone`
  width: 80%;
  height: 200px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  ${Screen.galaxyFold`
  width: 90%;
  height: 200px;
  padding: 1rem 0;
  margin-bottom: 2rem;
  `}
  /* z-index: 1; */
  div {
    &.content {
      display: none;
    }
  }
  &:hover {
    div {
      display: none;
    }
    div {
      &.content {
        display: inline;
        /* padding: 4rem 1rem 0; */
        padding: 2rem 1rem 0;
        text-align: center;
        font-size: 1.2rem;
        color: #002668;

        ${Screen.smallpc`
        font-size: 16px;
        padding: 1rem;
        `}
        ${Screen.surfaceDuo`
        font-size: 16px;
        padding: 1rem;
        `}
        ${Screen.mediumPhone`
        font-size: 14px;
        padding: 1rem;
        `}
        ${Screen.phone`
        font-size: 14px;
        padding: 1rem;
        `}
        ${Screen.smallPhone`
        font-size: 14px;
        padding: 1rem;
        `}
        /* font-size: 1.1rem; */

        p {
          padding-top: 3rem;
          ${Screen.mediumpc`
          font-size: 16px;
          `}
          ${Screen.smallpc`
          padding-top: 2rem;
          `}
          ${Screen.surfaceDuo`
          padding-top: 2rem;
          `}
          ${Screen.mediumPhone`
          padding-top: 2rem;
          `}
          ${Screen.phone`
          padding-top: 2rem;
          `}
          ${Screen.smallPhone`
          padding-top: 2rem;
          `}
          ${Screen.galaxyFold`
          padding-top: 0;
          `}
        }
      }
    }
  }
  &:nth-child(2) {
    margin-top: -20rem !important;
    ${Screen.mediumpc`
    margin-top: -15rem!important;
    `}
    ${Screen.smallpc`
    margin-top: -12rem!important;
    `}
    ${Screen.surfaceDuo`
    margin-top: 0!important;
    `}
    ${Screen.mediumPhone`
    margin-top: 0!important;
    `}
    ${Screen.phone`
    margin-top: 0!important;
    `}
    ${Screen.smallPhone`
    margin-top: 0!important;
    `}
    &:hover {
      div {
        &.content {
          p {
            ${Screen.mediumpc`
          padding-top: 2rem;
          `}
            ${Screen.smallpc`
          padding-top: 2rem;
          `}
          ${Screen.galaxyFold`
          padding-top: 0;
          `}
          p {
            ${Screen.tablet`
          padding-top: 2rem;
          `}
            ${Screen.mediumPhone`
          padding-top: 2rem;
          `}
            ${Screen.phone`
          padding-top: 1rem;
          `}
          ${Screen.smallPhone`
          padding-top: 0;
          font-size: 13px;
          `}
          ${Screen.galaxyFold`
          padding-top: 0!important;
          font-size: 13px;
          `}
          }
        }
      }
    }
  }
}
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10rem; */
  position: absolute;
  top: 15rem;
  ${Screen.miniLargeScreen`
  width: 100%;
  position: static;
  margin-top: 1rem!important;
  `}
  ${Screen.pad`
  width: 100%;
  position: static;
  margin-top: 1rem!important;
  `}
  ${Screen.mediumpc`
    position: static;
  margin-top: 5rem;
  width: 100%;
  `}
  ${Screen.smallpc`
    position: static;
  margin-top: 5rem;
  width: 100%;
  `}
  ${Screen.tablet`
  position: static;
  `}
  ${Screen.mediumPhone`
  margin-top: 3rem;
  width: 100%;
  `}
   ${Screen.phone`
  margin-top: 5rem;
  width: 100%;
  `}
  ${Screen.smallPhone`
  margin-top: 3rem;
  width: 100%;
  // position: absolute;
  `}
`;
const Header = styled.h1`
  color: #000a66;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 4rem;
  ${Screen.pad`
  padding-top: 2rem;
  `}

  &.leadership {
    text-align: left;
    color: #c81e19;
  }
  &.story,
  &.learn {
    text-transform: inherit;
    color: #002668;
    text-shadow: 2px 2px 3px #ccc;

    span {
      color: #c81e19;
    }
  }
  &.learn {
    text-align: left;
    text-shadow: none;
    padding-left: 5rem;
    font-size: 1.8rem;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 3rem;
    `}
    ${Screen.pad`
    padding-top: 3rem;
    `}
    ${Screen.mediumpc`
    padding-top: 3rem;
    `}
    ${Screen.smallpc`
    padding-top: 3rem;
    text-align: center;
    padding-left: 0;
    `}
    ${Screen.mediumPhone`
    font-size: 20px;
    padding-top: 3rem;
    padding-left: 0;
    text-align: center;
    `}
    ${Screen.phone`
    font-size: 20px;
    padding-top: 3rem;
    padding-left: 0;
    text-align: center;
    `}
    ${Screen.smallPhone`
    font-size: 20px;
    padding-top: 3rem;
    padding-left: 0;
    text-align: center;
    `}
    ${Screen.galaxyFold`
    font-size: 18px;
    padding-top: 3rem;
    padding-left: 0;
    text-align: center;
    `}
  }
`;
const Section = styled.section`
  width: 100%;

  &.hero {
    min-height: 100vh;
    /* background-image: url(${AboutImg}); */
    background-image: ${({ bg }) => bg};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    ${Screen.pad`
    padding-top: 5rem
    `}
    ${Screen.mediumpc`
    min-height: 40vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.story {
    margin-top: 3rem;
    min-height: 100vh;
    ${Screen.mediumpc`
    min-height: 40vh;
    `}
    ${Screen.smallpc`
    min-height: 20vh;
    `}
    ${Screen.surfaceDuo`
    margin-top: 1rem;
    `}
    ${Screen.mediumPhone`
    min-height: 60vh;
    `}
    ${Screen.phone`
    min-height: 80vh
    `}
    ${Screen.smallPhone`
    min-height: 90vh
    `}
  }
  &.values {
    min-height: 50vh;
    background-image: url(${Arrow});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: 5rem;
    ${Screen.mediumpc`
    padding-top: 1rem!important;
    min-height: 40vh;
    `}
    ${Screen.smallpc`
    padding-top: 1rem!important;
    min-height: 40vh
    `}
    ${Screen.surfaceDuo`
    padding-top: 1rem!important;
    min-height: 50vh;
    margin-bottom: 3rem;
    `}
    ${Screen.mediumPhone`
    padding-top: 1rem!important;
    margin-bottom: 0
    `}
    ${Screen.phone`
    padding-top: 1rem!important;
    `}
    ${Screen.smallPhone`
    padding-top: 1rem!important;
    `}
    ${Screen.surfaceDuo`
    padding-top: 1rem!important;
    margin-bottom: 3rem;
    `}
    /* min-height: 100vh; */
  }

  &.learn {
    min-height: 80vh;
    background-image: url(${AboutGroup});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 10rem!important;
    /* padding-bottom: 20rem; */
    ${Screen.miniLargeScreen`
    position: static;
    margin-bottom: 0!important;
    `}
    ${Screen.pad`
    min-height: 90vh
    `}
    ${Screen.mediumpc`
    min-height: 40vh
    `}
    ${Screen.smallpc`
    min-height: 40vh
    `}
    ${Screen.tablet`
    min-height: 45vh
    `}
    ${Screen.surfaceDuo`
    min-height: 50vh
    `}
    ${Screen.mediumPhone`
    min-height: 50vh
    `}
     ${Screen.phone`
    min-height: 70vh
    `}
    ${Screen.galaxyFold`
    min-height: 75vh
    `}
  }
`;
const BrandContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Screen.pad`
  margin-top: 10rem!important;
  `}
  ${Screen.mediumpc`
  margin-top: 7rem!important;
  `}
  ${Screen.smallpc`
  margin-top: 5rem!important;
  `}
  ${Screen.surfaceDuo`
  flex-direction: column;
  justify-content: center;
  margin-top: 0!important;
  `}
`;
const CardContent = styled.p`
  font-size: 23px !important;
  font-weight: bold;
  color: #002668;
  ${Screen.mediumPhone`
  font-size: 16px!important
  `}
  ${Screen.phone`
  font-size: 16px!important
  `}
  ${Screen.smallPhone`
  font-size: 16px!important
  `}
`;

const Text = styled.h1`
  font-size: 50px;
  font-weight: 900;
  color: #002668;
  margin-top: 5rem;
  align-self: center;
  text-align: center;
  ${Screen.smallpc`
  font-size: 3rem;
  margin-top: 4rem;
  font-weight: 700;
  `}
  ${Screen.mediumPhone`
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 700;
  `}
  ${Screen.phone`
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 700;
  `}
  ${Screen.smallPhone`
  font-size: 2rem;
  margin-top: 4rem;
  font-weight: 700;
  `}
`;

const CardLink = styled(Link)`
  color: #c81e19;
  font-weight: bold;
  font-size: 0.9rem;
  text-decoration: none;
  padding: 2rem 0 3rem;

  ${Screen.pad`
  padding: 1rem 0 2rem;
  `}
  ${Screen.mediumpc`
  padding: 1rem 0 2rem;
  `}
`;

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ListItem = styled.li`
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  color: #002668;
  ${Screen.mediumpc`
  font-size: 16px;
  `}
  ${Screen.smallpc`
  font-size: 16px;
  `}
  ${Screen.mediumPhone`
  font-size: 14px;
  `}
  ${Screen.phone`
  font-size: 14px;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  `}
`;
const FlexBox = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* padding-top: 2rem; */
`;
const Content = styled.div`
  padding-top: 3rem;
  ${Screen.pad`
  padding-top: 2rem;
  `}
  ${Screen.mediumpc`
  padding-top: 2rem;
  `}
  ${Screen.smallpc`
  padding-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  padding-top: 1rem;
  `}
  p {
    font-size: 1.1rem;
  }
  &.story {
    p {
    ${Screen.pad`
    padding-top: 2rem!important;
    font-size: 16px;
    `}
    ${Screen.mediumpc`
    padding-top: 2rem!important;
    font-size: 16px;
    `}
    }
  }
  &.description {
    h2 {
      font-size: 2.5rem;
      font-style: italic;
      color: #002668;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 30px;
      position: absolute;
      left: 13rem;
      top: 8rem
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 10rem;
      top: 7rem
      `}
       ${Screen.mediumPhone`
      font-size: 22px;
      position: absolute;
      left: 8rem;
      top: 5.5rem
      `}
      ${Screen.phone`
      font-size: 20px;
      position: absolute;
      left: 8rem;
      top: 5.5rem
      `}
      ${Screen.smallPhone`
      font-size: 18px;
      position: absolute;
      left: 8rem;
      top: 5.5rem
      `}

      span {
        color: #C81E19;
      }
    }
  }
  &.story-mobile {
    p {
      ${Screen.tablet`
    font-size: 16px!important;
    padding-bottom: 1rem!important;
    `}
    ${Screen.mediumPhone`
    font-size: 16px!important;
    padding-bottom: 1rem!important;
    `}
    ${Screen.phone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    `}
    ${Screen.smallPhone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    `}
    }
  }
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
${Screen.smallpc`
margin-left: 5rem;
`}
${Screen.surfaceDuo`
margin-left: 0;
`}
`;
export default About;
