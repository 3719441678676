import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialImg, WayShort, ShortMobile, OvalMobile } from '../../assets/img';
import { Grey, Blue } from '../../assets/svg';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { quickDials } from '../../components/main';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Dial = () => {
  const [mobile, setMobile] = useState(false)

  const responsive = () => {
    return window.innerWidth <= 900 ? setMobile(true) : setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'>
        <h1>*5103#</h1>
      </Section>
      {/* End of Hero Section */}

      {/* Description Section */}
      <Section className='description'>
        <Container>
          {
            mobile ? (
              <>
              <FlexBox>
            <ImageWrapper>
              <Image src={DialImg} alt='grey' className='dial' />
            </ImageWrapper>
            <ContentContainer>
              <p>
                Open an account, transfer funds, pay bills, buy airtime or data,
                and do much more by simply dialling *5103# from your mobile
                phone without data or internet connection.
              </p>
              <p>
                It is fast, convenient, and easy to use. This service offers a
                safe, reliable, and smooth banking experience to all our retail
                customers.
              </p>
              <p>
            Registration is self-service, just dial *5103# today to gets
            started.
          </p> 
            </ContentContainer>
          </FlexBox>
          <Content>
          <FlexBox className='list-mobile'>
            <MobileHeader>
                  <Image src={OvalMobile} alt='oval' className='mobile' />
                  <Content className='description'>
                    <h2>*5103# <br />Quick Dials</h2>
                  </Content>
            </MobileHeader>
            <p>Perform transactions with ease with these short code strings.</p>
          </FlexBox>
          </Content>
              <QuickContainer className='mobile'>
                  <ul>
                    {quickDials.map(({ title, code }) => (
                      <ListItem key={title}>
                        <li>{title}</li>
                        <li style={{ fontWeight: 'bold' }}>{code}</li>
                      </ListItem>     
                    ))}
                  </ul>
              </QuickContainer>
              </>
            )
            : (
              <>
          <FlexBox>
            <ImageWrapper>
              <Image src={DialImg} alt='grey' className='dial' />
            </ImageWrapper>
            <ContentContainer>
              <p>
                Open an account, transfer funds, pay bills, buy airtime or data,
                and do much more by simply dialling *5103# from your mobile
                phone without data or internet connection.
              </p>
              <p>
                It is fast, convenient, and easy to use. This service offers a
                safe, reliable, and smooth banking experience to all our retail
                customers.
              </p>
            </ContentContainer>
          </FlexBox>
          <p style={{ paddingTop: '2rem' }}>
            Registration is self-service, just dial *5103# today to gets
            started.
          </p> 
          <Content>
            <Header>*5103# Quick Dials</Header>
            <p>Perform transactions with ease with these short code strings.</p>
            <FlexBox className='list'>
              <ImageWrapper className='list'>
                <Image src={Grey} alt='grey' className='grey' />
                <Image src={Blue} alt='blue' className='blue' />
              </ImageWrapper>
              <QuickContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ul>
                    {quickDials.map(({ title }) => (
                      <li key={title}>{title}</li>
                    ))}
                  </ul>
                  <ul>
                    {quickDials.map(({ code }) => (
                      <li key={code} style={{ fontWeight: 'bold' }}>
                        {code}
                      </li>
                    ))}
                  </ul>
                </div>
              </QuickContainer>
            </FlexBox>
          </Content>
              </>
              
            )
          }
        </Container>
      </Section>
      {/* End of description section */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 75%;
  &.hero {
    margin: 0 auto;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 7rem;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
  }
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 80%!important;
  margin: 0 auto!important;
  `}
   ${Screen.galaxyFold`
  width: 90%!important;
  margin: 0 auto!important;
  `}
`;
const Content = styled.div`
  margin-top: 5rem;
  ${Screen.smallpc`
  margin-top: 7rem
  `}
  ${Screen.smallPhone`
  margin-top: 4rem
  `}
  p {
    text-align: center;
    padding-top: 1rem;
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }
  &.description {
    h2 {
      font-size: 2.5rem;
      font-style: italic;
      color: #c81e19;
      ${Screen.mediumpc`
      font-size: 30px;
      `}
      ${Screen.smallpc`
      font-size: 24px;
      position: absolute;
      left: 28rem;
      top: 7rem
      `}
      ${Screen.tablet`
      font-size: 24px;
      position: absolute;
      left: 27rem;
      top: 7rem
      `}
       ${Screen.surfaceDuo`
      font-size: 24px;
      position: absolute;
      left: 10rem;
      top: 5rem
      `}
       ${Screen.mediumPhone`
      font-size: 24px;
      position: absolute;
      left: 7rem;
      top: 3rem
      `}
      ${Screen.phone`
      font-size: 24px;
      position: absolute;
      left: 7rem;
      top: 3rem
      `}
      ${Screen.smallPhone`
      font-size: 20px;
      position: absolute;
      left: 7rem;
      top: 3rem
      `}
    }
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5rem;
  ${Screen.pad`
  width: 50%;
  padding-top: 0;
  `}
  ${Screen.mediumpc`
  width: 50%;
  padding-top: 0;
  `}
  ${Screen.smallpc`
  width: 50%;
  padding-top: 0;
  `}
   ${Screen.surfaceDuo`
  width: 100%;
  padding-top: 1rem
  `}
  ${Screen.mediumPhone`
  width: 100%;
  padding-top: 1rem
  `}
  ${Screen.phone`
  padding-top: 1rem
  `}
  ${Screen.smallPhone`
  padding-top: 1rem
  `}
  p {
    font-size: 1.1rem;
    ${Screen.mediumpc`
    font-size: 1rem;
    `}
    ${Screen.smallpc`
    font-size: .9rem;
    `}
    ${Screen.surfaceDuo`
    font-size: .9rem;
    padding-top: 1rem;
    `}
    ${Screen.mediumPhone`
    font-size: 16px;
    padding-top: 1rem;
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-top: 1rem;
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-top: 1rem;
    `}
  }
`;
const Header = styled.h2`
  color: #c81e19;
  font-size: 2rem;
  text-align: center;
`;
const Image = styled.img`
&.dial {
  ${Screen.pad`
    width: 100%;
    height: 12rem;
  `}
  ${Screen.mediumpc`
    width: 100%;
    height: 12rem;
  `}
  ${Screen.smallpc`
    width: 100%;
    height: 12rem;
  `}
   ${Screen.mediumPhone`
    width: 100%;
    height: 10rem;
  `}
  ${Screen.phone`
    width: 100%;
    height: 8rem;
  `}
}

  &.grey {
    width: 15rem;
    height: 15rem;
    position: absolute;
    z-index: 1;
    ${Screen.pad`
    width: 10rem;
    height: 8rem;
    display: none
    `}
    ${Screen.mediumpc`
    width: 10rem;
    height: 8rem;
    display: none
    `}

    /* top: -1rem; */
  }
  &.blue {
    width: 17rem;
    height: 17rem;
    position: absolute;
    ${Screen.pad`
    width: 7rem;
    height: 15rem;
    display: none
    `}
    ${Screen.mediumpc`
    width: 7rem;
    height: 15rem;
    display: none
    `}
    /* bottom: -3rem; */
    /* top: 1rem; */
  }
    &.mobile {
    ${Screen.smallpc`
    width: 250px;
    height:255px
    `}
     ${Screen.surfaceDuo`
    width: 200px;
    height: 205px
    `}
    ${Screen.mediumPhone`
    width: 150px;
    height: 155px
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  min-height: 50vh;
  &.hero {
    background-image: url(${WayShort});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-right: 5rem;
    padding-top: 10rem;
    /* ${Screen.miniLargeScreen`
    padding-top: 7rem
    `} */
    ${Screen.pad`
    padding-top: 7rem
    `}
    ${Screen.mediumpc`
    min-height: 20vh!important;
    padding-top: 7rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    h1 {
      text-align: right;
      text-transform: uppercase;
      font-size: 40px;
      color: #002668;
      ${Screen.surfaceDuo`
      font-size: 30px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.phone`
      font-size: 24px;
      text-align: center;
      `}
      ${Screen.smallPhone`
      font-size: 22px;
      text-align: center;
      `}
    }
    /* padding-top: 12rem; */
  }

  &.description {
    min-height: 120vh;
    margin-top: -3rem;

    ${Screen.miniLargeScreen`
    margin-top: 7rem;
    min-height: 100vh;
    `}
     ${Screen.pad`
    margin-top: 7rem;
    min-height: 110vh;
    `}
     ${Screen.mediumpc`
    margin-top: 7rem;
    min-height: 50vh;
    `}
    ${Screen.smallpc`
    margin-top: 7rem;
    min-height: 50vh;
    `}
    ${Screen.surfaceDuo`
    margin-top: 5rem;
    min-height: 100vh;
    `}
    ${Screen.mediumPhone`
    margin-top: 4rem;
    min-height: 100vh;
    `}
    ${Screen.phone`
    margin-top: 3rem;
    min-height: 100vh;
    `}
    /* margin-bottom: 5rem; */
  }
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  ${Screen.surfaceDuo`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `}
  .list {
    justify-content: center !important;
    align-items: center !important;
    margin-top: 3rem;
  }
  &.list-mobile {
    ${Screen.smallpc`
    display: flex;
    flex-direction: column;
    justify-content: space-between!important;
    `}
  }

`;

const ImageWrapper = styled.div`
  margin-right: 3rem;
   ${Screen.pad`
  width: 50%;
  margin-right: 2rem;
  `}
  ${Screen.mediumpc`
  width: 50%;
  margin-right: 2rem;
  `}
  ${Screen.smallpc`
  width: 50%;
  margin-right: 2rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  margin-right: 0;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  margin-right: 0;
  `}

  .list {
    width: 30%;
    margin-right: 0;
    /* right: -10rem; */
    position: relative;
    ${Screen.pad`
    width: 0!important;
    display: none;
    `}
    ${Screen.mediumpc`
    width: 0!important;
    display: none;
    `}
  }
`;
const QuickContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  margin-left: 0;
  width: 70%;
  ${Screen.pad`
  width: 100%!important;
  justify-content: flex-start;
  margin-right: 15rem!important;
  padding-top: 2rem;
  `}
  ${Screen.mediumpc`
  width: 100%!important;
  justify-content: flex-start;
  margin-right: 15rem!important;
  padding-top: 2rem;
  `}
  ${Screen.smallpc`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  margin-right: 0!important
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  margin-left: -.5rem
  `}

  div {
    ${Screen.mediumpc`
    justify-content: flex-start;
    `}
  }

  ul {
    list-style: none;
    padding-right: 3rem;
    ${Screen.pad`
    padding-right: 2rem;
    width: 50%;
    `}
    ${Screen.mediumpc`
    padding-right: 2rem;
    width: 50%;
    `}
    ${Screen.smallpc`
    padding-right: 0;
    width: 100%;
    `}
    ${Screen.surfaceDuo`
    padding-right: 0;
    width: 100%;
    `}
    ${Screen.mediumPhone`
    padding-right: 0!important;
    `}

    /* li {
      ${Screen.mediumpc`
      padding-top: 5px;
      `}
    } */
  }
`;
const MobileHeader = styled.div`
width: 100%;
position: relative;
display:flex;
${Screen.smallpc`
justify-content: center;
align-items: center;
margin-left: -5rem;
`}
/* ${Screen.surfaceDuo`
justify-content: center;
align-items: center;
`} */
${Screen.surfaceDuo`
justify-content: unset;
align-items: unset;
margin-left: 0!important
`}
`;
const ListItem = styled.div`
padding-top: .5rem;
li {
  ${Screen.mediumpc`
  font-size: 14px;
  `}
  ${Screen.smallpc`
  font-size: 16px;
  text-align: center;
  `}
  ${Screen.surfaceDuo`
  font-size: 16px;
  text-align: center;
  `}
  ${Screen.mediumPhone`
  font-size: 14px;
  text-align: center;
  `}
  ${Screen.phone`
  font-size: 13px;
  text-align: center;
  `}
  ${Screen.smallPhone`
  font-size: 11px;
  text-align: center;
  `}
  ${Screen.galaxyFold`
  font-size: 10px;
  text-align: center;
  `}
}
`;
export default Dial;
