import Investor from './investor';
import Corporate from './corporate';
import Financial from './financial';
import Forms from './forms';
import Events from './events';
import Meeting from './meeting';
import Egm from './egm'
import Timer from './timer';

const port = {
  Investor,
  Corporate,
  Financial,
  Forms,
  Events,
  Meeting,
  Egm,
  Timer
};

export default port;
