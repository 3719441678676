import styled from 'styled-components';
import { NavButton } from '../../../styles';

const StepOneExt = ({ next, prev, values, handleChange }) => {
  const validation =
    values.account === '' ||
    values.accountType === '' ||
    values.bvn === '' ||
    values.surname === '' ||
    values.firstname === '' ||
    values.middlename === '' ||
    values.dob === '' ||
    values.mobile === '' ||
    values.email === '' ||
    values.mobile.length < 11;
  return (
    <FormContainer>
      <FormHeader>Step 1 0f 6: Choose an Account</FormHeader>
      <Form autoComplete='off'>
        <FlexBox>
          <label>Who is this account for?</label>
          <Input>
            <input
              type={'text'}
              name='account'
              value={values.account}
              disabled
              readOnly
            />
          </Input>
        </FlexBox>
        <FlexBox mt='1rem'>
          <label>Which type of account do you wish to open?</label>
          <Input>
            <input
              type={'text'}
              name='accountType'
              value={values.accountType}
              disabled
              readOnly
            />
          </Input>
        </FlexBox>

        {/* Bvn validation */}
        <FormHeader pt='5rem'>Step 1: BVN Validation</FormHeader>
        <FlexBox mt='3rem'>
          <label>Bvn</label>
          <Input>
            <input
              type={'text'}
              name='bvn'
              placeholder='Please input 0 if you do not have BVN'
              onChange={handleChange('bvn')}
              value={values.bvn}
              disabled={values.bvn !== '0' && true}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='3rem'>
          <label>Surname</label>
          <Input>
            <input
              type={'text'}
              name='surname'
              placeholder='Please enter surname'
              onChange={handleChange('surname')}
              value={values.surname}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Firstname</label>
          <Input>
            <input
              type={'text'}
              name='firstname'
              placeholder='Please enter firstname'
              onChange={handleChange('firstname')}
              value={values.firstname}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Middle Name</label>
          <Input>
            <input
              type={'text'}
              name='middlename'
              placeholder='Please enter middle name'
              onChange={handleChange('middlename')}
              value={values.middlename}
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Date of Birth</label>
          <Input>
            <input
              type={'date'}
              name='dob'
              placeholder='dd-mm-yyyy'
              onChange={handleChange('dob')}
              value={values.dob}
              disabled
              readOnly
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Mobile Number</label>
          <Input>
            <input
              type={'text'}
              name='mobile'
              placeholder='Please enter mobile number'
              value={values.mobile}
              disabled
              readOnly
            />
          </Input>
        </FlexBox>
        <FlexBox mt='2rem'>
          <label>Email Address</label>
          <Input>
            <input
              type={'email'}
              name='email'
              placeholder='Please enter email address here'
              onChange={handleChange('email')}
              value={values.email}
            />
          </Input>
        </FlexBox>
        <ButtonWrapper>
          <PrevButton onClick={prev}>Back</PrevButton>
          <Button onClick={next} className={validation && 'disabled'}>
            Next
          </Button>
        </ButtonWrapper>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #002668;
  margin: 3rem 0;
  padding: 3rem;

  label {
    padding-top: 0.5rem;
  }
`;
const FormHeader = styled.h4`
  color: #002668;
  padding-top: ${({ pt }) => pt};
`;
const Form = styled.form`
  margin-top: 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ mt }) => mt};
`;
// const Select = styled.div`
//   width: 50%;
//   height: 2.5rem;
//   border-radius: 5px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

//   ${Decorate.select};
//   .select-css {
//     width: 100%;
//     height: 100%;
//     border: none;
//     margin-top: 0.5rem;
//     color: #000 !important;
//     font-weight: normal;
//   }
// `;
const Input = styled.div`
  width: 50%;
  height: 2.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 1rem; */
  box-shadow: inset 10px 10px 8px #d9d9d9, inset -10px -10px 8px #d9d9d9;

  input {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 0.5rem;
    color: #000 !important;
    font-weight: normal;
    background-color: #f5f5f5;
    outline: none;
    padding-left: 1rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const Button = styled(NavButton)`
  width: 15rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  &.disabled {
    background-color: #ccc !important;
    color: #fff;
    pointer-events: none;
  }
`;
const PrevButton = styled(Button)`
  background-color: white;
  border: 1px solid #002668;
  color: #002668;
  text-transform: uppercase;
`;
export default StepOneExt;
