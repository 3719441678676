import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { EgmDesktop } from '../../assets/img';
import { Screen } from '../../styles';
import { HashLink } from 'react-router-hash-link';

const Timer = ({ meetingLinks }) => {
    const countDownDate = new Date("Jan 24 2025").getTime()
    const [countDown, setCountDown] = useState();
    const [numberInDays, setNumberInDays] = useState("")
    const mounted = useRef()

      useEffect(() => {
        mounted.current = true
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime())
        }, 1000)

        setNumberInDays(Math.floor(countDown / (1000 * 60 * 60 * 24)) + 1)

        return () => {
            clearInterval(interval)
            mounted.current = false
        }
      }, [countDown])

  return (
    <Box>
        <Image src={EgmDesktop} alt='egm' />
        <CountDownContainer className='counter'>
            {numberInDays > 1 && `${numberInDays} days to go`}
            {numberInDays === 1 && `A day to go`}
            {numberInDays === 0 && "We're Live! Click here to join us on Zoom"}
            {numberInDays < 0 && ""}
        </CountDownContainer>
        <div style={{ marginTop: '20px'}}>
        {
          numberInDays >= 0 && (
            <ButtonWrapper>
            <MeetingLink to={meetingLinks[0]?.link} target='_blank' rel='noreferer' className={numberInDays < 0 || numberInDays >= 1 ? 'disabled' : undefined}>
            <CountDownContainer className={numberInDays < 0 || numberInDays >= 1 ? 'disabled' : 'button'}>Join on Zoom here</CountDownContainer>
            </MeetingLink>
            <MeetingLink to={meetingLinks[1]?.link} target='_blank' rel='noreferer' className={numberInDays < 0 || numberInDays >= 1 ? 'disabled' : undefined}>
              <CountDownContainer className={numberInDays < 0 || numberInDays >= 1 ? 'disabled' : 'button'}>Join on Youtube here</CountDownContainer>
            </MeetingLink>
            </ButtonWrapper>
          )
        }
        </div>
    </Box>
  )
}

const Box = styled.div`
width: 100%;

${Screen.tablet`
margin: 5rem auto;
width: 80%;
`}
${Screen.mediumPhone`
width: 100%;
margin-top: 3rem
`}
`
const CountDownContainer = styled.div`
width: 100%;
padding: 1rem;
background-color: #002668;
text-transform: uppercase;
font-size: 30px;
color: #fff;
text-align: center;
font-weight: 600;
margin-top: -4px;
cursor: pointer;

&.button {
  width: 40%;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  background-color: #002668;
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;  
  text-align: center;
  font-weight: 600;
  margin-top: -4px;
  cursor: pointer;
}

&:hover {
  background-color: #0d3375;
}

&.counter {
  pointer-events: none;
}

&.disabled {
  pointer-events: none!important;
  background-color: #DBE8FE;
  &:hover {
    background-color: #DBE8FE;
  }
}

${Screen.mediumPhone`
font-size: 20px;
`}
`;

const Image = styled.img`
width: 100%;
margin-bottom: 0;
`;

const MeetingLink = styled(HashLink)`
text-decoration: none;

&.disabled {
  pointer-events: none!important;
}
`;

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
gap: 20px;
justify-content: center;
`

export default Timer
