import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CurrentBg, CurrentMobile } from '../../assets/img';
import { PersonalForm } from '../../components';
// import { Footer } from '../../components/footer';
import { Screen } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const StepOne = PersonalForm.StepOne;
const StepOneExt = PersonalForm.StepOneExt;
const StepTwo = PersonalForm.StepTwo;
const StepThree = PersonalForm.StepThree;
const StepFour = PersonalForm.StepFour;
const StepFive = PersonalForm.StepFive;
const StepSix = PersonalForm.StepSix;
const Personal = () => {
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({
    account: '',
    accountType: '',
    bvn: '',
    dob: '',
    mobile: '',
    surname: '',
    firstname: '',
    middlename: '',
    marital: '',
    email: '',
    branch: '',
    maiden: '',
    gender: '',
    origin: '',
    lga: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    area: '',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCountry: '',
    mailingState: '',
    mailingArea: '',
    home: '',
    education: '',
    school: '',
    ways: [],
    nextOfKin: '',
    relationship: '',
    kinMobile: '',
    kinAddress1: '',
    kinAddress2: '',
    kinCountry: '',
    kinState: '',
    kinArea: '',
  });

  const {
    account,
    accountType,
    bvn,
    dob,
    mobile,
    surname,
    firstname,
    middlename,
    marital,
    email,
    branch,
    maiden,
    gender,
    origin,
    lga,
    address1,
    address2,
    country,
    state,
    area,
    mailingAddress1,
    mailingAddress2,
    mailingCountry,
    mailingState,
    mailingArea,
    home,
    education,
    school,
    ways,
    nextOfKin,
    relationship,
    kinMobile,
    kinAddress1,
    kinAddress2,
    kinCountry,
    kinState,
    kinArea,
  } = user;
  const values = {
    account,
    accountType,
    bvn,
    dob,
    mobile,
    surname,
    firstname,
    middlename,
    marital,
    email,
    branch,
    maiden,
    gender,
    origin,
    lga,
    address1,
    address2,
    country,
    state,
    area,
    mailingAddress1,
    mailingAddress2,
    mailingCountry,
    mailingState,
    mailingArea,
    home,
    education,
    school,
    ways,
    nextOfKin,
    relationship,
    kinMobile,
    kinAddress1,
    kinAddress2,
    kinCountry,
    kinState,
    kinArea,
  };
  const nextStep = (e) => {
    e.preventDefault();
    setStep((prevState) => prevState + 1);
  };
  const prevStep = (e) => {
    e.preventDefault();
    setStep((prevState) => prevState - 1);
  };
  const handleChange = (input) => (e) => { 
    setUser({
      ...user,
      [input]: e.target.value,
    });
  };

  // const handleBvn = (input) => e => {
  //     const red = /^[0-9\b]{1,11}$/;
  //     setUser({
  //       ...user,
  //       [input]: red.test(e.target.value)
  //     })
  // }

  const renderStepForm = () => {
    switch (step) {
      case 0:
        return (
          <StepOne
            next={nextStep}
            values={values}
            handleChange={handleChange}
            // handleBvn={handleBvn}
          />
        );
      case 1:
        return (
          <StepOneExt
            next={nextStep}
            prev={prevStep}
            values={values}
            handleChange={handleChange}
          />
        );
      case 2:
        return (
          <StepTwo
            next={nextStep}
            prev={prevStep}
            values={values}
            handleChange={handleChange}
          />
        );
      case 3:
        return (
          <StepThree
            next={nextStep}
            prev={prevStep}
            values={values}
            handleChange={handleChange}
          />
        );
      case 4:
        return (
          <StepFour
            next={nextStep}
            prev={prevStep}
            handleChange={handleChange}
            values={values}
          />
        );
      case 5:
        return (
          <StepFive
            next={nextStep}
            prev={prevStep}
            handleChange={handleChange}
            values={values}
          />
        );
      case 6:
        return (
          <StepSix
            next={nextStep}
            prev={prevStep}
            handleChange={handleChange}
            values={values}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}

      <Section className='hero'></Section>
      {/* Form */}
      <Section>
        <Container>
          {/* <Header>
            Open an Abbey <span>Personal</span> Account
          </Header> */}
          
          <Paragraph>
          {/* To open an account, Contact us at <br /> 
          <a href='mailto:customerexperience@abbeymortgagebank.com'>customerexperience@abbeymortgagebank.com</a> */}
          Coming Soon...
          </Paragraph>
          {/* <Text>Coming Soon...</Text> */}
          {/* {renderStepForm()} */}
        </Container>
      </Section>
      {/* End of Form */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  &.hero {
  min-height: 70vh;
  background: url(${CurrentBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
      ${Screen.tablet`
    min-height: 30vh!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 80%;
  margin: 5rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 3rem auto;
  word-break: break-all
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  word-break: break-all
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 5rem auto;
  word-break: break-all
  `}
  ${Screen.smallpc`
  width: 70%;
  margin: 5rem auto!important;
  word-break: break-all
  `}
  ${Screen.tablet`
  width: 70%;
  margin: 5rem auto!important;
  word-break: break-all
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 3rem auto!important;
  word-break: break-all
  `}
  ${Screen.mediumPhone`
  width: 90%;
  margin: 3rem auto!important;
  word-break: break-all
  `}
  ${Screen.phone`
  width: 90%;
  margin: 3rem auto!important;
  word-break: break-all
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 3rem auto!important;
  word-break: break-all
  `}
  ${Screen.galaxyFold`
  width: 95%;
  margin: 3rem auto!important;
  word-break: break-all
  `}

`;
const Header = styled.h2`
  font-size: 2rem;
  color: #002668;
  text-align: center;

  span {
    color: #c81e19;
  }
`;
const Paragraph = styled.p`
  // color: #9a9494;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding-bottom: 3rem;
  // font-size: 1.1rem;
  font-size: 1.5rem;
  margin-top: 3rem;

  a {
    color: #0867BE;
    padding-top: 1rem
  }

  ${Screen.smallpc`
  margin-top: 5rem!important;
  `}
  ${Screen.tablet`
  font-size: 1.1rem;
  `}
  ${Screen.mediumPhone`
  font-size: .9rem;
  `}
  ${Screen.phone`
  font-size: .9rem;
  `}
  ${Screen.smallPhone`
  font-size: .8rem;
  `}
  ${Screen.galaxyFold`
  font-size: .7rem;
  `}
`;
const Text = styled.h1`
text-align: center;
padding-top: 10rem;
`

export default Personal;
