import { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Download } from '../../assets/svg';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { Documents } from '../../utils';
// import { financial } from '../../components/main/products'
import HelpersComponent from '../helpers';
import { Screen } from '../../styles';
import { FinanicalContext } from '../../contexts/doc';
import { DOC_FAILURE, DOC_REQUEST, DOC_SUCCESS } from '../../constants';
import api from '../../services/api';

const Loader  = HelpersComponent.componentLoader
const ComponentLoader  = HelpersComponent.dashboardLoader
const Financial = () => {
  let mounted = useRef(false)
  const { dispatch, state } = useContext(FinanicalContext)
  useEffect(() => {
    mounted.current = true
    //get financial doc
    const getFinancial = async () => {
      try {
        if(state.data.length < 1){
          dispatch({ type: DOC_REQUEST })
          const result = await api.req('/api/document/relations/financial')
          dispatch({
            type: DOC_SUCCESS,
            payload: JSON.parse(result.details.data)
          })
        }
      } catch (error) {
        dispatch({
          type: DOC_FAILURE,
          payload: error.response && error.response.result.responseMessage 
          ? error.response.result.responseMessage : error.responseMessage
        }) 
      }
    }
    getFinancial()

    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <Box>
      <Header>Financial Information</Header>
      <Container>
        {
          state.loading ? <ComponentLoader /> : (
            <>
            {
            state?.data?.length > 0 && state.data.slice(-10).reverse().map((item, i) => (
              <div key={i}>
                <FinancialCards item={item} />
    
              </div>
            ))
          }
            </>
          )
        }
      </Container>
    </Box>
  );
};

const FinancialCards = ({ item }) => {
  const [loading, setLoading] = useState(false)
  const downloadDocs = async (filename) => {
    setLoading(true);
    const data = await Documents.downloadDocuments(filename);
    const blob = b64toBlob(data, "application/zip");
    saveAs(blob, 'document.zip');
    setLoading(false);
  }
  return (
    <Card>
        <Content>
          <p>{item.title}</p>
        </Content>
        <Icon onClick={() => downloadDocs(item.link)} content={!loading ? `url(${Download})` : null}>{loading && <Loader color={'#fff'} />}</Icon>
      </Card>
  )
}
const Box = styled.div`
  width: 100%;
`;
const Header = styled.h2`
  color: #002668;
  font-size: 2rem;
  ${Screen.pad`
  font-size: 24px;
  `}
  ${Screen.mediumpc`
  font-size: 24px;
  `}
  ${Screen.smallpc`
  font-size: 24px;
  `}
   ${Screen.mediumPhone`
  font-size: 24px;
  padding-left: 1rem;
  `}
  ${Screen.mediumPhone`
  font-size: 18px;ContentContainer
  padding-left: 1rem;
  `}
  ${Screen.phone`
  font-size: 18px;
  padding-left: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 18px;
  padding-left: 1rem;
  `}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  ${Screen.smallpc`
  width: 90%;
  margin: 1rem 1rem;
  `}

  ${Screen.surfaceDuo`
  width: 90%;
  margin: 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  ${Screen.phone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  ${Screen.smallPhone`
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  `}
  /* justify-content: space-between; */
`;
const Card = styled.div`
  width: 80%;
  height: 4rem;
  background-color: #f5f5f5;
  display: flex;
  margin-top: 2rem;
  ${Screen.mediumpc`
  width: 90%;
  margin-top: 1rem;
  `}
  ${Screen.smallpc`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.phone`
  width: 100%;
  margin-top: 1rem;
  `}
  ${Screen.smallPhone`
  width: 100%;
  margin-top: 1rem;
  `}
`;
const Content = styled.div`
  width: 92%;
  height: 100%;
  padding-top: 1.5rem;
  padding-left: 2rem;
  ${Screen.surfaceDuo`
  width: 90%;
  padding: 1rem;
  `}
  ${Screen.mediumPhone`
  width: 87%;
  padding: 1rem;
  `}
   ${Screen.phone`
  width: 87%;
  padding: 1rem;
  `}
  ${Screen.smallPhone`
  width: 85%;
  padding: 1rem;
  `}

  p {
    ${Screen.mediumPhone`
    font-size: 14px;
    `}
    ${Screen.phone`
    font-size: 14px;
    `}
    ${Screen.smallPhone`
    font-size: 13px;
    `}
  }
`;
const Icon = styled.div`
  width: 8%;
  height: 100%;
  background-color: #002668;
  /* content: url(${Download}); */
  content: ${({ content }) => content};
  cursor: pointer;
  padding: 0.5rem;
  ${Screen.surfaceDuo`
  width: 10%;
  `}
  ${Screen.mediumPhone`
  width: 13%;
  `}
   ${Screen.phone`
  width: 13%;
  `}
  ${Screen.smallPhone`
  width: 15%;
  `}

  &:hover {
    box-shadow: 5px 0px 20px rgb(199, 199, 199, 0.9);
  }
`;
export default Financial;
