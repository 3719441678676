import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, Beejay, BeejayImg, InvestorMobile } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Mobolaji = () => {
  const [mobile, setMobile] = useState(false);

  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
           mobile ? (
            <FlexBox>
              <div>
              <Header>Mobolaji Adewumi</Header>
              <Title>MD/CEO</Title>
              </div>
              <Image src={Beejay} alt='mobolaji' />
              <Paragraph>
              <p>
                Mobolaji Adewumi is the Managing Director/CEO of Abbey Mortgage Bank. He is an 
                experienced professional with a deep knowledge of the financial services industry. 
                He possesses an extensive working knowledge of sub-Saharan Africa. He obtained his MBA 
                (specializing in Finance) from Judge Business School, University of Cambridge, UK, and 
                his BSc in Management and Accounting from the Obafemi Awolowo University, Ile-Ife.
              </p>
              <p>
                Bolaji became a Chartered Accountant (ACCA) in 2007. He worked for KPMG as Audit Senior
                between 2006 to 2009 before joining UBA in 2009. He worked in various capacities at the 
                bank before leaving in 2011.
              </p>
              <p>
            Mr. Adewumi also worked for Africa Finance Corporation, Egon Zehnder 
            International London Office, and Fairtrade International before joining Aso 
            Savings and Loan Plc. At Aso Savings, he was the Special Adviser to the MD/CEO 
            & Head, Strategy between 2012 to 2015. Bolaji Adewumi then moved to First Bank 
            of Nigeria Limited, where he was Special Assistant to the MD/CEO between 2016 
            to 2019. He later joined VFD as the Group Executive Director, Finance from April
            2019 to March 2020. In March 2020, Bolaji Joined Abbey Mortgage Bank as 
            Executive Director Finance & Administration a position he held until he was 
            appointed the Managing Director/CEO in April 2022. Mr. Adewumi is also a fellow 
            of the Archbishop Tutu Fellowship program.
            </p>
              </Paragraph>
            </FlexBox>
           ): (
            <>
          <FlexBox>
            <Image src={Beejay} alt='mobolaji' />
            <Content>
              <Header>Mobolaji Adewumi</Header>
              <Title>MD/CEO</Title>
              <p style={{ paddingTop: '2rem'}}>
                Mobolaji Adewumi is the Managing Director/CEO of Abbey Mortgage Bank. He is an 
                experienced professional with a deep knowledge of the financial services industry. 
                He possesses an extensive working knowledge of sub-Saharan Africa. He obtained his MBA 
                (specializing in Finance) from Judge Business School, University of Cambridge, UK, and 
                his BSc in Management and Accounting from the Obafemi Awolowo University, Ile-Ife.
              </p>
              <p style={{ paddingTop: '2rem'}}>
                Bolaji became a Chartered Accountant (ACCA) in 2007. He worked for KPMG as Audit Senior
                between 2006 to 2009 before joining UBA in 2009. He worked in various capacities at the 
                bank before leaving in 2011.
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
            <p>
            Mr. Adewumi also worked for Africa Finance Corporation, Egon Zehnder 
            International London Office, and Fairtrade International before joining Aso 
            Savings and Loan Plc. At Aso Savings, he was the Special Adviser to the MD/CEO 
            & Head, Strategy between 2012 to 2015. Bolaji Adewumi then moved to First Bank 
            of Nigeria Limited, where he was Special Assistant to the MD/CEO between 2016 
            to 2019. He later joined VFD as the Group Executive Director, Finance from April
            2019 to March 2020. In March 2020, Bolaji Joined Abbey Mortgage Bank as 
            Executive Director Finance & Administration a position he held until he was 
            appointed the Managing Director/CEO in April 2022. Mr. Adewumi is also a fellow 
            of the Archbishop Tutu Fellowship program.
            </p>
          </Content>
            </>
           ) 
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 60vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;

${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;

  ${Screen.miniLargeScreen`
  padding-top: 0!important;
  `}

  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 22px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }

  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}
`;
export default Mobolaji;
