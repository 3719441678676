import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile, Member3 } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { profileBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Lolita = () => {

  const [mobile, setMobile] = useState(false);
  const responsive = () => {
    window.innerWidth <= 900 ? setMobile(true): setMobile(false)
  }
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
    
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      <BreadCrumb>
        {profileBreadcrumb.map(({ name, path }) => (
          <>
            <Links to={path}>{name}</Links> <span>/</span>
          </>
        ))}
      </BreadCrumb>

      {/* Profile section */}
      <Section>
        <Container>
          {
            mobile ? (
              <FlexBox>
                <div>
                <Header>Lolita Ejiofor</Header>
              <Title>GM/GROUP HEAD, IT & OPERATIONS</Title>
                </div>
                <Image src={Member3} alt='lolita' />
                <Paragraph>
                <p>
              Lolita Ejiofor joined Abbey Mortgage Bank Plc in 2013 
              as the Assistant General Manager – Business Systems 
              Review and Compliance where her core focus was IT based 
              process re-engineering and continuous improvements.
              <ul>
                <li>MBA – HULT International Business School</li>
                <li>MSc – Information Systems and Finance – University of 
                  Westminster, London UK
              </li>
              <li>BSc – Business Administration – Huron University USA</li>
              </ul>   
              </p>
              <p>
            An Honorary Member of the Chartered Institute of Banking, 
            Nigeria and ISO 27001 (Information Security) Lead Implementer, 
            with over 24 years of PRINCEII certified IT.
            </p>
            <p>
            Prior to her return to the UK, she worked for 5 years at SITA 
            inc, delivering tech solutions for air transportation and travel 
            experience in Europe. This was followed by 7 years of experience at Fujitsu
            Services Ltd focusing on UK government projects, National Rail transportation 
            network and infrastructure solutions, with high level security clearance of operations.
            </p>
            <p>
            In 2020, she became the General Manager, Group Head of IT & Operations, responsible for 
            driving the bank’s digital transformation, banking operations and customer experience.
            </p>
            <p>
            She continues to align the bank’s critical technology driven processes and tools with the 
            objectives to enhance efficiency, security, and growth.
            </p>
                </Paragraph>
              </FlexBox>
            ):(
              <>   
          <FlexBox>
            <Image src={Member3} alt='lolita' />
            <Content>
              <Header>Lolita Ejiofor</Header>
              <Title>GM/GROUP HEAD, IT & OPERATIONS</Title>
              <p style={{ paddingTop: '2rem'}}>
              Lolita Ejiofor joined Abbey Mortgage Bank Plc in 2013 
              as the Assistant General Manager – Business Systems 
              Review and Compliance where her core focus was IT based 
              process re-engineering and continuous improvements.
              <ul style={{ listStyleType: 'none', marginTop: '2rem'}}>
                <li>MBA – HULT International Business School</li>
                <li>MSc – Information Systems and Finance – University of 
                  Westminster, London UK
              </li>
              <li>BSc – Business Administration – Huron University USA</li>
              </ul>   
              </p>
            </Content>
          </FlexBox>
          <Content className='more'>
            <p>
            An Honorary Member of the Chartered Institute of Banking, 
            Nigeria and ISO 27001 (Information Security) Lead Implementer, 
            with over 24 years of PRINCEII certified IT.
            </p>
            <p>
            Prior to her return to the UK, she worked for 5 years at SITA 
            inc, delivering tech solutions for air transportation and travel 
            experience in Europe.<br /> This was followed by 7 years of experience at Fujitsu
            Services Ltd focusing on UK government projects, National Rail transportation 
            network and infrastructure solutions, with high level security clearance of operations.
            </p>
            <p>
            In 2020, she became the General Manager, Group Head of IT & Operations, responsible for 
            driving the bank’s digital transformation, banking operations and customer experience.
            </p>
            <p>
            She continues to align the bank’s critical technology driven processes and tools with the 
            objectives to enhance efficiency, security, and growth.
            </p>
          </Content>
              </>
            )
          }
        </Container>
      </Section>
      {/* End of profile section */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  min-height: 60vh;
  &.hero {
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    `}
  }
`;
const Container = styled.div`
  width: 70%;
  margin: 5rem auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  `}
  ${Screen.pad`
  width: 80%;
  `}
  ${Screen.mediumpc`
  width: 80%;
  `}
  ${Screen.smallpc`
  width: 70%;
  margin-bottom: 3rem
  `}
   ${Screen.tablet`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin-bottom: 3rem
  `}
  ${Screen.phone`
  width: 80%
  `}
  ${Screen.smallPhone`
  width: 80%
  `}
  ${Screen.galaxyFold`
  width: 90%
  `}
`;
const FlexBox = styled.div`
  display: flex;
  ${Screen.smallpc`
  flex-direction: column;
  `}
`;
const Image = styled.img`
width: 30%;
height: 380px;
${Screen.miniLargeScreen`
width: 300px;
height: 380px;
`}
${Screen.pad`
width: 300px;
height: 380px;
`}
${Screen.mediumpc`
width: 300px;
height: 380px;
`}
${Screen.smallpc`
width: 250px;
height: 280px;
margin: 2rem auto;
`}
${Screen.mediumPhone`
width: 70%;
height: 280px;
margin: 1rem auto;
`}
${Screen.phone`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
${Screen.smallPhone`
width: 90%;
height: 250px;
margin: 1rem auto;
`}
${Screen.galaxyFold`
width: 80%;
height: 250px;
margin: 1rem auto;
`}
`;
const Content = styled.div`
  width: 50%;
  margin-left: 3rem;
  padding-top: 1.5rem;


  p {
    word-spacing: 0.1rem;
    padding-top: 1rem;
  }

  &.more {
    width: 90%;
    margin-left: 0;
  }
`;
const Header = styled.h2`
  padding-bottom: 0.5rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 22px;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 20px;
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 18px;
  `}
`;
const Title = styled.article`
${Screen.smallpc`
font-size: 12px;
text-align: center;
`}
${Screen.mediumPhone`
font-size: 12px;
text-align: center;
`}
${Screen.phone`
font-size: 12px;
text-align: center;
`}
${Screen.smallPhone`
font-size: 12px;
text-align: center;
`}
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
  
  ${Screen.tablet`
  display: none;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.phone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  padding-bottom: 1rem;
  `}
   ${Screen.galaxyFold`
  font-size: 12px;
  padding-bottom: 1rem;
  `}
}

ul {
  list-style-type: none;
}
`;
export default Lolita;
