import { useEffect } from 'react';
import styled from 'styled-components';
import { CorporateGovernance, InvestorMobile } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { directors } from '../../components/main';
import { Link, useLocation } from 'react-router-dom';
import { boardBreadcrumb } from '../../components/main/products';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;
const Board = () => {
  const location = useLocation();
  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* navigation */}
      {/* <Navigation /> */}
      {/* End of Navigation */}

      {/* Hero Section */}
      <Section className='hero'></Section>
      {/* End of hero section */}
      
      <BreadCrumb>
        {boardBreadcrumb.map(({ name, path }) => (
          <LinkContainer>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </LinkContainer>
        ))}
      </BreadCrumb>

      {/* Board */}
      <Section className='board' id='board'>
        <Container className='board'>
          <Header>Board of Directors</Header>
          <Paragraph>
            <p>
            The Board aims to promote Abbey’s long-term success, deliver
            sustainable value to our shareholders, and promote a culture of
            openness and debate. It delegates day-to-day management of the
            business and implementation of strategy to the MD/CEO, who is
            supported by the{' '}
            </p>
            <TextLink to='/about/senior-management'>
              Senior Management Team.
            </TextLink>
          </Paragraph>
          <CardContainer>
            {directors.map(({ name, position, img, path }) => (
              <CardLink to={path}>
                <Card>
                  <CardTop>{img && <Image src={img} alt='board' />}</CardTop>
                  <CardBody>
                    <p>{name}</p>
                    <span>{position}</span>
                  </CardBody>
                </Card>
              </CardLink>
            ))}
          </CardContainer>
        </Container>
      </Section>
      {/* End of Board */}
      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Section = styled.div`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${CorporateGovernance});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    position: relative;
    ${Screen.mediumpc`
    min-height: 40vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.board {
    min-height: 100vh;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 3rem
    `}
    ${Screen.pad`
    padding-top: 3rem
    `}
    ${Screen.mediumpc`
    padding-top: 5rem
    `}
    ${Screen.smallpc`
    padding-top: 3rem
    `}
    ${Screen.tablet`
    padding-top: 7rem
    `}
     ${Screen.surfaceDuo`
    padding-top: 5rem
    `}
    ${Screen.mediumPhone`
    padding-top: 3rem
    `}
    ${Screen.phone`
    padding-top: 3rem
    `}
    ${Screen.smallPhone`
    padding-top: 3rem
    `}
  }
  &.management {
    min-height: 100vh;
    padding-top: 7rem;
  }
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 80%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 80%;
  `}
  ${Screen.tablet`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  `}

  &.board {
    
  }
`;
const Header = styled.h1`
  color: #002668;
  font-size: 2rem;
  padding-bottom: 2rem;
  ${Screen.smallpc`
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 1rem
  `}
  ${Screen.tablet`
  text-align: center;
  font-size: 18px;
  padding-bottom: 2rem
  `}
   ${Screen.surfaceDuo`
  text-align: center;
  font-size: 18px;
  padding-bottom: 1rem
  `}
  /* text-align: center; */
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 3rem;
  margin-top: 3rem;
  ${Screen.pad`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 3rem;
  margin-top: 3rem;
  `}
  ${Screen.mediumpc`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 3rem;
  margin-top: 3rem;
  `}
  ${Screen.smallpc`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 3rem;
  margin-top: 3rem;
  `}
  ${Screen.surfaceDuo`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
  ${Screen.phone`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
   ${Screen.smallPhone`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  row-gap: 1.5rem;
  column-gap: 0;
  `}
`;
const Card = styled.div`
  width: 380px;
  height: 500px;
  background-color: #f5f5f5;
  ${Screen.smallpc`
  width: 90%;
  height: 400px;
  `}
  ${Screen.surfaceDuo`
  width: 65%;
  height: 400px;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  height: 400px;
  `}
  ${Screen.phone`
  width: 80%;
  height: 400px;
  `}
  ${Screen.smallPhone`
  width: 90%;
  height: 400px;
  `}
  ${Screen.galaxyFold`
  width: 90%;
  height: 350px;
  `}
`;
const CardTop = styled.div`
  width: 100%;
  height: 400px;
  background-color: #d9d9d9;
  /* ${Screen.mediumPhone`
  width:
  height: 250px;
  `} */
  ${Screen.smallpc`
  width: 100%;
  height: 300px;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  height: 300px;
  `}
  ${Screen.phone`
  width: 100%;
  height: 300px;
  `}
  ${Screen.smallPhone`
  width: 100%;
  height: 300px;
  `}
  ${Screen.galaxyFold`
  width: 100%;
  height: 250px;
  `}
`;
const CardBody = styled.div`
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 1rem;
  ${Screen.mediumPhone`
  padding-top: .5rem
  `}
  ${Screen.phone`
  padding-top: .5rem
  `}
  ${Screen.smallPhone`
  padding-top: .5rem
  `}

  p {
      font-weight: bold;
      padding-top: 1rem;
      font-size: 1rem;
      padding-bottom: 0.5rem;
      ${Screen.mediumPhone`
    font-size: 14px
    `}
      ${Screen.phone`
    font-size: 14px
    `}
    ${Screen.smallPhone`
    font-size: 14px
    `}
  }

  span {
      text-transform: uppercase;
      font-size: 0.8rem;
      ${Screen.mediumPhone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
      ${Screen.phone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
      ${Screen.smallPhone`
      font-size: 12px;
      padding: 0 .5rem;
      `}
    }
`;
const TextLink = styled(Link)`
  color: #000;
  ${Screen.mediumPhone`
  font-size: 14px
  `}
  ${Screen.phone`
  font-size: 14px
  `}
  ${Screen.smallPhone`
  font-size: 14px
  `}
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const CardLink = styled(Link)`
  color: #000;
  text-decoration: none;
  /* ${Screen.smallPhone`
  margin-bottom: 1rem;
  `} */
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }

  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 5px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  &.active {
    color: #c81e19;
  }
  ${Screen.mediumPhone`
  padding-right: 0!important;
  `}
  ${Screen.phone`
  padding-right: 0!important;
  `}
  ${Screen.smallPhone`
  padding-right: 0!important;
  `}
`;
const LinkContainer = styled.p`
${Screen.surfaceDuo`
font-size: 14px;
word-break: keep-all!important;
`}
${Screen.mediumPhone`
font-size: 11px;
word-break: keep-all!important;
`}
${Screen.phone`
font-size: 11px;
word-break: keep-all!important;
`}
${Screen.samsungGalaxy`
font-size: 10px;
word-break: keep-all!important;
`}
${Screen.smallPhone`
font-size: 9px;
word-break: keep-all!important;
`}
${Screen.galaxyFold`
font-size: 8px;
word-break: keep-all!important;
`}
`;

const Paragraph = styled.div`
p {
  ${Screen.mediumPhone`
  font-size: 14px;
  `}
  ${Screen.phone`
  font-size: 14px;
  `}
  ${Screen.smallPhone`
  font-size: 14px;
  `}
}
`;
export default Board;
