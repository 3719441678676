import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { 
  financialReducer, 
  noticeReducer, 
  formsReducer,
  disclosureReducer,
  cibReducer
 } = Reducers
export const FinanicalContext = createContext()
export const NoticeContext = createContext()
export const FormsContext = createContext()
export const DisclosureContext = createContext()
export const CibContext = createContext()

const initialState = {
  loading: false,
  data: [],
  error: ''  
}
export const FinancialContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(financialReducer, initialState)
  return (
    <FinanicalContext.Provider value={{ state, dispatch }}>
        {children}
    </FinanicalContext.Provider>
  )
}

export const NoticeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(noticeReducer, initialState)
return (
  <NoticeContext.Provider value={{ state, dispatch }}>
      {children}
  </NoticeContext.Provider>
)
}

export const FormsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formsReducer, initialState)
return (
  <FormsContext.Provider value={{ state, dispatch }}>
      {children}
  </FormsContext.Provider>
)
}

export const DisclosureContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(disclosureReducer, initialState)
return (
  <DisclosureContext.Provider value={{ state, dispatch }}>
      {children}
  </DisclosureContext.Provider>
)
}


export const CibContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cibReducer, initialState)
return (
  <CibContext.Provider value={{ state, dispatch }}>
      {children}
  </CibContext.Provider>
)
}
