import { createContext, useReducer } from 'react'
import Reducers from '../reducers'

const { 
  abbeyLinksReducer, 
  helpLinksReducer, 
  otherLinksReducer 
} = Reducers
export const AbbeyLinkContext = createContext()
export const HelpLinkContext = createContext()
export const OtherLinkContext = createContext()
const initialState = {
    loading: false,
    data: [],
    error: ''  
}

// Abbey Link
export const AbbeyLinksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(abbeyLinksReducer, initialState)
  return (
    <AbbeyLinkContext.Provider value={{ state, dispatch }}>
        {children}
    </AbbeyLinkContext.Provider>
  )
}

// Help Link
export const HelpLinksContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(helpLinksReducer, initialState)
return (
  <HelpLinkContext.Provider value={{ state, dispatch }}>
      {children}
  </HelpLinkContext.Provider>
)
}

// Help Link
export const OtherLinksContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(otherLinksReducer, initialState)
return (
  <OtherLinkContext.Provider value={{ state, dispatch }}>
      {children}
  </OtherLinkContext.Provider>
)
}

// export default FooterLinks