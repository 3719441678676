import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Tools } from '../../utils'
import { Screen } from '../../styles'

const { shortenText, toText }= Tools
const mediumCard = ({ item}) => {
  return (
    <Card>
        <Image src={item.imageUrl} alt='blog' />
        <Container>
            {/* <div> */}
        <Header>
            {item.title}
        </Header>
        {/* <span>{dayjs(item.pubDate).format("dddd, MMMM D, YYYY h:mm A")}</span> */}

            {/* </div> */}
        {/* <Content>{shortenText(toText(item.content), 0, 200) + "..."}</Content> */}
        <TextLink to={item.link} target='_blank' rel='noreferrer'>Read More...</TextLink>
        </Container>
    </Card>
  )
}

export default mediumCard

/** Styles */

const Card = styled.div`
 width: 600px;
  height: 200px;
  border: 2px solid #000;
  margin-bottom: 2rem;
  display: flex;
  ${Screen.mediumpc`
  width: 100%;
  `}
  ${Screen.smallpc`
  width: 100%;
  `}
  ${Screen.tablet`
  width: 100%;
  height: auto;
  flex-direction: column;
  `}
   ${Screen.surfaceDuo`
  width: 100%;
  height: auto;
  flex-direction: column;
  `}
  ${Screen.mediumPhone`
  width: 100%;
  height: auto;
  flex-direction: column;
  `}
  ${Screen.phone`
  width: 100%;
  height: auto;
  flex-direction: column;
  `}
  ${Screen.smallPhone`
  width: 100%;
  height: auto;
  flex-direction: column;
  `}
/* justify-content: space-between; */
`
const Header = styled.h3`
color: #002668;
font-size: 1.3rem;
/* padding-bottom: 1rem; */
font-weight: bold;
${Screen.mediumpc`
font-size: 18px;
`}
${Screen.smallpc`
font-size: 16px;
`}
${Screen.tablet`
font-size: 16px;
padding-bottom: 1rem;
`}
${Screen.mediumPhone`
font-size: 16px;
padding-bottom: 1rem;
`}
${Screen.phone`
font-size: 16px;
padding-bottom: 1rem;
`}
${Screen.smallPhone`
font-size: 16px;
padding-bottom: 1rem;
`}
`;

const Content = styled.p`
font-size: .9rem;
/* padding-bottom: .5rem; */
`;
const Container = styled.div`
width: 55%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 1rem;
${Screen.tablet`
width: 100%;
padding-top: 2rem;
`}
${Screen.mediumPhone`
width: 100%;
padding-top: 2rem;
`}
${Screen.phone`
width: 100%;
padding-top: 1rem;
`}
${Screen.smallPhone`
width: 100%;
`}

/* span {
    color: #999;
    padding-top: 1rem;
    font-size: .8rem;

} */

`;
const Image = styled.img`
width: 45%;
height: auto;
${Screen.tablet`
width: 100%;
height: 180px;
`}
${Screen.mediumPhone`
width: 100%;
height: 180px;
`}
${Screen.phone`
width: 100%;
height: 180px;
`}
${Screen.smallPhone`
width: 100%;
height: 180px;
`}
`
const TextLink = styled(Link)`
color: red;
text-decoration: none;
font-weight: 500;
font-size: .9rem;
${Screen.mediumPhone`
font-size: 14px
`}
${Screen.phone`
font-size: 14px
`}
${Screen.smallPhone`
font-size: 14px
`}
`