import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AboutLeadership, InvestorMobile } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import {
  breadcrumbMenu,
  governance,
  leadership,
} from '../../components/main/products';
import { Screen } from '../../styles';

// const Navigation = NavigationBar.Navigation;
const Leadership = () => {
  const location = useLocation();
  const [mobile, setMobile] = useState(false);

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    const responsive = () => {
      window.innerWidth <= 768 ? setMobile(true) : setMobile(false)
    }
    responsive()
    window.addEventListener('resize', () => responsive())

    return () => {
      window.removeEventListener('resize', () => responsive())
    }
  }, []);
  return (
    <Box>
      {/* Navigation */}
      {/* <Navigation /> */}
      {/* End of navigation */}

      {/* Hero */}
      <Section className='hero'>
        <Header className='hero'>
          Leadership and <br /> Governance
        </Header>
      </Section>
      {/* End of hero */}
      {
        mobile ? (
          <BreadCrumb>
        {breadcrumbMenu.slice(0, 2).map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        ): (
          <BreadCrumb>
        {breadcrumbMenu.map(({ name, path }) => (
          <>
            <Links to={path} className={location.pathname === path && 'active'}>
              {name}
            </Links>{' '}
            <span>/</span>
          </>
        ))}
      </BreadCrumb>
        )
      }
      <Section className='leaders'>
        <Container>
          <Paragraph className='leaders'>
            <p>
              We have a comprehensive range of policies and systems in place to
              ensure that the bank is well-managed, with effective oversight and
              control.
            </p>{' '}
            <p>
              {' '}
              We comply with the applicable provisions of the Nigerian Corporate
              Governance Code
            </p>{' '}
            <p>
              Here, you can learn more about our Board of directors, senior
              management and approach to corporate governance.
            </p>
            <CardContainer className='leaders'>
              {leadership.map(({ img, caption, path }) => (
                <Card className='leaders'>
                  <ContentTop url={`url(${img})`}>
                  {/* <Image src={img} alt='leaders' className='leaders' /> */}
                  </ContentTop>
                  <ContentBottom>
                  <TextLink to={path}>{caption}</TextLink>
                  </ContentBottom>
                </Card>
              ))}
            </CardContainer>
          </Paragraph>
        </Container>
      </Section>

      {/* Corporate Governance */}
      <Section className='governance'>
        <Container>
          <Header className='governance'>Corporate Governance</Header>
          <Paragraph className='governance'>
            <p>
              Abbey Mortgage Bank Plc recognizes the fact that effective
              governance system is essential to retaining public trust and
              confidence in the way and manner we do our business. Our
              governance policies are structured to ensure maximum compliance
              with the provisions of the various laws and codes on the subject.
            </p>
            <p>
              These include the Nigerian Code of Corporate Governance 2018, the
              Central Bank of Nigeria Code of Corporate Governance of May 2014,
              the SEC Code of Corporate Governance dated 1 April, 2011, the Post
              Listing requirements of Nigerian Exchange Group (NGX) together
              with the amendments thereto, our internal Code of Corporate
              Governance and international best practices.
            </p>
            <p>
              Abbey's Code of Corporate Governance is targeted at achieving the
              highest standards of transparency, accountability and good
              corporate behaviour in line with international best practices. The
              governance structures and processes are primed for the
              satisfaction of the various stakeholders including employees,
              shareholders, creditors, host communities and regulatory
              authorities. Abbey's corporate ethos include accountability,
              transparency, integrity, fairness, discipline, communication,
              social and environmental responsibility, service excellence,
              responsible lending and stakeholder-rights' recognition. Directors
              and employees are expected to act honestly, in good faith and in
              the best interest of the Bank in all transactions.
            </p>
          </Paragraph>
          <CardContainer className='governance'>
            {governance.map(({ title, content, path }) => (
                <CardBody>
                  <h3>{title}</h3>
                  <p>{content}</p>
                  <CardLink to={path}>Read more</CardLink>
                </CardBody>
            ))}
          </CardContainer>
        </Container>
      </Section>
      {/* End of Corporate Governance */}

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${AboutLeadership});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 80px;
    ${Screen.mediumpc`
    min-height: 30vh!important;
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }

  &.governance {
    min-height: 100vh;
    ${Screen.mediumpc`
    min-height: 50vh;
    `}
    ${Screen.smallpc`
    min-height: 50vh;
    `}
  }

  &.leaders {
    min-height: 100vh;
    ${Screen.miniLargeScreen`
    min-height: 80vh;
    `}
    ${Screen.mediumpc`
    min-height: 40vh;
    `}
    ${Screen.smallpc`
    min-height: 50vh;
    `}
  }

`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
  ${Screen.miniLargeScreen`
  width: 80%;
  margin: 5rem auto;
  padding-top: 0;
  `}
  ${Screen.pad`
  width: 80%;
  margin: 5rem auto;
  `}
  ${Screen.mediumpc`
  width: 85%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  margin: 0 auto;
  padding-top: 3rem
  `}
  ${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  padding-top: 3rem
  `}
  ${Screen.surfaceDuo`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.phone`
  width: 80%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
  ${Screen.smallPhone`
  width: 90%;
  margin: 0 auto!important;
  padding-top: 0!important
  `}
`;
const Header = styled.h1`
  &.hero {
    color: #fff;
    font-size: 3rem;
    text-align: right;
    padding-top: 10rem;
    padding-right: 10rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${Screen.mediumpc`
    padding-right: 5rem;
    `}
    ${Screen.tablet`
    padding-top: 8rem;
    font-size: 2.5rem
    `}
    ${Screen.surfaceDuo`
    padding-top: 10rem;
    font-size: 2rem
    `}
    ${Screen.mediumPhone`
    visibility: hidden;
    padding-top: 0
    `}
  }
  &.governance {
    color: #002668;

    ${Screen.surfaceDuo`
    text-align: center;
    `}
    ${Screen.mediumPhone`
    font-size: 18px;
    text-align: center;
    `}
    ${Screen.phone`
    font-size: 18px;
    text-align: center;
    `}
    ${Screen.smallPhone`
    font-size: 18px;
    text-align: center;
    `}
  }
`;
const BreadCrumb = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 5rem;
  /* position: fixed; */

  span {
    font-weight: bold;
    padding-right: 0.5rem;
    ${Screen.mediumPhone`
    font-size: 10px;
    `}
  }

  ${Screen.surfaceDuo`
  padding-left: 10px;
  `}
  ${Screen.mediumPhone`
  padding-left: 10px;
  `}
  ${Screen.phone`
  padding-left: 10px;
  `}
  ${Screen.smallPhone`
  padding-left: 10px;
  `}
  ${Screen.galaxyFold`
  padding-left: 10px;
  `}
`;
const Links = styled(Link)`
  color: #002668;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.3rem;

  &.active {
    color: #c81e19;
  }

  ${Screen.mediumPhone`
  padding-right: 5px!important;
  font-size: 13px;
  `}
  ${Screen.phone`
  padding-right: 5px!important;
  `}
  ${Screen.smallPhone`
  padding-right: 5px!important;
  `}
    ${Screen.galaxyFold`
    font-size: 12px
  `}
`;
const Paragraph = styled.div`
  padding-top: 2rem;
  ${Screen.mediumpc`
  padding-top: 1rem
  `}
  ${Screen.surfaceDuo`
  padding-top: 2rem
  `}
  p {
    padding-bottom: 1.5rem;
    ${Screen.surfaceDuo`
    font-size: 14px;
    padding-bottom: 1rem;
    `}
    ${Screen.mediumPhone`
    font-size: 14px;
    padding-bottom: 1rem;
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-bottom: 1rem;
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-bottom: 1rem;
    `}
  }
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem auto 2rem;
  &.leaders {
    margin: 3rem 10rem 2rem;
    ${Screen.smallpc`
    margin: 2rem 3rem;
    `}
    // ${Screen.tablet`
    // flex-direction: column;
    // margin: 2rem 3rem;
    // `}
    ${Screen.surfaceDuo`
    margin: 2rem 0
    `}
    ${Screen.mediumPhone`
    margin: 2rem 0
    `}
    ${Screen.phone`
    margin: 2rem 0
    `}
    ${Screen.smallPhone`
    margin: 2rem 0
    `}
  }

  /* &.governance {
    margin
  } */

  ${Screen.surfaceDuo`
  flex-direction: column;
  width: 100%
  `}
  ${Screen.phone`
  width: 100%;
  `}
   ${Screen.smallPhone`
  width: 100%;
  `}
`;
const Card = styled.div`
  height: 460px;
  background-color: #f5f5f5;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.leaders {
    width: 350px;

    ${Screen.miniLargeScreen`
    width: 400px;
    height: 400px;
    // margin-bottom: 2rem;
    `}
    ${Screen.pad`
    width: 300px;
    height: 350px;
    `}
    ${Screen.mediumpc`
    width: 280px;
    height: 300px;
    `}
    ${Screen.smallpc`
    width: 300px;
    height: 300px;
    `}
    ${Screen.tablet`
    width: 300px;
    height: 450px;
    `}
    ${Screen.surfaceDuo`
    margin-bottom: 2rem
    `}
    ${Screen.mediumPhone`
    width: 80%;
    height: 400px;
    margin-bottom: 3rem
    `}
    ${Screen.phone`
    width: 90%;
    height: 400px;
    margin-bottom: 3rem
    `}
    ${Screen.smallPhone`
    width: 80%;
    height: 400px;
    margin-bottom: 3rem
    `}
     ${Screen.galaxyFold`
    width: 90%;
    height: 350px;
    margin-bottom: 3rem
    `}
  }
  &.governance {
    width: 350px;
    height: 200px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 400px;

  &.leaders {
    height: 100%;
    width: 100%;
  }

  ${Screen.mediumpc`
  height: 230px;
  `}

  /* &.leaders {
    width: 100%;
  } */
  /* padding-bottom: 5rem; */
`;
const TextLink = styled(Link)`
  color: #002668;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  padding-bottom: 2rem;
  padding-top: 1.5rem;

  ${Screen.mediumPhone`
  font-size: 1rem;
  `}
  ${Screen.phone`
  font-size: 1rem;
  `}
  ${Screen.smallPhone`
  font-size: 1rem;
  `}
`;
const CardBody = styled.div`
  width: 350px;
  height: auto;
  background-color: #f5f5f5;
  text-align: center;
  padding: 5rem 2rem 3rem;

  h3 {
    color: #c81e19;
    padding-bottom: 1.5rem;
    ${Screen.mediumpc`
    font-size: 16px;
    padding-bottom: 1rem
    `}
    ${Screen.smallpc`
    font-size: 16px;
    padding-bottom: 1rem
    `}
    ${Screen.mediumPhone`
    font-size: 16px;
    padding-bottom: 1rem
    `}
    ${Screen.phone`
    font-size: 16px;
    padding-bottom: 1rem
    `}
    ${Screen.smallPhone`
    font-size: 16px;
    padding-bottom: 1rem
    `}
  }

  p {
    font-size: 0.9rem;
    padding-bottom: 2rem;
    ${Screen.smallpc`
    font-size: 14px;
    padding-bottom: 1rem
    `}
    ${Screen.mediumPhone`
    font-size: 14px;
    padding-bottom: 1rem
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-bottom: 1rem
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-bottom: 1rem
    `}
  }

  ${Screen.miniLargeScreen`
  width: 330px;
  height: 250px;
  padding: 2rem 1rem;
  `}
  ${Screen.pad`
  width: 260px;
  height: 250px;
  padding: 2rem 1rem;
  `}
  ${Screen.mediumpc`
  width: 250px;
  height: 250px;
  padding: 2rem 1rem;
  `}
  ${Screen.smallpc`
  width: 240px;
  height: 250px;
  padding: 2rem 1rem;
  `}
  ${Screen.tablet`
  width: 220px;
  height: 250px;
  padding: 2rem 1rem;
  `}
  ${Screen.surfaceDuo`
  width: 280px;
  height: 250px;
  margin-bottom: 2rem;
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.mediumPhone`
  width: 250px;
  height: 230px;
  margin-bottom: 2rem;
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.phone`
  width: 250px;
  height: 230px;
  margin-bottom: 2rem;
  padding: 2rem 1rem 1rem;
  `}
  ${Screen.smallPhone`
  width: 250px;
  height: 230px;
  margin-bottom: 2rem;
  padding: 2rem 1rem 1rem;
  `}
`;
const CardLink = styled(Link)`
  color: #c81e19;
  text-decoration: none;
  font-weight: bold;
  ${Screen.smallpc`
  font-size: 14px;
  padding-top: 1rem
  `}
  ${Screen.mediumPhone`
  font-size: 14px;
  padidng-top: 0;
  `}
  ${Screen.phone`
  font-size: 14px
  `}
  ${Screen.smallPhone`
  font-size: 14px
  `}
`;

const ContentTop = styled.div`
width: 100%;
height: 380px;
content: ${({ url }) => url}
`;
const ContentBottom = styled.div`
width: 100%;
height: 80px;
text-align: center;
padding-top: 2rem;
`

export default Leadership;
