import { 
    MAIN_LINKS_REQUEST,
    MAIN_LINKS_SUCCESS,
    MAIN_LINKS_FAILURE,
    SECTION_LINKS_REQUEST,
    SECTION_LINKS_SUCCESS,
    SECTION_LINKS_FAILURE,
    MOBILE_NAV_REQUEST,
    MOBILE_NAV_SUCCESS,
    MOBILE_NAV_FAILURE,

} from "../constants"



export const mainLinksReducer = (state, action) => {
    switch(action.type){
        case MAIN_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case MAIN_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case MAIN_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const sectionLinksReducer = (state, action) => {
    switch(action.type){
        case SECTION_LINKS_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case SECTION_LINKS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case SECTION_LINKS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}

export const mobileNavReducer = (state, action) => {
    switch(action.type){
        case MOBILE_NAV_REQUEST:
            return {
                loading: true,
                data: [],
                error: ''
            }
        case MOBILE_NAV_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case MOBILE_NAV_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.error,
            }
        default:
            return state
    }
}
