import { createContext, useReducer } from 'react'
import Reducers from '../reducers'


const { 
  blogLinksReducer,
  blogsReducer,
  pressLandingReducer,
  pressReducer,
  footprintReducer
} = Reducers
export const BlogLinksContext = createContext()
export const YoutubeLinksContext = createContext()
export const BlogsContext = createContext()
export const PressContext = createContext()
export const PressLandingContext = createContext()
export const FootprintContext = createContext()
const initialState = {
    loading: false,
    data: [],
    error: ''  
}


// Blog Link
export const BlogLinksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(blogLinksReducer, initialState)
  return (
    <BlogLinksContext.Provider value={{ state, dispatch }}>
        {children}
    </BlogLinksContext.Provider>
  )
}


// Blogs
export const BlogsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(blogsReducer, initialState)
return (
  <BlogsContext.Provider value={{ state, dispatch }}>
      {children}
  </BlogsContext.Provider>
)
}

// Press
export const PressContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pressReducer, initialState)
return (
  <PressContext.Provider value={{ state, dispatch }}>
      {children}
  </PressContext.Provider>
)
}

// Press Landing
export const PressLandingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pressLandingReducer, initialState)
return (
  <PressLandingContext.Provider value={{ state, dispatch }}>
      {children}
  </PressLandingContext.Provider>
)
}

// Youtube Link
export const YoutubeLinksContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(blogLinksReducer, initialState)
return (
  <YoutubeLinksContext.Provider value={{ state, dispatch }}>
      {children}
  </YoutubeLinksContext.Provider>
)
}

// environmental footprint
export const FootprintContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(footprintReducer, initialState)
return (
  <FootprintContext.Provider value={{ state, dispatch }}>
      {children}
  </FootprintContext.Provider>
)
}
