import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Close, Open, TermMobile, TermsImg } from '../../assets/img';
// import { NavigationBar } from '../../components';
// import { Footer } from '../../components/footer';
import { terms } from '../../components/main';
import { Screen } from '../../styles'

// const Navigation = NavigationBar.Navigation;

const Terms = () => {
  const [termOne, setTermOne] = useState(false);
  const [termTwo, setTermTwo] = useState(false);
  const [termThree, setTermThree] = useState(false);
  const [termFour, setTermFour] = useState(false);
  const [termFive, setTermFive] = useState(false);
  const [termSix, setTermSix] = useState(false);
  const [termSeven, setTermSeven] = useState(false);
  const [termEight, setTermEight] = useState(false);
  const [termNine, setTermNine] = useState(false);
  const [termTen, setTermTen] = useState(false);
  const [termEleven, setTermEleven] = useState(false);
  const [termTwelve, setTermTwelve] = useState(false);
  const [termThirteen, setTermThirteen] = useState(false);
  const [termFourteen, setTermFourteen] = useState(false);
  const toggleTermOne = () => {
    setTermOne(!termOne);
  };
  const toggleTermTwo = () => {
    setTermTwo(!termTwo);
  };
  const toggleTermThree = () => {
    setTermThree(!termThree);
  };
  const toggleTermFour = () => {
    setTermFour(!termFour);
  };
  const toggleTermFive = () => {
    setTermFive(!termFive);
  };
  const toggleTermSix = () => {
    setTermSix(!termSix);
  };
  const toggleTermSeven = () => {
    setTermSeven(!termSeven);
  };
  const toggleTermEight = () => {
    setTermEight(!termEight);
  };
  const toggleTermNine = () => {
    setTermNine(!termNine);
  };
  const toggleTermTen = () => {
    setTermTen(!termTen);
  };
  const toggleTermEleven = () => {
    setTermEleven(!termEleven);
  };
  const toggleTermTwelve = () => {
    setTermTwelve(!termTwelve);
  };
  const toggleTermThirteen = () => {
    setTermThirteen(!termThirteen);
  };
  const toggleTermFourteen = () => {
    setTermFourteen(!termFourteen);
  };

  //Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      {/* nav */}
      {/* <Navigation /> */}
      {/* End of nav */}
      {/* Hero Section */}
      <Section className='hero'>
        <Container className='hero'>
          <Glass>
            <Content className='glass'>
              <h2>Terms And Conditions</h2>
            </Content>
          </Glass>
        </Container>
      </Section>
      <Container>
        {/* End of Hero Section */}

        {/* Terms & Conditions */}
        <Section className='terms'>
          <Header>Using the website</Header>
          {/* Abbey Terms */}
          <SubHeader>
            <p>Abbey Mortgage Bank Plc Website Access Agreement</p>
            <Image
              src={termOne ? Close : Open}
              onClick={toggleTermOne}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termOne && (
            <Content>
              <p>
                Please read this Agreement carefully before accessing the Abbey
                Mortgage Bank Plc Website. As a condition to and in
                consideration of receiving and accessing the Abbey Mortgage Bank
                Plc Website, the User agrees to be bound by the terms of this
                Agreement. Use of or access to the Abbey Mortgage Bank Plc
                Website shall constitute acceptance of and agreement to be bound
                by this Agreement. If you do not wish to be bound by this
                agreement, do not access The Abbey Mortgage Bank Plc Website. If
                you have any questions about this Agreement, please contact us
                via e-mail at enquries@abbeymortgagebank.com.
                <br />
                THIS AGREEMENT “Agreement” is entered into by and between Abbey
                Mortgage Bank Plc (herein after called “Abbey”) and any
                individual, corporation, association, agency, company, or other
                entity who accesses or uses Abbey's Website (the “User” or
                “you”).
                <br />
                You understand and agree that the contents of this website
                include all audio, video, graphics, images and textual
                materials, downloadable files, pdfs, Microsoft word, etc. All
                contents are protected by international copyright law. None of
                the contents on this website shall be used for any commercial
                purposes without the written consent of Abbey.
                <br />
                You acknowledge and agree that the Service and any necessary
                software used in connection with the Service contain proprietary
                and confidential information that is protected by applicable
                intellectual property and other laws. You further acknowledge
                and agree that the contents of sponsored advertisements or
                information presented to you through the Service or advertisers
                is protected by copyrights, trademarks, service marks, patents
                or other proprietary rights and laws. Except as expressly
                authorized by Abbey, you agree not to modify, rent, lease, loan,
                sell, distribute, or create derivative works based on the
                Service or the Software, in whole or in part. Abbey grants you a
                personal, non-transferable, and non-exclusive right and licence
                to use the contents of the site, provided that you do so within
                the ambit of the Terms of Service.
                <br />
                In addition, when using Abbey services, you and Abbey shall be
                subject to any posted guidelines or rules applicable to such
                services, which may be posted from time to time. All such
                guidelines or rules are hereby incorporated by reference into
                the Terms of Service.
              </p>
            </Content>
          )}
          {/* End of Abbey Terms */}
          {/* Trademark */}
          <SubHeader>
            <p>Our Trademarks and Copyright</p>
            <Image
              src={termTwo ? Close : Open}
              onClick={toggleTermTwo}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termTwo && (
            <Content>
              <p>
                The content and information contained within our website or
                delivered to you in connection with your use of our website is
                the property of Abbey. The materials on this website are
                protected by copyright and no part of such materials may be
                modified, reproduced, stored in a retrieval system, transmitted
                (in any form or by any means), copied, distributed, used for
                creating derivative works or used in any other way for
                commercial or public purposes without the Bank’s prior written
                consent.
              </p>
            </Content>
          )}
          {/* End of trademark */}
          {/* Web Content and Materials */}
          <SubHeader>
            <p>Web Content and Materials</p>
            <Image
              src={termThree ? Close : Open}
              onClick={toggleTermThree}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termThree && (
            <Content>
              <p>
                The information contained in this website is believed to be
                reliable, but Abbey does not warrant its completeness,
                timeliness, or accuracy. The information on the Website is not
                intended as an offer or solicitation for the purchase of Abbey
                shares, or any other security.
                <br />
                We reserve the right to change these terms and conditions at any
                time without notice to you, by updating this posting and your
                continued access to or use of this site will constitute your
                acceptance of any such changes.
              </p>
            </Content>
          )}
          {/* End of Web Content and Materials */}
          {/* Use of Information and Materials */}
          <SubHeader>
            <p>Use of Information and Materials</p>
            <Image
              src={termFour ? Close : Open}
              onClick={toggleTermFour}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termFour && (
            <Content>
              <p>
                The services and products offered on this website (“Services and
                Products”) are for information purposes only. No persons gaining
                access to this website in any jurisdictions may treat any
                materials or information provided on this website as
                constituting an invitation to them to subscribe for the
                services, nor should they in any event use such Services, unless
                in the relevant jurisdiction, the Services could lawfully be
                made to them. Accordingly, nothing contained in this website
                shall constitute an offer or solicitation by anyone in any
                jurisdiction in which such offer or solicitation is not lawful.
                It is the responsibility of any person to inform themselves of
                and to observe all applicable laws and regulations of any
                relevant jurisdiction before they subscribe for the services.
                Abbey shall have the final determination of eligibility to
                subscribe for any of the Services.
                <br />
                The information contained in these pages is not intended to
                provide professional advice and should not be relied upon in
                that regard. Persons accessing these pages are advised to obtain
                appropriate professional advice, when necessary at all times in
                their respective jurisdiction.
              </p>
            </Content>
          )}
          {/* End of use of information and materials */}
          {/* No warranties*/}
          <SubHeader>
            <p>No warranties</p>
            <Image
              src={termFive ? Close : Open}
              onClick={toggleTermFive}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termFive && (
            <Content>
              <p>
                Whilst diligence has been taken in preparing the information
                materials contained in this website, such information and
                materials are provided “as is” without warranty of any kind,
                either express or implied. In particular, no warranty regarding
                non-infringement, security, accuracy, fitness for a purpose or
                freedom from computer viruses is given in connection with such
                information and materials.
              </p>
            </Content>
          )}
          {/* End of no warranties */}
          {/* Personal Information*/}
          <SubHeader>
            <p>Use of Personal Information</p>
            <Image
              src={termSix ? Close : Open}
              onClick={toggleTermSix}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termSix && (
            <Content>
              <p>
                By subscribing to our website, you may from time to time receive
                our promotional e-newsletters and SMS on happenings in Abbey.
              </p>
            </Content>
          )}
          {/* End of Personal Information */}
          {/* Disruption of Service*/}
          <SubHeader>
            <p>Disruption of Service</p>
            <Image
              src={termSeven ? Close : Open}
              onClick={toggleTermSeven}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termSeven && (
            <Content>
              <p>
                Access to the Website may from time to time be unavailable,
                delayed, limited or slow due to, but not restricted to the
                following factors:
                <List>
                  {terms.map(({ item }) => (
                    <ListItem>{item}</ListItem>
                  ))}
                </List>
              </p>
            </Content>
          )}
          {/* End of disruption of service */}
          {/* Hyperlink Policy*/}
          <SubHeader>
            <p>Abbey Mortgage Bank Plc Hyperlink Policy</p>
            <Image
              src={termEight ? Close : Open}
              onClick={toggleTermEight}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termEight && (
            <Content>
              <p>
                There may be cases on our website when the Abbey provides
                hyperlinks to other locations or websites on the Internet. These
                hyperlinks lead to websites published or operated by third
                parties who are not affiliated with or in any way related to
                Abbey. They have been included in our website to enhance your
                user experience and are presented for information purposes only.
                We endeavour to select reputable websites and sources of
                information for your convenience.
                <br />
                However, by providing hyperlinks to an external website or
                webpage, Abbey shall not be deemed to endorse, recommend,
                approve, guarantee, or introduce any third parties or the
                services/ products they provide on their websites, or to have
                any form of cooperation with such third parties and websites
                unless otherwise stated by Abbey.
                <br />
                We are not in any way responsible for the content of any
                externally linked website or webpage. You use or follow these
                links at your own risk and Abbey is not responsible for any
                damages or losses incurred or suffered by you arising out of or
                in connection with your use of the link. Abbey is not a party to
                any contractual arrangements entered into between you and the
                provider of the external website unless otherwise expressly
                specified or agreed to by Abbey.
                <br />
                Any links to websites that contain downloadable software are
                provided for your convenience only. Again, we are not
                responsible for any difficulties you may encounter in
                downloading the software or for any consequences from your doing
                so. Please remember that the use of any software downloaded from
                the Internet may be governed by a licence agreement and your
                failure to observe the terms of such licence agreement may
                result in an infringement of intellectual property rights of the
                relevant software provider which we are not in any way
                responsible.
              </p>
            </Content>
          )}
          {/* End of Hyperlink Policy */}
          {/* Linked Websites */}
          <SubHeader>
            <p>Linked Websites</p>
            <Image
              src={termNine ? Close : Open}
              onClick={toggleTermNine}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termNine && (
            <Content>
              <p>
                Abbey is not responsible for the contents available on, or the
                set-up of any other websites linked to this site. Access to and
                use of such other websites is at the user's own risk and subject
                to any terms and conditions applicable to such access/use. By
                providing hyperlinks to other websites, Abbey shall not be
                deemed to endorse, recommend, approve, guarantee, or introduce
                any third parties or the service/products they provide on their
                web site, or have any form of cooperation with such third
                parties and web sites. The bank is not a party to any
                contractual arrangements entered into between you and the
                provider of the external website unless otherwise expressly
                specified or agreed to by the Bank.
              </p>
            </Content>
          )}
          {/* End of Hyperlink Policy */}
          {/* Exclusion of Liabilities */}
          <SubHeader>
            <p>Exclusion of Liability</p>
            <Image
              src={termTen ? Close : Open}
              onClick={toggleTermTen}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termTen && (
            <Content>
              <p>
                Although we have taken all reasonable care to ensure that the
                information provided on this site is accurate, we give no
                warranties of any kind, express or implied, with regard to the
                accuracy, timeliness or completeness of any such information.
                Abbey and third party data providers are not providing any
                warranties and representations whatsoever regarding the website.
                Further, Abbey will not be liable for any errors or omissions in
                the materials and information provided on the website or for any
                delay, difficulty in use, inaccuracy of information, computer
                viruses, other defect in this website or your inability to use
                this website to your satisfaction. No license to the user is
                implied in these disclaimers.
                <br />
                Under no circumstances will Abbey be liable for any lost profits
                or any form of damages arising out of any use of or inability to
                use the website or any portion thereof, regardless of whether
                Abbey has been informed of the likelihood of such damages
                occurring and regardless of the form of action, whether in
                contract, tort, or otherwise.
              </p>
            </Content>
          )}
          {/* End of exclusion of liabilities */}
          {/* Severability */}
          <SubHeader>
            <p>Severability</p>
            <Image
              src={termEleven ? Close : Open}
              onClick={toggleTermEleven}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termEleven && (
            <Content>
              <p>
                If a provision of these terms and conditions is determined by
                any court or other competent authority to be unlawful and/or
                unenforceable, the other provisions will continue in effect.
                <br />
                If any unlawful and/or unenforceable provision of these terms
                and conditions would be lawful or enforceable if part of it were
                deleted, that part will be deemed to be deleted, and the rest of
                the provision will continue in effect.
              </p>
            </Content>
          )}
          {/* End of severability */}
          {/* Third Party Rights */}
          <SubHeader>
            <p>Third party rights</p>
            <Image
              src={termTwelve ? Close : Open}
              onClick={toggleTermTwelve}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termTwelve && (
            <Content>
              <p>
                These terms and conditions are for our benefit and your benefit,
                and these terms and conditions are not intended to benefit or be
                enforceable by any third party. The exercise of the parties'
                rights under these terms and conditions is not subject to the
                consent of any third party.
              </p>
            </Content>
          )}
          {/* End of third party rights */}
          {/* Enforceability */}
          <SubHeader>
            <p>Enforceability</p>
            <Image
              src={termThirteen ? Close : Open}
              onClick={toggleTermThirteen}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termThirteen && (
            <Content>
              <p>
                Where any of these terms and conditions are held unenforceable,
                the remaining terms and conditions shall be unimpaired, and the
                unenforceable terms or conditions shall be replaced by such
                enforceable term or condition as comes closest to the intention
                underlying the unenforceable term or condition. These Terms and
                Conditions shall be subject to any other agreements you have
                entered with Abbey.
              </p>
            </Content>
          )}
          {/* End of enforceability */}
          {/* Governing Law */}
          <SubHeader>
            <p>Governing Law</p>
            <Image
              src={termFourteen ? Close : Open}
              onClick={toggleTermFourteen}
              alt='icon'
              className='icon'
            />
          </SubHeader>
          {termFourteen && (
            <Content>
              <p>
                The use of this website and the terms and conditions of this
                disclaimer shall be governed and construed in accordance with
                the laws of the Federal Republic of Nigeria.
              </p>
            </Content>
          )}
          {/* End of governing law */}
        </Section>
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
      {/* End of Footer */}
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  // background-color: #fff;
`;
const Container = styled.div`
  width: 70%;
  margin: 10rem auto 0;
  ${Screen.miniLargeScreen`
  width: 90%;
  margin: 5rem auto;
  `}
  ${Screen.pad`
  width: 90%;
  margin: 6rem auto 0;
  `}
  ${Screen.mediumpc`
  width: 90%;
  margin: 3rem auto;
  `}
  ${Screen.smallpc`
  width: 90%;
  `}
${Screen.tablet`
  width: 90%;
  margin: 0 auto!important;
  `}
  ${Screen.surfaceDuo`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.mediumPhone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.phone`
  width: 70%;
  margin: 0 auto!important;
  `}
  ${Screen.smallPhone`
  width: 80%;
  margin: 0 auto!important;
  `}
  ${Screen.galaxyFold`
  width: 90%;
  margin: 0 auto!important;
  `}
  &.hero {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 85%;
    ${Screen.miniLargeScreen`
    padding-top: 5rem;
    `}
    ${Screen.pad`
    padding-top: 0;
    `}
    ${Screen.mediumpc`
    padding-top: 5rem;
    `}
     ${Screen.smallpc`
    padding-top: 0;
    `}
    ${Screen.tablet`
    padding-top: 3rem;
    `}
    ${Screen.mediumPhone`
    width: 100%;
    margin: 0!important;
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.phone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
    ${Screen.smallPhone`
    width: 100%;
    margin: 0!important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem
    `}
  }
  /* margin-bottom: ${({ mb }) => mb}; */

  p {
    font-size: 1.2rem;
  }
`;
const Glass = styled.div`
  width: 700px;
  height: 130px;
  background: rgba(255, 255, 255, 0.7);
  ${Screen.pad`
  margin-top: 2rem;
  `}
   ${Screen.mediumpc`
  margin-top: 0;
  `}
  ${Screen.smallpc`
  background: rgba(255, 255, 255, 0);
  `}
`;

const Image = styled.img`
  &.terms {
    margin: 3rem 0;
  }

  &.icon {
    cursor: pointer;
    ${Screen.mediumPhone`
    width: 24px;
    height: 24px;
    `}
    ${Screen.phone`
    width: 22px;
    height: 22px;
    `}
    ${Screen.smallPhone`
    width: 20px;
    height: 20px;
    `}
  }
`;
const Section = styled.section`
  width: 100%;
  &.hero {
    min-height: 50vh;
    background-image: url(${TermsImg});
    background-position: center;
    background-size: cover;
    margin-top: 80px;
    padding-top: 7rem;
    ${Screen.miniLargeScreen`
    padding-top: 5rem
    `}
    ${Screen.pad`
    padding-top: 5rem;
    min-height: 60vh;
    `}
    ${Screen.mediumpc`
    min-height: 30vh!important;
    padding-top: 4rem
    `}
    ${Screen.smallpc`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 1rem;
    `}
     ${Screen.tablet`
    min-height: 40vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
     ${Screen.surfaceDuo`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.mediumPhone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.phone`
    min-height: 30vh!important;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
    ${Screen.smallPhone`
    min-height: 30vh;
    margin-top: 5rem!important;
    padding-top: 4rem;
    `}
  }
  &.terms {
    margin: 5rem 0;
    width: 100%;
    min-height: 100vh;
    padding: 0 7rem;
    ${Screen.mediumpc`
    min-height: 50vh;
    `}
    ${Screen.smallpc`
    min-height: 60vh;
    `}
    ${Screen.surfaceDuo`
    padding: 0;
    min-height: 60vh;
    `}
    ${Screen.mediumPhone`
    padding: 0;
    margin-top: 3rem;
    min-height: 60vh;
    `}
    ${Screen.phone`
    padding: 0;
    margin-top: 3rem;
    min-height: 70vh;
    `}
    ${Screen.smallPhone`
    padding: 0;
    margin-top: 3rem;
    min-height: 70vh;
    `}
  }
`;
const Header = styled.header`
  font-size: 3rem;
  color: #002668;
  font-weight: bold;
  padding-bottom: 1.5rem;
  padding-top: ${({ pt }) => pt};
  ${Screen.mediumPhone`
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  `}
  ${Screen.phone`
  text-align: center;
  font-size: 22px
  `}
  ${Screen.smallPhone`
  text-align: center;
  font-size: 22px
  `}
  ${Screen.galaxyFold`
  text-align: center;
  font-size: 20px
  `}
`;
const SubHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #c81e19;
    padding-right: 1rem;
    ${Screen.mediumPhone`
    font-size: 16px;
    padding-right: 0
    `}
    ${Screen.phone`
    font-size: 14px;
    padding-right: 0
    `}
    ${Screen.smallPhone`
    font-size: 14px;
    padding-right: 0
    `}
  }
`;
const Content = styled.div`
  &.glass {
    padding: 3rem;
    h2 {
      font-size: 45px;
      color: #002668;
      text-shadow: 5px 3px 5px #666;
      text-transform: uppercase;
      ${Screen.smallpc`
      padding-left: 5rem;
      padding-top: 12rem;
      font-size: 35px;
      `}
      ${Screen.tablet`
      padding-left: 5rem;
      padding-top: 4rem;
      font-size: 35px;
      `}
      ${Screen.surfaceDuo`
      padding-left: 4rem;
      padding-top: 1rem;
      font-size: 35px;
      font-size: 24px;
      `}
      ${Screen.mediumPhone`
      font-size: 24px;
      padding-left: 3rem;
      padding-top: 0;
      `}
      ${Screen.phone`
      font-size: 24px;
      padding-left: 2rem;
      padding-top: 0;
      `}
      ${Screen.smallPhone`
      font-size: 24px;
      padding: 0;
      `}
      ${Screen.galaxyFold`
      font-size: 20px;
      `}
    }
  }
  p {
    padding-bottom: 2rem;
    font-size: 1.05rem;
    word-spacing: 0.1rem;
    ${Screen.mediumPhone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}
    ${Screen.phone`
    font-size: 14px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}
  ${Screen.smallPhone`
    font-size: 13px!important;
    padding-bottom: 1rem!important;
    word-spacing: 0;
    `}

&:last-child {
      padding-bottom: 2rem;
    }
  }

  b {
    ${Screen.mediumPhone`
    font-size: 13px!important
    `}
    ${Screen.phone`
    font-size: 13px!important
    `}
    ${Screen.smallPhone`
    font-size: 13px!important
    `}
  }
`;
const List = styled.ul`
  list-style-type: none;
`;
const ListItem = styled.li`
  padding-top: 0.5rem;
  ${Screen.mediumPhone`
  font-size: 14px!important
  `}
  ${Screen.phone`
  font-size: 14px!important
  `}
  ${Screen.smallPhone`
  font-size: 13px!important
  `}
  &:last-child {
    padding-bottom: 2rem
  }
`;
export default Terms;
