import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Screen } from '../../styles';

const ImageGallery = ({ state }) => {
  const [mobile1, setMobile1] = useState(false)
  const [mobile2, setMobile2] = useState(false)
  const [mobile3, setMobile3] = useState(false)
  
  const responsive = () => {
    return window.innerWidth <= 600 ? setMobile1(true) : setMobile1(false)
  }
  const tab = () => {
    return window.innerWidth <= 900 ? setMobile2(true) : setMobile2(false)
  }
  const smallScreen = () => {
    return window.innerWidth <= 300 ? setMobile3(true) : setMobile3(false)
  }
  useEffect(() => {
    responsive()
    tab()
    window.addEventListener('resize', () => responsive())
    window.addEventListener('resize', () => tab())
    window.addEventListener('resize', () => smallScreen())

    return () => {
      window.removeEventListener('resize', () => responsive())
      window.removeEventListener('resize', () => tab())
      window.removeEventListener('resize', () => smallScreen())
    }
  }, [])

  const patch = state.length && state.map(item => {
    return {
      src: item
    }
  })

  const slides2 = patch.length && patch.map((item, { height, width}) => ({
    src: item.src
  }));

  const [index, setIndex] = useState(-1)


    const handleClick = (index) => setIndex(index);
  return (
    <Box>
      <Gallery
        images={patch}
        onClick={handleClick}
        enableImageSelection={false}
        maxRows={mobile1 ? 10 : 20}
        rowHeight={mobile3 ? 100: mobile1 ? 110 : mobile2 ? 130 : 180}
      />
      <Lightbox
        slides={slides2}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </Box>
  )
}

export default ImageGallery

const Box = styled.div`
width: 100%;
height: 500px;
margin-top: 3rem;

${Screen.mediumPhone`
margin-top: 2rem
`}
${Screen.phone`
margin-top: 1rem
`}
${Screen.samsungGalaxy`
margin-top: 1.5rem
`}
${Screen.smallPhone`
margin-top: 1rem
`}
`;
